import { SceneComponent, ComponentInteractionType } from "../../SubSystems/sceneManagement/SceneComponent";
import * as THREE from "three"
import Utils from "../../Tools/Utils";
import { UserDataProperties } from "../../SubSystems/ui-interop/PropertiesPanel";
import Simulation from "../../SubSystems/core/Simulation";
import { Object3D } from "three";
import { store } from "App";
import { onSetVariableValue } from "redux/actions";

interface Inputs {
    toggle: boolean;
}

export class ToggleComponent extends SceneComponent {

    inputs: Inputs = {
        toggle: false
    };

    //private combinedObject3D:Object3D;

    private initialized: boolean;
    private localEphemeralIndex: number = -1;
    private localTargetMesh: Object3D;

    onInit() {
        /*
        // for (const component of this.context.root.componentIterator()) {
            if (component.componentType === 'mp.gltfLoader') {
                let gltfInstance = (component as any);
                gltfInstance.visible = false;

            }
        }*/
        //this.updateComponentsBasedOnToggleValue();

        this.outputs.collider = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);
        (this.getCompFromRoot(2) as any).ignoreInMeshFindPopulation = true;

        this.initialized = false;
    }

    updateComponentsBasedOnToggleValue(): void {
        console.log(`toggle is ${this.inputs.toggle}`);

        if (this.localEphemeralIndex == -1) {
            //let tempMesh = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);
            if (this.localTargetMesh) {
                let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(this.localTargetMesh);
                this.localEphemeralIndex = Simulation.instance.outlineComponentColor2?.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
            }
        } else {
            Simulation.instance.outlineComponentColor2?.removeEphemeralMeshes(this.localEphemeralIndex);
            this.localEphemeralIndex = -1;
        }

        if (this.inputs.toggle) {
            this.localTargetMesh = ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D);
            ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D).visible = true;
            ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D).scale.set(1, 1, 1);
            ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D).visible = false;
            ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D).scale.set(0, 0, 0);
        } else {
            this.localTargetMesh = ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D);
            ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D).visible = false;
            ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D).scale.set(0, 0, 0);
            ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D).visible = true;
            ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D).scale.set(1, 1, 1);

        }



        if (this.localEphemeralIndex == -1) {
            //let tempMesh = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);

        } else {
            Simulation.instance.outlineComponentColor2?.removeEphemeralMeshes(this.localEphemeralIndex);
            this.localEphemeralIndex = -1;

            if (this.localTargetMesh) {
                let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(this.localTargetMesh)
                this.localEphemeralIndex = Simulation.instance.outlineComponentColor2?.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
            }
        }
    }

    setNextInputValue() {
        (this.inputs!.toggle = !this.inputs!.toggle);
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
        if (interactionType === ComponentInteractionType.CLICK) {
            this.notify(ComponentInteractionType.CLICK, {
                type: interactionType,
                node: this.context,
                component: this,
            });

            //   (this.inputs!.toggle = !this.inputs!.toggle);


            // if(this.context.root.userData && UserDataProperties.inputSource1 in this.context.root.userData) {
            //     if(this.context.root.userData[UserDataProperties.inputSource1]) {
            //         let varSearch = Simulation.instance.Variables?.find(v => v.name === this.context.root.userData[UserDataProperties.inputSource1]);

            //         if(varSearch) {
            //             let allowedValuesArray = (varSearch.values as string).split(",").map(x => x.trim());

            //             let newValue = '';
            //             if(this.inputs.toggle) {
            //                 newValue = allowedValuesArray[0];
            //             } else {
            //                 newValue = allowedValuesArray[1];
            //             }

            //             store.dispatch(onSetVariableValue(this.context.root.userData[UserDataProperties.inputSource1], newValue));
            //         }

            //     }
            // }

        }
        if (interactionType === ComponentInteractionType.HOVER) {
            this.notify(ComponentInteractionType.HOVER, {
                hover: (<{ hover: boolean }>eventData).hover,
            });

            if (this.localEphemeralIndex == -1) {
                //let tempMesh = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);
                if (this.localTargetMesh) {
                    let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(this.localTargetMesh)
                    this.localEphemeralIndex = Simulation.instance.outlineComponentColor2?.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
                }
            } else {
                Simulation.instance.outlineComponentColor2?.removeEphemeralMeshes(this.localEphemeralIndex);
                this.localEphemeralIndex = -1;
            }
        }
    }

    onInputsUpdated(oldInputs: Inputs) {

        if (oldInputs.toggle !== this.inputs.toggle) {
            // console.log((this.getCompFromRoot(0) as any));
            //this.context.scene.remove((this.getCompFromRoot(0) as any).pivot)

            this.updateComponentsBasedOnToggleValue();

        }
    }

    onTick(delta: number) {
        super.onTick(delta);
        if (!this.initialized) {
            this.initializeToggleComponent();
        }
    }

    initializeToggleComponent() {
        let clickableObject = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);

        if (clickableObject.children.length > 0) {
            let localTargetMesh = ((this.getCompFromRoot(2) as any).pivot as THREE.Object3D);
            /*
            if(this.inputs.toggle) {
                localTargetMesh = ((this.getCompFromRoot(0) as any).pivot as THREE.Object3D);
            } else {
                localTargetMesh = ((this.getCompFromRoot(1) as any).pivot as THREE.Object3D);
            }*/

            let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(localTargetMesh);
            Utils.SetMeshesVisibility(meshes, false);
            this.initialized = true;
            this.updateComponentsBasedOnToggleValue();
        }
    }
}

export const toggleComponentType = 'mp.ToggleComponent';
export const makeToggleComponent = function () {
    return new ToggleComponent();
};
