import { store } from "App";
import _ from "lodash";
import { SDKInstance } from "modules/fiber/Sdk";
import PropertiesPanel from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import { ANNOTATION_TYPE, ShowcaseTag, SweepData, SweepTransition } from "types/models/home/HomeApp";

let _currentTagsIdToSidMap: Map<string, string>;
let _currentTagSidToIdMap: Map<string, string>;

export function resetSpaceMeta() {
  setCurrentTagsSidMapGlobal(new Map());
  setAddAnnotationType(ANNOTATION_TYPE.INFO);
  setExternalTags([]);
  externalTagsWritten = false;
  setTagSidBeingAdded('');
  setCurrentZoom(null);
  setIsPlacingTag(false);
  setCurrentTagsSidMapGlobal(new Map());
}

export const addToCurrentTagsSidMapGlobal = (showcaseTagId: string, tagSid: string) => {
  (_currentTagsIdToSidMap || new Map()).set(showcaseTagId, tagSid);
  (_currentTagSidToIdMap || new Map()).set(tagSid, showcaseTagId);
}
export const setCurrentTagsSidMapGlobal = (tags: Map<string, string>) => {
  _currentTagsIdToSidMap = tags;
  setTagSidToIdMap();
}

export const getTagSidForTagId = (tagId: string) => {
  return _currentTagsIdToSidMap && _currentTagsIdToSidMap.get(tagId)
}

// export const tagsAlreadyImported = () => {
//     if (Array.from(store.getState().home.spaceTags.values()).find((x) => x.data?.source == 'MP')) {
//         return true;
//     }
//     return false;
// }


export let isRepositioning: boolean;
export const setIsRepositioning = (value: boolean) => {
  isRepositioning = value;
}


export let isPlacingTag: boolean;
export const setIsPlacingTag = (value: boolean) => {
  isPlacingTag = value;
}

// export let currentSweep: any;
// export let currentRotation: any;
// export const setCurrentSweep = (pose: any) => {
//     currentSweep = pose.sweep;
//     currentRotation = pose.rotation;
// }


export let currentZoom: any;
export const setCurrentZoom = (zoom: any) => {
  currentZoom = zoom;
}

const setTagSidToIdMap = () => {

  _currentTagSidToIdMap = Array.from(_currentTagsIdToSidMap.entries()).reduce(function (map, obj) {
    map.set(obj[1], obj[0]);
    return map;
  }, new Map<string, string>());

}

export const getTagIdForTagSid = (tagSid: string) => {
  let tagId = _currentTagSidToIdMap.get(tagSid)
  return tagId;
}

export let tagSidBeingAdded: any = '';
export const setTagSidBeingAdded = (value: any) => {
  tagSidBeingAdded = value
};

export let externalTags: any[] = [];
export const setExternalTags = (tags: any[]) => {
  if (!externalTagsWritten) {
    externalTags = tags;
    externalTagsWritten = true;
  }
};
export let externalTagsWritten = false;

export let spaceViewDispatchHook: any = null;
export const setSpaceViewDispatchHook = (dispatch: any) => {
  spaceViewDispatchHook = dispatch;
  PropertiesPanel.getDispatch = dispatch;
}

// export let rawRichText: string = '';
// export const setRawRichText = (text: string) => rawRichText = text;

export let addAnnotationType: ANNOTATION_TYPE = ANNOTATION_TYPE.INFO;
export const setAddAnnotationType = (annoType: ANNOTATION_TYPE) => addAnnotationType = annoType;



export let tagHTMLMap: any = {};
export const addToTagHTMLMap = (tagId: string, htmlString: string) => tagHTMLMap[tagId] = htmlString;

export function logAllMpTags() {
  SDKInstance.sdk.Mattertag.getData().then((allTags: any[]) => {
    console.log(`[st] [tags] all tags: ${allTags.map(
      (x: any) => `${x.sid} -- ${x.label} -- ${JSON.stringify(x.color)}`,
    )}`)
  })

}

export function getLabelIdsForTag(showcaseTag: ShowcaseTag): any[] {
  let labelIds: any[] = [];
  if (showcaseTag.annotationType != ANNOTATION_TYPE.TASK) {
    labelIds = showcaseTag.labelIds ? showcaseTag.labelIds : [];
  } else {
    labelIds = showcaseTag.taskTag && showcaseTag.taskTag.label ? showcaseTag.taskTag.label.map((l) => (l.id)) as Array<number> : [];
  }
  return labelIds;
}