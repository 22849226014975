import {
  AnimationMixer,
  Group,
  LineSegments,
  Mesh,
  Object3D,
  //@ts-ignore
} from 'three';
//@ts-ignore
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
//@ts-ignore
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader';
//@ts-ignore
import * as THREE from 'three';

import { generateUUID } from 'shared/helpers/helper';
import { SceneComponent, ComponentInteractionType } from '../../SubSystems/sceneManagement/SceneComponent';

interface Inputs {
  size: {x: number; y: number; z: number};
  position: {x: number; y: number; z: number};
  visible: boolean;
  transitionTime: number;
  scale: {x: number; y: number; z: number};
  rotation: {x: number; y: number; z: number};
  objUrl: string;
  materialUrl: string;
}

export class CustomObjLoader extends SceneComponent {
  private root: Object3D | null = null;
  private obj: Group | null = null;
  public id: string;

  inputs: Inputs = {
    size: {x: 1, y: 1, z: 1},
    visible: true,
    position: { x: 0, y: 0, z: 0},
    rotation: { x: 0, y: 0, z: 0},
    scale: { x: 0, y: 0, z: 0},
    objUrl: '',
    materialUrl: '',
    transitionTime: 300
  };

  events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
    [ComponentInteractionType.DRAG]: true,
    [ComponentInteractionType.DRAG_BEGIN]: true,
    [ComponentInteractionType.DRAG_END]: true,
  };

  onInit() {
    // @ts-ignore
    const THREE = this.context.three;
    this.root = new THREE.Object3D();
    // @ts-ignore
    this.outputs.objectRoot = this.root;
    // @ts-ignore
    this.outputs.collider = this.root;

    this.makeModel();
    this.id = generateUUID();
  }

  onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
    if (interactionType === ComponentInteractionType.CLICK) {
      let _tmp_webstorm_ = this.context;
      // @ts-ignore
      const {root} = this.context;
      this.notify(ComponentInteractionType.CLICK, {
        type: interactionType,
        node: root,
        component: this,
      });
    }
    if (interactionType === ComponentInteractionType.HOVER) {
      this.notify(ComponentInteractionType.HOVER, {
        hover: (<{hover: boolean}>eventData).hover,
      });
    }
    if (interactionType === ComponentInteractionType.DRAG_BEGIN) {
      // @ts-ignore
      const { root } = this.context;
      this.notify(ComponentInteractionType.DRAG_BEGIN, {
        event: eventData,
        component: this,
        node: root,
        interactionType: interactionType
      });
    }
    if (interactionType === ComponentInteractionType.DRAG_END) {
      // @ts-ignore
      const { root } = this.context;
      this.notify(ComponentInteractionType.DRAG_END, {
        event: eventData,
        component: this,
        node: root,
        interactionType: interactionType
      });
    }
    if (interactionType === ComponentInteractionType.DRAG) {
      // @ts-ignore
      const { root } = this.context;
      this.notify(ComponentInteractionType.DRAG, {
        event: eventData,
        component: this,
        node: root,
        interactionType: interactionType
      });
    }
  }

  makeModel() {
    // @ts-ignore
    const THREE = this.context.three;

    //const manager = new THREE.LoadingManager();
    const obj = this.inputs.objUrl;
    const mtl = this.inputs.materialUrl;

    var mtlLoader = new MTLLoader(/*manager*/);
    mtlLoader.crossOrigin = '';

    mtlLoader
      .load(mtl, (materials: any) => {

        materials.preload();
        //materials.Material.side = THREE.DoubleSide;
        var objLoader = new OBJLoader(/*manager*/);
        objLoader.setMaterials(materials);
        objLoader.crossOrigin = '';
        objLoader
          .load(obj, (object: any) => {
              let mesh = object;
              mesh.position.set(this.inputs.position.x, this.inputs.position.y, this.inputs.position.z);
              mesh.scale.set(this.inputs.scale.x, this.inputs.scale.y, this.inputs.scale.z);
              mesh.rotation.set(THREE.MathUtils.DEG2RAD * this.inputs.rotation.x, THREE.MathUtils.DEG2RAD * this.inputs.rotation.y, THREE.MathUtils.DEG2RAD *this.inputs.rotation.z);
              this.root!.add(mesh);
              this.obj = mesh;
            },
            (xhr: any) => {


            },
            // called when loading has errors
            (error: any) => {

            }
          );
    });
  }

  onInputsUpdated(oldInputs: Inputs) {
    // @ts-ignore

    if (
      oldInputs.size.x !== this.inputs.size.x ||
      oldInputs.size.y !== this.inputs.size.y ||
      oldInputs.size.z !== this.inputs.size.z
    ) {

    }

    if(this.obj) {
      (this.obj as Group).position.set(this.inputs.position.x, this.inputs.position.y, this.inputs.position.z);
      (this.obj as Group).scale.set(this.inputs.scale.x, this.inputs.scale.y, this.inputs.scale.z);
      (this.obj as Group).rotation.set(this.inputs.rotation.x, this.inputs.rotation.y, this.inputs.rotation.z);
    }
  }
}

export const customObjType = 'mp.customObjLoader';
export const makeCustomObjLoader = function () {
  return new CustomObjLoader();
};
