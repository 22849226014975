import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess, onSignOutFirebaseUser } from '../../../redux/actions';
import { useAuthUser } from '../../../@crema/utility/AppHooks';
import AppContext from '../../../@crema/utility/AppContext';
import clsx from 'clsx';
import { Button, Dialog, DialogContent, DialogTitle, makeStyles, Select, TextField, Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import { blueGrey, orange } from '@material-ui/core/colors';
import { Fonts, ThemeMode } from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType, {
  CremaTheme,
} from '../../../types/AppContextPropsType';
// import {AuthUser} from '../../../types/models/AuthUser';
import { useHistory } from 'react-router-dom';
import { AuthUser } from '../../../types/models/AuthUser';
import { AppState } from 'redux/store';
import { auth } from '@crema/services/auth/firebase/firebase';
import Api from '@crema/services/ApiConfig';
import ApiConfig from '@crema/services/ApiConfig';
import { appIntl } from '@crema/utility/Utils';
import { getUserAvatar } from "@crema/utility/Utils";
import { Stack } from '@mui/material';
import { Release, ReleaseItem, RELEASE_LEVEL } from 'types/models/Releases';
import { ReleaseNotesEditor } from '../ReleaseNotesEditor';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    crHeaderUser: {
      backgroundColor: (props: { header: boolean; themeMode: ThemeMode }) =>
        props.header ? 'transparent' : 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 18,
      backgroundColor: blueGrey[700],
    },
    userInfo: {
      width: (props: { header: boolean; themeMode: ThemeMode }) =>
        !props.header ? 'calc(100% - 75px)' : '100%',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props: { header: boolean; themeMode: ThemeMode }) =>
        props.themeMode === ThemeMode.DARK || !props.header
          ? 'white'
          : '#313541',
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

interface HeaderUserProps {
  header?: boolean;
}

const HeaderUser: React.FC<HeaderUserProps> = ({ header = true }) => {
  const history = useHistory();
  const { themeMode } = useContext<AppContextPropsType>(AppContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
  const { messages } = appIntl();
  const [showReleaseNotesEditor, setShowReleaseNotesEditor] = React.useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    // console.log(`[st] event.currentTarget ${event.currentTarget}`);

  };

  const handleClose = () => {
    // console.log(`[st] event.currentTarget close`);
    setAnchorEl(null);
  };

  const getUserAvatarView = () => {
    if (authUser && authUser.photoURL) {
      return <Tooltip title={authUser?.email || ""}><Avatar className={classes.profilePic} src={authUser.photoURL} /></Tooltip>;
    } else {
      return <Tooltip title={authUser?.email || ""}><Avatar className={classes.profilePic}>{getUserAvatar(authUser)}</Avatar></Tooltip>;
      // return `Hello ${(authUser && authUser.user ? authUser.user.firstName : '')}`

    }
  };

  const classes = useStyles({ themeMode, header });

  //FLOW currently used
  return (<>
    {showReleaseNotesEditor && <ReleaseNotesEditor setShowReleaseNotesEditor={setShowReleaseNotesEditor} showReleaseNotesEditor={showReleaseNotesEditor} />}
    <Box
      px={{ xs: 2, xl: 6 }}
      // style={{cursor: 'pointer'}} onClick={handleClick}
      className={clsx(classes.crHeaderUser, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        {/* @ts-ignore */}
        <Hidden mdDown> {getUserAvatarView()}</Hidden>
        <Box className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            {/* @ts-ignore */}
            <Hidden mdDown>
              <Box ml={4} mb={1.5} className={classes.userName}>
                {authUser && (authUser.user?.firstName || authUser.email)}
                <Box
                  fontSize={13}
                  fontWeight={Fonts.LIGHT}
                  color='text.secondary'></Box>
              </Box>
            </Hidden>
            <Box
              ml={{ md: 3 }}
              className={classes.pointer}
              color={
                themeMode === ThemeMode.DARK || !header ? 'white' : '#313541'
              }>
              {/* @ts-ignore */}
              <Hidden mdDown>
                <ExpandMoreIcon onClick={handleClick} />
              </Hidden>
              {/* @ts-ignore */}
              <Hidden lgUp>
                <Box component='span' onClick={handleClick}>
                  {getUserAvatarView()}
                </Box>
              </Hidden>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >

                {authUser?.user.email && <MenuItem
                  divider
                  component='p'
                  style={{ cursor: 'text', color: 'grey' }}
                >
                  Logged in as {authUser?.user.email}
                </MenuItem>}
                <MenuItem
                  onClick={() => {
                    // console.log('clicked');
                    history.push('/myaccount');
                    handleClose();
                  }}>
                  My account
                </MenuItem>
                <MenuItem onClick={() => dispatch(onSignOutFirebaseUser())}>
                  Logout
                </MenuItem>
                {authUser && authUser.user.permissions && authUser.user.permissions.includes('ADMIN') && <MenuItem onClick={async () => {
                  let email = (await navigator.clipboard.readText()).trim();
                  let res = window.confirm(`Impersonate user: ${email} ? (If not, simply copy the email via Cmd or Ctrl C)`);

                  if (res) {
                    dispatch(fetchStart());
                    ApiConfig.get(`/admin/customToken/`, { params: { userEmail: email } })
                      .then(data => {
                        if (data.status === 200) {
                          dispatch(fetchSuccess());
                          // { console.log(`[st] current space ${JSON.stringify(data.data)}`); }
                          // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                          auth.signInWithCustomToken(data.data).then(() => history.push('/'))
                        } else {
                          dispatch(
                            fetchError(messages['message.somethingWentWrong'] as string),
                          );
                        }
                      })
                      .catch(error => {
                        dispatch(fetchError(error.message));
                      });
                  }

                }}>
                  Impersonate
                </MenuItem>}
                {authUser && authUser.user.permissions && authUser.user.permissions.includes('ADMIN') && <MenuItem onClick={async () => {

                  setShowReleaseNotesEditor(true);

                }}>
                  Create Release Notes
                </MenuItem>}
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </>);
};
export default HeaderUser;
HeaderUser.defaultProps = {
  header: true,
};
