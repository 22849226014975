// import { SET_SPACES, GET_SPACES } from 'types/actions/Reports.action';
import { SET_SPACES, SET_STEP_SESSION_DATA, SET_USER_SESSION } from 'types/actions/Reports.action';
import { UserSessionModel } from 'types/models/Reports';
import { AppActions } from '../../types';

export type StepSessionData = { stepNum: number, stepId: string, [y: string]: number | string };

export type GraphDataType = { [x: string]: StepSessionData[] };
const initialState: {
    spaces: any[];
    loading: boolean;
    userSession: UserSessionModel | undefined;
    ssd: GraphDataType
} = {
    spaces: [],
    loading: true,
    userSession: undefined,
    ssd: {}
};

const Reports = (state = initialState, action: AppActions) => {
    switch (action.type) {
        // case GET_SPACES:
        //     return {
        //         spaces: state.spaces,
        //     };
        case SET_SPACES:
            return {
                ...state,
                spaces: action.payload,
                loading: false
            };
        case SET_USER_SESSION:
            return {
                ...state,
                userSession: action.payload
            }

        case SET_STEP_SESSION_DATA:
            return {
                ...state,
                ssd: action.payload
            }
        default:
            return state;
    }
};
export default Reports;