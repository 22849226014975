import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import * as Common from './Common';
import * as HomeAction from 'types/actions/Home.action';
import * as HomeApp from 'types/models/home/HomeApp';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { AuthUser } from 'types/models/AuthUser';
import { appIntl, BasicProfiler } from '@crema/utility/Utils';
import Api from '@crema/services/ApiConfig';
import { SpaceErrors } from 'types/models/dataAccess/Space';
import { store } from 'App';
import { getMetadata } from 'types/models/Model';
import NotificationService from '@crema/services/NotificationService';
import { baseUrl } from 'Config';
import { CollaboratorObj, LabelObj, ProjectObj } from 'types/models/apps/ProjectBoard';
import { userRoles } from '@crema';
import { onGetLessonsList, onStopLesson } from './Layer';
import { ThreeDModel } from "types/models/dataAccess/User";
import _ from 'lodash';
import { SHOW_PROP_PANEL } from 'types/actions/ThreeD.actions';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import { history } from 'redux/store';
import Utils from 'mp/core/craEngine/Tools/Utils';
import { GET_PROJECT_DETAIL } from 'types/actions/Projectboard.actions';
import { getDefaultMatterportColorObj, hexToMatterportColorObj, initialUrl } from 'shared/constants/AppConst';
import { getProjectLabel } from 'modules/home/SpaceDetail/utils';
import { getTagSidForTagId } from 'modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { SDKInstance } from 'modules/fiber/Sdk';

export const onGetSpace = ({ id, did, pDid }: { did: string, id?: string, pDid?: string }, onFailure: (err: any) => void): any => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {
    try {
      dispatch(Common.fetchStartNonBlocking(messages['space.loading']));
      let q = !!id ? firestore.collection(`Spaces`).where('id', '==', id) : firestore.collection(`Spaces`).where('did', '==', did);

      q.withConverter(HomeApp.SpaceData.spaceConverter).get().then(qs => {
        let s = qs.docs.map(doc => doc.data())[0];

        // let searchParams = new URLSearchParams(window.location.search);
        // let newSearchParams = new URLSearchParams();

        // let viewIn = searchParams.get('viewIn');
        // !!viewIn && newSearchParams.set('viewIn', viewIn);

        if (!s) {
          // dispatch(fetchError(messages['space.notFound']));
          dispatch(Common.showAlert({
            open: true, title: messages['space.notFound'],
            dialogTitle: 'Not found',
            cancelNotAllowed: true,
            yesLabel: 'Go back home',
            onConfirm: () => {
              // history.push({ pathname: initialUrl, search: newSearchParams.toString() });
              history.push({ pathname: initialUrl });
              dispatch(Common.hideAlert());
            },
            onDeny: () => {
              // history.push({ pathname: initialUrl, search: newSearchParams.toString() });
              history.push({ pathname: initialUrl });
              dispatch(Common.hideAlert());
            },
          }));

        } else {

          console.log(`[vars] getting space with params: ${id} ${did} ${pDid}`);
          serializeSpace({ space: s, spaceMemberships: [], projects: [], pDid: pDid || '' }).then(space => {
            dispatch({ type: HomeAction.SET_SPACE_DATA, payload: space });
            dispatch(Common.fetchSuccess());
          })
        }
      })
    }
    catch (error) {

      console.log('error', error);
      onFailure(error);

      // history && history.goBack();

      dispatch(Common.fetchError('Unable to load space - please try again'));
    };
  };
}


// export const onGetSpace = (params: { did: string, id?: string, pDid?: string }, onFailure: (err: any) => void): any => {
//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch(fetchStart());

//     Api.get(`/api/spaces/${params.did}`, { params })
//       .then(data => {
//         if (data.status === 200) {
//           let payload = data.data;
//           if (!!payload.code) {
//             dispatch(fetchError(SpaceErrors.get(payload.code)));
//             setTimeout(() => {
//               dispatch(fetchError("")); //clear error message after a few seconds, so next error can be displayed
//             }
//               , 3000);
//           } else {
//             dispatch(fetchSuccess());
//             dispatch({ type: SET_SPACE_DATA, payload: data.data });
//           }

//         } else {
//           console.log(JSON.stringify(data))
//           dispatch(fetchError('Space not found'));
//         }
//       })
//       .catch(error => {

//         console.log(JSON.stringify(error));
//         onFailure(error);

//         // history && history.goBack();

//         dispatch(fetchError('Unable to load space - please try again'));
//       });
//   };
// }

export const setCurrentSpace = (space: HomeApp.SpaceData): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_SPACE_DATA, payload: space });
  };
};

export const serializeSpace = async ({ space, spaceMemberships, projects, pDid }:
  { space: HomeApp.SpaceData, spaceMemberships: HomeApp.SpaceMembership[], projects: any[], pDid: string }) => {
  let memberships = spaceMemberships;
  if (!memberships || memberships.length == 0) {
    let qs = await firestore.collection(`SpaceMemberships`).where("spaceId", '==', space.id).get();

    spaceMemberships = qs.docs.map(doc => doc.data() as HomeApp.SpaceMembership);
  }
  let authUser = store.getState().auth.authUser;
  let sm = spaceMemberships.find(sm => sm.spaceId == space.id && sm.userId == authUser?.uid);
  if (sm) {
    space.spaceMembership = sm;
    let ps = projects;
    if (!ps || ps.length == 0) {
      console.log(`[st] [spaces] getting projects: ${pDid}`);
      ps = (await firestore.collection(`Projects`).where("spaceId", '==', space.id).get())
        .docs.map(doc => ({ ...doc.data(), id: doc.id }));
      console.log(`[st] [spaces] got projects: ${ps.map(p => p.did)}`);
    }

    space.spaceProjects = ps.filter(p => p.spaceId == space.id);
    console.log(`[st] [spaces]] serializing space pDid: ${pDid}`);

    if (!!pDid) {
      space.currentSpaceProject = ps.find(p => p.did == pDid);
      store.dispatch({ type: GET_PROJECT_DETAIL, payload: space.currentSpaceProject });
      console.log(`[vars] serializing space project: ${space.currentSpaceProject}`);
    }
  }

  return space;
}

export const deleteTag = (
  id: string,
  tagCollectionPath: string,
  tagCollectionName: string,
  sceneTagCollectionPath: string,
  sceneTagCollectionName: string,
): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    try {
      const deleteTagRef: any = await firestore
        .doc(tagCollectionPath)
        .collection(tagCollectionName)
        .doc(id);

      // const { id: deleteTagRefId } = deleteTagRef;
      return deleteTagRef.delete();
    } catch (error: any) {
      console.log('Error delete tag: ', error?.message);
      return error;
    }
  };
};

// export const addToSpaceTags = (showcaseTag: ShowcaseTag): any => {

//   return (dispatch: Dispatch<AppActions>) => {
//     console.log('2 ' + new Date().valueOf() / 1000);
//     let tagMap = store.getState().home.spaceTags || new Map();
//     tagMap.set(showcaseTag.id, showcaseTag);
//     console.log('3 ' + new Date().valueOf() / 1000);
//     dispatch(setSpaceTagsList(tagMap));

//   }
//   // spaceTags = new Map(
//   //   Array.from(spaceTags).sort(
//   //     (a: [string, ShowcaseTag], b: [string, ShowcaseTag]) =>
//   //       (new Date(b[1].createdOn || '').getTime() || -Infinity) -
//   //       (new Date(a[1].createdOn || '').getTime() || -Infinity),
//   //   ),
//   // );
//   // return (dispatch: Dispatch<AppActions>) => {
//   //   dispatch({ type: SET_SPACE_TAGS_LIST, payload: spaceTags });
//   // };
// };

export const refreshTagIcons = (): any => {
  Object.values(store.getState().home.spaceTags).map((tag) => {
    // updateTagIcon(tag);
  })
}

export const updateTagIcon = (tag: HomeApp.ShowcaseTag): any => {

  let colorHex = tag.labelIds?.map(lid => getProjectLabel(lid))?.find(label => !!label?.color)?.color || '';
  // if(tag.annotationType == HomeApp.ANNOTATION_TYPE.TASK && (!tag.labelIds || tag.labelIds.length == 0) ){
  //   colorHex = (tag?.taskTag as any)?.labelIds?.map((lid: any) => getProjectLabel(lid))?.find((label: LabelObj) => !!label?.color)?.color || '';
  // }
  if (!_.isEqual(tag.data.color, hexToMatterportColorObj(colorHex))) {
    let c = hexToMatterportColorObj(colorHex);
    // console.log(`[tags] tag label changed. Chaging color from ${JSON.stringify(tag.data.color)} to ${JSON.stringify(c)} for tag ${tag.data.label}`);
    let tagSid = getTagSidForTagId(tag.id);
    !tagSid && console.error('couldnt find tagSid for tagid', tag.id);
    tagSid && SDKInstance.sdk.Mattertag.editColor(tagSid, { r: c["r"], g: c["g"], b: c["b"] })
  }
}

export const updateTag = (tag: HomeApp.ShowcaseTag): any => {

  return (dispatch: Dispatch<AppActions>) => {

    // console.log(`[tags] tag color is ${JSON.stringify(tag.data.color)} for tag ${tag.data.label}`);
    // if (!tag.labelIds || tag.labelIds.length === 0) {
    //   //see if label was removed.
    //   SDKInstance.sdk.Mattertag.editColor(getTagSidForTagId(tag.id), getDefaultMatterportColorObj())
    // } else {
    //   //see if first label changed
    //   updateTagIcon(tag);
    // }

    dispatch({ type: HomeAction.UPDATE_TAG, payload: tag });
  };
}

export const setSpaceTagsList = (spaceTags: ShowcaseTagMap): any => {
  // console.log('4 ' + new Date().valueOf() / 1000);

  // console.log('5 ' + new Date().valueOf() / 1000);
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_SPACE_TAGS_LIST, payload: spaceTags });
    // console.log('6 ' + new Date().valueOf() / 1000);
  };
};

export const setCurrentShowcaseTags = (currentShowcaseTags: HomeApp.ShowcaseTag[]): any => {
  currentShowcaseTags.sort(
    (a: HomeApp.ShowcaseTag, b: HomeApp.ShowcaseTag) =>
      (new Date(b.createdOn || '').getTime() || -Infinity) -
      (new Date(a.createdOn || '').getTime() || -Infinity),
  );
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_CURRENT_SHOWCASE_TAGS, payload: currentShowcaseTags });
  };
};

export const setSpaceModelsList = (spaceModels: Map<string, any>): any => {
  return (dispatch: Dispatch<AppActions>) => {
    console.log(`[nodes] dispatching ${Array.from(spaceModels.keys()).length})`);
    dispatch({ type: HomeAction.SET_SPACE_MODELS_LIST, payload: spaceModels });
    // dispatch({type: SET_SPACE_TAGS_LIST, payload: {}});
  };
};


export const setSpaceModel = (id: string, node: any): any => {
  return (dispatch: Dispatch<AppActions>) => {
    // console.log(`[nodes] dispatching ${Array.from(spaceModels.keys()).length})`);
    let map = new Map(store.getState().home.spaceModels);
    map.set(id, node);

    dispatch({ type: HomeAction.SET_SPACE_MODELS_LIST, payload: map });
    // dispatch({type: SET_SPACE_TAGS_LIST, payload: {}});
  };
};


// export const updateSpaceTagsList = (updatedSpaceTags: ShowcaseTagMap, spaceTags: ShowcaseTagMap) => {

//   let tagMap = new Map();
//   spaceTags.forEach(t => tagMap.set(t.id, t));
//   updatedSpaceTags.forEach(t => )

//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch({type: SET_SPACE_TAGS_LIST, payload: spaceTags});
//   };
// }

// export const onSaveVariablesList = (currentSpace: SpaceData, authUser: AuthUser | null, toSaveVariableList: any[]) => {

//   return (dispatch: Dispatch<AppActions>) => {
//     if (authUser != null) {
//       dispatch(fetchStart());

//       firestore.doc(`Spaces/${currentSpace.id}`).update({ variables: toSaveVariableList })
//         .then((querySnapshot) => {
//           dispatch({ type: SET_SPACE_DATA, payload: currentSpace })
//           dispatch(fetchSuccess());

//         })
//         .catch((error) => {
//           console.log('Error saving variables: ', error);
//         });
//     } else {
//       dispatch(fetchError('User is not logged in! Please try again'));
//     }
//   };
// };

export const logUserActivity = (action: HomeApp.ACTIONS, object: HomeApp.OBJECT_TYPES, objectId: string = '', metadata: HomeApp.ActivityLogMeta = undefined): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    let userId = store.getState().auth.authUser?.uid;
    const newActivityLogRef = firestore
      .doc(`Users/${userId}`)
      .collection('ActivityLog')
      .doc();

    const { id: activityDocId } = newActivityLogRef;
    let newActivity: HomeApp.IActivityLog = {
      id: activityDocId,
      uid: userId,
      action: action,
      object: object,
      objectId: objectId,
      timestamp: new Date(),
      metadata: metadata
    };
    newActivityLogRef
      .set(newActivity)
      .then(() => {
        return newActivity;
      })
      .catch((error) => {
        console.log(error);
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const setTagsViewModeFromSidebar = (mode: string): any => {
  return (dispatch: Dispatch<AppActions>) => {

    dispatch(onStopLesson());
    history?.push(`/home/space/${store.getState().home.currentSpace?.did}/project/${store.getState().home.currentSpace?.currentSpaceProject.did}`);

    // const history = useHistory();
    // history.push(`/home/space/${currentSpace?.did}/`);
    // useHistoryPush(`/home/space/${store.getState().home.currentSpace?.did}/`);

    dispatch({ type: HomeAction.SET_TAGS_VIEW_MODE, payload: mode });
  };
};

export const setCurrentSidebar = (value: HomeApp.SIDEBAR_INDEX): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SHOW_PROP_PANEL, payload: false });
    store.getState().layer.currentLesson && dispatch(onStopLesson());
    dispatch({ type: HomeAction.SET_CURRENT_SIDEBAR_INDEX, payload: value });
  }

}

export const setTagsViewMode = (mode: string, history?: any): any => {
  return (dispatch: Dispatch<AppActions>) => {
    // if(store.getState().home.tagsViewMode !== ViewMode.LAYERS && store.getState().home.tagsViewModeSecondary !== ViewMode.LAYERS){
    //   dispatch(onStopLesson());
    //   historyHack && historyHack.push(`/home/space/${store.getState().home.currentSpace?.did}/`);
    // }

    // const history = useHistory();
    // history.push(`/home/space/${currentSpace?.did}/`);
    // useHistoryPush(`/home/space/${store.getState().home.currentSpace?.did}/`);

    dispatch({ type: HomeAction.SET_TAGS_VIEW_MODE, payload: mode });
  };
};

export const setWorkflowsSidebarOpen = (isOpen: boolean): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_BROAD_SIDEBAR_OPEN, payload: isOpen });
  };
};

export const setTagsViewModeSecondary = (mode: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_TAGS_VIEW_MODE_SECONDARY, payload: mode });
  };
};

export const setSelectedTag = (tag: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_SELECTED_TAG, payload: tag });
  };
};

// export const setCloseAllPopUp = (mode: boolean) => {
//   return (dispatch: Dispatch<AppActions>) => {
//     dispatch({ type: SET_CLOSE_ALL_POPUPS, payload: mode });
//   };
// };

export const setEditShowcaseTagId = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_EDIT_SHOWCASE_TAG_ID, payload: id });
  };
};

export const setOpenTagForm = (value: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.SET_OPEN_TAG_FORM, payload: value });
  };
};

// export const setTagSidBeingAdded = (value: any) => {

//   // return (dispatch: Dispatch<AppActions>) => {
//   //   dispatch({ type: SET_TAG_SID_BEING_ADDED, payload: value });
//   // };
// };


export const onShareSpace = (form: HomeApp.IShareSpace, space: HomeApp.SpaceData, projectObj: ProjectObj, authUser: AuthUser | null, callback: () => void) => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions | any>) => {
    console.log('sharing w ' + form.email);
    firestore
      .collection('Users')
      .where('email', '==', form.email)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          let recipient = querySnapshot.docs[0].data();
          // let userId = recipient.id;
          let membershipsRef = firestore.collection(`SpaceMemberships`);

          // Check Space Memberships if exist then update role otherwise show user already exist with same role
          firestore
            .collection('SpaceMemberships')
            .where('userId', '==', recipient.id)
            .where('spaceId', '==', space?.id)
            .get()
            .then(async (querySnapshot) => {
              if (querySnapshot.size !== 0) {
                let membership = querySnapshot.docs[0].data();
                let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                project.exists && !((project.data()?.memberIdList || []).includes(recipient.id)) && await firestore.collection(`Projects`).doc(project.id).update({ memberIdList: [...project.data()?.memberIdList, recipient.id] });

                if (membership.role.toUpperCase() !== userRoles.OWNER) {
                  if (form.role.toUpperCase() !== membership.role.toUpperCase()) {
                    await membershipsRef.doc(membership.id).delete();

                    let doc = membershipsRef.doc();
                    await doc.set({
                      userId: recipient.id,
                      spaceId: space?.id,
                      role: form.role.toUpperCase(),
                      ...getMetadata(authUser?.uid || '', doc.id)
                    });

                    dispatch(NotificationService.shareSpaceWithUser(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '', recipient.id,
                      {
                        fromFirstName: authUser ? authUser.user.firstName : '',
                        fromLastName: authUser ? authUser.user.lastName : '',
                        toEmail: form.email,
                        spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                        spaceName: space?.name || ''
                      }));

                    callback();
                    // dispatch(showMessage(`Shared space with ${form.email}`));
                    console.log(`[st] shared space mem id: ${doc.id}`);
                  } else {
                    dispatch(Common.fetchError(`Already shared space with ${form.email} with role ${form.role}`));
                  }
                } else {
                  dispatch(Common.fetchError(`You don't have access to change ${userRoles.OWNER} role!`));
                }
              } else {
                let doc = membershipsRef.doc();
                await doc.set({
                  userId: recipient.id,
                  spaceId: space?.id,
                  role: form.role.toUpperCase(),
                  ...getMetadata(authUser?.uid || '', doc.id)
                });

                let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                project.exists && !((project.data()?.memberIdList || []).includes(recipient.id)) && await firestore.collection(`Projects`).doc(project.id).update({ memberIdList: [...project.data()?.memberIdList, recipient.id] });

                dispatch(NotificationService.shareSpaceWithUser(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '', recipient.id,
                  {
                    fromFirstName: authUser ? authUser.user.firstName : '',
                    fromLastName: authUser ? authUser.user.lastName : '',
                    toEmail: form.email,
                    spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                    spaceName: space?.name || ''
                  }));

                dispatch(Common.showMessage(`Shared space with ${form.email}`));
                console.log(`[st] shared space mem id: ${doc.id}`);
              }
            })
            .catch((error) => {
              console.log(error);
              Common.fetchError(messages['message.somethingWentWrong'] as string);
            });
        } else {
          // if user not exist in User collection
          let invitesRef = firestore.collection(`SpaceInvites`);

          // Check Space Invites if exist then update role otherwise show user already exist with same role
          firestore
            .collection('SpaceInvites')
            .where('userEmail', '==', form.email)
            .where('spaceId', '==', space?.id)
            .get()
            .then(async (querySnapshot) => {
              if (querySnapshot.size !== 0) {
                let invites = querySnapshot.docs[0].data();
                if (form.role.toUpperCase() !== invites.role.toUpperCase()) {
                  let doc = invitesRef.doc(invites.id);
                  await doc.delete();

                  Api.post(`/api/spaces/${space?.did}/invite`, { "email": form.email, "role": form.role })
                    .then(async data => {
                      if (data.status === 200) {
                        dispatch(Common.fetchSuccess());
                        dispatch(Common.showMessage(`Invite sent to ${form.email}`));

                        let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                        project.exists && !((project.data()?.memberListInvites || []).some((el: CollaboratorObj) => el.email == form.email)) && await firestore.collection(`Projects`).doc(project.id).update({ memberListInvites: [...project.data()?.memberListInvites, form] });
                        dispatch(NotificationService.inviteNewUserToSpace(`home/space/${space?.did}/project/${projectObj.did}`, authUser?.uid || '',
                          {
                            fromFirstName: authUser ? authUser.user.firstName : '',
                            fromLastName: authUser ? authUser.user.lastName : '',
                            toEmail: form.email,
                            spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                            spaceName: space?.name || ''
                          }));
                        // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                      } else {
                        // console.error()
                        dispatch(
                          Common.fetchError(messages['message.somethingWentWrong'] as string),
                        );
                      }
                    })
                    .catch(error => {
                      dispatch(Common.fetchError(error.message));
                    });
                } else {
                  dispatch(Common.fetchError(`Already invite sent to ${form.email}`))
                }
              } else {
                Api.post(`/api/spaces/${space?.did}/invite`, { "email": form.email, "role": form.role })
                  .then(async data => {
                    if (data.status === 200) {
                      dispatch(Common.fetchSuccess());
                      dispatch(Common.showMessage(`Invite sent to ${form.email}`));

                      let project = await firestore.collection(`Projects`).doc(projectObj.id).get();
                      project.exists && !((project.data()?.memberListInvites || []).some((el: CollaboratorObj) => el.email == form.email)) &&
                        await firestore.collection(`Projects`).doc(project.id).update({ memberListInvites: [...project.data()?.memberListInvites || [], form] });
                      dispatch(
                        NotificationService.inviteNewUserToSpace(
                          `home/space/${space?.did}/project/${projectObj.did}`,
                          authUser?.uid || '',
                          {
                            fromFirstName: authUser ? authUser.user.firstName : '',
                            fromLastName: authUser ? authUser.user.lastName : '',
                            toEmail: form.email,
                            spaceLink: `${baseUrl}/home/space/${space?.did}/project/${projectObj.did}`,
                            spaceName: space?.name || '',
                          },
                        ),
                      );
                      // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                    } else {
                      dispatch(
                        Common.fetchError(messages['message.somethingWentWrong'] as string),
                      );
                    }
                  })
                  .catch((error) => {
                    dispatch(Common.fetchError(error.message));
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              Common.fetchError(messages['message.somethingWentWrong'] as string);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        Common.fetchError(messages['message.somethingWentWrong'] as string);
      });
  };
};
export const showRadialMenu = (open: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: HomeAction.OPEN_RADIAL_MENU, payload: open });
  }
}

export const saveSpaceCustomData = (spaceId: string, name: string, userId: string | undefined, customDataType: HomeApp.CustomDataType) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const customDataRef =
      await firestore
        .doc(`Spaces/${spaceId}`)
        .collection('CustomData')
        .doc();

    const { id: customDataId } = customDataRef;
    let customData: HomeApp.CustomData = {
      id: customDataId,
      name: name,
      customDataType: customDataType,
      createdBy: userId ? userId : '',
      createdOn: new Date()
    };

    customDataRef
      .set(customData)
      .then(() => {
        dispatch({ type: HomeAction.SET_CURRENT_CUSTOM_DATA, payload: customData });
        dispatch(Common.fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(Common.fetchError(error.message));
      });

    return customData;
  };
};

export const saveSpaceCustomDataField = (spaceId: string, customDataId: string, customDataFieldType: HomeApp.FieldType, data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    const customDataFieldRef =
      await firestore
        .doc(`Spaces/${spaceId}/CustomData/${customDataId}`)
        .collection('Fields')
        .doc();

    const { id: customDataFieldId } = customDataFieldRef;
    let customDataField: HomeApp.CustomDataField = {
      id: customDataFieldId,
      fieldType: customDataFieldType,
      data: data
    };

    customDataFieldRef
      .set(customDataField)
      .then(() => {
        dispatch(Common.fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(Common.fetchError(error.message));
      });

    return customDataField;
  };
};

export const linkSpaceCustomDataFieldToTag = (spaceId: string, customDataId: string, fieldWithTag: HomeApp.CustomDataField) => {
  return async (dispatch: Dispatch<AppActions>) => {
    await firestore
      .doc(`Spaces/${spaceId}/CustomData/${customDataId}/Fields/${fieldWithTag.id}`)
      .set(fieldWithTag)
      .then(() => {
        dispatch(Common.fetchSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(Common.fetchError(error.message));
      });
  };
};

export function onSetSpaceVariables(vars: any[]) {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    let spaceId = store.getState().home.currentSpace?.id;
    dispatch(Common.fetchStartNonBlocking(messages['common.saving']));
    let bp = new BasicProfiler();
    let v = _.cloneDeep(vars);
    bp.lap('[clone] vars in onSetSpaceVariables');
    spaceId && firestore.doc(`Spaces/${spaceId}`).update({ variables: v }).then(() => {

      dispatch(Common.fetchSuccess());
      dispatch({ type: HomeAction.SET_SPACE_VARIABLES, payload: v });


      store.getState().home.lessonsList.map((l, i) => {

        l.defaultVars = l.defaultVars || [];
        vars.forEach(v => {
          let dv = l.defaultVars?.find(dv => dv.name == v.name);
          if (!dv) {
            //if not in dv, add it w/o value
            l.defaultVars?.push({ ...v, allowControl: false, value: '' });
          } else if (dv && dv.values !== v.values) {
            // if in dv, but range changed, set value to '', range to new range.
            dv.value = '';
            dv.values = v.values;
          }
        });

        _.remove(l.defaultVars, (dv) => !vars.find(sv => sv.name == dv.name));

        firestore.doc(`Spaces/${store.getState().home.currentSpace?.id}/lessons/${l.id}`)
          .update('defaultVars', l.defaultVars).catch(console.error).then(() => {
            // console.log(`[vars] lesson updated ${l.name} - ${JSON.stringify(l.defaultVars)}`);
            if (i == store.getState().home.lessonsList.length - 1) {
              store.dispatch(onGetLessonsList(store.getState().home.currentSpace?.id || ''))

            }
          })
      });


    }).catch((e) => {
      console.error(e);
      dispatch(Common.fetchError('Something went wrong saving the variables. Please try again'));
    })
  }
}


export function setSpacePoses(poses: HomeApp.SpacePose[]) {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    let space = store.getState().home.currentSpace;
    dispatch(Common.fetchStartNonBlocking(messages['common.saving']));
    console.log(`[st] setting poses ${poses.map(p => p.id + " " + p.label)}`)
    space?.id && firestore.doc(`Spaces/${space.id}`).update({ poses: _.cloneDeep(poses) }).then(() => {
      dispatch(Common.fetchSuccess());
      // dispatch({type: SET_SPACE_DATA, payload: });
      dispatch(onGetSpace({ id: space?.id, did: space?.did || '', pDid: space?.currentSpaceProject?.did }, () => { }));
    }).catch((e) => {

      console.error(e);
      dispatch(Common.fetchError('Something went wrong saving the variables. Please try again'));
    })
  }
}

export function setSpaceHomePose(pose: HomeApp.SpacePose) {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<AppActions>) => {
    let space = store.getState().home.currentSpace;
    dispatch(Common.fetchStartNonBlocking(messages['common.saving']));
    // console.log(`[st] setting poses ${poses.map(p => p.id + " " + p.label)}`)
    space?.id && firestore.doc(`Spaces/${space.id}`).update({ homePose: pose }).then(() => {
      dispatch(Common.fetchSuccess());
      // dispatch({type: SET_SPACE_DATA, payload: });
      dispatch(onGetSpace({ id: space?.id, did: space?.did || '', pDid: space?.currentSpaceProject?.did }, () => { }));
    }).catch((e) => {
      console.error(e);
      dispatch(Common.fetchError('Something went wrong saving the variables. Please try again'));
    })
  }
}

export function onShowPropertiesPanel(show: boolean) {
  return async (dispatch: Dispatch<AppActions>) => {
    console.log('[capt] dispatch ' + store.getState().threeD.showPropertiesPanel + show)
    if (store.getState().threeD.showPropertiesPanel !== show) {
      if (store.getState().home.currentSidebarIndex == HomeApp.SIDEBAR_INDEX.NONE) {
        // console.log(`[vars] dispatching SI to 3d`);
        dispatch(setCurrentSidebar(HomeApp.SIDEBAR_INDEX.THREED));

      }
      store.dispatch({ type: SHOW_PROP_PANEL, payload: show });
    };
  }

}

export function setLockView(lv: boolean) {
  // return (dispatch: Dispatch<AppActions>) => {
  // InputSubSystem.input.setMatterPortCameraMovement,(lv);
  // console.log(`[st] [lockview] ${lv}`)
  store.dispatch({ type: HomeAction.LOCK_VIEW, payload: lv })
  // }
}
