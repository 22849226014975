import {ComponentInteractionType, SceneComponent } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import {Group, Mesh, Object3D} from 'three';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {generateUUID} from '../../../../../shared/helpers/helper';
import {ColladaLoader} from 'three/examples/jsm/loaders/ColladaLoader';
import * as THREE from 'three';

type Inputs = {
    size: {x: number; y: number; z: number};
    position: {x: number; y: number; z: number};
    visible: boolean;
    scale: {x: number; y: number; z: number};
    rotation: {x: number; y: number; z: number};
    url: string;
}

export class CustomGLTFLoader extends SceneComponent {
    inputs: Inputs = {
        size: {x: 1, y: 1, z: 1},
        visible: true,
        position: { x: 0, y: 0, z: 0},
        rotation: { x: 0, y: 0, z: 0},
        scale: { x: 1, y: 1, z: 1},
        url: ''
    };
    events = {
        [ComponentInteractionType.CLICK]: true,
        [ComponentInteractionType.HOVER]: true,
        [ComponentInteractionType.DRAG]: true,
        [ComponentInteractionType.DRAG_BEGIN]: true,
        [ComponentInteractionType.DRAG_END]: true,
    };
    //mixer
    protected mixer: THREE.AnimationMixer;
    protected currentActionIndex = 0;
    protected animationActions: THREE.AnimationAction[] = [];
    private root: THREE.Object3D | null = null;
    private obj: THREE.Group | null = null;

    private _animationActionsMap: {[key: string]: THREE.AnimationAction} = {};

    public get animationActionsMap(): {[p: string]: THREE.AnimationAction} {
        return this._animationActionsMap;
    }

    onInit() {
        const THREE = this.context.three;

        this.root = new THREE.Object3D();

        this.outputs.objectRoot = this.root;
        this.outputs.collider = this.root;

        this.makeModel();
    }


    onTick(delta: number) {
        super.onTick(delta);

        // if (!!this.inputs.noColliders) {
        //     this.outputs.collider = null
        // }

        this.mixer?.update(delta * 0.001);

        if(this.animationActions.length > 1 && this.animationActions[this.currentActionIndex].isRunning() === false) {
            this.animationActions[this.currentActionIndex].stop();
            this.currentActionIndex = (this.currentActionIndex + 1) % this.animationActions.length;
            this.animationActions[this.currentActionIndex].play();
        }
    }

    // onEvent(eventType: string, eventData: unknown) {
    //     this.notify(eventType, eventData);
    // }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
        if (interactionType === ComponentInteractionType.CLICK) {
            let _tmp_webstorm_ = this.context;
            // @ts-ignore
            const {root} = this.context;
            this.notify(ComponentInteractionType.CLICK, {
                type: interactionType,
                node: root,
                component: this,
            });
        }
        if (interactionType === ComponentInteractionType.HOVER) {
            this.notify(ComponentInteractionType.HOVER, {
                hover: (<{hover: boolean}>eventData).hover,
            });
        }
        if (interactionType === ComponentInteractionType.DRAG_BEGIN) {
            // @ts-ignore
            const { root } = this.context;
            this.notify(ComponentInteractionType.DRAG_BEGIN, {
                event: eventData,
                component: this,
                node: root,
                interactionType: interactionType
            });
        }
        if (interactionType === ComponentInteractionType.DRAG_END) {
            // @ts-ignore
            const { root } = this.context;
            this.notify(ComponentInteractionType.DRAG_END, {
                event: eventData,
                component: this,
                node: root,
                interactionType: interactionType
            });
        }
        if (interactionType === ComponentInteractionType.DRAG) {
            // @ts-ignore
            const { root } = this.context;
            this.notify(ComponentInteractionType.DRAG, {
                event: eventData,
                component: this,
                node: root,
                interactionType: interactionType
            });
        }
    }

    makeModel() {
        // @ts-ignore
        const THREE = this.context.three;

        //const manager = new THREE.LoadingManager();
        const obj = this.inputs.url;

        var loader = new GLTFLoader(); //FIXME: s36/arp/refactored
        loader.crossOrigin = 'annonymous';
        loader.
        load(obj, (gltf: GLTF) => { //FIXME: s36/arp/refactored
                let mesh = gltf.scene;
                mesh.position.set(this.inputs.position.x, this.inputs.position.y, this.inputs.position.z);
                mesh.scale.set(this.inputs.scale.x, this.inputs.scale.y, this.inputs.scale.z);
                mesh.rotation.set(THREE.MathUtils.DEG2RAD * this.inputs.rotation.x, THREE.MathUtils.DEG2RAD * this.inputs.rotation.y, THREE.MathUtils.DEG2RAD *this.inputs.rotation.z);
                this.root!.add(mesh);
                this.obj = mesh;

                // console.log("kakakakk");
                // //traverse mesh and get materials and set color of said materials
                // mesh.traverse((o:THREE.Object3D) => {
                //     if (o instanceof THREE.Mesh) {
                //         // o.castShadow = true;
                //         // o.receiveShadow = true;
                //         if (o.material instanceof THREE.MeshStandardMaterial) {
                //             // o.material.color = new THREE.Color(0xff0000);
                //             var newMat = o.material.clone();
                //             console.log(newMat.map);
                //             o.material = new THREE.MeshBasicMaterial(
                //                 {
                //                     color: 0xff0000,
                //                     // map: newMat.map,
                //                 });
                //         }
                //
                //         console.log(o.material);
                //     }
                // });

                this.outputs.objectRoot = this.root;
                this.outputs.collider = this.obj;

                this.mixer = new THREE.AnimationMixer( gltf.scene );

                for (let i = 0; i < gltf.animations.length; i++) {
                    const action = this.mixer.clipAction(gltf.animations[i]);
                    // console.log(gltf.animations[i].name);

                    if(gltf.animations.length == 1) {
                        // action.setLoop(THREE.LoopRepeat, 1);
                    } else {
                        action.setLoop(THREE.LoopOnce, 1);
                    }
                    this.animationActions.push(action);
                    this._animationActionsMap[gltf.animations[i].name] = action;
                }
                this.currentActionIndex = 0;

                if (this.animationActions.length > 0) {
                    this.animationActions[this.currentActionIndex].play();
                }
            },
            (xhr: ProgressEvent<EventTarget>) => {
                //
                //
                //Problem: xhr.total always shows zero
            },
            // called when loading has errors
            (error: any) => {

            }
        );
    }

    onInputsUpdated(oldInputs: Inputs) {
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        // this.mesh!.geometry.dispose();
    }

}

export const customGLTFType = 'st.customGLTFLoader';

export function makeCustomGLTFLoader() {
    return new CustomGLTFLoader();
}
