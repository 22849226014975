import { ISceneNode, SceneComponent } from './SceneComponent';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { UserDataGizmoMinorMods, UserDataProperties, UserDataTypes } from '../ui-interop/PropertiesPanel';
import Simulation from '../core/Simulation';
import { CustomObjLoader } from '../../components/CustomModelLoaders/CustomObjLoader';
import light from 'mp/jsonScenes/light.json';
import { modelMap, internalModelMap } from 'modules/home/models';
import Utils from '../../Tools/Utils';
import catalogMap from 'mp/catalog/catalog';
import { GizmoTools } from 'modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools';
import { store } from 'App';
import { showGizmo } from 'redux/actions/ThreeD';
import { isR3F, isUserAdmin } from 'modules/home/SpaceDetail/utils';
import { showMessage } from 'redux/actions';
import { Scene } from 'types/SdkTypes2';
// import { Scene } from 'types/SceneNamespace';
// import { Scene } from '../../../../../../public/bundle/sdk';
export class SceneLoader {
    private nodes: ISceneNode[] = [];
    public transformGizmoComponent: Scene.IComponent;
    private transformGizmoNode: Scene.INode;
    private lastTransformGizmoTargetNode: ISceneNode;
    private transformControls: TransformControls;
    public sceneObject: Scene.IObject;

    private lastNodeIndex: number;

    public objectCounters: { [key: string]: number };
    public objectCountersNew: { [key: string]: number };
    private lastObjectCounterKey: string;

    public setSDK(_sdk: any): void {
        this.sdk = _sdk;
    }

    public getNodes() {
        return this.nodes;
    }

    constructor(private sdk: any, private sim: Simulation) {
        this.objectCounters = {};
        this.objectCountersNew = {};
    }

    public getComponentObjects() {
        let w: any = null;
        let objects: any[] = [];
        this.nodes.find((n: ISceneNode) => {

            // if (n?.userData?.nameToShow.contains('Walking Woman 1')) {
            if (n?.userData?.nameToShow.includes('Tull')) {
                // let o = n?.obj3D?.getObjectByName('Wheels_objs');
                let o = n?.obj3D?.getObjectByName('F010_Saber_Face');

                let i = 0;
                n.obj3D?.traverse((child: any) => {

                    if (child.isMesh) {
                        objects.push(child);
                        w = child;
                        w.position.x = w.position.x + 1 + i++;
                        // w.position.y = w.position.y + 1 + i++;
                    };

                });
                if (o) {
                    w = o;
                }
                return o;

            }


        });

        // if (w) {
        //     console.log(`[st] wheels found ${w?.name}`);
        //     w.position.x = w.position.x + 1;
        //     w.position.y = w.position.y + 10;
        // }

    }
    // @ts-ignore
    /**
     * Load the scene for a given model.
     *
     * @param sid sid of the model, used to lookup the scene.
     * @param callback an optional callback which is called once for scene node created.
     */
    public async load(sid: string, callback: (node: ISceneNode) => void) {
        let scene = sidToScene.get(sid);
        if (!scene) {
            //scene doesn't already exist in sidToScene map, will try to load the json and map it
            // load in JSON data from file
            var sceneJSON;

            const reqListener = (e: ProgressEvent<EventTarget>) => {
                sceneJSON = JSON.parse(xhr.responseText);

                sidToScene.set(sid, sceneJSON);
                scene = sidToScene.get(sid);
                if (!scene) {

                    return;
                }
            };

            var xhr = new XMLHttpRequest();
            xhr.onload = reqListener;
            xhr.open('get', sid, false);
            xhr.send();
        }

        const nodesToStart: ISceneNode[] = [];
        this.sceneObject = await this.sdk.Scene.deserialize(
            JSON.stringify(scene),
        );


        if (callback) {
            for (const node of nodesToStart) {
                callback(node);
            }
        }
        for (const node of nodesToStart) {
            node.start();
            this.nodes.push(node);


            // if (isUserAdmin()) {
            //     for (const c of node.componentIterator()) {
            //
            //         //x
            //         const linex = new THREE.Line(new THREE.BufferGeometry().setFromPoints([
            //             new THREE.Vector3(0,0,0),
            //             new THREE.Vector3(1,0.1,0),
            //         ]), new THREE.LineBasicMaterial({ color: 0xff0000 }));
            //
            //         //z
            //         const liney = new THREE.Line(new THREE.BufferGeometry().setFromPoints([
            //             new THREE.Vector3(0,0,0),
            //             new THREE.Vector3(0,1,0),
            //         ]), new THREE.LineBasicMaterial({ color: 0x00ff00 }));
            //
            //         //z
            //         const linez = new THREE.Line(new THREE.BufferGeometry().setFromPoints([
            //             new THREE.Vector3(0,0,0),
            //             new THREE.Vector3(0,0,1),
            //         ]), new THREE.LineBasicMaterial({ color: 0x0000ff }));
            //
            //         c.context.scene.add(linex);
            //         c.context.scene.add(liney);
            //         c.context.scene.add(linez);
            //         break;
            //     }
            //
            // }
        }
    }

    // public async startNodes(nodesToStart: ISceneNode[], callback: (node: ISceneNode, sim: Simulation) => void) {

    //     //
    //     if (callback) {
    //         for (const node of nodesToStart) {
    //             callback(node, this.sim);
    //         }
    //     }

    //     this.lastNodeIndex = this.nodes.length - 1;
    //     for (const node of nodesToStart) {
    //         node.start();
    //         this.nodes.push(node);
    //     }

    // }

    //public async loadNodesFromDB()

    public generateNameFromCount(node: ISceneNode): string {
        let counterKey = node.userData[UserDataProperties.type];
        var displayName: string = node.name;
        if (node.userData[UserDataProperties.type] === UserDataTypes.InteriorDesignModel) {
            counterKey = node.userData[UserDataProperties.catalogDetails].name;
            displayName = counterKey;
        }

        if (!this.objectCounters[counterKey]) {
            this.objectCounters[counterKey] = 1;
        } else {
            this.objectCounters[counterKey]++;
        }

        this.lastObjectCounterKey = counterKey;

        return displayName + ' ' + this.objectCounters[counterKey];
    }

    /**
     * Only for r3f. //TODO: catalog models pending
     * @param nodeName
     * @returns
     */
    public generateNameFromCountNew(nodeName: string): string {
        let counterKey = nodeName;
        var displayName: string = nodeName;
        // if (node.userData[UserDataProperties.type] === UserDataTypes.InteriorDesignModel) {
        //     counterKey = node.userData[UserDataProperties.catalogDetails].name;
        //     displayName = counterKey;
        // } //todo fiber do this for fiber

        if (!this.objectCountersNew[counterKey]) {
            this.objectCountersNew[counterKey] = 1;
        } else {
            this.objectCountersNew[counterKey]++;
        }

        let counter = this.objectCountersNew[counterKey];
        let name = displayName + ' ' + counter;

        //dedupe counter
        Array.from(store.getState().home.spaceModels.values())
            .filter(n => n.userData.nameToShow.toLowerCase().includes(nodeName.toLowerCase()))
            .forEach((model) => {

                const regex = new RegExp(nodeName + "\\s*(\\d+)");

                // let na: string[] = [];
                // model.userData.nameToShow.split(' ').map((x: string) => !!x.trim() && na.push(x.trim()));
                let ma = (model.userData.nameToShow as string).match(regex);


                if(!ma || ma.length < 2){
                // if (na.length != 2 || na[0] !== nodeName || !parseInt(na[1])) {
                    return name;
                } else {
                    // let nm = na[0]
                    let count = parseInt(ma[1])

                    if (count >= counter) {
                        counter = count + 1;
                        name = displayName + ' ' + counter;
                    }
                }
            });
        return name;
    }

    public async loadClassical(scene: string, callback: (node: ISceneNode, dbJSON: any) => void, dbJSON: any = null): Promise<ISceneNode[]> {

        try {

            let template;
            let userDataTemplate;

            let newModelURL: string = '';

            if (modelMap.has(scene)) { // if object is from ThreeDAnnotations
                let tempTemplate = modelMap.get(scene);
                template = JSON.parse(JSON.stringify(tempTemplate));

                userDataTemplate = JSON.parse(JSON.stringify(template.payload.objects[0].userData));

            } else if (internalModelMap.has(scene)) { // if object is internal to our models
                let tempTemplate = internalModelMap.get(scene);
                template = JSON.parse(JSON.stringify(tempTemplate));
                userDataTemplate = JSON.parse(JSON.stringify(template.payload.objects[0].userData));
            } else {
                let catalogItem: any = {};
                let loadingFromUserData: boolean = false;
                try {
                    if (store.getState().auth.authUser?.user.permissions && store.getState().auth.authUser?.user.permissions.includes('MODEL_ADMIN')) {


                    }
                    catalogItem = JSON.parse(scene);
                    template = catalogMap.get(catalogItem.type); //gltf, obj, dae etc.
                } catch (e: any) {
                    template = catalogMap.get(scene); //gltf, obj, dae etc.
                    loadingFromUserData = true;
                }
                if (!template) {
                    console.error('[SceneLoader] template undefined for catalogue entry');
                    return [];
                }

                userDataTemplate = (template.payload.objects[0].userData && JSON.parse(JSON.stringify(template.payload.objects[0].userData))) || {};

                if (!loadingFromUserData) {
                    // if (catalogItem.type == 'gltf'){

                    let url = catalogItem.fileUrls.find((url: string) => url.endsWith(`.${catalogItem.type}`));

                    if (url) {
                        template.payload.objects[0].components[0].inputs.url = url;
                    }

                    let materialURL = catalogItem.fileUrls.find((url: string) => url.endsWith(`.mtl`));
                    if (materialURL) {
                        template.payload.objects[0].components[0].inputs.materialUrl = materialURL;
                    }
                    userDataTemplate[UserDataProperties.catalogDetails] = {
                        url: template.payload.objects[0].components[0].inputs.url,
                        materialUrl: template.payload.objects[0].components[0].inputs.materialUrl,
                        name: catalogItem.name,
                        type: catalogItem.type,
                        // thumbnailUrl: catalogItem.thumbnailUrl,
                    };
                } else {
                    if (dbJSON) {
                        if (UserDataProperties.userData in dbJSON) {
                            if (UserDataProperties.catalogDetails in dbJSON[UserDataProperties.userData]) {
                                template.payload.objects[0].components[0].inputs.url = dbJSON[UserDataProperties.userData][UserDataProperties.catalogDetails].url;
                                template.payload.objects[0].components[0].inputs.materialUrl = dbJSON[UserDataProperties.userData][UserDataProperties.catalogDetails].materialUrl;
                            } else {
                                console.warn('UserData[UserDataProperties.catalogDetails] was missing for a catalogue model, cannot proceed');
                                return [];
                            }
                        } else {
                            console.warn('UserData was missing for a DBJSON catalogue model, cannot proceed');
                            return [];
                        }
                    } else {
                        console.warn('DB JSON corrupt for a catalogue model. Critical error! Cannot proceed.');
                        return [];
                    }
                }


            }


            if (dbJSON) {
                if (UserDataProperties.overrideUserData in userDataTemplate) {
                    Utils.ApplyAllPropertiesFromJSONtoJSON(dbJSON.userData[UserDataProperties.overrideUserData], userDataTemplate[UserDataProperties.overrideUserData]);
                }

                if (UserDataProperties.newModelURL in dbJSON.userData) {
                    newModelURL = dbJSON.userData[UserDataProperties.newModelURL];

                    if (newModelURL.length > 0) {
                        if (newModelURL.indexOf('.gltf') > 0) {
                            template.payload.objects[0].components[0].type = 'mp.gltfLoader';
                        } else if (newModelURL.indexOf('.obj') > 0) {
                            template.payload.objects[0].components[0].type = 'mp.objLoader';
                        }
                        template.payload.objects[0].components[0].inputs.url = newModelURL;
                    }
                }
            }

            // for (const component in template.payload.objects[0].components) {
            //     delete component?.id;
            // }

            template.payload.objects[0]?.components?.forEach((c: any) => {
                delete c?.id
            })

            // var userDataCopy = JSON.parse(JSON.stringify(template.payload.objects[0]?.userData));
            //
            // delete template.payload.objects[0]?.userData;

            let sos: Scene.IObject = await this.sdk.Scene.deserialize(JSON.stringify(template));

            // const [sceneObject] = await this.sdk.Scene.createObjects(1);

            // const nodesToStart: Scene.INode[] = [];
            const nodesToStart: ISceneNode[] = [];
            // for (const node of sos.nodeIterator()) {
            // nodesToStart.push(node);
            // this.sceneObject.addNode();
            //     nodesToStart.push(node as ISceneNode);
            // }

            this.lastNodeIndex = this.nodes.length - 1;
            // for (const node of nodesToStart) {
            for (const n of sos.nodeIterator()) {

                let node = this.sceneObject.addNode() as ISceneNode;
                node = n as ISceneNode;
                nodesToStart.push(node);
                //node.start();
                this.nodes.push(node); //not really needed anymore, we can just get it from this.sceneObject.nodeIterator()

                node.animatedPosition = new THREE.Vector3(node.position.x, node.position.y, node.position.z);
                node.userData = userDataTemplate;

                if (dbJSON) {
                    if (UserDataProperties.nameToShow in dbJSON.userData) {
                        node.userData[UserDataProperties.nameToShow] = dbJSON.userData[UserDataProperties.nameToShow];

                        let currentNumber = dbJSON.userData[UserDataProperties.nameToShow].match(/\d+/);//Number.parseInt((dbJSON.userData[UserDataProperties.nameToShow] as string).replace(node.name, ""));

                        if (currentNumber == null) {
                            currentNumber = NaN;
                        } else {
                            currentNumber = currentNumber[0];
                        }

                        if (isNaN(currentNumber)) {

                        } else {
                            if (!this.objectCounters[node.userData[UserDataProperties.type]]) {
                                this.objectCounters[node.userData[UserDataProperties.type]] = 1;
                            }
                            if (currentNumber > this.objectCounters[node.userData[UserDataProperties.type]]) {
                                this.objectCounters[node.userData[UserDataProperties.type]] = currentNumber;
                            }
                        }

                    } else {
                        node.userData[UserDataProperties.nameToShow] = this.generateNameFromCount(node);
                    }

                    dbJSON[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];
                } else {
                    node.userData[UserDataProperties.nameToShow] = this.generateNameFromCount(node);
                }
                node && dbJSON && Utils.ApplyAllPropertiesFromJSONtoJSON(node.userData, dbJSON.userData);

            }

            this.lastNodeIndex = this.nodes.length - 1;
            for (const node of nodesToStart) {
                node.components = node.components || [];
                if (node.components.length == 0) {
                    const componentIterator = node.componentIterator();
                    for (const component of componentIterator) {
                        node.components.push(component as SceneComponent);
                    }
                }
                node.start();
                // this.nodes.push(node);
            }

            if (callback) {
                for (const node of nodesToStart) {
                    callback(node, dbJSON);
                }
            }
            //return this.nodes[this.lastNodeIndex];
            return nodesToStart;
        } catch (err: any) {
            // console.error(`[st] error loading model json ${scene}, dbjson: ${model.id}`)
            console.error(err);
        }
        //return {};

        console.warn('[SceneLoader] Model failed to load as scene for unknown reason', scene);
        return [];
    }

    public getLastNodeAdded(): ISceneNode {
        return this.nodes[this.nodes.length - 1];
    }

    public removeLastNodeAdded(): void {
        let node = this.nodes[this.nodes.length - 1];
        node.stop();
        this.hideTransformGizmo();

        if (this.objectCounters[this.lastObjectCounterKey]) {
            this.objectCounters[this.lastObjectCounterKey]--;
        }
    }

    /**
     * Method to change the scene gizmo's transform behaviour
     * @param mode String that can be 'translate', 'rotate' or 'scale'. Changes the gizmo's behaviour appropriately
     */

    public async changeGizmoTransformMode(mode: String) {

        if (this.transformGizmoComponent != null) {
            this.transformGizmoComponent.inputs!.mode = mode;

            this.checkForGizmoMinorScaleMod();
        }
    }

    private checkForGizmoMinorScaleMod(): void {
        if (this.lastTransformGizmoTargetNode) {
            if (this.lastTransformGizmoTargetNode?.userData) {
                if (UserDataGizmoMinorMods.disableScale in this.lastTransformGizmoTargetNode!.userData) {
                    if (this.transformGizmoComponent.inputs!.mode === 'scale') {
                        if (this.lastTransformGizmoTargetNode.userData[UserDataGizmoMinorMods.disableScale]) {
                            store.dispatch(showMessage(`Be careful resizing this object. To size uniformly in all dimensions, use Size in the 'Object Properties' box in the sidebar.`));
                            // this.transformGizmoComponent.inputs!.mode = 'translate';
                        }
                    }
                }
            }
        }
    }


    public hideTransformGizmo(checkNode: ISceneNode | null = null) {

        if(isR3F()){
            return;
        }
        if (checkNode) {
            if (checkNode === this.lastTransformGizmoTargetNode) {
                if (this.transformGizmoComponent) {

                    if (this.transformGizmoComponent.inputs!.visible || this.transformGizmoComponent.inputs!.showX) {
                        // this.transformGizmoComponent.inputs!.visible = false;
                        this.showOrHideTransformControls(false);
                        this.transformGizmoComponent.inputs!.selection = null;
                    }
                    //
                    //(this.transformGizmoComponent as any).transformControls.detach();
                }
                store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
            }
        } else {
            if (this.transformGizmoComponent) {
                if (this.transformGizmoComponent.inputs!.visible || this.transformGizmoComponent.inputs!.showX) {
                    this.showOrHideTransformControls(false);
                    this.transformGizmoComponent.inputs!.selection = null;
                }
                //
                //(this.transformGizmoComponent as any).transformControls.detach();
            }
        }
    }

    public async showTransformGizmo(node: ISceneNode = this.lastTransformGizmoTargetNode) {

        if (this.transformGizmoComponent) {
            // this.transformGizmoComponent.inputs!.visible = true;
            this.showOrHideTransformControls(true);
            //this.transformGizmoComponent.inputs!.selection = this.lastTransformGizmoTargetNode;

            this.setTransformControlsNode(node);
            this.checkForGizmoMinorScaleMod();
        }
    }

    // public async showTransformGizmo() {
    //   if (this.transformGizmoComponent) {
    //     this.transformGizmoComponent.inputs!.visible = true;
    //     //this.transformGizmoComponent.inputs!.selection = this.lastTransformGizmoTargetNode;

    //     this.setTransformControlsNode(this.lastTransformGizmoTargetNode);
    //     this.checkForGizmoMinorScaleMod();
    //   }
    // }

    /**
     * Method to attach the scene's transform gizmo to a node
     * @param node The node to which the transform gizmo is attached to
     */
    public async setGizmoToNode(node: any) {
        if (this.transformGizmoNode != null && this.transformGizmoComponent != null) {
            //this.translateNode.stop();
            //this.transformGizmoNode.start();
        } else {
            this.transformGizmoNode = Simulation.instance.sceneLoader.sceneObject.addNode() as Scene.INode;
            this.transformGizmoComponent = this.transformGizmoNode.addComponent("mp.transformControls", {
                visible: false
            });
            this.transformGizmoNode.start();
            this.transformControls = (this.transformGizmoComponent as any).transformControls!;
            this.transformControls.setTranslationSnap(0.01);

            // this.transformControls.setRotationSnap(THREE.MathUtils.DEG2RAD * 11.25);
            this.transformControls.setScaleSnap(0.01);
            this.transformControls.traverse((obj) => { // To be detected correctly by OutlinePass.
                (obj as any).isTransformControls = true;
            });


            this.transformControls.addEventListener('objectChange', (e: any) => {
                this.sim.propertiesPanel.saveLastNode(true, this.lastTransformGizmoTargetNode);
            });
        }

        this.showOrHideTransformControls(true);
        this.transformGizmoComponent.inputs!.size = 1;
        // Attach the model to the transform control
        //this.lastTransformGizmoTargetNode = this.transformGizmoComponent.inputs!.selection = node;      \
        this.lastTransformGizmoTargetNode = node;
        this.setTransformControlsNode(node);
    }


    private showOrHideTransformControls(show: boolean) {
        if (this.transformGizmoComponent.inputs?.hasOwnProperty('visible')) {
            this.transformGizmoComponent.inputs!.visible = show;
        } else {
            this.transformGizmoComponent.inputs!.showX = show;
            this.transformGizmoComponent.inputs!.showY = show;
            this.transformGizmoComponent.inputs!.showZ = show;
        }
    }

    public getGizmoBoundingBox(): THREE.Box3 {
        //this.transformControls.children[0].visible = false;
        // let translateGizmo = this.transformControls.children[0].children[0];

        this.transformControls && this.transformControls.traverse((object) => {
            if ((object as any).hasOwnProperty('geometry')) {
                (object as any).geometry.computeBoundingBox();
            }
        });

        /*
        translateGizmo.children.forEach(element => {
            if(element.type === 'Mesh') {

            }
        });*/
        return new THREE.Box3().setFromObject(this.transformControls && this.transformControls.children[0].children[0]);
    }

    private setTransformControlsNode(node: any) {
        this.transformGizmoComponent.inputs!.selection = node;
    }

    public async removeObject(objId: string, type: string) {

        for (let i = 0; i < this.nodes.length; i++) {

            const componentIterator = this.nodes[i].componentIterator();
            for (const component of componentIterator) {
                if (component.componentType === type) {
                    let model = component as SceneComponent as CustomObjLoader;
                    if (model.id === objId) {
                        this.nodes[i].stop();
                        this.nodes.splice(i, 1);

                        return;
                    }
                }
            }
        }
    }

    public * nodeIterator(): IterableIterator<ISceneNode> {
        for (const node of this.nodes) {
            yield node;
        }
    }

    public removeNodeByID(objectID: string): void {
        for (let i = 0; i < this.nodes.length; i++) {
            if (this.nodes[i].userData && this.nodes[i].userData['id'] === objectID) {
                this.nodes[i].stop();
                this.nodes.splice(i, 1);
                return;
            }
        }
    }

    findNodeByID(objectID: string): ISceneNode | null {
        let nodeIterator = this.nodeIterator();
        for (const node of nodeIterator) {
            if (node.userData && node.userData['id'] == objectID) {
                return node;
            }
        }

        return null;
    }

    findNodesByName(names: string[]): ISceneNode[] {
        let nodeIterator = this.nodeIterator();
        let n: ISceneNode[] = [];
        for (const node of nodeIterator) {
            if (names.includes(node.name)) {
                n.push(node);
            }
        }

        return n;
    }

    //Stop all nodes with iterator
    public stopAllNodes() {
        let nodeIterator = this?.nodeIterator();
        for (const node of nodeIterator!) {
            node?.stop();
        }

        this.nodes && (this.nodes.length = 0);
    }

    public destroy() {


    }
}

const sidToScene: Map<string, any> = new Map();

//sidToScene.set('AAWs9eZ9ip6', scene);
sidToScene.set('light', light);
