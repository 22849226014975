import Utils from "../../Tools/Utils";
import { CraSubSystem } from "../CraSubSystem";
import InputSubSystem from "../input/InputSubSystem";
import { ISceneNode, SceneComponent } from "../sceneManagement/SceneComponent";
import { SceneLoader } from "../sceneManagement/SceneLoader";
import { SimpleCamera } from "../ancillary/SimpleCamera";
import { GizmoTools } from "../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { PropertiesPanelMode, UserDataGizmoMinorMods, UserDataProperties } from "../ui-interop/PropertiesPanel";
import { OutlinePostProcess } from "../../components/PostProcess/OutlineComponent";
import Simulation, { ExternalSdkMode } from "./Simulation";
import * as THREE from "three";
import messages from "@crema/services/db/messages/messages";
import { store } from "App";
import { showGizmo } from "redux/actions/ThreeD";
import ApplyRotation from './ApplyRotation';
import { SceneNode } from "CustomSdk/Mimick/SceneNode";
import { isR3F } from "modules/home/SpaceDetail/utils";
import { SET_HIDDEN_NODE_IDS } from "types/actions/ThreeD.actions";
import { SDKInstance } from "modules/fiber/Sdk";

export enum SimulationMode {
    NONE = 1,
    ADD_OBJECT,
    PLACE_MODE,
    ADD_INTERNAL_OBJECT,
    GRAB_MODE
}

export default class RenderingAndPlaceObjectStateSystem {
    camera: SimpleCamera;
    renderingSubSystem: CraSubSystem;
    sceneLoader: SceneLoader;
    lastSelectedNode: ISceneNode | null;
    outlineComponent: OutlinePostProcess;
    outlineComponentColor2: OutlinePostProcess;

    applyRotation: ApplyRotation;
    srcObjectIdForInternalObjects: string;

    grabbedObject: ISceneNode | null;
    dragging: boolean = false;

    public spaceModels(): Map<string, any> {
        return store.getState().home.spaceModels;
    };

    public lockViewState: [boolean, (lv: boolean) => void];
    public propertyPanelShowOverride: [boolean, React.Dispatch<React.SetStateAction<boolean>>];

    // protected _gizmoUIData: PropertiesPanelGizmoToolbarUIData;

    protected alreadyLocked: boolean;
    protected simulationMode: SimulationMode;

    // public get gizmoUIData(): PropertiesPanelGizmoToolbarUIData {
    //     return this._gizmoUIData;
    // }

    protected InitializeCamera() {
        this.camera = new SimpleCamera();
        this.camera.initialize(this.renderingSubSystem);
    }

    cancelPlaceMode() {
        if (this.simulationMode == SimulationMode.PLACE_MODE) {
            setTimeout(this.resumeMatterPortCameraMovement.bind(this), 1000);
            this.simulationMode = SimulationMode.NONE;
        }
    }

    resumeMatterPortCameraMovement() {
        console.log("[PlaceObjectBehaviors] Camera movement resumed");
        if (!isR3F() && !this.alreadyLocked) {
            InputSubSystem.input.setMatterPortCameraMovement(true);
            this.lockViewState && this.lockViewState[1](false);
        }
    }

    getSimulationMode(): SimulationMode {
        return this.simulationMode;
    }

    public async selectNode(node: ISceneNode) {
        if (this.propertyPanelShowOverride[0]) {
            await this.sceneLoader.setGizmoToNode(node);
            this.sceneLoader.showTransformGizmo();
        }

        /*
        let showSizeOption: boolean = false;
        if (UserDataGizmoMinorMods.disableScale in node.userData) {
            if (node.userData[UserDataGizmoMinorMods.disableScale]) {
                showSizeOption = true;
            }
        }*/

        // this._gizmoUIData.disableScale = false;
        // if (UserDataGizmoMinorMods.disableScale in node.userData) {
        //     if (node.userData[UserDataGizmoMinorMods.disableScale]) {
        //         this._gizmoUIData.disableScale = true;
        //     }
        // }

        // this._gizmoUIData.nameToShow = node.userData[UserDataProperties.nameToShow];

        setTimeout(() => {
            let obj = Utils.Find3DRootOfNode(node);
            if (obj) {
                //Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(obj, new THREE.Vector3(0, -0.45, 0))

                try {
                    let gizmoAABB = Simulation.instance.sceneLoader.getGizmoBoundingBox();

                    let boxCorners: THREE.Vector3[] = [];

                    boxCorners.push()

                    boxCorners.push(gizmoAABB.min.clone());
                    boxCorners.push(new THREE.Vector3(gizmoAABB.max.x, gizmoAABB.min.y, gizmoAABB.min.z));
                    boxCorners.push(new THREE.Vector3(gizmoAABB.max.x, gizmoAABB.max.y, gizmoAABB.min.z));
                    boxCorners.push(new THREE.Vector3(gizmoAABB.min.x, gizmoAABB.max.y, gizmoAABB.min.z));

                    boxCorners.push(new THREE.Vector3(gizmoAABB.max.x, gizmoAABB.min.y, gizmoAABB.max.z));
                    boxCorners.push(new THREE.Vector3(gizmoAABB.max.x, gizmoAABB.max.y, gizmoAABB.max.z));
                    boxCorners.push(new THREE.Vector3(gizmoAABB.min.x, gizmoAABB.max.y, gizmoAABB.max.z));
                    boxCorners.push(gizmoAABB.max.clone());

                    let _2dMin = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
                    let _2dMax = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);

                    boxCorners.forEach(element => {
                        _2dMin.min(element);
                        _2dMax.max(element);
                    });

                    _2dMin = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(_2dMin, new THREE.Vector3(0, 0, 0));
                    _2dMax = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(_2dMax, new THREE.Vector3(0, 0, 0));

                    let screenCoords = Simulation.instance.camera.getScreenCoordsOfPointWithoutMatterPortSDK(obj, new THREE.Vector3(0, 0, 0));
                    //screenCoords.y = _2dMin.y;
                    //GizmoTools?.instance?.setPosition(screenCoords, _2dMin, _2dMax);
                    // GizmoTools?.instance?.setPosition(this._gizmoUIData, screenCoords, _2dMin, _2dMax);
                    store.dispatch(showGizmo(true));
                } catch (e: any) { console.error(e) }
            }
        }, 16); //Why 16? well 16 ms is 0.016 s, 1 over 0.01666 hz which is 60 hz, why 60 hz you ask? Felt cute might change later (On a serious note: I just need like 1 ms here but 1 frame on a 60 hz screen
        // seemed like a goood wait time, ie a wait time of a single frame)

        this.lastSelectedNode = node;
    }

    tryToEnterPlacementMode(): boolean {
        if (this.lastSelectedNode) {
            if (this.getSimulationMode() == SimulationMode.NONE) {
                this.simulationMode = SimulationMode.PLACE_MODE;
                this.alreadyLocked = this.lockViewState[0];
                // InputSubSystem.input.setMatterPortCameraMovement(false);
                this.lockViewState[1](true);
                return true;
            }
        }

        return false;
    }

    tryToEnterGrabMode(): boolean {
        if (this.lastSelectedNode) {
            if (this.getSimulationMode() == SimulationMode.NONE) {
                this.simulationMode = SimulationMode.GRAB_MODE;
                this.alreadyLocked = this.lockViewState[0];
                // InputSubSystem.input.setMatterPortCameraMovement(false);
                this.lockViewState[1](true);
                return true;
            }
        }

        return false;
    }

    cancelAddObject() {
        if (this.simulationMode == SimulationMode.ADD_OBJECT) {
            this.sceneLoader.removeLastNodeAdded();
            setTimeout(this.resumeMatterPortCameraMovement.bind(this), 1000);
            this.simulationMode = SimulationMode.NONE;
        }
    }

    public filterVisibleNodes(nodeIds: string[]) {
        if (!this.spaceModels() || this.spaceModels().size == 0) {
            return;
        }

        let hiddenNodeIds: string[] = [];
        for (const model of this.spaceModels().values()) {
            let node = this.spaceModels().get(model.id).nodeRef;

            if(isR3F()){
                node = SDKInstance.sceneNodes.get(model.id);
            }

            if (node?.userData && node.userData["id"]) {
                if (nodeIds.includes(node.userData["id"])) {
                    // console.log(`[st] step: showing node ${node.userData["nameToShow"]}`);
                        let meshes = Utils.FindAllMeshesAndLineSegments(node);
                        Utils.EnableCollidersOnNode(node);
                        Utils.SetVisibility(true, node, meshes);
                        // Simulation.instance.highlightModel(model.id, meshes);

                } else {

                        // console.log(`[st] step: hiding node ${node.userData?.nameToShow} initializatioonComplete: ${Simulation.instance.InitializationComplete()}`);
                        let meshes = Utils.FindAllMeshesAndLineSegments(node);
                        Utils.DisableCollidersOnNode(node);
                        Utils.SetVisibility(false, node, meshes);
                        Simulation.instance.sceneLoader.hideTransformGizmo(node);

                }
            }
        }
    }

    async showNode(node: ISceneNode) {
        Utils.EnableCollidersOnNode(node);
        Utils.SetVisibility(true, node);
    }

    protected constructor() {
        this.applyRotation = new ApplyRotation();
        // this._gizmoUIData = new PropertiesPanelGizmoToolbarUIData();
        this.lastSelectedNode = null;
        this.renderingSubSystem = new CraSubSystem();

        this.alreadyLocked = false;
    }

    public snapGrabbedObject(droppedObjectposition: THREE.Vector3,) {

    }
}
