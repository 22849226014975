import { Scrollbar } from '@crema';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';

interface Props {
    style: any,
    save: (value: string) => void,
    multiline: boolean,
    initValue: string,
    placeholder: string,
    saveOnEnter?: boolean
}

const EditableTextField: React.FC<Props> = ({ save, style, multiline, initValue, placeholder, saveOnEnter = false }) => {

    const [value, setValue] = useState<string>('');
    useEffect(() => setValue(initValue), [initValue])

    return (<Scrollbar>
        <TextField
            id='outlined-dense-multiline'
            variant='standard'
            multiline={multiline}
            maxRows='4'
            value={value}
            InputProps={{
                style: style
            }}

            onKeyDown={(e) => !!saveOnEnter && e.key === 'Enter' && save(value)}
            placeholder={placeholder}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                // if (!!saveOnEnter && event.target.value. === 'Enter') {
                //     //don't save newline
                // } else {
                    setValue(event.target.value);
                // }
            }}
            onMouseOut={() => {
                save(value);
            }}
        />
    </Scrollbar>)
}

export default EditableTextField;