import {
    Texture,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Euler,
    MathUtils,
    Wrapping,
    ClampToEdgeWrapping,
    RepeatWrapping,
    Material,
    ShapeGeometry,
} from 'three';
import {SceneComponent, ComponentInteractionType} from '../../../SubSystems/sceneManagement/SceneComponent';
import Utils from '../../../Tools/Utils';
import * as THREE from 'three';
import CardinalAxesAndPlanes from '../../../Tools/CardinalAxesAndPlanes';
import QueueScheduler from '../../../Tools/QueueScheduler';
import {CanvasRendererDecoupled} from '../../DecoupledComponents/CanvasRendererDecoupled';
import {CanvasTextDecoupled} from '../../DecoupledComponents/CanvasTextDecoupled';
import {Size} from '../../DecoupledComponents/DecoupledComponent';
import * as TWEEN from '@tweenjs/tween.js';
import {CanvasRenderingTool} from '../../DecoupledComponents/CanvasRenderingTool';

type Inputs = {
    transparent: boolean;
    visible: boolean;
    opacity: number;
    color: string;
    hoverColor: string;
    clickColor: string;
    bgColor: string;
    bgHoverColor: string;
    bgClickColor: string;
    fontSize: number;
    textLabel: string;
    borderRadius: number;
    borderShadowColor: string;
    borderStyle: string;
    borderLineWidth: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    size: Size;
    //planeSize: { w:number, y:number };
    localScale: {x: number; y: number; z: number;};
    localPosition: {x: number; y: number; z: number;};
    localRotation: {x: number; y: number; z: number;};
}

export class ButtonTextRenderer extends SceneComponent {
    inputs: Inputs = {
        transparent: true,
        visible: true,
        opacity: 1,
        color: '#d70d0d',
        clickColor: '#ff0000',
        hoverColor: '#ffff00',
        bgColor: 'rgba(203,80,26,0.55)',
        bgHoverColor: "rgba(180,23,232,0.55)",
        bgClickColor: "#00ff00",
        polygonOffset: false,
        borderRadius: 10,
        borderShadowColor: '#000000',
        borderStyle: '#ff0000',
        borderLineWidth: 10,
        fontSize: 80,
        textLabel: 'Menu Item 1',
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        size: {w: 1, h: 1},
        localScale: {x: 1, y: 1, z: 1},
        localPosition: {x: 0, y: 0, z: 0},
        localRotation: {x: 0, y: 0, z: 0},
    };
    events = {
        [ComponentInteractionType.CLICK]: true,
        [ComponentInteractionType.HOVER]: true,
    };
    private mesh: Mesh | null = null;
    private pivotNode: Object3D;
    private oldRootScale: THREE.Vector2;
    private rebuildMeshQueue: QueueScheduler<any>;
    private canvasRendererDecoupled: CanvasRendererDecoupled;
    private canvasTextDecoupled: CanvasTextDecoupled;
    private rollOver: boolean = false;
    private buttonClickedTime: number = 0;
    private buttonClicked: boolean = false;

    buildMesh(any: any | null = null): boolean {
        const THREE = this.context.three;

        if (this.mesh) {
            this.pivotNode.remove(this.mesh);
            this.mesh.geometry.dispose();
            (this.mesh.material as Material).dispose();
            this.mesh = null;
        }
        this.mesh = new THREE.Mesh(
            //new THREE.PlaneBufferGeometry(this.oldRootScale.x, this.oldRootScale.y),

            new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, this.inputs.borderRadius)).translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            new THREE.MeshBasicMaterial({
                transparent: this.inputs.transparent,
                map: this.canvasRendererDecoupled.RenderTargetTexture,
                opacity: this.inputs.opacity,
                color: '#ffffff',
                polygonOffset: this.inputs.polygonOffset,
                polygonOffsetFactor: this.inputs.polygonOffsetFactor,
                polygonOffsetUnits: this.inputs.polygonOffsetUnits,
                side: THREE.DoubleSide,
            }));
        //this.mesh!.scale.set(1.0/this.oldRootScale.x, 1.0/this.oldRootScale.y, 1);
        //makeButtonTextRenderer
        var geometry = (this.mesh!.geometry as ShapeGeometry);
        geometry.computeBoundingBox();
        var max = geometry.boundingBox!.max,
            min = geometry.boundingBox!.min;

        this.oldRootScale.y = 1;
        var inverseOldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(this.oldRootScale);
        var offset = inverseOldRootScale.clone().multiplyScalar(0.5);//new THREE.Vector2(0 - min.x, 0 - min.y);
        var range = new THREE.Vector2(max.x - min.x, max.y - min.y);

        range.multiply(inverseOldRootScale);
    
        // console.log(`root scale: ${this.root.scale.x} ${this.root.scale.y}`);
        // this.mesh!.scale.set(1 / oldRootScale.x, 1 / oldRootScale.y, 1);
        this.mesh!.scale.set(this.inputs.size.w,  this.inputs.size.h, 1);
        
        // this.mesh!.scale.set(1.0, 1.0, 1);
        this.outputs.collider = this.mesh!;

        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
        this.mesh!.updateMatrixWorld();
        this.pivotNode.add(this.mesh!);
        

        return true;
    }

    onInit() {
        const THREE = this.context.three;

        this.pivotNode = new THREE.Group();
        //

        this.outputs.objectRoot = this.pivotNode;
        this.outputs.collider = this.pivotNode;

        this.pivotNode.position.set(0, 0, 0);

        this.canvasRendererDecoupled = new CanvasRendererDecoupled({
            w: Math.ceil(512 * this.inputs.size.w),
            h: Math.ceil(512 * this.inputs.size.h),
        });
        this.canvasTextDecoupled = new CanvasTextDecoupled(this.inputs.color,
            'sans-serif', 'normal bold', this.inputs.fontSize, this.inputs.textLabel);

        this.canvasRendererDecoupled.clear(this.inputs.bgColor);
        CanvasRenderingTool.RoundedCorners(this.canvasRendererDecoupled, 0, 0, this.canvasRendererDecoupled.resolution.w,
            this.canvasRendererDecoupled.resolution.h, this.inputs.borderRadius * 0.5, this.inputs.borderShadowColor, this.inputs.borderStyle, this.inputs.borderLineWidth);
        this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
        this.canvasRendererDecoupled.commitRepaint();

        this.rebuildMeshQueue = new QueueScheduler<any>(this.buildMesh.bind(this), 50);
        this.oldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone();
        this.rebuildMeshQueue.addQueueElement({}, true);

    }

    onTick(tickDelta: number) {
        super.onTick(tickDelta);
        if (this.buttonClicked) {
            if (this.buttonClickedTime > 0) {
                this.buttonClickedTime -= tickDelta;
            } else {
                if (this.buttonClicked) {
                    this.buttonClicked = false;
                    this.animateScale({x: 1, y: 1, z: 1});

                    if (this.rollOver) {
                        //this.animateScale({x: 1.025, y: 1.025, z: 1});
                        //this.canvasTextDecoupled.fontColor = this.inputs.hoverColor;
                        this.setHoverColor();
                    } else {
                        //this.animateScale({x: 1, y: 1, z: 1});
                        //this.canvasTextDecoupled.fontColor = this.inputs.color;
                        this.setRegularColor();
                    }
                    //this.canvasTextDecoupled.fontColor = this.inputs.color;
                    //this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
                    //this.canvasRendererDecoupled.commitRepaint();
                }
            }
        }
    }

    private setHoverColor():void {
        this.canvasTextDecoupled.fontColor = this.inputs.hoverColor;
        this.canvasRendererDecoupled.clear(this.inputs.bgHoverColor);
        CanvasRenderingTool.RoundedCorners(this.canvasRendererDecoupled, 0, 0, this.canvasRendererDecoupled.resolution.w,
            this.canvasRendererDecoupled.resolution.h, this.inputs.borderRadius * 0.5, this.inputs.borderShadowColor, this.inputs.borderStyle, this.inputs.borderLineWidth);
        this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
        this.canvasRendererDecoupled.commitRepaint();
    }

    private setRegularColor():void {
        this.canvasTextDecoupled.fontColor = this.inputs.color;
        this.canvasRendererDecoupled.clear(this.inputs.bgColor);
        CanvasRenderingTool.RoundedCorners(this.canvasRendererDecoupled, 0, 0, this.canvasRendererDecoupled.resolution.w,
            this.canvasRendererDecoupled.resolution.h, this.inputs.borderRadius * 0.5, this.inputs.borderShadowColor, this.inputs.borderStyle, this.inputs.borderLineWidth);
        this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
        this.canvasRendererDecoupled.commitRepaint();
    }

    private setClickColor():void {
        this.canvasTextDecoupled.fontColor = this.inputs.clickColor;
        this.canvasRendererDecoupled.clear(this.inputs.bgClickColor);
        CanvasRenderingTool.RoundedCorners(this.canvasRendererDecoupled, 0, 0, this.canvasRendererDecoupled.resolution.w,
            this.canvasRendererDecoupled.resolution.h, this.inputs.borderRadius * 0.5, this.inputs.borderShadowColor, this.inputs.borderStyle, this.inputs.borderLineWidth);
        this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
        this.canvasRendererDecoupled.commitRepaint();
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown) {
        this.notify(interactionType, eventData);
        if (interactionType === ComponentInteractionType.CLICK) {
            this.notify(ComponentInteractionType.CLICK, {
                type: interactionType,
                node: this.context,
                component: this,
            });

            this.animateScale({x: 0.975, y: 0.975, z: 1});
            this.buttonClickedTime = 250;
            this.buttonClicked = true;
            // this.canvasTextDecoupled.fontColor = this.inputs.clickColor;
            // this.canvasRendererDecoupled.repaint(this.canvasTextDecoupled);
            // this.canvasRendererDecoupled.commitRepaint();

            this.setClickColor();
            //console.log("button click " + this.inputs.);
        }

        if (interactionType === ComponentInteractionType.HOVER) {
            this.notify(ComponentInteractionType.HOVER, {
                type: interactionType,
                node: this.context,
                component: this,
            });

            this.rollOver = !this.rollOver;
            console.log('button hover ');

            if (this.rollOver) {
                this.animateScale({x: 1.025, y: 1.025, z: 1});
                //this.canvasTextDecoupled.fontColor = this.inputs.hoverColor;

                this.setHoverColor();
            } else {
                this.animateScale({x: 1, y: 1, z: 1});
                //this.canvasTextDecoupled.fontColor = this.inputs.color;
                this.setRegularColor();
            }
        }
    }

    onInputsUpdated(oldInputs: Inputs) {
        if (!this.mesh) {
            return;
        }

        if (oldInputs.transparent !== this.inputs.transparent) {
            (this.mesh!.material as MeshBasicMaterial).transparent = this.inputs.transparent;
        }

        if (oldInputs.visible !== this.inputs.visible) {
            this.mesh!.visible = this.inputs.visible;
        }

        if (oldInputs.color !== this.inputs.color) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).color.set(this.inputs.color);
        }

        if (oldInputs.opacity !== this.inputs.opacity) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).opacity = this.inputs.opacity;
        }

        if (oldInputs.polygonOffset !== this.inputs.polygonOffset) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.polygonOffset = this.inputs.polygonOffset;
            material.polygonOffsetFactor = this.inputs.polygonOffsetFactor;
            material.polygonOffsetUnits = this.inputs.polygonOffsetUnits;
        }

        this.mesh!.scale.set(this.inputs.localScale.x, this.inputs.localScale.y, this.inputs.localScale.z);
        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;

        (this.mesh!.material as MeshBasicMaterial).dispose();
        this.mesh!.geometry.dispose();
    }

    private animateScale(position: {x: number, y: number, z: number}, duration: number = 100.0) {
        new TWEEN.Tween(this.pivotNode!.scale)
            .to(
                position,
                1000,
            )
            .easing(TWEEN.Easing.Elastic.Out)
            .start();
    }

}

export const buttonTextRendererType = 'st.buttonTextRenderer';

export function makeButtonTextRenderer() {
    return new ButtonTextRenderer();
}
