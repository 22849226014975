import { store } from "App";
import { Object3D } from "three";
import { SceneComponent, ComponentInteractionType, ISceneNode } from "../../SubSystems/sceneManagement/SceneComponent";
interface Inputs {
  intensity: number;
}

export class Spotlight extends SceneComponent {
  inputNode: ISceneNode;
  public scene: THREE.Scene;
  inputs: Inputs = {
    intensity: 100
  }
  public root: Object3D | null = null;

  private boxMesh: THREE.Mesh;


  events = {
    [ComponentInteractionType.CLICK]: false,
    [ComponentInteractionType.HOVER]: false,
    [ComponentInteractionType.DRAG]: false,
  };
  onInit() {
    // @ts-ignore
    const THREE = this.context.three;

    this.scene = this.context.scene;

    this.root = new THREE.Group();

    this.outputs.objectRoot = this.root;
    //BoxGeometry (makes a geometry)
    var geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
    //Material to apply to the cube (green)
    let opacity = !store.getState().layer.presentationMode ? 0.5 : 0.01; //check the latest value every time
    // let opacity = this.inputs.boxVisible ? 0.5 : 0.01;
    var material = new THREE.MeshBasicMaterial({ color: 0xf35c14, transparent: true, opacity: opacity });
    //Applies material to BoxGeometry
    this.boxMesh = new THREE.Mesh(geometry, material);

    this.root.add(this.boxMesh);
    this.outputs.collider = this.boxMesh;

    const color = 0xFFFFFF;
    const intensity = 300;
    let light = new THREE.DirectionalLight('#000000', intensity);
    this.root.add(light);

    // light.position.set(0.5, 1, -1);
    // light.position.multiplyScalar(10);

    // light.shadow.mapSize.width = 2048;
    // light.shadow.mapSize.height = 2048;

    // light.shadow.camera.near = 0.5; // default
    // light.shadow.camera.far = 450;
    // light.shadow.bias = -0.0001;

    /*
    const dirLightHelper = new THREE.DirectionalLightHelper( light, 10 );
    (this.context as any).scene.add( dirLightHelper );
    */
    // light.castShadow = true;
    // (this.context as any).scene.add(light);
    // (this.context as any).scene.add(new THREE.AmbientLight(0xffffFF, 3.0));

    for (let child of this.context.scene.children) {
      //console.log(child.type)
      //(child as THREE.Object3D).receiveShadow = true;
      this.traverseChildrenAndSetShadowReception(child);
    }

  }


  onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;

    //window.removeEventListener('pointerdown', this.onPointerDown);
  }

  private traverseChildrenAndSetShadowReception(obj: THREE.Object3D) {
    if (obj.children.length < 1) {
      obj.receiveShadow = true;
    } else {
      for (let child of obj.children) {
        //console.log(child.type)
        this.traverseChildrenAndSetShadowReception(child);
      }

    }
  }
}

export const spotlightType = 'mp.spotlight';
export const makeSpotlight = function () {
  return new Spotlight();
};
