import { isST } from "modules/home/SpaceDetail/utils";
import * as THREE from "three"
import { ThreeContext } from "../components/systemComponents/ThreeContext";

enum TextureManagerTextureCollectionState {
    Nothing = "Texture not loaded",
    Loading = "Texture loading",
    Loaded = "Texture loaded",
    Error = "Error loading texture"

}

export class TextureWithLoadState
{
    texture:THREE.Texture|null;
    state:TextureManagerTextureCollectionState;
    src:string;

    constructor() {

    }

    public TextureManagerTextureCollection() {
        this.texture = null;
        this.state = TextureManagerTextureCollectionState.Nothing;
        this.src = "";
    }

    public Destroy():boolean {
        return TextureManager.instance.DestroyTexture(this.src);
    }
}

export default class TextureManager {
    private texturesKeyValueCollection:Map<string, TextureWithLoadState>;

    private static _instance:TextureManager|null = null;

    private textureLoader:THREE.TextureLoader|undefined = undefined;

    getThree(){
        if(isST()){
            return THREE;
        } else {
            return ThreeContext!.i!.t;
        }
    }

    private constructor() {
        if(isST()){
            this.textureLoader = new THREE.TextureLoader();
        } else {
            const THREE = ThreeContext!.i!.t;
            this.textureLoader = new THREE.TextureLoader();
        }

        this.texturesKeyValueCollection = new Map<string, TextureWithLoadState>();
    }

    public LoadTexture(src:string,
        onLoadCallback:((t?:THREE.Texture) => void)|null,
        onErrorCallback:((e:any) => void)|null):TextureWithLoadState|undefined {


        if(this.texturesKeyValueCollection.has(src)) {
            let alreadyExistingResource = this.texturesKeyValueCollection.get(src);
            if(onLoadCallback) {
                onLoadCallback(alreadyExistingResource?.texture!);
            }
            return alreadyExistingResource;
        }

        this.texturesKeyValueCollection.set(src, new TextureWithLoadState());
        let element = this.texturesKeyValueCollection.get(src);

        let tempTexture = this.textureLoader?.load(
            src,
            this.onLoad.bind(this, src, onLoadCallback!),
            undefined,
            this.onError.bind(this, src, onErrorCallback)
            );

        if(tempTexture) {
            element!.texture = tempTexture;
        }

        return element;
    }

    private onError(src:string, onErrorCallback:((e:any) => void)|null = null, e:any) {
        /*
        let ref = this.texturesKeyValueCollection[src];
        ref.state = TextureManagerTextureCollectionState.Error;
        ref.texture = null;
        this.texturesKeyValueCollection[src] = null;*/
        console.error('[st] image Texture loader error', src, e)
        this.texturesKeyValueCollection.delete(src);

        if(onErrorCallback) {
            onErrorCallback(e);
        }
    }

    public DestroyTexture(src:string):boolean {
        if(this.texturesKeyValueCollection.has(src)) {
            let alreadyExistingResource = this.texturesKeyValueCollection.get(src);
            alreadyExistingResource!.texture && alreadyExistingResource?.texture.dispose();
            this.texturesKeyValueCollection.delete(src);
            return true;
        }

        return false;
    }

    private onLoad(src:string, onLoadCallback:((t?:THREE.Texture) => void)|null = null, _texture:THREE.Texture) {
        let ref = this.texturesKeyValueCollection.get(src);
        if(ref) {
            ref.state = TextureManagerTextureCollectionState.Loaded;
            ref.texture = _texture;
            ref.src = src;
        }

        if(onLoadCallback) {
            onLoadCallback(_texture);
        }
    }

    public static release() {
        this._instance = null;
    }

    public static get instance():TextureManager {
        if(!this._instance) {
            this._instance = new TextureManager();
        }

        return this._instance;
    }
}