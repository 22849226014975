//import { ComponentInteractionType, ISceneNode, SceneComponent } from "mp";
import { Object3D } from 'three';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js';
import { SceneComponent, ISceneNode } from '../../SubSystems/sceneManagement/SceneComponent';


export class PaintSpray extends SceneComponent {
    //public members
    inputNode: ISceneNode;

    //private members
    private root: Object3D | null = null;
    private moved:boolean = false;

    constructor()
    {
        super();
    }

    onInit() {
        // @ts-ignore
      const THREE = this.context.three;
      this.root = new THREE.Object3D();
      // @ts-ignore
      this.outputs.objectRoot = this.root;
      // @ts-ignore
      //this.outputs.collider = this.root;

      window.addEventListener( 'pointerdown', this.onPointerDown);
    }

    private onPointerDown() {
        this.moved = false;
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        window.removeEventListener( 'pointerdown', this.onPointerDown);
      }
}

export const paintSprayType = 'cra.paintSpray';
export const makePaintSpray = function () {
    return new PaintSpray();
};