// import * as THREE from "three";
import { Scene, MpSdk } from "types/SdkTypes2";
import * as Types from "types/SdkNamespace";

export abstract class Node {
    public node: Scene.INode;
    public mpSdk: MpSdk;
    public sceneObject: Scene.IObject;
    private _objectRoot: THREE.Object3D | null = null;

    constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {
        this.mpSdk = mpSdk;
        this.sceneObject = sceneObject;
    }

    public objectRoot(): THREE.Object3D | null {
        if (!this._objectRoot) {
            for (const c of this.node.componentIterator()) {
                if (c.outputs && c.outputs.objectRoot) {
                    this._objectRoot = c.outputs.objectRoot;
                    break;
                }
            }
        }
        return this._objectRoot;
    }

    addClickSpy(component: Scene.IComponent, onClick: (payload: unknown) => void): void {
        let clickEventPath = this.sceneObject.addEmitPath(component, "INTERACTION.CLICK");

        this.sceneObject.spyOnEvent(new Spy(clickEventPath, (payload: unknown) => {
            onClick(payload);
        }));
    }

    addHoverSpy(component: Scene.IComponent, onHover: (payload: unknown) => void): void {
        let hoverEventPath = this.sceneObject.addEmitPath(component, "INTERACTION.HOVER");
        this.sceneObject.spyOnEvent(new Spy(hoverEventPath, (payload: unknown) => {
            onHover(payload);
        }));
    }
}

export class AccessGrantedSpy {
    public eventType: 'ONACCESSGRANTED';
    public onEvent(payload: unknown) {
        // myCamera is now enabled, changes to your camera will
        // be reflected on the showcase camera.
        console.log(' [r3f] camera access granted', payload);
    }
}

export class Spy implements Scene.ISceneObjectSpy {
    constructor(public path: Types.MpSdkNamespace.Scene.InputPath<unknown> | Types.MpSdkNamespace.Scene.OutputPath<unknown>
        | Scene.EventPath | Types.MpSdkNamespace.Scene.EmitPath,
        public onEvent: (payload: unknown) => void) { }
}

// Define a click event spy
// class ClickSpy implements Scene.IComponentEventSpy {
//     public eventType = 'INTERACTION.CLICK';
//     public onEvent(payload: unknown) {
//         console.log(' [r3f] received', payload);
//     }
// }
