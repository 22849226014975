import * as THREE from 'three';
import { store } from '../../App';
import { RenderingSystem } from './RenderingSystem';
import _ from 'lodash';
import { FontLoader, Font } from 'three/examples/jsm/loaders/FontLoader.js';
import Simulation from '../../mp/core/craEngine/SubSystems/core/Simulation';
import { QuizModel } from '../../modules/home/models';
import { SceneNode } from '../Mimick/SceneNode';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import { ISceneNodeExtensions } from '../../mp/core/craEngine/extensions/ISceneNodeExtensions';
import { Material } from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { StSdk } from 'CustomSdk/SpatialThinkSDK';


// import * as TROIKA from 'troika-three-text'
import { CSS3DObject, CSS3DSprite } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { CommentObj, TaskObj } from 'types/models/apps/ProjectBoard';
// import {Text} from 'troika-three-text'
// import { Text } from troika-three-text

export class TagSystem {
    protected fontLoader: FontLoader;
    protected iotArray: any[];
    protected tagMeshes: THREE.Mesh[] = [];
    protected labels: any[] = [];
    protected taskTags: any[] = [];

    // protected troikaMeshes: THREE.Mesh[] = [];
    protected mesh360: THREE.Mesh = new THREE.Mesh();
    protected font: Font;
    protected quizNodes: SceneNode[] = [];

    constructor(protected renderingSystem: RenderingSystem) {
        this.fontLoader = new FontLoader();

        this.fontLoader.load('/fonts/helvetiker_regular.typeface.json', (font: Font) => {
            this.font = font;
        });
        this.iotArray = [];

    }
    protected getRandomNIoTNumber() {
        return Math.floor(Math.random() * 99);
    }

    protected RoundedRectShape(width: number, height: number, radius: number) {
        const roundedRectShape = new THREE.Shape();
        radius *= 0.001;

        roundedRectShape.moveTo(0, radius);
        roundedRectShape.lineTo(0, height - radius);
        roundedRectShape.quadraticCurveTo(0, height, radius, height);
        roundedRectShape.lineTo(0 + width - radius, height);
        roundedRectShape.quadraticCurveTo(width, height, width, height - radius);
        roundedRectShape.lineTo(width, radius);
        roundedRectShape.quadraticCurveTo(width, 0, width - radius, 0);
        roundedRectShape.lineTo(radius, 0);
        roundedRectShape.quadraticCurveTo(0, 0, 0, radius);

        return roundedRectShape;
    }

    protected createText(font: Font, labelText: string, color: number, zOffset = 0) {
        const matLite = new THREE.MeshBasicMaterial({
            color: color,
            transparent: false,
            opacity: 1,
            side: THREE.DoubleSide,
        });

        const message = labelText;

        const shapes = font.generateShapes(message, 100);
        const geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();

        const xMid = -0.5 * (geometry.boundingBox!.max.x - geometry.boundingBox!.min.x);
        geometry.translate(xMid, 0, zOffset);

        const text = new THREE.Mesh(geometry, matLite);
        text.scale.set(0.0001, 0.0001, 0.0001);
        //text.position.y += 0.05;

        return text;
    }

    addTagsAsLabels(tag: any) {
        let tagPosition = new THREE.Vector3(tag.data.stemVector.x + tag.data.anchorPosition.x,
            tag.data.stemVector.y + tag.data.anchorPosition.y,
            tag.data.stemVector.z + tag.data.anchorPosition.z,
        );

        let color = !!tag.data.color ?
            new THREE.Color(tag.data.color.r, tag.data.color.g, tag.data.color.b)
            : new THREE.Color('#ff0000');
    }

    // troikaTextWidget(tag: any, tagMesh: THREE.Mesh) {

    //     // Create:
    //     const troikaMesh = new TROIKA.Text()
    //     tagMesh.add(troikaMesh)

    //     // Set properties to configure:
    //     troikaMesh.text = tag?.data?.label || 'Back to the lobby';
    //     troikaMesh.fontSize = 0.2
    //     troikaMesh.position.z = -2
    //     troikaMesh.color = tag?.data?.color || 0x9966FF
    //     // troikaMesh.rotateY(THREE.MathUtils.degToRad(180));
    //     // (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(troikaMesh);

    //     tagMesh.userData.arType = 'troikaText';
    //     // this.troikaMeshes.push(troikaMesh);
    //     // Update the rendering:
    //     troikaMesh.sync();
    // }

    public async loadTags(tagIds: string[]) {
        this.taskTags = [];

        if (tagIds.length > 0) {
            let qs = await firestore.collection(`Projects/${store.getState().projects.projectDetail?.id || ''}/Tasks`).where('tagId', 'in', tagIds)
                .get();

            qs.forEach((doc) => {
                let task = doc.data() as TaskObj;//task

                this.taskTags.push(task);
            })
        }
        console.log('[stsdk] task', this.taskTags, tagIds);

        this.tagMeshes?.forEach(tm => {

            tm?.children.forEach((child: any) => {
                child && (child.visible = false);
                child?.geometry?.dispose();
                child?.material?.dispose();
            });

            tm.visible = false;
            tm.geometry?.dispose();
            (tm.material as Material)?.dispose();
        });

        this.quizNodes.forEach(qn => {
            Utils.DisableCollidersOnNode(qn);
            Utils.SetVisibility(false, qn);
        })
        this.labels.map(l => this.renderingSystem.base.remove(l));

        this.tagMeshes = [];
        // let spaceTags: ShowcaseTagMap = store.getState().home.spaceTags;
        let tags = tagIds.map(tid => store.getState().home.spaceTags[tid]);

        // console.log("onFontLoaded");
        // console.log(store.getState().home.spaceTags);
        // console.log(spaceTags);
        // const geometryTag = new THREE.BoxGeometry(0.05, 0.05, 0.05);

        for (let i = 0; i < tags.length; i++) {
            // let tag = Object.values(spaceTags)[i];

            // this.addTagsAsLabels(tags[i]);
            let tag = tags[i];
            // console.log(tag.data.label);
            // console.log(tag.data.anchorPosition);
            // console.log(tag.data.stemVector);

            let tagPosition = new THREE.Vector3(tag.data.stemVector.x + tag.data.anchorPosition.x,
                tag.data.stemVector.y + tag.data.anchorPosition.y,
                tag.data.stemVector.z + tag.data.anchorPosition.z,
            );

            let color = !!tag.data.color ?
                new THREE.Color(tag.data.color.r, tag.data.color.g, tag.data.color.b)
                : new THREE.Color(1, 0, 0);

            tag.data.color = color;
            let iconColor = new THREE.Color(color);
            let tagMesh = null;




            let isQuiz: boolean = false;
            if (tag.quizDetails != undefined) {
                isQuiz = true;
            }

            let tempTag = null;

            if (isQuiz) {
                let dbJSON = {
                    userData: {
                        promptText: tag.quizDetails?.question,
                        choices: tag.quizDetails?.options,
                        tagId: tag.id,
                    }
                }
                let nodesLoaded = await Simulation.instance.sceneLoader.loadClassical(QuizModel.payload.objects[0].name, Simulation.instance.scenePreProcess, dbJSON);
                console.log(nodesLoaded);
                let nodeRef = Simulation.instance.sceneLoader.getLastNodeAdded() as SceneNode;
                // console.log(nodeRef);
                ISceneNodeExtensions.setPosition(nodeRef, tagPosition);
                // (nodeRef as SceneNode).no
                // @ts-ignore
                this.tagMeshes.push(nodeRef.customComponents[0].root);
                this.quizNodes.push(nodeRef);
            } else {

                let labelString: string = tag.data.label;
                //tagMesh
                let planeSize = new THREE.Vector2(0.1 * labelString.length * 0.1, 0.025);
                tagMesh = new THREE.Mesh(
                    //new THREE.PlaneBufferGeometry(2, 1),
                    new THREE.ShapeGeometry(this.RoundedRectShape(planeSize.x, planeSize.y, 2)).translate(-0.5 * planeSize.x, -0.5 * planeSize.y, 0),//.scale(1, this.oldRootScale.y, 1),
                    //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                    new THREE.MeshBasicMaterial({
                        // transparent: false,
                        opacity: 0,
                        transparent: true,
                        color: 0x000000,
                        polygonOffset: true,
                        polygonOffsetFactor: 100,
                        polygonOffsetUnits: 1,
                        side: THREE.DoubleSide,
                    }));
                tagMesh.userData['text'] = tag.data.label;
                tagMesh.userData['tagId'] = tag.id;

                tagMesh.position.set(tagPosition.x, tagPosition.y, tagPosition.z);
                this.renderingSystem.base.add(tagMesh);

                // Create:


                // console.log(tag.data);
                if (tag.data.quizDetails) {
                    console.log(tag.data.quizDetails);
                }

                try {
                    //2D HTML CSS label
                    const el = document.createElement('div' + i);
                    // el.className = 'label';
                    // el.style.color = 'black';
                    // // el.style.background = '#00000090';
                    // el.style.background = 'white';
                    // el.style.transform = 'translate(-50%, -100%)';
                    // el.style.marginTop = '-10px';
                    // el.style.padding = '5px 10px';
                    // el.style.borderRadius = '10px';
                    // el.style.borderStyle = 'solid';
                    // el.style.borderWidth = '2px';

                    // el.style.padding = '8px';




                    // try {
                    //     // el.style.borderColor = `rgb(${color.r}, ${color.g}, ${color.b})` || '#000000';
                    //     let c = this.taskTags.find(tt => tt.tagId == tag.id);
                    //     if (c) {
                    //         switch (c.sectionId) {
                    //             case 0:
                    //                 el.style.borderColor = `rgb(255,20,147)` || '#000000';
                    //                 break;
                    //             case 1:
                    //                 el.style.borderColor = `rgb(0,150,255)` || '#000000';
                    //                 break;
                    //             case 2:
                    //                 el.style.borderColor = `rgb(0,114,0)` || '#000000';
                    //                 break;
                    //             case 3:
                    //                 el.style.borderColor = `rgb(128,0,0)` || '#000000';
                    //                 break;
                    //             default:
                    //                 el.style.borderColor = `rgb(128,0,0)` || '#000000';
                    //                 break;
                    //         }
                    //     }
                    // }
                    // catch (e) {
                    //     console.error(e);
                    // }



                    // el.textContent = tag.data.label;
                    // el.appendChild(document.createElement('br'));

                    // const button = document.createElement('button');
                    // button.style.marginTop = '10px';;
                    // button.innerText = 'Click';

                    // button.addEventListener('pointerdown', () => {
                    //     // When there is a "click"
                    //     // it shows an alert in the browser
                    //     // alert('Oh, you pointerdown me!')

                    //     (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.executeClickOnTags([tag.id])
                    //     console.log("pointerdown");
                    // });

                    // el.appendChild(button);

                    // //create arrow html element
                    // const arrow = document.createElement('div');
                    // arrow.className = 'arrow';
                    // //arrow wip

                    // el.appendChild(document.createElement('br'));

                    // let tagBoxes = document.getElementById('tagBoxes');
                    // console.log('tagBoxes', tagBoxes);

                    // let tagBox = document.getElementById(`${tag.id}-ar-react-overlay`);

                    // tagBox?.parentElement?.removeChild(tagBox);
                    // if (tagBox) {


                    // const button = document.createElement('button');
                    // button.style.marginTop = '10px';;
                    // button.innerText = 'Click';

                    // button.style._innerHTML =`background-color: #2196f3; color: #fff; border: none; border-radius: 4px; padding: 8px 16px; font-size: 16px; cursor: pointer; transition: background-color 0.3s ease-in-out;" onmouseover="this.style.backgroundColor='#1976d2';" onmouseout="this.style.backgroundColor='#2196f3';`;
                    // const styles = {
                    //     backgroundColor: '#2196f3',
                    //     color: '#fff',
                    //     border: 'none',
                    //     borderRadius: '4px',
                    //     padding: '8px 16px',
                    //     fontSize: '16px',
                    //     cursor: 'pointer',
                    //     transition: 'background-color 0.3s ease-in-out',
                    // };

                    // Object.assign(button.style, styles);

                    ///


                    // Get the task card element
                    // const taskCard = document.getElementById('task-card');

                    this.createTaskEl(tag, i, el);
                    const label = new CSS2DObject(el);
                    label.position.copy(tagPosition);
                    this.renderingSystem.base.add(label);
                    this.labels.push(label);
                    // }
                } catch (e) { console.error('error with css2d', e); }

                //stem
                try {
                    const points = [
                        new THREE.Vector3(tag.data.anchorPosition.x, tag.data.anchorPosition.y, tag.data.anchorPosition.z),
                        tagPosition,
                    ];
                    const stemGeometry = new THREE.BufferGeometry().setFromPoints(points);
                    const stemMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
                    const stem = new THREE.Line(stemGeometry, stemMaterial);

                    // tagMesh.add(stem);
                    this.renderingSystem.base.add(stem);
                } catch (e) { console.error(e); }

                try {
                    // tag.data.label == 'troikaText' && this.troikaTextWidget(tag, tagMesh);
                } catch (e) { console.error('error with troika', e); }
                // const labelDiv = document.createElement('div');
                // labelDiv.className = 'label';
                // labelDiv.textContent = tag.data.label;
                // labelDiv.style.color = '#FFFFFF';
                // labelDiv.style.background = '#000000';
                //
                // const tagLabel = new CSS2DObject(labelDiv);
                // tagLabel.position.copy(tagMesh.position);
                // this.renderingSystem.base.add(tagLabel);

                // labelDiv.style.marginTop = '-1em';
                // const earthLabel = new CSS2DObject(labelDiv);
                // tagMesh.add(earthLabel);
                // earthLabel.position.set(1,1,1);
                // earthLabel.position.set(0, 0, 0);
                // earthLabel.layers.set(0);

                // for debugging - XYZ from origin
                // console.log(`[st] [ar] points ${JSON.stringify(points)}`)
                // const stemz = new THREE.Line(new THREE.BufferGeometry().setFromPoints([
                //     new THREE.Vector3(0,0,0),
                //     new THREE.Vector3(1,0,0),
                // ]), new THREE.LineBasicMaterial({ color: 0xff0000 }));
                // this.renderingSystem.base.add(stemz);

                // text on tag
                tagMesh.rotateY(THREE.MathUtils.degToRad(180));
                // let textMesh = this.createText(this.font, tag.data.label, iconColor.getHex());
                // textMesh.userData['tagId'] = tag.id;
                // textMesh.userData['text'] = tag.data.label;
                // tagMesh.add(textMesh);
                // textMesh.scale(0.5, 0.5, 0.5);

                (Simulation.instance.sdk as StSdk).RaycastSystem.addRayWorldObject(tagMesh);

                this.tagMeshes.push(tagMesh);
                // textMesh.position.set(0, -0.00625, 0);
            }
        }

        let rootDiv = document.getElementById('xrOverlay');
        rootDiv?.appendChild(this.renderingSystem.labelRenderer.domElement);

        this.renderingSystem.registerRenderCallback(this.render.bind(this));
    }

    public createTaskEl(tag: any, i: number, el: HTMLElement) {

        // Define styles for the task card elements
        const styles = {
            taskCard: {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '16px',
                width: '300px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                backgroundColor: '#fff',
            },
            title: {
                fontWeight: 'bold',
                marginBottom: '8px',
            },
            dot: {
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '8px',
            },
            statusText: {
                fontSize: '14px',
            },
            button: {
                backgroundColor: '#2196f3',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease-in-out',
            },
            editButton: {
                backgroundColor: '#2196f3',
            },
            commentButton: {
                backgroundColor: '#f44336',
            },
        };


        // const el = document.createElement('div' + i);
        // Create the title element
        const title = document.createElement('div');
        title.textContent = tag.data.label;
        Object.assign(title.style, styles.title);

        // Create the dot element to represent the task status
        const dot = document.createElement('div');
        Object.assign(dot.style, styles.dot);
        // dot.style.backgroundColor = 'green';
        let task = this.taskTags.find(tt => tt.tagId == tag.id);
        let ts = '';
        try {
            // el.style.borderColor = `rgb(${color.r}, ${color.g}, ${color.b})` || '#000000';

            if (task) {
                let cc = '#000000';

                switch (task.sectionId) {
                    case 0:
                        // el.style.borderColor = `rgb(255,20,147)` || '#000000';
                        cc = `rgb(255,20,147)` || '#000000';
                        ts = 'Scheduled';
                        break;
                    case 1:
                        // el.style.borderColor = `rgb(0,150,255)` || '#000000';
                        cc = `rgb(0,150,255)` || '#000000';
                        ts = 'In progress';
                        break;
                    case 2:
                        cc = `rgb(0,114,0)` || '#000000';
                        ts = 'Completed';
                        break;
                    case 3:
                        cc = `rgb(128,0,0)` || '#000000';
                        ts = 'Needs Attention';
                        break;
                    default:
                        cc = `rgb(128,0,0)` || '#000000';
                        break;
                }
                dot.style.backgroundColor = cc;
            }

        }
        catch (e) {
            console.error(e);
        }

        // Create the status text element
        const statusText = document.createElement('div');
        statusText.textContent = ts || '';
        Object.assign(statusText.style, styles.statusText);



        console.log('tag ar', task, tag, this.taskTags)



        // Create the status container element to hold the dot and status text
        const statusContainer = document.createElement('div');
        Object.assign(statusContainer.style, { display: 'flex', alignItems: 'center', marginTop: '8px' });
        statusContainer.appendChild(dot);
        statusContainer.appendChild(statusText);

        // // Create the status text element
        // const statusText = document.createElement('div');
        // statusText.textContent = 'Completed';
        // Object.assign(statusText.style, styles.statusText);


        // // Create the status text element
        // const latestComment = document.createElement('div');
        // latestComment.textContent = task?.comments.length > 0 ? task?.comments[task?.comments.length - 1].comment : '';
        Object.assign(statusText.style, styles.statusText);

        let image = null;
        if (tag.data.media?.src) {
            image = document.createElement('img');
            image.src = tag.data.media?.src

        }
        // Create the edit button element
        const editButton = document.createElement('button');
        editButton.textContent = 'Edit';
        Object.assign(editButton.style, styles.button, styles.editButton);

        editButton.addEventListener('pointerdown', () => {
            // When there is a "click"
            // it shows an alert in the browser
            // alert('Oh, you pointerdown me!')

            (Simulation.instance.sdk as StSdk).RaycastSystem.executeClickOnTags([tag.id])
            // console.log("pointerdown");
        });

        //     (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.executeClickOnTags([tag.id])

        // Create the comment button element
        // const commentButton = document.createElement('button');
        // commentButton.textContent = 'Add Comment';
        // Object.assign(commentButton.style, styles.button, styles.commentButton);

        // Create the button container element to hold the edit and comment buttons
        const buttonContainer = document.createElement('div');
        Object.assign(buttonContainer.style, { display: 'flex', justifyContent: 'space-between', marginTop: '16px' });
        buttonContainer.appendChild(editButton);
        // buttonContainer.appendChild(commentButton);

        // Create the task card element and add the title, status container, and button container
        const taskCardElement = document.createElement('div');



        Object.assign(taskCardElement.style, styles.taskCard);
        taskCardElement.appendChild(title);
        taskCardElement.appendChild(statusContainer);
        // taskCardElement.appendChild(latestComment);
        image && taskCardElement.appendChild(image);

        taskCardElement.appendChild(buttonContainer);


        // Add the task card element to the DOM
        el.appendChild(taskCardElement);

    }

    public createTaskEl2(tag: any, i: number, el: HTMLElement) {

        let task = this.taskTags.find(tt => tt.tagId == tag.id);
        // Define styles for the task card elements
        const styles = {
            taskCard: {
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '16px',
                width: '300px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
            },
            title: {
                fontWeight: 'bold',
                marginBottom: '8px',
            },
            dot: {
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                marginRight: '8px',
            },
            statusText: {
                fontSize: '14px',
            },
            button: {
                backgroundColor: '#2196f3',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                padding: '8px 16px',
                fontSize: '14px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease-in-out',
            },
            editButton: {
                backgroundColor: '#2196f3',
            },
            commentButton: {
                backgroundColor: '#f44336',
            },
            commentSection: {
                marginTop: '16px',
            },
            commentContainer: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
            },
            avatar: {
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                marginRight: '8px',
                backgroundColor: '#ccc',
            },
            commenterName: {
                fontWeight: 'bold',
                marginRight: '8px',
            },
            commentText: {
                fontSize: '14px',
            },
        };

        // Create the title element
        const title = document.createElement('div');
        title.textContent = 'Task Title';
        Object.assign(title.style, styles.title);

        // Create the dot element to represent the task status
        const dot = document.createElement('div');
        Object.assign(dot.style, styles.dot);
        dot.style.backgroundColor = 'green';

        // Create the status text element
        const statusText = document.createElement('div');
        statusText.textContent = 'Completed';
        Object.assign(statusText.style, styles.statusText);

        // Create the status container element to hold the dot and status text
        const statusContainer = document.createElement('div');
        Object.assign(statusContainer.style, { display: 'flex', alignItems: 'center', marginTop: '8px' });
        statusContainer.appendChild(dot);
        statusContainer.appendChild(statusText);

        // Create the edit button element
        const editButton = document.createElement('button');
        editButton.textContent = 'Edit';
        Object.assign(editButton.style, styles.button, styles.editButton);

        // Create the comment button element
        const commentButton = document.createElement('button');
        commentButton.textContent = 'Add Comment';
        Object.assign(commentButton.style, styles.button, styles.commentButton);

        // Create the button container element to hold the edit and comment buttons
        const buttonContainer = document.createElement('div');
        Object.assign(buttonContainer.style, { display: 'flex', justifyContent: 'space-between', marginTop: '16px' });
        buttonContainer.appendChild(editButton);
        buttonContainer.appendChild(commentButton);

        // Create the comment section element
        const commentSection = document.createElement('div');
        Object.assign(commentSection.style, styles.commentSection);

        // Add a sample comment to the comments section
        // const comment = document.createElement('div');
        // Object.assign(comment.style, styles.comment);

        task?.comments?.forEach((comment: CommentObj) => {
            // let comment = c.comment;
            const commentElement = document.createElement('div');
            Object.assign(commentElement.style, { display: 'flex', alignItems: 'center', marginBottom: '8px' });

            const avatarElement = document.createElement('img');
            avatarElement.src = comment.image;
            avatarElement.alt = comment.name;
            avatarElement.style.borderRadius = '50%';
            avatarElement.style.marginRight = '8px';
            avatarElement.style.width = '24px';
            avatarElement.style.height = '24px';

            const commentTextElement = document.createElement('div');
            commentTextElement.textContent = comment.comment;
            Object.assign(commentTextElement.style, { fontSize: '14px' });

            commentElement.appendChild(avatarElement);
            commentElement.appendChild(commentTextElement);

            commentSection.appendChild(commentElement);
        });

        // Create the task card element and add the title, status container, and button container
        const taskCardElement = document.createElement('div');
        Object.assign(taskCardElement.style, styles.taskCard);
        taskCardElement.appendChild(title);
        taskCardElement.appendChild(statusContainer);
        taskCardElement.appendChild(buttonContainer);

        // Add the task card element to the DOM
        el.appendChild(taskCardElement);
    }
    public load360(textureSource: string) {

        this.renderingSystem.base.remove(this.mesh360);
        this.mesh360.geometry.dispose();
        (this.mesh360.material as Material).dispose();

        if (!!textureSource) {
            console.log('loading 360image');
            var geometry = new THREE.SphereGeometry(500, 500, 500);
            geometry.scale(-1, 1, 1);

            var material = new THREE.MeshBasicMaterial({
                side: THREE.FrontSide,
                map: new THREE.TextureLoader().load(textureSource)
                // map: new THREE.TextureLoader().load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/2294472375_24a3b8ef46_o.jpg')
            });

            let mesh = new THREE.Mesh(geometry, material);
            this.mesh360 = mesh;
            this.renderingSystem.base.add(mesh);
        }
    }

    public load360Alt(textureSource: string) {

        this.mesh360 && this.renderingSystem.base.remove(this.mesh360);
        this.mesh360?.geometry?.dispose();
        (this.mesh360?.material as any)?.dispose();

        function createMaterialArray(filename: string) {
            const skyboxImagepaths = createPathStrings(filename);
            const materialArray = skyboxImagepaths.map(image => {
                let texture = new THREE.TextureLoader().load(image);
                return new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide }); // <---

            });
            return materialArray;
        }
        function createPathStrings(filename: string) {
            const basePath = "./static/skybox/";
            const baseFilename = basePath + filename;
            const fileType = ".png";
            const sides = ["ft", "bk", "up", "dn", "rt", "lf"];
            const pathStings = sides.map(side => {
                return baseFilename + "_" + side + fileType;
            });
            console.log('360 pathStings', pathStings)
            return pathStings;
        }
        const skyboxImage = textureSource;

        const materialArray = createMaterialArray(skyboxImage);
        console.log('360 materialArray', materialArray)
        let skyboxGeo = new THREE.BoxGeometry(5000, 5000, 5000);
        let skybox = new THREE.Mesh(skyboxGeo, materialArray);

        this.mesh360 = skybox;
        this.renderingSystem.base.add(skybox);
    }

    render(timestamp: any, frame: any) {

        if (frame) {
            const referenceSpace = this.renderingSystem.renderer.xr.getReferenceSpace();
            const session = this.renderingSystem.renderer.xr.getSession();

            //lloop through tagMeshes and make them look at VR camera

        }


        this.tagMeshes.forEach(tagMesh => {
            tagMesh.lookAt(this.renderingSystem.cameraSystem.camera.position);
            // let el = document.getElementById('ar-react-overlay');
            // if (el) {
            //     let pos = this.renderingSystem.cameraSystem.worldToScreenPoint(tagMesh.position, el);
            //     // el.style.marginLeft = Math.ceil(pos.x).toString() + 'px';
            //     // el.style.marginTop = Math.ceil(pos.y).toString() + 'px';

            // }
        });


        // if (this.tagRenderer) {
        //     this.tagRenderer.render(this.renderingSystem.scene, this.renderingSystem.cameraSystem.camera);
        // }


    }
}