import { Loader } from "@crema";
import ConfirmationDialog from "@crema/core/ConfirmationDialog";
import { FileUploader } from "@crema/core/FileUploader";
import ToggleButtons from "@crema/core/ToggleButtons";
import { storage } from "@crema/services/auth/firebase/firebase";
import { Box, InputLabel, TextField, Button, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchError } from "redux/actions";
import { Fonts } from "shared/constants/AppEnums";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useAuthUser } from "@crema/utility/AppHooks";
import { AppState } from "redux/store";
import { onModelUpload } from "redux/actions/ThirdParty";
import { SpaceData } from "types/models/home/HomeApp";
import clsx from "clsx";
import Spacer from "@crema/core/Spacer";

// const { messages } = appIntl();
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        required: { //doesn't work
            '&:after': {
                content: " *",
                color: 'red',
            },
        },
        fieldLabel: {
            // paddingRight: '16px',
            fontSize: '13px',
            color: 'black',
            // width: '100%',
            marginBottom: '8px',
            marginTop: '10px'
        },
        textField: {
            // width: '100%',
            fontWeight: Fonts.LIGHT,
            marginBottom: 20,
            marginTop: 0,
            // marginLeft: 16,
            // marginLeft: 5,
            // marginRight: 5
            '& .MuiOutlinedInput-input': {
                padding: "10px 14px"
            },
            '& .MuiInputBase-root': {
                fontSize: '14px'

            }
        },
    })

);

interface ModelUploaderProps {
    isUploadOpen: boolean
    setClose: () => void
}

interface ModelUploaderState {
    name: string,
    type: string,
    subfolderName: string,
    mainFolderFiles: any[],
    subFolderFiles: any[],
    loading: boolean
}

export default function ModelUploader({ isUploadOpen, setClose }: ModelUploaderProps) {

    const [name, setName] = useState('');
    const [subfolderName, setSubfolderName] = useState('');
    const [mainFolderFiles, setMainFolderFiles] = useState<any[]>([]);
    const [subFolderFiles, setSubFolderFiles] = useState<any[]>([]);
    const [thumbnailFile, setThumbnailFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const currentSpaceId = useSelector<
        AppState,
        string
    >(({ home }) => home.currentSpace?.id || '');


    const catalogItems = useSelector<
        AppState,
        any[]
    >(({ auth }) => auth.catalogItems);

    const currentSpace = useSelector<AppState, SpaceData | undefined>(({ home }) => home.currentSpace);
    // const [{ name, type, subfolderName, mainFolderFiles, subFolderFiles, loading }, setState] = useState(initialState);
    const dispatch = useDispatch();

    const classes = useStyles();
    let authUser = useAuthUser();

    const uploadMainFolderFiles = async () => {

        if (!type) {
            dispatch(fetchError("Please select a file type!"));
            return;
        }
        if (!name) {
            dispatch(fetchError("Please add a name!"));
            return;
        }
        // if (!thumbnailFile) {
        //     dispatch(fetchError("Please upload a thumbnail image!"));
        //     return;
        // }
        if (mainFolderFiles.length == 0) {
            dispatch(fetchError("You need to upload some files for the main folder!"));
            return;
        }
        if ((subFolderFiles.length > 0 && !subfolderName) || (subFolderFiles.length == 0 && !!subfolderName)) {
            dispatch(fetchError("Sub folder files and name should either both be empty, or both have data"));
            return;
        }

        //main folder files
        dispatch(onModelUpload(mainFolderFiles, type, thumbnailFile, catalogItems, name, '', currentSpaceId, setClose));

    }


    const uploadSubfolderFiles = async (): Promise<any[]> => {

        return await Promise.all(
            subFolderFiles.map((fileObj: any) => {
                let file = fileObj.file;
                let filename: any = file?.name;
                let baseModelRef = storage.ref(`3d/${type}/${name}/${subfolderName}`).child(filename);

                return baseModelRef
                    .put(file)
                // .then(() => {
                //   const imageURL = baseModelRef.getDownloadURL();
                //   return imageURL;
                // }).catch(console.error);

            }))
    }

    return (<ConfirmationDialog
        open={isUploadOpen}
        onDeny={setClose}
        onConfirm={setClose}
        title={""}
        dialogTitle={"Upload a 3D model"}
        yesLabel={"Done"}
        noLabel={"Cancel"}
        hideYesButton={true}
        isYesButtonCTA={false}
        children={

            [loading && <Loader />,
            < Box component='div'
                display='flex'
                // flexWrap='wrap'
                justifyContent="flex-start"
                flexDirection='column'
                alignContent='flex-start'
                mb={5}
            >
                {/* <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'baseline' }} > */}

                <Box style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: "50%",
                }} >

                    <InputLabel className={classes.fieldLabel}>File Type</InputLabel>
                    <ToggleButtons size={"small"} children={["obj", "dae", "gltf", "glb"]} initialValue={type}
                        handleChange={setType} />
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: "50%" }} >
                    <InputLabel className={classes.fieldLabel}>Name</InputLabel>
                    <TextField
                        style={{
                            color: 'black'
                            // width: "30%",
                            // paddingRight: "1%"
                        }}
                        className={classes.textField}
                        name='name'
                        placeholder="Name"
                        value={name}
                        onChange={event => {
                            setName(event.target.value);
                        }}
                    />
                </Box>

                {/* </Box> */}
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <h5 className={clsx(classes.fieldLabel)}>Folder Upload</h5>
                        <Spacer x={2} />
                        <span className={clsx(classes.fieldLabel)} style={{ color: 'red' }}> Use drag and drop if uploading a folder</span>
                    </div>
                    <FileUploader
                        title={`Click to select files, or drag n' drop a folder`}
                        // acceptedFileTypes='.obj, .mtl'
                        handleFiles={setMainFolderFiles}
                    // title={`Selected type: .${type} will be the main file`}
                    />
                </Box>
                <Divider />

                {/* <Box style={{ display: 'flex', flexDir
                ection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <h5 className={classes.fieldLabel} >Additional files - from a sub-folder</h5>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                        <InputLabel className={classes.fieldLabel}>Sub-folder name (ensure correct spelling and case)</InputLabel>
                        <TextField
                            style={{
                                color: 'black', fontSize: '12px'

                            }}
                            className={classes.textField}
                            name='subfolder-name'
                            value={subfolderName}
                            placeholder={"Sub-folder Name"}
                            onChange={event => {
                                setSubfolderName(event.target.value);
                            }}
                        />
                    </Box>

                    <FileUploader
                        // acceptedFileTypes='.obj, .mtl'
                        handleFiles={setSubFolderFiles} />
                </Box> */}


                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                    <h5 className={classes.fieldLabel} >Thumbnail image</h5>

                    <FileUploader
                        acceptedFileTypes='image/*'
                        handleFiles={setThumbnailFile}
                        allowOnlyOne={true}
                        title={`Drag n' drop a file here, or click to select a file`}
                         />
                </Box>
                {/* <FileUploader handleFiles={(files2: any[]) => setState(prevState => ({ ...prevState, "subFolderFiles": files2 }))} /> */}

                <Button
                    variant='contained'
                    color='secondary'
                    size='small'
                    style={{ height: '90%', alignSelf: "center", marginTop: '10px' }}
                    type='button'
                    onClick={async () => {
                        if (mainFolderFiles.length == 0) {
                            dispatch(fetchError("Please add files for the main folder!"));
                            return;
                        }
                        if (!subfolderName && subFolderFiles.length > 0) {
                            dispatch(fetchError("Please specify the sub-folder name!"));
                            return;
                        }
                        setLoading(true);
                        try {
                            await uploadMainFolderFiles();
                            // await uploadSubfolderFiles();
                            // await uploadThumbnailFile();
                            setLoading(false);

                        } catch (error) {
                            dispatch(fetchError("Something went wrong uploading your files. Please try again!"));
                            setLoading(false);
                        }

                    }}>
                    Upload
                </Button>



            </Box>
            ]
        }

    />)
}