
import type {Texture} from 'three';
import { ComponentOutput, SceneComponent } from '../SubSystems/sceneManagement/SceneComponent';
import { Size, SizeScale } from './meshComponents/basic/PlaneRenderer';

export interface IPainter2d {
  paint(context2d: CanvasRenderingContext2D | null, size: Size): void;
}

type Inputs = {
  painter: IPainter2d | null;
  textureRes: Size;
  sizeScale: SizeScale;
};

type Outputs = {
  texture: Texture | null;
} & ComponentOutput;

type Events = {
  repaint: boolean;
};

export class CanvasRenderer extends SceneComponent {
  private canvas: HTMLCanvasElement;
  private renderContext2D: CanvasRenderingContext2D | null;
  // private renderTarget: WebGLRenderTarget;

  inputs: Inputs = {
    painter: null,
    textureRes: {w: 256, h: 256},
    sizeScale: {x: 1, y: 1}
  };

  outputs = {
    texture: null,
  } as Outputs;

  events = {
    repaint: true,
  } as Events;

  onInit() {
    // @ts-ignore
    const THREE = this.context.three;

    // set up canvas 2d context
    this.canvas = document.createElement('canvas');
    this.renderContext2D = this.canvas.getContext('2d');
    this.outputs.texture = new THREE.CanvasTexture(this.canvas);

    // create three.js objects to render
    // this.renderTarget = new THREE.WebGLRenderTarget(
    //   this.inputs.textureRes.w,
    //   this.inputs.textureRes.h,
    // );
    // // @ts-ignore
    // this.renderContext2D && (this.renderTarget.texture.image = this.renderContext2D.canvas);
    this.resize(this.inputs.textureRes);
    this.repaint();

    // this.outputs.texture = this.renderTarget.texture;
  }

  onInputsUpdated(oldInputs: Inputs) {
    if (
      oldInputs.textureRes.w !== this.inputs.textureRes.w ||
      oldInputs.textureRes.h !== this.inputs.textureRes.h
    ) {
      this.resize(this.inputs.textureRes);
    }

    this.resizeSizeScale(this.inputs.sizeScale);

    if (oldInputs.painter !== this.inputs.painter) {
      this.repaint();
    }
  }

  onEvent(eventType: string, _eventData: unknown) {
    if (eventType === 'repaint') {
      this.repaint();
    }
  }

  onDestroy() {
    this.outputs.texture && this.outputs.texture.dispose();
    this.outputs.texture = null;
    // this.renderTarget.dispose();
  }

  private resizeSizeScale(sizeScale:SizeScale) {
    this.canvas.width = this.inputs.textureRes.w * sizeScale.x;
    this.canvas.height = this.inputs.textureRes.h * sizeScale.y;
    this.repaint();
  }

  private resize(size: Size) {
    this.canvas.width = size.w * this.inputs.sizeScale.x;
    this.canvas.height = size.h * this.inputs.sizeScale.y;
    // this.renderTarget.width = size.w;
    // this.renderTarget.height = size.h;
  }

  private repaint() {
    if (this.inputs.painter) {
      this.inputs.painter.paint(this.renderContext2D, this.inputs.textureRes);
      // this.renderTarget.texture.needsUpdate = true;
      this.outputs.texture && (this.outputs.texture.needsUpdate = true);
    }
  }
}

export interface ICanvasRenderer extends SceneComponent {
  inputs: Inputs;
  outputs: Outputs;
}

export const canvasRendererType = 'mp.canvasRenderer';
export function makeCanvasRenderer() {
  return new CanvasRenderer();
}
