import { SpatialThinkSDKMode } from "CustomSdk/SpatialThinkSDK"
import { isST } from "modules/home/SpaceDetail/utils"
import Simulation, { ExternalSdkMode } from "mp/core/craEngine/SubSystems/core/Simulation"
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent"
import NodeStorage from "mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage"
import { Dispatch } from "react"
import { AppActions } from "types"
import { SET_SDK_MODE, SHOW_GIZMO } from "types/actions/ThreeD.actions"

export const saveLastNode2 = (node: ISceneNode, delay: boolean = false, forceSpecificNode: ISceneNode | null = null) => {

    return (dispatch: Dispatch<AppActions>) => {

        let localTargetNodeToSave = node;
        if (forceSpecificNode) {
            localTargetNodeToSave = forceSpecificNode;
        }
        if (delay) {
            // if (this.nodeSaveQueueScheduler) {
            //     this.nodeSaveQueueScheduler.addQueueElement(localTargetNodeToSave, true);
            // } else {
            //     console.error("Critical: Delayed Save Scheduler seems to be broken")
            // }
        } else {
            if (localTargetNodeToSave) {
                NodeStorage.storeNode(localTargetNodeToSave);
            }
        }
    }

    // let spaceId = store.getState().home?.currentSpace?.id;
    // let lessonId = store.getState().layer?.currentLesson?.id;
    // let currentTagGroupId = store.getState().layer?.currentTagGroupId;
    // let tagGroupsList = store.getState().layer.tagGroupsList;

    // let currentTagGroup = tagGroupsList.find(tg => tg.id == currentTagGroupId);

    // if (currentTagGroup) {
    //     currentTagGroup.logic = logic;
    //     console.log(`[st] saving step logic ${JSON.stringify(logic)}`);

    //     spaceId && lessonId && currentTagGroup && dispatch(onUpdateTagGroup(currentTagGroup
    //         // spaceId, lessonId, currentTagGroup, tagGroupsList
    //     ));
    // }
}
export const showGizmo = (show: boolean) => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SHOW_GIZMO, payload: show });
    }
}

export const setSDKMode = (sdkMode: SpatialThinkSDKMode | ExternalSdkMode | undefined) => {
    return (dispatch: Dispatch<AppActions>) => {

        (!sdkMode || sdkMode !== ExternalSdkMode.MP) && Simulation.instance.stopMPSDK();
        (!sdkMode || isST()) && Simulation.instance.stopSTSDK();

        dispatch({ type: SET_SDK_MODE, payload: sdkMode });
    }
}

// import Simulation from "mp/core/craEngine/SubSystems/core/Simulation"
// import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent"
// import { Dispatch } from "react"
// import { AppActions } from "types"
// import { SELECT_NODE } from "types/actions/ThreeD.actions"
// import { NodeProperties } from "types/models/home/HomeApp"

// export const updateSelectedNodePosition = (
//     node: NodeProperties,
//     position: any
// ): any => {
//     return (dispatch: Dispatch<AppActions>) => {

//         // dispatch({ type: SELECT_NODE, payload: { ...node } })
//         // node.position.setX(n)

//         // Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.position.setX(n));
//         // Simulation.instance.propertiesPanel.saveLastNode(false, { name: node.name, node.position, node.});


//     }
// }
