import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import NodeStorage from "mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage";
import { MpSdk, Scene } from "types/SdkTypes2";
import { SDKInstance } from "../Sdk";
import { Node } from "./Node";

export class FireLegacy extends Node {

	/*
	 * Factory method to create a new instance of the class
	*/
	public static async makeLegacyFire(mpSdk: MpSdk, sceneObject: Scene.IObject) {
		let f = new FireLegacy(mpSdk, sceneObject);

		let nodes = await NodeStorage.loadNode({objectOrObjectName: 'Fire'}

		);
		if (nodes.length > 0){
			f.node = nodes[0];
		} else {
			console.error(' [r3f] error: no nodes could be loaded for legacy fire');
		}
		return f;
	}

	private constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {

		console.log('loading legacy fire');
		super(mpSdk, sceneObject);

	}
}
