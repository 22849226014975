import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const store = configureStore();

if(process.env.NODE_ENV !== 'development'){
  setupLogRocketReact(LogRocket);
  LogRocket.init('jpligl/spatial-think');
}

const App = () => (
  <>
  {/* @ts-ignore */}
  <ContextProvider>
    <Provider store={store}>
      {/* @ts-ignore */}
      <CremaThemeProvider>
        {/* @ts-ignore */}
        <CremaStyleProvider>
          <LocaleProvider>
            <ConnectedRouter history={history}>
              <AuthRoutes>
                <CssBaseline />
                <AppLayout />
              </AuthRoutes>
            </ConnectedRouter>
          </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </Provider>
  </ContextProvider>
  </>
);

export default App;
