import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { hideAlert, onCreateTask, showAlert, showMessage } from '../../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { GridContainer, Scrollbar } from '../../../../../../@crema';
import { useAuthUser } from '../../../../../../@crema/utility/AppHooks';
import IntlMessages from '../../../../../../@crema/utility/IntlMessages';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography, Tooltip, Checkbox, DialogContent } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Button, Divider, Fab, Grid, makeStyles, TextField, IconButton, useTheme } from '@material-ui/core';
import { Fonts } from '../../../../../../shared/constants/AppEnums';
import { TransitionProps } from '@material-ui/core/transitions';
import { useIntl } from 'react-intl';
import VariablesForm from './VariablesForm';
import { CremaTheme } from '../../../../../../types/AppContextPropsType';
import { AppState } from 'redux/store';
import CancelIcon from '@material-ui/icons/Cancel';
import { VariableTypeAllowedValues, getLabelForVariableType, VariableTypeName, VariableTypesAvailable } from './VariableTypes';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SpaceData } from 'types/models/home/HomeApp';
import { onSetSpaceVariables } from 'redux/actions/Home';
import Spacer from '@crema/core/Spacer';
import { RichHelpBadge } from '@crema/core/HelpBage';
import Utils from 'mp/core/craEngine/Tools/Utils';
import _ from 'lodash';

const useStyles = makeStyles((theme: CremaTheme) => ({
  card: {
    height: 'auto',
    padding: '20px 32px',
  },
  scrollRoot: {
    paddingTop: 4,
    // display: "flex",
    // max-height: calc(100% - 64px);
    // flexDirection: "column",
    // alignContent: "center",
    // alignItems: "center"
    // height: 520,
  },
  dialogBox: {
    position: 'relative',

    '& .MuiDialog-paperWidthSm': {
      maxWidth: 900,
      width: '100%',
      backgroundColor: theme.palette.background.default,
      padding: '16px'
    },
  },

  textField: {
    // width: '100%',
    fontWeight: Fonts.LIGHT,
    margin: theme.spacing(1),
    // marginBottom: 20,
    marginTop: 16,
    // marginLeft: 5,
    // marginRight: 5
  },

  selectFormControl: {
    display: 'flex',
    minWidth: "20%",
    color: "black",
    '& .MuiFormLabel-root': {
      color: "black",
      marginLeft: 16,
      marginTop: 8,
    }
  },

  selectBox: {
    // width: '100%',
    minWidth: '20%',
    display: 'flex',
    margin: theme.spacing(1),
    fontWeight: Fonts.LIGHT,
    // marginBottom: 20,
    marginTop: 16,
    // marginLeft: 5,
    // marginRight: 5
  },

  selectMenuItem: {
    display: 'flex',
    color: "black",
    // fontWeight: Fonts.LIGHT,
  },

  divider: {
    marginTop: 20,
    // marginBottom: 20,
    [theme.breakpoints.up('xl')]: {
      marginTop: 32,
      marginBottom: 32,
    },
  },
  button: {
    // backgroundColor: theme.palette.primary.main,
    fontWeight: Fonts.LIGHT,
    color: theme.palette.primary.contrastText,
    padding: '12px 16px',
    width: 192,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.contrastText,
    },
  },
  btnRoot: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  saveBtnBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBotton: "40px"
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface VariablesProps {
  isVariablesOpen: boolean;
  onCloseVariables: () => void;
  // variables: any[];
}

const Variables: React.FC<VariablesProps> = ({
  isVariablesOpen,
  onCloseVariables,
}) => {
  const dispatch = useDispatch();

  const currentSpace = useSelector<AppState, SpaceData | undefined>(({ home }) => home.currentSpace);
  const spaceVariables = useSelector<AppState, any[]>(({ home }) => home.spaceVariables || []);
  const [localVariablesList, setLocalVariablesList] = useState<any[]>([]);
  // const [taskLabels, setTaskLabels] = useState<LabelObj[]>([]);

  const theme = useTheme();
  const classes = useStyles();

  const { messages } = useIntl();

  useEffect(() => {
    setLocalVariablesList(JSON.parse(JSON.stringify(spaceVariables)));
  }, []);


  const onAddNewVariable = () => {
    localVariablesList.push({ name: '', values: '', type: '' });
    setLocalVariablesList([...localVariablesList]);
    // dispatch(onSetSpaceVariables(localVariablesList));
    dispatch(showMessage('Empty variable added to the bottom'));
  }

  const handleDeleteClick = async (
    event: React.MouseEvent,
    index: number
  ) => {
    let res = window.confirm(`Are you sure you want to delete variable: ${localVariablesList[index].name}?`);
    if (res) {
      localVariablesList.splice(index, 1);
      setLocalVariablesList([...localVariablesList]);
      //Simulation.instance.propertiesPanel.deleteItem(typeOfDropDown, index);
    }

  };

  const validateVariables = (): boolean => {
    if (currentSpace) {
      currentSpace.variables = localVariablesList;
      let toSaveVariableList = localVariablesList.map(x => {
        return {
          "name": x.name,
          "type": x.type,
          "values": x.values,
          "allowControl": x.allowControl,
        };
      });

      if ((toSaveVariableList || []).length == 0) {
        return true;
      }

      // toSaveVariableList = toSaveVariableList.filter(v => !!v.name || !!v.values);

      if (toSaveVariableList.find(v => v.name.trim() == '')) {
        alert(`Variable Name can't be empty`);
      } else if (toSaveVariableList.find(v => v.values.trim() == '')) {
        alert(`List of Allowed Values can't be empty!`);
      } else if (toSaveVariableList.find(v => {
        let range = v.values.trim().split(',').filter((v: any) => !!v.trim());

        if (range.length == 0) {
          return true;
        }

        return false;
      })) {
        alert(`Commas without values are not allowed in Allowed Values`);
      } else if (toSaveVariableList.find(v => v.type.trim() == '')) {
        alert(`Variable Type can't be empty`);
      } else if (hasDuplicateNames(toSaveVariableList)) {
        alert(`Duplicate variable names found. Please rename the variables to make sure all names are unique!`);
      } else {
        console.log(toSaveVariableList);
        return true;
      }

    }
    return false;
  }

  function hasDuplicateNames(toSaveVariableList: any[]) {
    let namesSet = new Set(toSaveVariableList.map(v => v.name.toLowerCase().trim()) || []);
    if (namesSet.size !== toSaveVariableList.length) {
      return true;
    }
    return false;
  }

  function onCancel() {
    if (validateVariables()) {

      if (!_.isEqual(JSON.parse(JSON.stringify(spaceVariables)), JSON.parse(JSON.stringify(localVariablesList)))) {

        dispatch(showAlert({
          open: true,
          title: messages['common.unsavedChanges'],
          dialogTitle: 'Unsaved Changes',
          // cancelNotAllowed: true,
          noLabel: 'Discard',
          yesLabel: 'Review',
          onConfirm: () => {

            dispatch(hideAlert());
          },
          onDeny: () => {
            onCloseVariables();
            dispatch(hideAlert());
          },
        }));
      } else {
        onCloseVariables();
      }

    }
  }

  function getDeletedVariables() {
    return spaceVariables.map(v => v.name)
      .filter(sv => !((localVariablesList.map(v => v.name)).includes(sv))) || [];
  }

  console.log(`%c[st] rendering Variables ${localVariablesList?.length} & ${spaceVariables?.length}`, 'color: orange;');
  return (
    <Dialog
      open={isVariablesOpen}
      onClose={onCancel}
      scroll='paper'
      aria-labelledby='variables'
      TransitionComponent={Transition}
      aria-describedby='Add your system variables here'
      className={classes.dialogBox}>
      <DialogContent>
        <Box pt={4} px={4} id={'Variables-heading'} style={{ margin: 'auto' }} display='inline-flex' flexWrap='wrap' alignItems='center'>
          <Typography variant='h5' id={'Variables-heading-text'}
          // fontWeight={Fonts.LIGHT}
          >
            Variables
          </Typography>
          <RichHelpBadge
            // type="popper"
            title="Variables"
            content={[{
              text: `Variables represent state of your system that can change`
            },
            { text: `For e.g. 'Power could be Off or On'. So a variable called Power would have 'Allowed values' = 'On,Off'` },
            { text: `Make sure to separate your Allowed Values with commas. There's no limit on the number of allowed values` },
            { text: `Temperature could be '70,70.1,73, a Lever could be 'Open, Partially closed, Closed'. ` },
            {
              text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose. You can create scenarios like: 'If the Power is On and the Lever is Open, show Fire",
              imgSrc: "/assets/images/help/InteractionsHelp.png"
            }
            ]}
          >
          </RichHelpBadge>
          <Spacer y={6} />
        </Box>

        {/* <Scrollbar className={classes.scrollRoot} > */}
        {localVariablesList?.length > 0 && <Grid container alignItems="center"
          style={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
            // boxShadow: 'box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '16px',
            padding: '16px',
            margin: '8px'
          }}>

          {localVariablesList.map((variable: any, key: number) => (
            <Box key={key}
              style={{ width: '100%' }}
              display='inline-flex'
              // justifyContent="flex-start"
              // flexDirection="row"
              flexWrap='wrap'
              alignItems="center"
              mb={5}
            >
              <Grid item sm={3} xs={6}>
                <Box display='inline-flex' alignItems={'center'} flexWrap='wrap'>

                  {/* <GridContainer>
                <Grid item sm={12} sm={6} md={3}> */}

                  {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}
                  <TextField

                    className={classes.textField}
                    variant='outlined'
                    label="Variable Name"
                    name='variableName'
                    value={variable.name}
                    style={{ width: '100%', }}
                    onChange={event => {
                      // localVariablesList[index].name = event.target.value;
                      variable.name = event.target.value;
                      setLocalVariablesList([...localVariablesList]);
                    }}
                  />
                </Box>
              </Grid>
              {/* </Box> */}
              {/* </Grid> */}

              {/* <Grid item sm={12} sm={6} md={3}> */}
              {/* <Box style={{
                // width: "30%",
                paddingRight: "1%"
              }} */}
              {/* // width={1} clone
              > */}
              {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}

              {/* </Box> */}
              {/* </Box>
              <Box style={{
                // width: "30%",
                paddingRight: "1%"
              }} */}
              {/* // width={1} clone
              > */}

              {/* <Box display={{ xs: 'block', sm: 'block', md: 'inline', lg: 'inline' }}> */}
              {/* <TextField

                  className={classes.textField}
                  variant='outlined'
                  label="Allowed Values"
                  name='variableValues'
                  value={variable.values}
                  onChange={event => {
                    // localVariablesList[index].values = event.target.value;
                    variable.values = event.target.value;
                    setLocalVariablesList([...localVariablesList]);
                  }}
                // onChange={value => setFieldValue('variableValues', value)}
                /> */}
              {/* </Box>/ */}
              {/* </Box> */}
              {/* </Grid> */}
              {/* </GridContainer> */}
              <Grid item sm={2} xs={6}>
                <FormControl className={classes.selectFormControl}>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    className={classes.selectBox}
                    variant='outlined'
                    label="Type"
                    name='variableType'
                    value={variable.type}
                    onChange={event => {
                      // localVariablesList[index].values = event.target.value;

                      if (variable.type !== event.target.value) {
                        variable.type = event.target.value;
                        setLocalVariablesList([...localVariablesList]);

                        if (variable.type === VariableTypeName.booleanVariableType) {
                          variable.values = VariableTypeAllowedValues.getAllowedTypes(variable.type as VariableTypeName).toString();
                        }
                      }
                    }}
                  // onChange={value => setFieldValue('variableValues', value)}
                  >
                    {Object.values(VariableTypesAvailable).map((val: any) =>
                      (val !== 'List Difference' || currentSpace?.did?.includes('q4NzdpbTsj6'))  //TODO: FIXME HARDCODED space id for list difference
                      && <MenuItem key={val} className={classes.selectMenuItem} value={val}>{getLabelForVariableType(val)}</MenuItem>
                    )}
                  </Select>

                </FormControl>
              </Grid>
              <Grid item sm={6} xs={10}>
                {<TextField
                  className={classes.textField}
                  variant='outlined'
                  label="Allowed Values"
                  name='variableValues'
                  placeholder='Add comma separated values e.g. Open,Close,Broken'
                  value={variable.values}
                  style={{ width: '100%', }}
                  onChange={event => {
                    // localVariablesList[index].values = event.target.value;
                    variable.values = event.target.value;
                    setLocalVariablesList([...localVariablesList]);
                  }}
                // onChange={value => setFieldValue('variableValues', value)}
                />}
              </Grid>
              {/* <FormControl fullWidth size="small">
                <Select
                  labelId={`default-var-value-${variable.name}`}
                  id={`default-var-value-${variable.name}`}
                  value={(variable.values?.split(",") || []).concat('')[0]}
                  // label="Age"
                  onChange={(x) => {
                    variable.value = x.target.value;
                  }}
                >
                  {(variable.values || []).concat("").map((rv: any) => (
                    <MenuItem value={rv}>{rv}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              {/* <Checkbox
                // edge='start'
                checked={variable.allowControl}
                // tabIndex={-1}
                onChange={(event) => {
                  variable.allowControl = event.target.checked
                  setLocalVariablesList([...localVariablesList]);

                }}
                // disableRipple
                inputProps={{ 'aria-labelledby': "Show?" }}
              /> Show in Control Panel? */}
              {/* <Box px={2} py={6}> */}
              <Grid item sm={1} xs={1} style={{ display: 'inline-flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                <Tooltip arrow title="Delete" style={{ marginLeft: '2px' }}>
                  <IconButton color="primary" size='small' onClick={(e) => { handleDeleteClick(e, key) }} >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              {/* </Box> */}
            </Box>))}

          {/* <Fab
            className={classes.btnRoot}
            onClick={() => onAddNewVariable()}>
            Add New
          </Fab> */}


        </Grid>}
        {/* </Scrollbar> */}
      </DialogContent>
      <Box
        style={{
          margin: 'auto',
        }}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          style={{ height: '90%', marginBottom: '10px' }}
          type='button'
          onClick={onAddNewVariable}>
          Add Variable
        </Button>
      </Box>

      {/* <Divider className={classes.divider} /> */}

      <Box px={8} py={4} className={classes.saveBtnBox}>
        {getDeletedVariables().length > 0 && <Typography variant="body2" color="error" style={{ marginRight: 'auto' }}>
          Deleted: {getDeletedVariables().join(", ")}
        </Typography>}
        <Button
          style={{ marginRight: '4px' }}
          // className={classes.btnRoot}
          color='secondary'
          variant='outlined'
          // disabled={isSubmitting}
          // type='submit'
          onClick={onCancel}>
          Cancel</Button>
        <Button
          className={classes.btnRoot}
          color='secondary'
          variant='contained'
          // disabled={isSubmitting}
          // type='submit'
          onClick={async () => {
            if (validateVariables()) {
              let toSaveVariableList = localVariablesList.filter(v => !!v.name && !!v.values);
              dispatch(onSetSpaceVariables(toSaveVariableList));
              // await firestore.doc(`Spaces/${currentSpace.id}`).update({ variables: toSaveVariableList });
              // dispatch({ type: SET_SPACE_DATA, payload: currentSpace });
              // Simulation.instance.updateVariablesState(toSaveVariableList, undefined, undefined);
              onCloseVariables();
            }
          }}
        >
          <IntlMessages id='common.save' />
        </Button>
      </Box>
    </Dialog >
  );
};

export default Variables;
