import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import Utils from 'mp/core/craEngine/Tools/Utils';
import React from 'react';
import {Color, ColorResult, SketchPicker} from 'react-color';

interface Props {
  onColorChanged(colorHex:string, alpha:number):void;
  visible:boolean;
  color:Color;
  children?:any;
}

interface State {
  displayColorPicker:boolean;
  color:Color;
}

class ColorPicker extends React.Component<Props, State> {
  state = {
    displayColorPicker: false,
    color: "#ffff00ff",
  };

  handleClick = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker});
  };

  handleClose = () => {
    this.setState({displayColorPicker: false});
  };

  componentDidUpdate() {

    //(this.props as any).color = this.state.color;
    //this.setColor(this.props.color);
  }

  componentDidMount() {
    this.setColor(this.props.color);
  }

  constructor(props:Props) {
    super(props);


  }

  handleChange = (color:ColorResult) => {
    let alpha:number = 0;
    if(color.rgb.a) {
      alpha = color.rgb.a;
    }
    this.setState({color: Utils.GetHexWithAlpha(color.hex, alpha )});
    this.props.onColorChanged(color.hex, alpha);
    Simulation.instance.propertiesPanel.saveLastNode(true);
  };

  setColor(newColor:Color) {
      this.setState( {
        color: newColor
      });
      //this.forceUpdate();
  }


  render() {
    //const background = `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`;
    var background = `${this.state.color}`;
    const isVisible = this.props.visible;
    return (
      isVisible &&
      <div>
        <div onClick={this.handleClick.bind(this)}>
          <div
            style={{
              backgroundColor: background,
              border: 1,
              borderColor: "#000000",
              borderWidth:1,
              borderStyle: "solid",
              width: 36,
              height: 14,
              borderRadius: 2,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div>
            <div onClick={this.handleClose.bind(this)} />
            <SketchPicker
              color={this.state.color as any}
              onChange={this.handleChange.bind(this)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;