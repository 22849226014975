import {WindowingSystem} from './WindowingSystem';
import * as THREE from 'three';
import {RenderingSystem} from './RenderingSystem';
import {WebXRController} from 'three';

export interface GenericEventCallback<T> {
    (e:T):void;
}

export enum TriggerState {
    Pressing,
    NotPressing,
}

export class InputSystem {
    protected mouseDownCallbacks:GenericEventCallback<MouseEvent>[];
    protected mouseUpCallbacks:GenericEventCallback<MouseEvent>[];
    protected mouseMoveCallbacks:GenericEventCallback<MouseEvent>[];
    protected touchStartCallbacks:GenericEventCallback<TouchEvent>[];
    protected touchMoveCallbacks:GenericEventCallback<TouchEvent>[];
    protected touchEndCallbacks:GenericEventCallback<TouchEvent>[];
    protected touchCancelCallbacks:GenericEventCallback<TouchEvent>[];
    protected clickCallbacks:GenericEventCallback<MouseEvent>[];

    protected xrOnSelectCallbacks:GenericEventCallback<any>[];
    protected xrOnSelectStartCallbacks:GenericEventCallback<any>[];
    protected xrOnSelectEndCallbacks:GenericEventCallback<any>[];

    protected xrOnSqueezeStartCallbacks:GenericEventCallback<any>[];
    protected xrOnSqueezeEndCallbacks:GenericEventCallback<any>[];

    readonly keyStates:{ [key: string]: boolean } = {};

    private _mouseDown:boolean;

    protected pointer: THREE.Vector2;

    protected controllers: THREE.Group[];

    private _initialized:boolean = false;

    constructor(protected windowingSystem:WindowingSystem) {
        this.windowingSystem.getShowcaseDocument().addEventListener('mousedown', this.onMouseDown.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('mouseup', this.onMouseUp.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('mousemove', this.onMouseMove.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('mouseleave', this.onMouseUp.bind(this));

        window.addEventListener('keydown', this.onKeyDown.bind(this));
        window.addEventListener('keyup', this.onKeyUp.bind(this));

        this.windowingSystem.getShowcaseDocument().addEventListener('touchstart', this.onTouchStart.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('touchmove', this.onTouchMove.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('touchend', this.onTouchEnd.bind(this));
        this.windowingSystem.getShowcaseDocument().addEventListener('touchcancel', this.onTouchCancel.bind(this));

        this.windowingSystem.getShowcaseDocument().addEventListener('click', this.onClick.bind(this));

        this.pointer = new THREE.Vector2();

        this.mouseDownCallbacks = [];
        this.mouseUpCallbacks = [];
        this.mouseMoveCallbacks = [];
        this.touchStartCallbacks = [];
        this.touchMoveCallbacks = [];
        this.touchEndCallbacks = [];
        this.touchCancelCallbacks = [];
        this.clickCallbacks = [];


        this.xrOnSelectCallbacks = [];
        this.xrOnSelectStartCallbacks = [];
        this.xrOnSelectEndCallbacks = [];
        this.xrOnSqueezeStartCallbacks = [];
        this.xrOnSqueezeEndCallbacks = [];

        this._mouseDown = false;

        //For the pointer
        this.registerMouseMoveCallback(this.__onPointerMove.bind(this));

        this.registerTouchMoveCallback(this.__onTouchMove.bind(this));
        this.registerTouchStartCallback(this.__onTouchMove.bind(this));
        this.registerTouchEndCallback(this.__onTouchMove.bind(this));
    }

    public clearXRCallbacks() {
        this.xrOnSelectCallbacks = [];
        this.xrOnSelectStartCallbacks = [];
        this.xrOnSelectEndCallbacks = [];
        this.xrOnSqueezeStartCallbacks = [];
        this.xrOnSqueezeEndCallbacks = [];
    }

    public getControllers(){
        return this.controllers;
    }

    public setupXRControllers(renderingSystem: RenderingSystem):void {
        if (this._initialized) {
            return;
        }
        this.clearXRCallbacks();
        this.controllers = [];
        for (let i = 0; i < 2; i++) {
            let controller = renderingSystem.renderer.xr.getController(i);
            controller.userData.trigger1State = TriggerState.NotPressing;
            controller.userData.trigger2State = TriggerState.NotPressing;

            controller.addEventListener('select', (event) => {
                // console.log(event.data.handedness);
                // console.log(event.target);

                for (const singleCallback of this.xrOnSelectCallbacks) {
                    singleCallback(event);
                }

            });

            controller.addEventListener('squeezestart', (event) => {
                // console.log(event.data.handedness);
                // console.log(event.target);

                for (const singleCallback of this.xrOnSqueezeStartCallbacks) {
                    singleCallback(event);
                }

                if(controller.userData.trigger2State == TriggerState.NotPressing) {
                    controller.userData.trigger2State = TriggerState.Pressing;
                }


            });

            controller.addEventListener('squeezeend', (event) => {
                // console.log(event.data.handedness);
                // console.log(event.target);

                for (const singleCallback of this.xrOnSqueezeEndCallbacks) {
                    singleCallback(event);
                }

                if(controller.userData.trigger2State == TriggerState.Pressing) {
                    controller.userData.trigger2State = TriggerState.NotPressing;
                }
            });

            controller.addEventListener('selectstart', (event) => {
                // console.log(event.data.handedness);
                // console.log(event.target);



                for (const singleCallback of this.xrOnSelectStartCallbacks) {
                    singleCallback(event);
                }

                if(controller.userData.trigger1State == TriggerState.NotPressing) {
                    controller.userData.trigger1State = TriggerState.Pressing;
                }
            });

            controller.addEventListener('selectend', (event) => {
                // console.log(event.data.handedness);
                // console.log(event.target);

                for (const singleCallback of this.xrOnSelectEndCallbacks) {
                    singleCallback(event);
                }
                if(controller.userData.trigger1State == TriggerState.Pressing) {
                    controller.userData.trigger1State = TriggerState.NotPressing;
                }

                console.log(this.xrOnSelectEndCallbacks.length);
            });



            renderingSystem.scene.add(controller);
            this.controllers.push(controller);
        }
        this._initialized = true;
    }

    public registerXROnSelectCallback(callback:GenericEventCallback<WebXRController>):number {
        return this.xrOnSelectCallbacks.push(callback) - 1;
    }

    public registerXROnSelectStartCallback(callback:GenericEventCallback<WebXRController>):number {
        return this.xrOnSelectStartCallbacks.push(callback) - 1;
    }

    public registerXROnSelectEndCallback(callback:GenericEventCallback<WebXRController>):number {
        return this.xrOnSelectEndCallbacks.push(callback) - 1;
    }

    public registerXROnSqueezeStartCallback(callback:GenericEventCallback<WebXRController>):number {
        return this.xrOnSqueezeStartCallbacks.push(callback) - 1;
    }

    public registerXROnSqueezeEndCallback(callback:GenericEventCallback<WebXRController>):number {
        return this.xrOnSqueezeEndCallbacks.push(callback) - 1;
    }

    //get controllers
    public get Controllers(): THREE.Group[] {
        return this.controllers;
    }

    protected onKeyDown(e:KeyboardEvent) {
        this.keyStates[ e.code ] = true;
    }

    protected onKeyUp(e:KeyboardEvent) {
        this.keyStates[ e.code ] = false;
    }

    public registerMouseUpCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseUpCallbacks.push(callback) - 1;
    }

    public registerMouseDownCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseDownCallbacks.push(callback) - 1;
    }

    public registerMouseMoveCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.mouseMoveCallbacks.push(callback) - 1;
    }

    public registerMouseClickCallback(callback:GenericEventCallback<MouseEvent>):number {
        return this.clickCallbacks.push(callback) - 1;
    }

    public registerTouchMoveCallback(callback:GenericEventCallback<TouchEvent>):number {
        return this.touchMoveCallbacks.push(callback) - 1;
    }

    public registerTouchStartCallback(callback:GenericEventCallback<TouchEvent>):number {
        return this.touchStartCallbacks.push(callback) - 1;
    }

    public registerTouchEndCallback(callback:GenericEventCallback<TouchEvent>):number {
        return this.touchEndCallbacks.push(callback) - 1;
    }

    public registerTouchCancelCallback(callback:GenericEventCallback<TouchEvent>):number {
        return this.touchCancelCallbacks.push(callback) - 1;
    }

    protected onMouseMove(e:MouseEvent):void {
        for (const singleCallback of this.mouseMoveCallbacks) {
            singleCallback(e);
        }
    }

    protected onMouseDown(e:MouseEvent):void {
        for (const singleCallback of this.mouseDownCallbacks) {
            singleCallback(e);
        }

        this._mouseDown = true;
    }

    protected onMouseUp(e:MouseEvent):void {
        for (const singleCallback of this.mouseUpCallbacks) {
            singleCallback(e);
        }

        this._mouseDown = false;
    }

    protected onTouchStart(e:TouchEvent):void {
        for (const singleCallback of this.touchStartCallbacks) {
            singleCallback(e);
        }
        this._mouseDown = true;
    }

    protected onTouchMove(e:TouchEvent):void {
        for (const singleCallback of this.touchMoveCallbacks) {
            singleCallback(e);
        }
    }

    protected onTouchEnd(e:TouchEvent):void {
        for (const singleCallback of this.touchEndCallbacks) {
            singleCallback(e);
        }

        this._mouseDown = false;
    }

    protected onTouchCancel(e:TouchEvent):void {
        for (const singleCallback of this.touchCancelCallbacks) {
            singleCallback(e);
        }

        this._mouseDown = false;
    }

    protected onClick(e:MouseEvent):void {
        for (const singleCallback of this.clickCallbacks) {
            singleCallback(e);
        }

        this._mouseDown = false;
    }

    get MouseDown(): boolean {
        return this._mouseDown;
    }

    protected __onPointerMove(e: MouseEvent) {
        // this.pointer.x = (e.clientX / this.renderSystem.cameraSystem.windowingSystem.width) * 2 - 1;
        // this.pointer.y = -(e.clientY / this.renderSystem.cameraSystem.windowingSystem.height) * 2 + 1;
        const rect = this.windowingSystem.getBoundingClientRect();
        this.pointer.x = ( ( e.clientX - rect.left ) / ( rect. right - rect.left ) ) * 2 - 1;
        this.pointer.y = - ( ( e.clientY - rect.top ) / ( rect.bottom - rect.top) ) * 2 + 1;
    }

    protected __onTouchMove(e: TouchEvent) {
        var touch = e.touches[0] || e.changedTouches[0];

        this.pointer.x = (touch.pageX / this.windowingSystem.width) * 2 - 1;
        this.pointer.y = -(touch.pageY / this.windowingSystem.height) * 2 + 1;
    }

    //getter for the pointer
    public get Pointer(): THREE.Vector2 {
        return this.pointer;
    }
}
