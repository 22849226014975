// @ts-ignore
import { Color } from 'react-color';
import * as THREE from "three"
import * as TWEEN from '@tweenjs/tween.js'
import Utils from '../../Tools/Utils';
import QueueScheduler from '../../Tools/QueueScheduler';
import Simulation from '../../SubSystems/core/Simulation';
import { ComponentContext, ComponentInteractionType, ISceneNode, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';

interface Inputs {
  running: boolean;
}

// (elsewhere at top-level)
export interface EulerIntegratorFunctionMachine {
  (dt:number): void;
}

  export class EulerIntegrator extends SceneComponent {
    public pivot: THREE.Object3D | null = null;
    public scene: THREE.Scene;

    private static _instance:EulerIntegrator | null = null;

    public integrators : EulerIntegratorFunctionMachine[];

    inputs: Inputs = {
        running: true,
    };

    events = {
      [ComponentInteractionType.CLICK]: false,
      [ComponentInteractionType.HOVER]: false,
      [ComponentInteractionType.DRAG]: false,
    };

    onInit() {
      
      const THREE = this.context.three;
      const root = this.context.root;

      this.scene = this.context.scene;

      this.pivot = new THREE.Object3D();
      // @ts-ignore
      this.outputs.objectRoot = this.pivot;

      this.scene = this.context.scene;

      (root as any).obj3D.add(this.pivot)
      
      //create light in threejs
      // const light = new THREE.DirectionalLight( 0xffffff, 1 );
      // light.position.set( 0, 1, 1 );
      // this.scene.add( light );
      //
      // //create ambient light in threejs
      //   const ambientLight = new THREE.AmbientLight( 0xffffff, 0.5 );
      //   this.scene.add( ambientLight );
      
      

      EulerIntegrator._instance = this;
      this.integrators = [];
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
    }

    onInputsUpdated(oldInputs: Inputs) {
      // @ts-ignore
      const THREE = this.context.three;

      if (oldInputs.running !== this.inputs.running) {
        // @ts-ignore
      }
    }

    public static get instance():EulerIntegrator | null {
      return EulerIntegrator._instance;
    }

    onTick(delta: number) {
    super.onTick(delta);
      // @ts-ignore
      //console.log("hieulerIntegratorNode")
      TWEEN.update();

      //Fear not, these are glorified tickers!
      for (let i of this.integrators) {
        i(delta);
      }

      if(Simulation) {
          QueueScheduler.ProcessAllQueueSchedulers(delta);
      }
    }

    onDestroy() {
      this.outputs.collider = null;
      this.outputs.objectRoot = null;
      EulerIntegrator._instance = null;
    }
  }

  export const eulerIntegratorType = 'mp.eulerIntegrator';
  export const makeEulerIntegrator = function () {
    return new EulerIntegrator();
  };
