import { store } from "App";
import { showGizmo } from "redux/actions/ThreeD";
import { SHOW_PROP_PANEL } from "types/actions/ThreeD.actions";
import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import Simulation from "../../core/Simulation";
import { IComponentEventSpy } from "../../sceneManagement/SceneComponent";
import { PropertiesPanelMode } from "../../ui-interop/PropertiesPanel";

export class GenericInputDragBeginSpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.DragBegin;
    constructor() {
    }
    onEvent(payload: any) {
        //Mouse was clicked in scene
        console.log('drag object GenericInputDragBeginSpy', payload);
        if (payload.event.buttons == 1) {
            Simulation.instance.resetSimulationMode();

            if (Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Adding ||
                Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Editing) {

            } else {

                //check for collides
                // if (Simulation.instance.grabbedObject) {
                //     LogicEngine.processStepLogicConditions({
                //         tgId: tg.id, stepLogic: tg.logic as Logic, targetNode: this.node,
                //         targetNodeList: [node.userData?.id || '', Simulation.instance.grabbedObject?.userData?.id || ''],
                //         eventType: OBJECT_EVENT_TYPES.COLLIDES
                //     }
                //     );
                // }

                if (!Simulation.instance.dragging) {
                    Simulation.instance.grabbedObject = null;
                };
                Simulation.instance.sceneLoader.hideTransformGizmo();
                // if (GizmoTools?.instance) {
                store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
                //     Simulation.instance.propertiesPanel.hidePropertiesPanel();
                !!store.getState().threeD.showPropertiesPanel && store.dispatch({ type: SHOW_PROP_PANEL, payload: false });

                //     // (store.getState().home?.tagsViewModeSecondary == ViewModeSecondary.LAYERS_PROP && store.dispatch(setTagsViewModeSecondary('')));
                // }
                // }
            }
        }
    }
}
