import Utils from "../../Tools/Utils";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { UserDataProperties } from '../ui-interop/PropertiesPanel';
import * as THREE from 'three';
import { store } from "App";
import { ExternalSdkMode } from "../core/Simulation";
export default class Serialization {
    public static SerializeNode(node: ISceneNode, id: string): any {
        /*
        if(node.userData['id'] !== id) {
            node.userData['id'] = id;
            console.log("Patching id for duplicate node")
        }*/

        try {
            let finalUserData = JSON.parse(JSON.stringify(node.userData));

            if (UserDataProperties.skinnedNode in finalUserData) {
                //remove skinnedNode key in finalUserData
                delete finalUserData[UserDataProperties.skinnedNode];
            }
            let serializedNodeData: any = {};
            // if (store.getState().threeD.sdkMode == ExternalSdkMode.MPR3F) {
            //     (node as any).nodeRef && delete (node as any).nodeRef;
            //     serializedNodeData = {
            //         annotationType: "3dObject",
            //         name: node.name,
            //         position: Utils.SimpleClone( node.position),
            //         scale: Utils.SimpleClone(node.scale),
            //         quaternion: Utils.SimpleClone(node.quaternion),
            //         userData: finalUserData,
            //         id: id,
            //         // createdOn: new Date(),
            //         // createdBy: user.uid
            //     }
            //     let q = serializedNodeData.quaternion;
            //     if (q._x !== undefined && q._y !== undefined && q._z !== undefined && q._w !== undefined) {
            //         serializedNodeData.quaternion = new THREE.Quaternion(q._x, q._y, q._z, q._w);
            //     }
            //     serializedNodeData.quaternion= Utils.SimpleClone(serializedNodeData.quaternion);
            //     console.log("[r3f] spacemodel serializedNodeData", serializedNodeData)
            // } else {
                serializedNodeData = {
                    annotationType: "3dObject",
                    name: node.name,
                    position: JSON.stringify(node.position),
                    scale: JSON.stringify(node.scale),
                    quaternion: JSON.stringify(node.quaternion),
                    userData: finalUserData,
                    id: id,
                    // createdOn: new Date(),
                    // createdBy: user.uid
                }
            // }
            return serializedNodeData;

        } catch (error) {
            console.error("[st] [r3f] Error serializing node", error);
            let serializedNodeData = {
                annotationType: "3dObject",
                name: node.name,
                position: JSON.stringify(node.position),
                scale: JSON.stringify(node.scale),
                quaternion: JSON.stringify(node.quaternion),
                userData: node.userData,
                id: id,
                // createdOn: new Date(),
                // createdBy: user.uid
            }
            return serializedNodeData;
        }

    }

    public static DeserializeNode(doc: any) {
        return {
            ...doc.data(),
            ...{
                id: doc.id,
                /*nameToShow: Utils.ExtractModelDisplayName(doc.data().name, doc.id)*/
            }
        }
    }

    public static deserDbNode(node: any): any {
        try {
            node.position = JSON.parse(node.position);
            let q = JSON.parse(node.quaternion);
            if (q._x !== undefined && q._y !== undefined && q._z !== undefined && q._w !== undefined) {
                node.quaternion = new THREE.Quaternion(q._x, q._y, q._z, q._w);
            }
            // console.log('[r3f] deser spaceModel', q, node.quaternion);
            node.scale = JSON.parse(node.scale);
        } catch (e) {
        }
        return node;

    }
}