import {DecoupledComponent} from './DecoupledComponent';
import {Size} from '../meshComponents/basic/PlaneRenderer';
import {IRenderable2D} from './IRenderable';

export class CanvasTextDecoupled extends DecoupledComponent implements IRenderable2D {
    public constructor(public fontColor: string,
                       public fontName: string,
                       public fontStyle: string,
                       public fontSize: number,
                       public text: string) {
        super();
    }
    
    public paint(context2d: CanvasRenderingContext2D, size: Size): void {
        context2d.strokeStyle = this.fontColor;
        context2d.fillStyle = this.fontColor;
        context2d.font = this.fontStyle + ' ' + this.fontSize + 'px ' + this.fontName;
        context2d.textAlign = 'left';
        context2d.textBaseline = 'top';
        //context2d.clearRect(0, 0, size.w, size.h);
        this.wrapText(context2d, this.text, size.w * 0.5, size.h * 0.5, size.w, this.fontSize * 0.9);
    }
    
    private wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
                     maxWidth: number, lineHeight: number) {
        var startY: number = y;
        var startX: number = x;
        
        text = text.replaceAll('\r', '');
        
        var words = text.split(' ');
        var line = '';
        var lines: string[] = [];
        var linesToRender: string[] = [];
        
        //while
        //let glyphWidth = context.measureText("X");
        
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        var wordCount = words.length;
        for (var n = 0; n < wordCount; n++) {
            var testLine = line + words[n] + ((n < (wordCount - 1)) ? ' ' : '');
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }
        
        lines.push(line);
        for (var n = 0; n < lines.length; n++) {
            var currentLine = lines[n];
            let lineBreaksLines = currentLine.split('\n');
            for (var i = 0; i < lineBreaksLines.length; i++) {
                var newLine = lineBreaksLines[i];
                var brokenLines = this.breakBigLines(context, newLine, maxWidth);
                
                for (var j = 0; j < brokenLines.length; j++) {
                    linesToRender.push(brokenLines[j]);
                }
                
            }
        }
        var totalLines = linesToRender.length;
        var halfTextHeight = lineHeight * totalLines * 0.5;
        for (var n = 0; n < totalLines; n++) {
            context.fillText(linesToRender[n], x, y + n * lineHeight - halfTextHeight + lineHeight * 0.5);
        }
    }
    
    private breakBigLines(context: CanvasRenderingContext2D, text: string, maxWidth: number): string[] {
        let line = '';
        let lines: string[] = [];
        let lastIndex = 0;
        for (var n = 0; n < text.length; n++) {
            var glyph = text[n];
            line += glyph;
            var metrics = context.measureText(line);
            var testWidth = metrics.width;
            
            if (testWidth < maxWidth) {
            
            } else {
                lines.push(line);
                line = '';
                lastIndex = n + 1;
            }
        }
        
        lines.push(text.substr(lastIndex, text.length));
        
        return lines;
    }
    
}