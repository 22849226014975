import {store} from 'App';
import * as THREE from 'three';
import {WindowingSystem} from './WindowingSystem';

class InternalPosition {
    constructor(public left: number, public top:number) {
    }
}


export class CameraSystem {
    protected _camera: THREE.PerspectiveCamera;
    protected mouseDown: boolean;

    public aimSensitivity: number;
    public cameraSpeed: number;

    protected direction: THREE.Vector3;


    constructor(protected _windowingSystem: WindowingSystem) {
        // this._camera = new THREE.PerspectiveCamera(75, this._windowingSystem.aspectRatio, 0.1, 50);
        this._camera = new THREE.PerspectiveCamera(75, this._windowingSystem.aspectRatio, 0.1, 1500);

        this.camera.rotation.order = 'YXZ';

        this.goToDefaultPose();

        // let cameraPosition = new THREE.Vector3(0, 0, 0);
        // let cameraRotation = new THREE.Vector2(0, 0);
        // let poses = store.getState().home.currentSpace?.poses;

        // if (poses && poses.length > 0) {
        //     let defaultPose = poses.find(pose => !!pose.isDefault);
        //     defaultPose = (defaultPose || poses[0]);

        //     cameraPosition.x = defaultPose.data.pose?.position.x || 0;
        //     cameraPosition.y = defaultPose.data.pose?.position.y || 0;
        //     cameraPosition.z = defaultPose.data.pose?.position.z || 0;

        //     cameraRotation.x = defaultPose.data.pose?.rotation.x || 0;
        //     cameraRotation.y = defaultPose.data.pose?.rotation.y || 0;
        // }

        // console.log(`[st] [vp] [custom] setting camera position to ${cameraPosition} and rotation to ${cameraRotation}`);
        // this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        // // let newRot = new THREE.Quaternion().setFromEuler(new THREE.Euler(
        // //     120.34569532688162 * Math.PI / 180,
        // //     -3.2167042253612945 * Math.PI / 180,
        // //     (null || 0) * Math.PI / 180,
        // //     'YXZ'));

        // this.camera.rotation.set(cameraRotation.x * Math.PI / 180, cameraRotation.y * Math.PI / 180, (null || 0) * Math.PI / 180, 'YXZ');

        this.mouseDown = false;
        this._windowingSystem.inputSystem.registerMouseDownCallback(this.onMouseDown.bind(this));
        this._windowingSystem.inputSystem.registerMouseUpCallback(this.onMouseUp.bind(this));
        this._windowingSystem.inputSystem.registerMouseMoveCallback(this.onMouseMove.bind(this));

        this._windowingSystem.registerUpdateCallback(this.onUpdate.bind(this));

        this.aimSensitivity = 2.5;
        this.cameraSpeed = 1;
        this.direction = new THREE.Vector3();
    }

    protected onUpdate(deltaTime: number): void {
        //console.log(deltaTime);
        if (this._windowingSystem.inputSystem.keyStates['KeyW']) {
            this.camera.getWorldDirection(this.direction);
            this.camera.position.add(this.direction.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        } else if (this._windowingSystem.inputSystem.keyStates['KeyS']) {
            this.camera.getWorldDirection(this.direction);
            this.camera.position.sub(this.direction.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        }

        if (this._windowingSystem.inputSystem.keyStates['KeyA']) {
            this.camera.getWorldDirection(this.direction);
            let strafeVector = this.camera.up.clone().cross(this.direction);
            this.camera.position.add(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        } else if (this._windowingSystem.inputSystem.keyStates['KeyD']) {
            this.camera.getWorldDirection(this.direction);
            let strafeVector = this.camera.up.clone().cross(this.direction);
            this.camera.position.sub(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
        }
    }

    protected onMouseDown(e: MouseEvent): void {
        // console.log("mouse down " + e);
        // console.log(e);
        this.mouseDown = true;
    }

    protected onMouseUp(e: MouseEvent): void {
        // console.log("mouse up " + e);
        // console.log(e);
        this.mouseDown = false;
    }

    protected onMouseMove(e: MouseEvent): void {
        if (this.mouseDown) {
            // console.log("dragging " + e.movementX);
            // console.log(e);
            this.camera.rotation.y -= e.movementX * 0.001 * this.aimSensitivity;
            this.camera.rotation.x -= e.movementY * 0.001 * this.aimSensitivity;
        }
    }

    get camera(): THREE.PerspectiveCamera {
        return this._camera;
    }

    get windowingSystem(): WindowingSystem {
        return this._windowingSystem;
    }

    public goToDefaultPose() {
        let poses = store.getState().home.currentSpace?.poses;

        if (poses && poses.length > 0) {
            let defaultPose = poses.find(pose => !!pose.isDefault);
            defaultPose = (defaultPose || poses[0]);

            if (defaultPose) {
                this.goToPose(defaultPose.id);
            }
        }

    }

    public goToPose(poseId: string) {
        if (!poseId) {
            return;
        }
        let cameraPosition = new THREE.Vector3(0, 0, 0);
        let cameraRotation = new THREE.Vector2(0, 0);
        let poses = store.getState().home.currentSpace?.poses;
        let pose = poses?.find(pose => pose.id == poseId);

        if (pose) {

            cameraPosition.x = pose.data.pose?.position.x || 0;
            cameraPosition.y = pose.data.pose?.position.y || 0;
            cameraPosition.z = pose.data.pose?.position.z || 0;

            cameraRotation.x = pose.data.pose?.rotation.x || 0;
            cameraRotation.y = pose.data.pose?.rotation.y || 0;
        }

        // console.log(`[st] [vp] [custom] setting camera position and rotation to`, poseId, cameraPosition, cameraRotation);
        this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        // let newRot = new THREE.Quaternion().setFromEuler(new THREE.Euler(
        //     120.34569532688162 * Math.PI / 180,
        //     -3.2167042253612945 * Math.PI / 180,
        //     (null || 0) * Math.PI / 180,
        //     'YXZ'));

        this.camera.rotation.set(cameraRotation.x * Math.PI / 180, cameraRotation.y * Math.PI / 180, (null || 0) * Math.PI / 180, 'YXZ');
    }

    public worldToScreenPoint(position: THREE.Vector3, div: HTMLElement) {
        var pos = position.clone();
        let projScreenMat = new THREE.Matrix4();
        let VPMatrix = this.camera.projectionMatrix.clone().multiply(this.camera.matrixWorldInverse);
        projScreenMat.multiply(VPMatrix);
        // projScreenMat.multiplyVector3(pos);

        pos =  pos.applyMatrix4(projScreenMat);

        var offset = this.findOffset(div);

        return {
            x: (pos.x + 1) * div.clientWidth / 2 + offset.left,
            y: (-pos.y + 1) * div.clientHeight / 2 + offset.top,
        };

    }

    private findOffset(element: HTMLElement): InternalPosition {
        var pos = new InternalPosition(0, 0);
        if (element.offsetParent) {
            do {
                pos.left += element.offsetLeft;
                pos.top += element.offsetTop;
            } while (element = element.offsetParent as HTMLElement)
        }
        return pos;
    }
}
