import { SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import { EulerIntegrator } from './EulerIntegrator';
import * as THREE from 'three';
import { store } from 'App';
import { ExternalSdkMode } from '../../SubSystems/core/Simulation';
import { SDKInstance } from 'modules/fiber/Sdk';
import { MpSdk } from 'types/SdkTypes2';

export class ThreeContext extends SceneComponent {
    private static _i: ThreeContext | null = null;

    private threeContext: typeof THREE;

    public get t(): typeof THREE {
        if (store.getState().threeD.sdkMode === ExternalSdkMode.MPR3F) {
            return (SDKInstance.sdk as MpSdk)?.threeContext || (window as any).THREE;
        }
        return this.threeContext;
    }

    onInit() {
        this.threeContext = this.context.three;

        ThreeContext._i = this;
    }

    public static get i(): ThreeContext | null {
        if (store.getState().threeD.sdkMode === ExternalSdkMode.MPR3F && !ThreeContext._i) {
            let tc = new ThreeContext();
            tc.threeContext = (SDKInstance.sdk as MpSdk)?.threeContext || (window as any).THREE;;
            ThreeContext._i = tc;
            return tc;
        }
        return ThreeContext._i;
    }
}


export const rootThreeContextType = 'st.rootThreeJSContext';
export const makeRootThreeContext = function () {
    return new ThreeContext();
};