import { tryCatch } from "@crema/utility/Utils";
import { store } from "App";
import { SpatialThinkSDKMode } from "CustomSdk/SpatialThinkSDK";
import _ from "lodash";
import { SDKInstance } from "modules/fiber/Sdk";
import { setSpaceModel, setSpaceModelsList } from "redux/actions/Home";
import { showGizmo } from "redux/actions/ThreeD";
import { SELECT_NODE } from "types/actions/ThreeD.actions";
import { Logic } from "types/models/dataAccess/Logic";
import { getNodePropsFromNode, ShowcaseTag, SpaceNode } from "types/models/home/HomeApp";
import { firestore, storage } from "../../../../../@crema/services/auth/firebase/firebase";
import { GizmoTools } from "../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import Utils from "../../Tools/Utils";
import { SimulationMode } from "../core/RenderingAndPlaceObjectStateSystem";
import Simulation, { ExternalSdkMode } from "../core/Simulation";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";
import Serialization from "./Serialization";
import { MpSdk } from "types/SdkTypes2";
import { isST, isR3F } from "modules/home/SpaceDetail/utils";

export default class NodeStorage {

    // public static async storeLogicForStep(logic:Logic):Promise<boolean> {
    //     let currentLessonId = store.getState().layer.currentLesson?.id;
    //     let currentTagGroupId = store.getState().layer.currentTagGroupId;
    //     //console.log(currentLessonId);
    //     let docRef = await firestore.doc(`Spaces/${Simulation.instance.spaceID}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);

    //     if(!docRef) {
    //         return false;
    //     }
    //     //console.log(await (await docRef.get()).data())
    //     //console.log(docRef)
    //     await docRef.update( { "logic" : JSON.parse(JSON.stringify(logic)}));
    //     return true;
    // }

    public static async storeNode(node: ISceneNode): Promise<boolean> {
        let spaceID = store.getState().home.currentSpace?.id || '';
        try {
            if (node == null) {
                return false;
            }
            if (!isR3F()) {
                store.dispatch({ type: SELECT_NODE, payload: getNodePropsFromNode(node) });
            }

            // if (store.getState().threeD.sdkMode === SpatialThinkSDKMode.PlainR3F) {
            //     return Promise.resolve(true);
            // }
            let docRef: any;
            let model;

            //TODO if this slows things down, we can only get floor at create time, not update
            let floorData: any = {};
            try {
                if (store.getState().threeD.sdkMode == ExternalSdkMode.MPR3F) {
                    floorData = await (SDKInstance?.sdk as MpSdk).Floor.getData().catch(console.error);
                }
                else {
                    floorData = await Simulation.instance.renderingSubSystem.sdk().Floor.getData().catch(console.error);
                }
            }
            catch (e) {
                !isST() && console.error(e);
            }

            let currentFloor = floorData ? floorData.currentFloor : '';
            if (!node.userData["id"]) {

                docRef = firestore.collection(`Spaces/${spaceID}/nodes`).doc();
                let id = docRef.id;
                node.userData["id"] = id;
                node.userData.floorIndex = currentFloor || node.userData?.floorIndex;

                if(!isST()){
                    node.userData.pose = await SDKInstance.getCurrentSweep();
                }

                model = Serialization.SerializeNode(node, docRef.id);
                model.createdOn = new Date();
                model.createdBy = store.getState().auth.authUser?.uid || '';
                model.lastUpdatedOn = model.createdOn;
                model.lastUpadatedBy = model.createdBy;

            } else {
                docRef = firestore.doc(`Spaces/${spaceID}/nodes/${node.userData["id"]}`);
                node.userData.floorIndex = currentFloor || node.userData?.floorIndex;

                if(!isST()){
                    node.userData.pose = await SDKInstance.getCurrentSweep();
                }

                model = Serialization.SerializeNode(node, docRef.id);
                model.createdOn = model.createdOn;
                model.createdBy = model.createdBy;
                model.lastUpdatedOn = new Date();
                model.lastUpadatedBy = store.getState().auth.authUser?.uid;
            }

            delete model.obj3D;
            await docRef.set(model, { merge: true });

            //model = this.deserializeNode(docRef);
            //nameToShow: Utils.ExtractModelDisplayName(doc.data().name, doc.id)
            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];

            // console.log(`[st] spaceModelsMap model ${JSON.stringify(model)}`);
            (!isR3F()) && ((model as any).nodeRef = node);
            store.dispatch(setSpaceModel(model.id, model));
            // GizmoTools?.instance?.props.updateNodes();
            // console.log("Node saved");
            return true;
        } catch (e: any) {
            console.error(`[st] storeNode failed with: ${e}`)
        }

        return false;
    }

    public static async deleteNode() {

        let nodeToDelete = Simulation.instance.lastSelectedNode;
        let nodeId = nodeToDelete?.userData["id"];
        let nodeUserData = Object.assign({}, nodeToDelete?.userData);
        this.deleteNodeById(nodeId);
        tryCatch(() => {
            if (nodeUserData[UserDataProperties.markers]) {
                (nodeUserData[UserDataProperties.markers] as any[]).forEach((marker: any) => {
                    this.deleteNodeById(marker.id);
                });
            }
        }, 'deleting markers')
    }

    public static async deleteNodeById(nodeId: string) {
        try {
            let spaceID = store.getState().home.currentSpace?.id || '';
            let dr = firestore.doc(`Spaces/${spaceID}/nodes/${nodeId}`);
            let o = await dr.get();

            await o.ref.delete();

            if (Simulation.instance.getSimulationMode() == SimulationMode.ADD_OBJECT || Simulation.instance.getSimulationMode() == SimulationMode.ADD_INTERNAL_OBJECT) {
                setTimeout(Simulation.instance.resumeMatterPortCameraMovement.bind(this), 1000);
            }

            if (!Simulation.instance.spaceModels() || Simulation.instance.spaceModels().size == 0) {
                return;

            }
            if (Simulation.instance.spaceModels().get(nodeId)) {
                if (Simulation.instance.lastSelectedNode === Simulation.instance.spaceModels().get(nodeId).nodeRef) {
                    Simulation.instance.sceneLoader.hideTransformGizmo();
                    store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
                    // Simulation.instance.propertiesPanel.hidePropertiesPanel();
                    Simulation.instance.lastSelectedNode = null;
                }
            }

            if (Simulation.instance.spaceModels().get(nodeId).nodeRef) {
                Simulation.instance.spaceModels().get(nodeId).nodeRef.stop();
                Simulation.instance.spaceModels().get(nodeId).nodeRef = null;
            }
            Simulation.instance.spaceModels().delete(nodeId);
            Simulation.instance.sceneLoader.removeNodeByID(nodeId);

            // let sm = _.cloneDeep(Simulation.instance.spaceModels());
            // sm.delete(nodeId);
            // Simulation.instance.spaceModels() = sm;

            store.dispatch(setSpaceModelsList(Simulation.instance.spaceModels()));
            //TODO this is buggy. spaceModels object has already been changed, dispatch will have no effect.
            //Deleting from checkboxes in InsertedObjects with the above commented code was causing threejs to hang.
            //DEBUG to see why that is. see what the impact is, and figure out a low cost way to update
        } catch (e: any) {
            console.error(`[st] deleteNode failed with: ${e}`)
        }
    }

    public static async loadNodesFromDB(callback: (node: ISceneNode, dbJSON: any) => void): Promise<Map<string, any>> {
        try {

            // this.d.log("LOADING nodes... " + `Spaces/${this.spaceID}/nodes`)
            let spaceID = store.getState().home.currentSpace?.id || '';

            let nodesFromDBDocs = (await firestore.collection(`Spaces/${spaceID}/nodes`)
                .withConverter(SpaceNode.nodeConverter)
                .get()
            )?.docs
                .map((doc: any) => Serialization.DeserializeNode(doc)).sort((a, b) => b.lastUpdatedOn - a.lastUpdatedOn);


            nodesFromDBDocs = nodesFromDBDocs.filter(n => n.userData.name !== 'Quiz')
            // let tagsFromDBDocs = (await firestore.collection(`Spaces/${Simulation.instance.spaceID}/tags`)
            //     // .withConverter(SpaceNode.nodeConverter)
            //     .get()).docs.map(doc => doc.data() as ShowcaseTag);x

            // tagsFromDBDocs.forEach(tag => {
            //     if (tag.quizDetails) {
            //         let quizNode = {
            //             annotationType: '3dObject',
            //             name: 'Quiz',
            //             position: tag.data.anchorPosition,
            //             scale: '{ "x": 1, "y": 1, "z": 1 }',
            //             quaternion: '{ "_x": 0, "_y": 0, "_z": 0, "_w": 1 }',
            //             userData: { 'id': tag.id },
            //         } as SpaceNode;
            //         console.log('quiz node added', quizNode);
            //         // nodesFromDBDocs.push(quizNode);
            //     }
            // })

            // nodesFromDBDocs.map(node => {
            //     if (!node.createdOn) {
            //         console.error(`[error] [3D] createdOn not found for node
            //             ${store.getState().home.currentSpace?.name} - ${store.getState().home.currentSpace?.id} - ${node.userData.nameToShow} - ${node.id} `)
            //     }
            //     if (!node.lastUpdatedOn) {
            //         console.error(`[error] [3D] lastUpdatedOn not found for node
            //             ${store.getState().home.currentSpace?.name} - ${store.getState().home.currentSpace?.id} - ${node.userData.nameToShow} - ${node.id} `)
            //     }
            // })

            //Added to quickly load a lesson when opened directly from space thumbnail
            // if(!!store.getState().layer.currentLesson){
            //     let lesson = store.getState().layer.currentLesson;
            //     let lessonQs = await firestore.collection(`Spaces/${Simulation.instance.spaceID}/lessons`)
            //     .where('did', '==', lesson?.did)
            //     .get();

            //     if (!lessonQs.empty){
            //         let lessonDoc = lessonQs.docs[0];
            //         let firstStepQs = await firestore.collection(`Spaces/${Simulation.instance.spaceID}/lessons/${lessonDoc?.id}/tagGroups`)
            //         .where('sortIndex', '==', 0).limit(1)
            //         .get();

            //         // if (!firstStepQs.empty) {
            //             let firstStepNodesAndTagsIds = firstStepQs?.docs[0].data().tagIds || [];
            //             let alwaysShowNodeIds = nodesFromDBDocs.filter(n => n.userData?.alwaysShow).map(n => n.id);
            //             nodesFromDBDocs = nodesFromDBDocs.filter((n: any) => firstStepNodesAndTagsIds.includes(n.id) || alwaysShowNodeIds.includes(n.id));
            //         // }

            //     }

            // }

            let dbNodesArray = nodesFromDBDocs;
            let spaceModelsMap = new Map<string, any>();
            for (let i = 0; i < dbNodesArray.length; i++) {

                let model = dbNodesArray[i];
                spaceModelsMap.set(dbNodesArray[i].id, model);

                // DebugLogger.BigLine();
                // if (!excludeIds.includes(dbNodesArray[i].userData.id)) {
                await Simulation.instance.sceneLoader.loadClassical(model.name, callback, model);

                model.nodeRef = Simulation.instance.sceneLoader.getLastNodeAdded();
                // }
            }


            return spaceModelsMap;
        } catch (e: any) {
            console.error(`[st] failed to load nodes in Simulation : ${e}`)
        }
        //TODO: Show an error message
        return new Map<string, any>();
    }

    public static async duplicateNode() {
        try {
            let node = Simulation.instance.lastSelectedNode;
            if (node == null) {
                return;
            }
            let spaceID = store.getState().home.currentSpace?.id || '';

            let docRef = firestore.collection(`Spaces/${spaceID}/nodes`).doc();
            //node.userData["id"] = docRef.id;
            let model = Serialization.SerializeNode(node, docRef.id);
            model.userData['id'] = docRef.id;
            model.userData[UserDataProperties.nameToShow] = Simulation.instance.sceneLoader.generateNameFromCount(node);//model.userData[UserDataProperties.nameToShow] + " copy";
            await docRef.set(model);


            //let newNode = Object.assign({}, node);
            //newNode.userData["id"] = docRef.id;

            await NodeStorage.loadNode({
                objectOrObjectName: model.name,
                callback: Simulation.instance.scenePreProcess.bind(Simulation.instance),
                dbJSON: model
            });
            node = Simulation.instance.sceneLoader.getLastNodeAdded();
            let tempNodePosition = node.position;
            tempNodePosition.x += 0.01;
            tempNodePosition.y += 0.05;
            tempNodePosition.z += 0.01;
            node.position.x = tempNodePosition.x;
            node.position.y = tempNodePosition.y;
            node.position.z = tempNodePosition.z;
            (model as any)[UserDataProperties.nameToShow] = node.userData[UserDataProperties.nameToShow];
            (model as any).nodeRef = node;
            store.dispatch(setSpaceModel(model.id, model));

            //Simulation.instance.selectNode(node);
            await Simulation.instance.selectNodeWithPropertiesUpdate(node);

            console.log("Node duplicated!");
        } catch (e: any) {
            console.error(`[st] duplicateNode failed with: ${e}`)
        }
    }

    public static async loadNode({ objectOrObjectName,
        callback = Simulation.instance.scenePreProcess.bind(Simulation.instance),
        dbJSON }: {
            objectOrObjectName: string,
            callback?: (node: ISceneNode, dbJSON: any) => void,
            dbJSON?: any
        }): Promise<ISceneNode[]> {
        try {
            return await Simulation.instance.sceneLoader.loadClassical(objectOrObjectName, callback, dbJSON);
        } catch (e: any) {
            console.log(`[st] scene wasn't yet loaded: ${e}`)
            return [];
        }
    }

    /*
    Only for MPR3F
    */
    public static updateNodeProp(id: string, updatePropFunc: (node: ISceneNode) => void) {
        let n = store.getState().home.spaceModels.get(id) as ISceneNode;
        n && updatePropFunc(n);// rgbaToHex(v); //hexstringwithAlpha
        NodeStorage.storeNode(n);
    }
}
