import { IInteractionDragBeginEvent } from "../SubSystems/core/Behaviors";
import Simulation, { ExternalSdkMode } from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { Logic, LogicEngine, OBJECT_EVENT_TYPES } from "../../../../types/models/dataAccess/Logic";
import { store } from "App";
import { isST, getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { onSetVariableValue } from "redux/actions";
import { logUserActivity, onShowPropertiesPanel } from "redux/actions/Home";
import { ACTIONS, getNodePropsFromNode, OBJECT_TYPES } from "types/models/home/HomeApp";
import { SceneNode } from "CustomSdk/Mimick/SceneNode";


export class AddObjectClickSpy implements IComponentEventSpy<IInteractionDragBeginEvent> {
    public eventType = ComponentInteractionType.CLICK;
    public static ptrToSetActionToNode: ((node: ISceneNode, i: number) => void) | undefined = undefined;
    public static ptrToSetConditionToNode: ((node: ISceneNode, i: number) => void) | undefined = undefined;
    // public static dispatch: any | undefined = undefined;
    public static objectSelectionState: number = -1;
    public static actionOrConditionStatePointer: React.Dispatch<React.SetStateAction<number>> | undefined = undefined;

    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }

    public static executeClick(node: ISceneNode | SceneNode) {


        console.log(`[vars] [st] clicked`, JSON.stringify(node.name), node, getNodePropsFromNode(node));

        let currentSpace = store.getState().home.currentSpace;

        // !isST() && Simulation.instance.resetSimulationMode();

        // if (!store.getState().layer.presentationMode
        //     && !isST()
        //     // && (store.getState().home.currentSidebarIndex !== SIDEBAR_INDEX.DATA)
        //     && (AddObjectClickSpy.objectSelectionState == -1)
        // ) {
        //     (store.getState().threeD.sdkMode !== ExternalSdkMode.MPR3F) && Simulation.instance.selectNodeWithPropertiesUpdate(node).then(() => {
        //         store.dispatch(onShowPropertiesPanel(true));
        //     })
        // }

        if (!!store.getState().layer.presentationMode && !Simulation.instance.grabbedObject) {
            console.log('[st] [nodes] selected node');
            Simulation.instance.lastSelectedNode = node;
        }

        if (AddObjectClickSpy.ptrToSetActionToNode) {
            AddObjectClickSpy.ptrToSetActionToNode(node, AddObjectClickSpy.objectSelectionState);
        }

        if (AddObjectClickSpy.ptrToSetConditionToNode) {
            AddObjectClickSpy.ptrToSetConditionToNode(node, AddObjectClickSpy.objectSelectionState);
        }

        if (node.userData?.inputSource1) {

            if (!node.userData?.noClickVarChange) {
                let v = store.getState().layer.variableValues.find(v => v.name == node.userData?.inputSource1);
                let range = (v?.values.split(',') || []).map((v: string) => v.trim());
                let i = range.findIndex((vv: any) => vv == v.value);
                if (i > -1) {
                    let j = (i + 1) % range.length;
                    console.log(`[vars] from ClickSpy: setting var value to ${range[j]}. Var updates from if-else should happen after this.`)
                    store.dispatch(onSetVariableValue(node.userData?.inputSource1, range[j]));
                }
            }
        }

        // this.node.addComponent('mp.light', {});

        // Behaviors.instance.processAllNodeClickEvents(this.node);

        let currentLessonId = store.getState().layer.currentLesson?.id;
        let currentSpaceId = currentSpace?.id;
        let tg = getCurrentTagGroup();
        if (store.getState().layer.presentationMode) {
            store.dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.NODE, node.userData.id, { currentStepId: tg?.id }));
        }

        if (currentSpaceId && currentLessonId && tg?.logic) {
            if (Simulation.instance.grabbedObject) {
                Simulation.instance.dragging = false;
                LogicEngine.processStepLogicConditions({
                    tgId: tg.id, stepLogic: tg.logic as Logic, targetNode: node,
                    targetNodeList: [node.userData?.id || '', Simulation.instance.grabbedObject?.userData?.id || ''],
                    eventType: OBJECT_EVENT_TYPES.COLLIDES
                }

                );

            } else {
                LogicEngine.processStepLogicConditions({
                    tgId: tg.id, stepLogic: tg.logic as Logic, targetNode: node,
                    // true, false
                    eventType: OBJECT_EVENT_TYPES.CLICK
                });
            }
        }

        if (!currentLessonId && !node.userData?.noClickVarChange) {
            Simulation.instance.processNodeClickOutsideLesson(node);
        }
    }

    async onEvent(payload: IInteractionDragBeginEvent) {

        AddObjectClickSpy.executeClick(this.node);
    }


}
