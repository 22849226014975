import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { TagGroup } from 'types/models/home/HomeApp';

// import { onSortData } from 'redux/actions/Home';
//import lodash from 'lodash';
// import {OverflowMenu} from '../../PopMenu';
// import { ThreeDIcon } from '@crema/core/Icons/CustomIcons';
import _ from 'lodash';
import AppList from '@crema/core/AppList';
import ListEmptyResult from '@crema/core/AppList/ListEmptyResult';
import AppsContent from '@crema/core/AppsContainer/AppsContent';
import AppsHeader from '@crema/core/AppsContainer/AppsHeader';
import TodoListSkeleton from '@crema/core/Skeleton/TodoListSkeleton';
import ThreeDObjectsHeader from './ThreeDObjectsHeader';
import ModelListItem from './ModelListItem';
import { UserDataTypes } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import modelMap from 'modules/home/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsRoot: {
      // padding: '25px 10px ',
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'flex-start',
      width: "100%",
      height: "100%"
      // width: '320px',

    },
    heading: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: '48px',
    },
    root: {
      // width: '100%',
      // maxWidth: 360,
      marginBottom: '20px',
      paddingBottom: '20px',
      // backgroundColor: theme.palette.background.default,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    listItem: {
      paddingLeft: '2px',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderRadius: '16px',
      marginBottom: '8px',
      backgroundColor: theme.palette.background.paper,
      // width: '300px',
    },
  }),
);

export enum SORTBY {
  CREATED_ON = 'Created On',
  LAST_UPDATED_ON = 'Last Updated On',
  ALPHABETICAL = 'Alphabetical'
}

const InsertedObjects: React.FC = React.memo(() => {

  const classes = useStyles();

  const [sortBy, setSortBy] = React.useState(SORTBY.LAST_UPDATED_ON);
  const [showCheckboxes, setShowCheckboxes] = React.useState<boolean>(false);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [visibleSpaceModels_, setvisibleSpaceModels] = React.useState<any>();
  // const [models, setModels] = useState<any[]>([]);
  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentLessonId = useSelector<AppState, string>(({ layer }) => layer.currentLesson?.id || '');
  const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId || '');
  const visibleSpaceModels = useSelector<AppState, any[]>(({ home }) => {
    let spaceModels: any[] = [];
    (Array.from(home.spaceModels.entries()))
      .filter(m => !(m[1].userData.type && m[1].userData.type === UserDataTypes.internalObject))
      .map((sm: any) => {
        spaceModels.push({ id: sm[1].id, name: sm[1].name, nameToShow: sm[1].userData.nameToShow,
          createdOn: sm[1].createdOn, icon: modelMap.get(sm[1].name)?.iconMui,
          // thumbnailUrl: sm[1].userData?.thumbnailUrl
        })
      });
    return spaceModels;
  }
  );

  function getSorter(sortBy: SORTBY): (a: any, b: any) => number {

    switch (sortBy) {
      case SORTBY.ALPHABETICAL:
        return (a: any, b: any) => a.nameToShow.localeCompare(b.nameToShow);

      case SORTBY.CREATED_ON:
        return (a: any, b: any) => a.createdOn - b.createdOn;

      case SORTBY.LAST_UPDATED_ON:
      default:
        return (a: any, b: any) => a.lastUpdatedOn - b.lastUpdatedOn;
      // case SORTBY.ALPHABETICAL:
      //   return Model.sortByAlphabetical;
    }
  }

  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ layer }) => layer.tagGroupsList || []);
  //  let visibleSpaceModels: any[] = [];

  const dispatch = useDispatch();
  let sidebarTitle = '';

  // visibleSpaceModels = spaceModels.size > 0 ? Array.from(spaceModels.values()) : [];
  // visibleSpaceModels = _.sortBy(visibleSpaceModels, x => (x.userData?.nameToShow || x.name)?.toLowerCase())
  sidebarTitle = '3D Objects';
  const theme = useTheme();

  function isObjInStep(model: any): boolean {
    return currentTagGroupId &&
      tagGroupsList &&
      tagGroupsList.length > 0 &&
      (tagGroupsList
        .filter(
          (tagGroup: TagGroup) =>
            tagGroup.id === currentTagGroupId,
        )[0]
        ?.tagIds || []).includes(model.id)
      ? true
      : false
  }

  // const io = (console.log(`%c[st] rendering InsertedObjects`, 'color: orange;'),
  //   <div className={classes.tagsRoot}>
  //     {!currentTagGroupId && isUserAdmin &&
  //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
  //         <SmallButton onClick={() => {
  //           setShowCheckboxes(!showCheckboxes);
  //         }}>Select</SmallButton>
  //         {showCheckboxes && <SmallButton onClick={() => {
  //           // setShowCheckboxes(!showCheckboxes);
  //           selectedIds.length > 0 ? setSelectedIds([]) : selectedIds.push(...visibleSpaceModels.map(m => m.id as string));
  //         }}>{showCheckboxes ? 'Unselect' : 'Select'} All</SmallButton>}
  //         {(selectedIds.length > 0) && <SmallButton onClick={() => {
  //           let res = window.confirm(`Delete ${selectedIds.length} object${selectedIds.length > 1 ? 's' : ''}?`);
  //           if (res) {
  //             selectedIds.map(async mid => await NodeStorage.deleteNodeById(mid));
  //           }
  //         }}>Delete ({selectedIds.length})</SmallButton>}
  //       </div>
  //     }
  //     <List
  //       component='nav'
  //       aria-labelledby='nested-list-subheader'
  //       className={classes.root}
  //     // style={{backgroundColor: tagsViewModeSecondary == ViewModeSecondary.LAYERS_3D ? theme.palette.background.default : theme.palette.background.paper}}
  //     >
  //       {(!visibleSpaceModels || visibleSpaceModels?.length == 0) && <Box style={{ textAlign: "center" }}>No 3D objects have been added to this space</Box>}
  //       {visibleSpaceModels?.map((model: any) => {
  //         return (
  //           <ItemHover key={model.id}>
  //             <ListItem key={model.id} className={classes.listItem}>
  //               {/* {Object.keys(currentScene || {}).length !== 0 && ( */}
  //               {currentTagGroupId && (
  //                 <Tooltip title={isObjInStep(model) ? 'Remove from Step' : 'Add to Step'}>
  //                   <Checkbox
  //                     edge='start'
  //                     icon={<RadioButtonUncheckedRoundedIcon fontSize="medium" />}
  //                     checkedIcon={<CheckCircleRoundedIcon fontSize="medium" />}
  //                     checked={isObjInStep(model)

  //                     }
  //                     tabIndex={-1}
  //                     onChange={async () => {
  //                       let currentTagGroup = getCurrentTagGroup();
  //                       let tgs: TagGroup[] = [];
  //                       if (!currentTagGroup) {
  //                         return;
  //                       }
  //                       if ((currentTagGroup?.tagIds || []).includes(model.id)) {
  //                         // tagGroupsList =
  //                         // // currentTagGroup?.tagIds = currentTagGroup?.tagIds.filter((tagId: string) => tagId !== showcaseTag.id);
  //                         // tagGroupsList.map(((tagGroup: TagGroup) => tagGroup.id == currentTagGroup.id && tagGroup?.tagIds.splice(
  //                         //   tagGroup?.tagIds.indexOf(showcaseTag.id)
  //                         // )))

  //                         //remove model from tagGroup
  //                         tagGroupsList.forEach((tagGroup) => {
  //                           if (tagGroup.id == currentTagGroupId) {
  //                             tagGroup.tagIds = (tagGroup?.tagIds || []).filter(
  //                               (tid) => tid != model.id,
  //                             );
  //                             currentTagGroup = tagGroup;
  //                           }
  //                           tgs.push(tagGroup);
  //                         });
  //                       } else {

  //                         //add model to tagGroup
  //                         tagGroupsList.forEach((tagGroup) => {
  //                           if (tagGroup.id == currentTagGroupId) {
  //                             (tagGroup?.tagIds || []).push(model.id);
  //                             currentTagGroup = tagGroup;
  //                           }
  //                           tgs.push(tagGroup);
  //                         });
  //                       }
  //                       dispatch(setTagGroupsList(tgs));

  //                       await firestore
  //                         .doc(
  //                           `Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroup.id}`,
  //                         )
  //                         .set(currentTagGroup);
  //                     }}
  //                     disableRipple
  //                     inputProps={{ 'aria-labelledby': model.nameToShow }}
  //                   /></Tooltip>
  //               )}

  //               {/* {tile.iconMui ? <tile.iconMui /> : (tile.img ? getImageIcon(tile.img, tile.title) :
  //               <ThreeDIcon2Svg style={{ fill: "black", height: 23 }} />)} */}

  //               {/* {modelMap.get(model?.name)?.iconMui && */}
  //               {/* {(() => { let i = modelMap.get(model.name).iconMui; console.log(i); return <i /> })()} */}
  //               {/* } */}

  //               {!currentTagGroupId && showCheckboxes && <RoundCheckbox
  //                 style={{ marginLeft: '8px' }}
  //                 edge='start'
  //                 checked={selectedIds.includes(model.id)}
  //                 // tabIndex={-1}
  //                 onChange={async (e, checked) => {
  //                   if (selectedIds.includes(model.id)) {
  //                     selectedIds.splice(selectedIds.indexOf(model.id), 1)
  //                   } else {
  //                     selectedIds.push(model.id)
  //                   }
  //                   setSelectedIds(_.cloneDeep(selectedIds));
  //                 }}
  //                 disableRipple
  //                 inputProps={{ 'aria-labelledby': model.nameToShow }}
  //               />}

  //               {/* <ListItemIcon style={{ minWidth: '40px' }}> */}
  //               {/* <ThreeDIcon2Svg style={{ fill: "grey", height: 23 }} /> */}

  //               {/* <ThreeDIcon
  //                 // style={{
  //                 //   // color: '#F57E7E',
  //                 //   color: theme.palette.secondary.main,
  //                 // width: '17px'}}
  //                 /> */}
  //               {/*
  //                 <SportsVolleyballIcon
  //                 style={{
  //                   // color: '#F57E7E',
  //                   color: theme.palette.secondary.main,
  //                 width: '17px'}}
  //                 /> */}

  //               {/* </ListItemIcon> */}

  //               <ListItemText
  //                 id={`${model.name}`}
  //                 disableTypography={true}
  //                 primary={model.nameToShow}
  //                 // className={classes.listItem}
  //                 style={{
  //                   cursor: 'pointer',
  //                   // fontSize: '12px',
  //                 }}
  //                 onClick={() => {
  //                   Simulation.instance.handleModelClick(model.id);
  //                   // handleTagClick(showcaseTag.id);
  //                 }}
  //               />
  //               {/* {currentSpace && canEditSpace(currentSpace.spaceMembership.role) && (
  //               <span style={{ marginLeft: 'auto' }}>
  //                 <LongMenu
  //                   mattertag={showcaseTag}
  //                   onClickTagsPopup={onClickTagsPopup}
  //                 />
  //               </span>
  //             )} */}
  //             </ListItem>
  //             {/* <Spacer style={{backgroundColor: theme.palette.background.default}} y ={2}/> */}

  //           </ItemHover>

  //         );
  //       })}
  //     </List>
  //   </div>
  // );

  const onSortItemClickHandler = (sortBy: SORTBY) => {
    // This function is working without redux

    setSortBy(sortBy);

    // if (sortBy == "Alphabetical") {
    //   visibleSpaceModels = visibleSpaceModels.sort((a: any, b: any) => a.nameToShow.localeCompare(b.nameToShow))
    // } else if (sortBy == "Created On") {
    //   visibleSpaceModels = visibleSpaceModels.sort((a: any, b: any) => a.createdOn - b.createdOn);
    // }

    // It's sorting but not reflecting in the list
    // console.log("Visible State Model",  visibleSpaceModels);

    // This function is usingg redux
    // dispatch(onSortData(sortBy));
  }

  const onChangeCheckedModels = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    var selectedItems: string[] = [...selectedIds];
    if (event.target.checked) {
      selectedItems.push(id);
    } else {
      selectedItems = selectedItems.filter((item) => item != id);
    }
    setSelectedIds(selectedItems);
  }

  return (console.log(`[st] rendering InsertedObjects`), <>
    <AppsHeader>
      <ThreeDObjectsHeader
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        onSortItemClickHandler={onSortItemClickHandler}
      // filterText={filterText}
      // onSetFilterText={onSetFilterText}
      // viewMode={viewMode}
      // onViewModeSelect={setViewMode}
      // onPageChange={onPageChange}
      // page={page}
      />
    </AppsHeader>
    <AppsContent>
      <AppList
        data={visibleSpaceModels.filter(m => {
          if (m?.userData?.type && m?.userData?.type == UserDataTypes.internalObject) {
            return false;
          } else
            return true;
        }).sort(getSorter(sortBy))}
        renderRow={(model) => (
          <ModelListItem
            key={model.id}
            model={model}
            onChangeCheckedModels={onChangeCheckedModels}
            checkedModels={selectedIds}
            onChangeStarred={() => { }}
            onDeleteModel={() => { }}
          />
        )}
        ListEmptyComponent={
          <ListEmptyResult
            loading={false}
            // actionTitle="Add Model"
            // onClick={() => {}}
            placeholder={<TodoListSkeleton />}
          />
        }
      />
    </AppsContent>

  </>);

});

export default InsertedObjects;
