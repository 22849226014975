import { micIcon } from '@crema/core/Icons/CustomIcons';
import { avatarName } from '@crema/utility/Utils';
import { getProjectName, getTaskSection } from 'modules/apps/ProjectBoard/ProjectUtils';
import moment from 'moment';
import { CommentObj, ProjectObj, TaskObj } from 'types/models/apps/ProjectBoard';



export const taskTagHtml = (taskItem: TaskObj, tagSid: string,
	// project?: ProjectObj
) => {
	const commentList = taskItem.comments?.map((comment: CommentObj) => `
		<div class="comment-container">
		  <div class="comment-meta">
			<div class="comment-image">
			  ${comment.image ?
			  `<img src=${comment.image} class="image-border" />` :
			  `<div class="avatar">
				<p class="avatar-text">${avatarName(comment.name)}</p>
			  </div>`
			  }

			</div>
			<div class="comment-content-cont">
			  <div class="comment-meta-cont">


				<div class="comment-name">${comment.name}</div>
				<div class="comment-date">${moment(comment.date).format('MMM DD, hh:mm A')}</div>
			  </div>
			  <div class="user-comment">
				<span class="value">${comment.comment}
				</span>
			  </div>
			</div>
		  </div>
		</div>`
	).join('\n');



	return `	<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Task</title>
${css}
</head>

<body>
<div class="tag">
      <div class="container">
        <div class="quickAccessButtons" id="quickAccess">
          <button id="editTaskButton" class="editIcon tooltip">
            ${editSVG}
			<span class="tooltiptextBottomRight">Edit Task</span>
          </button>

          <button id="voiceCommentButton" class="editIcon tooltip">
            ${micIcon}
			<span class="tooltiptextBottomRight">Add Voice Comment</span>
          </button>
        </div>

      </div>

      <div class="container" id="taskTag">

	<div class="row">
	  <div class="underlined twoThirds">
				<p class="label">Title</p>
				<span class="value">${taskItem.title
		}</span>

			</div>
			<div class="underlined oneThird">
				<p class="label">Date</p>
				<span class="value">${taskItem.date && taskItem.date !== null ? moment(taskItem.date).utc().format('YYYY-MM-DD') : ""
		}</span>

			</div>
		</div>

		<div class="row">
			<div class="underlined half">
				<p class="label">Project</p>
				<span class="value">${getProjectName(taskItem)
		}</span>

			</div>
			<div class="underlined half">
				<p class="label">List</p>
				<span class="value">${getTaskSection(taskItem)?.name || ''
		}</span>

			</div>
		</div>
		<div class="row">
			<div class="underlined full">
				<p class="label">Description</p>
				<span class="value">${taskItem.desc
		}</span>
			</div>
		</div>
		<div class="row">
			<div class="underlined half">
				<p class="label">Label</p>
				${taskItem.label?.map(lab => `<span class="chip" style="background-color:${lab.color};">${lab.name}</span>`)}
			</div>
			<div class="underlined half">
				<p class="label">Collaborators</p>
				${taskItem.collaborators?.map(
			(c: any) =>
				`<div class="chip tooltip">${c.name?.trim() || c?.email}
							<span class="tooltiptext">${c?.email || ''}</span>
						</div>`
		)}
			</div>
		</div>
		<div class="column">
			<div class="header"><p >Checklist</p></div>
			<div class="tasks-container">
				${taskItem.checkedList?.map((el: any) => {
			return `
					<div class="row subtask">
						{${el.checked ? `<input type="checkbox" checked={${el.checked}} disabled />` : `<input type="checkbox" disabled />`}}
						<span class="value">${el?.title} </span>
					</div>`
		})}
			</div>
		</div>


		<div class="divider"></div>
		<div class="header">
            <p>Comments</p>
          </div>
		<div class="row">
			<div class="underlined full">
			${commentList}

			</div>
		</div>
	</div>
			</div>
	<script>
	var editTaskButton = document.getElementById("editTaskButton");
	editTaskButton.addEventListener("click",() => {
		 window.send("editTask", '${tagSid}');
	});

	var voiceCommentButton = document.getElementById("voiceCommentButton");
	voiceCommentButton.addEventListener("click",() => {
		 window.send("addVoiceComment", '${tagSid}');
	});

</script>
</body>
</html>`


};

const expandContainer = `
<div class="container">
  <div class="expandTabContainer">
	<div>
	  <button id="expandButton" class="expandButton">
		<svg width="96" height="15" viewBox="0 0 96 15" fill="lightgrey" xmlns="http://www.w3.org/2000/svg">
		  <path d="M0 15V12H1.00588C4.42584 11.9989 9.97716 11.0898 13.2873 7.72705C13.4625 7.54912 13.6347 7.37371 13.8048 7.20043L13.8637 7.14041C15.8599 5.10682 17.602 3.33217 19.7206 2.07659C21.6956 0.906105 23.9527 0.207998 27 0.0398134V0H68C71.7381 0 74.3685 0.687476 76.6235 1.98561C78.8142 3.24675 80.5911 5.05688 82.6344 7.13848L82.6957 7.20095C82.8656 7.37406 83.0377 7.5493 83.2127 7.72705C86.5228 11.0898 92.0739 11.9989 95.4938 12H96V15H0Z" class="Axi-6A"></path>
		  <path d="M0 12V13H1.00619C4.50696 12.9989 10.4021 12.0836 14 8.42857C14.1757 8.25007 14.3484 8.07418 14.5184 7.90097C18.6046 3.73843 21.1704 1.12476 28 1.00435V1H68C75.2071 1 77.7976 3.63885 81.9821 7.90143L82.004 7.92379C82.1668 8.08963 82.332 8.2579 82.5 8.42857C86.0979 12.0836 91.993 12.9989 95.4938 13H96V12H95.4938C92.0739 11.9989 86.5228 11.0898 83.2127 7.72705C83.0377 7.5493 82.8656 7.37406 82.6957 7.20095L82.6344 7.13848C80.5911 5.05688 78.8142 3.24675 76.6235 1.98561C74.3685 0.687476 71.7381 0 68 0H27V0.0398134C23.9527 0.207998 21.6956 0.906105 19.7206 2.07659C17.602 3.33217 15.8599 5.10682 13.8637 7.14041L13.8048 7.20043C13.6347 7.37371 13.4625 7.54912 13.2873 7.72705C9.97716 11.0898 4.42584 11.9989 1.00588 12H0Z" class="zl9Kag"></path>
		</svg>

		<span class="tQOSVg R3BUpw dkWypw"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
			<path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="1.25" d="M8.83 7 6.7 4.88a1 1 0 0 0-1.42 0L3.17 7"></path>
		  </svg></span>

	  </button>
	</div>


  </div>
</div>`;
// var expandButton = document.getElementById("expandButton");
// expandButton?.addEventListener("click",() => {
// let taskTag = document.getElementById("taskTag");
// taskTag.style.display = (taskTag.style.display == "none") ? '' : "none";
// });

const css = `<style>
body {
	font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
	color: darkgrey;
	background-color: #000;
	font-size: 14px;

	}


	p {
	margin: 4px auto
	}

	.tag{
		border-radius: 16px;
		width: 95%;
	}

	.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-self: center;
			padding: 8px 8px 0px 8px;
	background-color: #FFFFFF;
	}

	.quickAccessButtons {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	border-bottom: 0.5px solid darkgrey;
	/* background-color: #222222e9; */
	/* margin: 10px; */
	/* border-radius: 8px; */
	/*         box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); */
	}

	  .editIcon {
		border: none;
		border-radius: 50%;
		height: 40px;
		width: 40px;
		background-color: transparent;
		cursor: pointer;

	  }

	.column {
	display: flex;
	flex-direction: column;
	}

	.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	}

	.underlined {
	/* height: 30px; */
	/* background-color: #00000010; */
	border: 0.5px solid lightgrey;
	border-radius: 8px;
	margin: 4px 8px;
	padding-left: 8px;
	padding-bottom: 8px;
	}

	.full {
	width: 94%;
	/*         margin: 0 auto; */
	}

	.half {
	width: 46%;
	}

	.twoThirds {
	width: 62%;
	}

	.oneThird {
	width: 30%;
	}

	.divider {
	width: 98%;
	margin: 10px auto;
	border-bottom: 1px solid grey;

	}

	.header {
	font-weight: 600;
	color: #000;
	margin: 8px;
	}

	.label {
	color: darkgrey;
	font-size: 0.75em;
	margin: 4px auto;
	}

	.chip {
	padding: 0 12px;
	background-color: #CECECE;
	border-radius: 100px;
	display: inline-flex;
	margin: 4px;
	color: rgba(0, 0, 0, .87);
	align-items: center;
	height: 32px;
	font-size: 14px;
	cursor: pointer;
	border: none;
	}


	.value {
	color: #000;
	}

	.subtask {
	justify-content: flex-start;
	padding-left: 20px;
	}

	input[type=checkbox] {
	margin-right: 10px;
	}

	.tasks-container {
	color: #fff;
	}

	.comment {
	display: flex;
	}

	.comment.author-comment {
	justify-content: flex-end;
	}

	.comment-container {
	display: flex;
	flex-direction: column;
	/* margin: 8px; */
	padding: 8px;
	}

	.comment-meta {
	display: flex;
	flex-direction: row;
	margin: 8px;
	align-items: center


	}

	.comment-content-cont{
	display: flex;
	flex-direction: column;

	}

	.comment-meta-cont {
	display: flex;
	flex-direction: row;
	align-items: center


	}

	.comment-name {
	color: #000;
	font-size: 13px;
	font-weight: 600;
	}

	.comment-date {
	color: #00000095;

	margin: 8px;
	font-size: 12px;
	}

	.user-comment {
	color: #000;

	}

	.image-border {
	width: 50%;
	clipPath: circle();
	}

	.avatar {
	background: #455A64;
	border-radius: 50%;
	height: 36px;
	width: 36px;
	color: #fff;
	margin-right: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	}

	.avatar-text {
	color: #fff;
	font-size: 18px;
	}

	.tooltip {
	position: relative;
	}

	.tooltip .tooltiptext {
	visibility: hidden;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	}


	.tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
	}


	.tooltip .tooltiptextBottomRight{
		visibility: hidden;
	top: 10%;
	left: 100%;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	wudth: max-content;

	}


	.tooltip .tooltiptextBottomRight::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
		}

	.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
	}

	.tooltip:hover .tooltiptextBottomRight {
		visibility: visible;
		opacity: 1;
		}

	.expandTabContainer{
	position:relative;
	top: -20px;
		display: flex;
		justify-content: center;
		  background-color: #FFFFFF;


		pointer-events: none;
	}
	.expandButton{
	  outline: none;
	  background: transparent;
		border: none;
		position: relative;
		height: 14px;
		cursor: pointer;
		pointer-events: auto;
	}

	.tQOSVg {
		position: absolute;
		top: 6px;
		left: 0;
		min-width: 100%;
		color: var(--pUV7hA);
	}
	.R3BUpw {
		width: 12px;
		height: 12px;
	}
	.dkWypw {
		display: inline-block;
		vertical-align: text-bottom;
		box-sizing: border-box;
	}</style>`;

const editSVG = `<svg fill="#000" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
<path d="M0 0h24v24H0z" fill="none" />
</svg>`;


