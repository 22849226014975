import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { useAuthUser } from '@crema/utility/AppHooks';
import Badge from '@mui/material/Badge';
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import { Link, ListItem, ListItemAvatar, ListItemText, makeStyles, Popover, Tooltip, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Scrollbar from '../Scrollbar';
import IntlMessages from '../../utility/IntlMessages';
import clsx from 'clsx';
// import ReleaseNotesItem from './ReleaseNotesItem';
import { CremaTheme } from '../../../types/AppContextPropsType';
// import { onGetReleaseNotesList, onMarkReadAllReleaseNotes } from 'redux/actions';
import { Release, UserRelease } from 'types/models/Releases';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { Stack } from '@mui/material';
import * as Icons from '@mui/icons-material/';
import { DateConverter } from 'types/models/home/HomeApp';
// import { GET_NOTIFICATIONS } from 'types/actions/Notification.actions';
import moment from 'moment';
import FancyBox from '../FancyBox';
import pkg from '../../../../package.json';
import _ from 'lodash';
import { setHasNewVersion } from 'redux/actions';
import { CardMedia } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { reloadWithoutCache } from '@crema/utility/Utils';
import { Fonts } from 'shared/constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme: CremaTheme) => ({
    crPopover: {
        '& .MuiPopover-paper': {
            width: 260,
            [theme.breakpoints.up('sm')]: {
                width: 300,
            },
            [theme.breakpoints.up('xl')]: {
                width: 380,
            },
        },
        '& .scroll-submenu': {
            maxHeight: 500, //TODO this should be 100%
            [theme.breakpoints.up('xl')]: {
                maxHeight: 380,
            },
        },
    },
    btnPopover: {
        borderRadius: 0,
        width: '100%',
        textTransform: 'capitalize',
    },
    reloadButton: {
        padding: '0px !important',
    },
    releaseBtn: {
        justifyContent: 'flex-start',
        // width: '100%',
        height: 56,
        fontSize: 14,
        borderRadius: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: theme.palette.grey[800],
        '&:hover, &:focus': {
            color: theme.palette.text.primary,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
            borderLeft: 'solid 1px',
            borderLeftColor: theme.palette.grey[200],
            color: theme.palette.grey[400],
            '&:hover, &:focus': {
                color: theme.palette.text.primary,
            },
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
        },
    },
    notiIcon: {
        fontSize: 22,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('xl')]: {
            fontSize: 30,
        },
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiListItemAvatar-root': {
            minWidth: '30px'
        },

        '& .MuiListItemText-multiline': {
            marginTop: '2px',
            marginBottom: '2px',
        }
    },
    innerList: {
        '& .MuiListItemText-primary': {
        },
        '& .MuiListItemText-secondary': {
            fontSize: '12px !important'
        },
        '& .MuiListItemText-multiline': {
            marginTop: '2px',
            marginBottom: '2px',
        }

    },
    badge: {
        marginRight: 8,
    },
    notificationHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

interface ReleaseNotesProps { }

const ReleaseNotes: React.FC<ReleaseNotesProps> = () => {

    const [releaseNotes, setReleaseNotes] = React.useState<Release[]>([]);
    const [seenUserReleases, setSeenUserReleases] = React.useState<UserRelease[]>([]);
    const [hasNew, setHasNew] = React.useState(false);
    // const notifCount = useSelector<AppState, number>(({ auth }) => auth.notifCount);
    const [latestRelease, setLatestRelease] = React.useState<Release | undefined>(undefined);

    const dispatch = useDispatch();
    const classes = useStyles();
    const authUser = useAuthUser();

    useEffect(() => {
        const subscriber = onGetRealTimeReleaseNotes();
        markAllReleasesAsSeen();
        // Stop listening for updates when no longer required
        return () => subscriber();
    }, []);

    useEffect(() => {
        const subscriber = onGetSeenUserReleases();
        // Stop listening for updates when no longer required
        return () => subscriber();
    }, []);

    const onGetRealTimeReleaseNotes = () => {
        return firestore
            .collection('Releases')
            .where('isDraft', '==', false)
            .withConverter(DateConverter)
            .onSnapshot((qs) => {
                setHasNew(true);

                let releases: Release[] = qs.docs.map((doc) => doc.data()) as Release[];
                releases = _.sortBy(releases, x => (x.lastUpdatedOn.valueOf() * -1));

                let lr = (releases && releases.length > 0) ? releases[0] : undefined;

                releases = releases.filter(release => !!release.title?.trim());
                // console.debug(`[st] [release] ${releases?.length} -- ${JSON.stringify(releases)}`)
                // setReleaseNotes(JSON.parse(JSON.stringify(releases)));

                setReleaseNotes(releases);
                setLatestRelease(lr);
                if (lr) {
                    dispatch(setHasNewVersion(pkg.version !== lr.id));
                }
            });
    };

    const onGetSeenUserReleases = () => {
        return firestore
            .collection(`Users/${authUser?.uid}/UserReleases`)
            .onSnapshot((qs) => {
                // setHasNew(true);

                let ur: UserRelease[] = qs.docs.map((doc) => doc.data()) as UserRelease[];

                setSeenUserReleases(ur);
            });
    };

    const [anchorReleaseNotes, setAnchorReleaseNotes] =
        React.useState<HTMLButtonElement | null>(null);

    const onClickReleaseNotesButton = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorReleaseNotes(event.currentTarget);
        // dispatch(onGetReleaseNotesList(authUser));
    };

    function userHasUnseenReleases() {
        for (let i = 0; i < releaseNotes.length; i++) {
            let rn = releaseNotes[i];
            let ur = seenUserReleases.find(ur => (ur.id === rn.id));
            if (!ur || !ur.seen) {
                // console.log("userHasUnseenReleases " + 'true');
                return true;
            }
        };

        // console.log("userHasUnseenReleases " + 'false');
        return false;
    }

    function markReleaseAsSeen(releaseId: string) {
        firestore.collection(`Users/${authUser?.uid}/UserReleases`).doc(releaseId).set({ id: releaseId, seen: true, userId: authUser?.uid }, { merge: true })
            .catch((error: any) => console.error('error marking user release as seen: ' + error));
    }

    function markAllReleasesAsSeen() {
        let batch = firestore.batch();
        releaseNotes.forEach(release => {
            batch.set(firestore.collection(`Users/${authUser?.uid}/UserReleases`).doc(release.id), {
                id: release.id, seen: true, userId: authUser?.uid
            })
        });
        batch.commit()
            // firestore.collection(`Users/${authUser?.uid}/UserReleases`).doc(releaseId).set({ id: releaseId, seen: true, userId: authUser?.uid }, { merge: true })
            .catch((error: any) => console.error('error marking ALL user releases as seen: ' + error));
    }

    function appVersionMatchesLatest(): boolean {

        if (!latestRelease) {
            return false;
        }

        console.log(`[st] [release] app version is ${pkg.version} & latest release is ${latestRelease.id}`);

        if (pkg.version !== latestRelease.id) {
            // let res = window.confirm('This app has updated! For the best experience, click OK to refresh the page now, or save any pending changes and the page will refresh in 1 minute')
            // if (res) {
            //     window.location.reload();
            //     // } else {
            //     //     return false;
            // }

            return false;
        } else {
            return true;
        };
    }

    return ((console.log(`%c[st] rendering release notes, 'color: orange;`)), <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {//!appVersionMatchesLatest() && dispatch(setHasNewVersion(true))
            // <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            //     <FancyBox>
            //         {/* // className={'fancyBorder'} */}
            //         <Tooltip title='This app has updated! Click to refresh or the app will auto-refresh soon. Your progress will not be lost!'>
            //             <Button size='small' variant='outlined' color='secondary' className={classes.reloadButton}
            //                 onClick={() => window.location.reload()}>
            //                 Reload
            //             </Button>
            //         </Tooltip>
            //     </FancyBox>
            //     {latestRelease?.mustReload && <Countdown seconds={60} />}
            // </div>
        }
        {/* {dispatch(setHasNewVersion(!appVersionMatchesLatest()))} */}

        <IconButton
            className={clsx(classes.releaseBtn, 'releaseBtn')}
            aria-label='new releases'
            color='inherit'
            onClick={onClickReleaseNotesButton}>
            <Badge
                className={classes.badge}
                invisible={!userHasUnseenReleases()}
                badgeContent={'Updates'}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                color='secondary'
            >
                <NewReleasesRoundedIcon
                    className={clsx(classes.notiIcon, 'notiIcon')}
                />
            </Badge>
            {/* @ts-ignore */}
            <Hidden mdUp>
                <Box
                    ml={4}
                    fontSize={16}
                    fontFamily='Poppins'
                    fontWeight={Fonts.REGULAR}
                    component='span'>
                    <IntlMessages id='common.updates' />
                </Box>
            {/* @ts-ignore */}
            </Hidden>
        </IconButton>

        <Popover
            anchorEl={anchorReleaseNotes}
            id='language-switcher'
            className={classes.crPopover}
            keepMounted
            open={Boolean(anchorReleaseNotes)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={() => setAnchorReleaseNotes(null)}>
            <Box>
                <Box px={5} py={3} className={classes.notificationHeader}>
                    <Box component='h5' fontFamily='Poppins' fontSize={16}>
                        <IntlMessages id='common.updates' />
                    </Box>
                    <Typography variant='caption'>v{pkg.version}</Typography>
                    {!!userHasUnseenReleases() && (
                        <Box>
                            <Button
                                onClick={() => {
                                    // const markReadReleaseNotes = releaseNotes.map(
                                    //     (notif) => ({
                                    //         ...notif,
                                    //         read: true,
                                    //     }),
                                    // );
                                    // setHasNew(false);
                                    markAllReleasesAsSeen();
                                    // dispatch(
                                    // onMarkReadAllReleaseNotes(authUser, markReadReleaseNotes),
                                    // );
                                    // dispatch(onGetReleaseNotesList(authUser));
                                }}
                                variant='contained'
                                color='primary'
                                style={{ fontSize: '10px' }}>
                                Mark all as read
                            </Button>
                        </Box>
                    )}
                </Box>
                <Scrollbar className='scroll-submenu'>
                    <List dense
                        className={classes.list}
                        onClick={() => {
                            // setAnchorReleaseNotes(null);
                        }}>

                        {releaseNotes.map((release: Release, index) => {

                            let hasUserSeenRelease = !!seenUserReleases.find(ur => ur.id === release.id)?.seen;
                            return <Tooltip key={index} title={release.id}><ListItem divider alignItems="flex-start" className={'item-hover'} onClick={() => {
                                if (!hasUserSeenRelease) {
                                    markReleaseAsSeen(release.id);
                                }
                            }}
                            >
                                <ListItemAvatar>
                                    <Icons.FiberManualRecordRounded style={{ color: hasUserSeenRelease ? 'grey' : '#f5831b' }} fontSize='small' />
                                </ListItemAvatar>
                                <ReleaseNotesItem key={release.id} release={release} />
                                {/* // <Box>{JSON.stringify(item)}</Box> */}
                                {/* <Divider variant='middle'/> */}
                            </ListItem>
                            </Tooltip>
                        }
                        )}
                    </List>
                </Scrollbar>
                {/* <Box mt={2}>
            <Button
              className={classes.btnPopover}
              variant='contained'
              color='primary'>
              <IntlMessages id='common.viewAll' />
            </Button>
          </Box> */}
            </Box>
        </Popover>
    </div>
    );
};

export default ReleaseNotes;

export const ReleaseNotesItem = ({ release }: { release: Release }) => {

    const classes = useStyles();
    const history = useHistory();
    return ((console.log(`%c[st] APP VERSION IS ${pkg.version}`, 'color: #FF0000')), <>

        <Stack direction='column' justifyContent={'flex-start'} style={{ width: '100%' }}>
            <ListItemText primary={release.id} secondary={moment(release.lastUpdatedOn).fromNow() || (release as any).timestamp} />
            {/* <Typography variant='h6'>{release.title}</Typography> */}

            <List dense className={classes.innerList}>
                {/* <Stack direction='column' justifyContent={'flex-start'}> */}
                {(release.items || []).map((releaseItem, i) => {

                    // console.log(i);
                    // return <p>{JSON.stringify(releaseItem)}</p>

                    return <ListItem key={i}>

                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                            <div>
                                <ListItemText primary={releaseItem.title} secondary={releaseItem.details} />
                                {/* <Link onClick={() => history.push('/releases')}
                                    color='secondary'
                                    component='button'
                                //   underline='always'
                                >Details</Link> */}

                            </div>
                            {releaseItem.img && <img
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    display: 'block' /* remove extra space below image */
                                }}
                                src={releaseItem.img} />}
                            {/* <div style={{ width: '50%', height: '100%' }}>
                                <iframe src="https://www.loom.com/embed/fe8b6940528a475e85d8ac1e2eed32a4" frameBorder="0" allowFullScreen style={{ top: 0, left: 0, }}>
                                </iframe>
                            </div> */}
                        </Box>
                        {/* <div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/fe8b6940528a475e85d8ac1e2eed32a4" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div> */}
                        {/* <video
                            height="300px"
                            autoPlay
                            src={'https://www.loom.com/embed/fe8b6940528a475e85d8ac1e2eed32a4'} /> */}
                    </ListItem>
                })}
                {/* </Stack> */}
            </List>
        </Stack>
    </>
    )
}
export function Countdown({ seconds }: { seconds: number }) {
    const [secLeft, setSecLeft] = React.useState(seconds);

    React.useEffect(() => {

        if (secLeft <= 0) {
            reloadWithoutCache();
            return;
        }

        // save intervalId to clear the interval when the
        // component re-renders
        const intervalId = setInterval(() => {
            setSecLeft(secLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);

    }, [secLeft]);

    return (<Typography variant='caption' color="secondary" style={{ position: 'absolute', top: '32px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><Icons.Refresh fontSize='small' />{' in ' + formatMinSec(secLeft)}</Typography>)
}

function formatMinSec(s: number) {
    let min = Math.trunc(s / 60);
    //.toFixed(0);
    let ms = (min === 0 ? '' : min + 'm ');

    let sec = s % 60;
    let ss = sec === 0 ? '' : sec + 's';
    return `${ms}${ss}`;
}