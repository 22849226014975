import {DecoupledComponent} from './DecoupledComponent';
import {Size} from '../meshComponents/basic/PlaneRenderer';
import {CanvasRendererDecoupled} from './CanvasRendererDecoupled';

export class CanvasRenderingTool extends DecoupledComponent {
    public static RoundedCorners(canvasRendererDecoupled: CanvasRendererDecoupled,
                                 x: number, y: number, w: number, h: number,
                                 radius: number,
                                 shadowColor: string,
                                 strokeStyle: string,
                                 lineWidth: number): void {
        
        let context2d: CanvasRenderingContext2D = canvasRendererDecoupled.Context2D;
        let r = x + w;
        let b = y + h;
        // context2d.shadowBlur = 100;
        context2d.shadowColor = shadowColor;
        // context2d.shadowOffsetX = -100;
        // context2d.shadowOffsetY = -100;
        context2d.beginPath();
        context2d.strokeStyle = strokeStyle;
        context2d.lineWidth = lineWidth;
        context2d.moveTo(x + radius, y);
        context2d.lineTo(r - radius, y);
        context2d.quadraticCurveTo(r, y, r, y + radius);
        context2d.lineTo(r, y + h - radius);
        context2d.quadraticCurveTo(r, b, r - radius, b);
        context2d.lineTo(x + radius, b);
        context2d.quadraticCurveTo(x, b, x, b - radius);
        context2d.lineTo(x, y + radius);
        context2d.quadraticCurveTo(x, y, x + radius, y);
        
        context2d.stroke();
    }
}
