import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import NodeStorage from "mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage";
import { MpSdk, Scene } from "types/SdkTypes2";
import { SDKInstance } from "../Sdk";
import { Node } from "./Node";

export class DialLegacy extends Node {

	public static async makeLegacyDial(mpSdk: MpSdk, sceneObject: Scene.IObject) {

		let d = new DialLegacy(mpSdk, sceneObject);
		let nodes = await NodeStorage.loadNode({objectOrObjectName: 'Dial'});
		if (nodes.length > 0){
			d.node = nodes[0];
		} else {
			console.error(' [r3f] error: no nodes could be loaded for legacy dial');
		}
		return d;

	}

	private constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {
		super(mpSdk, sceneObject);
	}
}
