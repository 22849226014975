// @ts-ignore
import { evalEnablement } from '@jsonforms/core';
import { Color } from 'react-color';
import { Matrix4 } from 'three';
import {
    AnimationAction,
    AnimationClip,
    AnimationMixer,
    BoxGeometry,
    EdgesGeometry,
    Euler,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshLambertMaterial,
    Object3D,
    Quaternion,
    Vector3,
    // @ts-ignore
  } from 'three';
import Simulation from '../../SubSystems/core/Simulation';
import { ComponentInteractionType, ISceneNode, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import Utils from '../../Tools/Utils';

  const makeMaterialOpacityClip = function (
    THREE: any,
    time: number,
    startOpacity: number,
    endOpacity: number,
  ): AnimationClip {
    const track = new THREE.NumberKeyframeTrack(
      '.material.opacity',
      [0, time],
      [startOpacity, endOpacity],
    );
    return new THREE.AnimationClip(null, time, [track]);
  };

  const makeRotationTrack = function (
    THREE: any,
    time: number,
    startRotation: Quaternion,
    endRotation: Quaternion,
  ): THREE.QuaternionKeyframeTrack {
    const track = new THREE.QuaternionKeyframeTrack(
      '.quaternion',
      [0, time],
      [startRotation.x,
       startRotation.y,
       startRotation.z,
       startRotation.w,
       endRotation.x,
       endRotation.y,
       endRotation.z,
       endRotation.w],
       THREE.InterpolateLinear
    );
    //return new THREE.AnimationClip(null, time, [track]);
    return track;
  };

//THREE.ColorKeyframeTrack
  const makeMaterialColorTrack= function (
    THREE: any,
    time: number,
    startColor: THREE.Color,
    endColor: THREE.Color,
  ): THREE.ColorKeyframeTrack {
    const track = new THREE.ColorKeyframeTrack(
      '.material.color',
      [0, time],
      [ startColor.r,
        startColor.g,
        startColor.b,
        endColor.r,
        endColor.g,
        endColor.b
      ],
      THREE.InterpolateSmooth
    );
    //return new THREE.AnimationClip(null, time, [track]);
    return track;
  };

  const playAnimation = function (
    THREE: any,
    mixer: AnimationMixer,
    clip: AnimationClip,
    root?: any,
  ) {
    const action: AnimationAction = mixer.clipAction(clip, root);
    action.loop = THREE.LoopOnce;
    action.clampWhenFinished = true;
    action.play();
  };

  interface Inputs {
    size: {x: number; y: number; z: number};
    color: number;
    colorOff: number;
    toggle: boolean;
    transitionTime: number;
    rotationAxis: { x: number, y: number, z: number};
  }

  export class OnOffButton extends SceneComponent {
    private root: Object3D | null = null;
    private box: Mesh | null = null;
    private boxMixer: AnimationMixer | null = null;
    private rootMixer: AnimationMixer | null = null;
    private clipToggleOn: AnimationClip | null = null;
    private clipToggleOff: AnimationClip | null = null;
    private clipColorToggleOn: AnimationClip | null = null;
    private clipColorToggleOff: AnimationClip | null = null;

    private oldScale:Vector3|null = null;
    private localEphemeralIndex:number = -1;

    inputs: Inputs = {
      //size: {x: 0.01, y: 0.1, z: 0.01},
      size: {x: 0.05, y: 0.5, z: 0.15},
      color: 0xa31e03,
      colorOff: 0x03a31e,
      toggle: true,
      transitionTime: 0.3,
      rotationAxis: { x: 0, y: 0, z: 1},
    };

    events = {
      [ComponentInteractionType.CLICK]: true,
      [ComponentInteractionType.HOVER]: true,
      [ComponentInteractionType.DRAG]: false,
    };

    onInit() {
      // @ts-ignore
      const THREE = this.context.three;
      this.root = new THREE.Object3D();
      // @ts-ignore
      this.outputs.objectRoot = this.root;
      // @ts-ignore
      this.outputs.collider = this.root;

      this.makeBox();

      // must be done after the box is created
      // @ts-ignore
      this.boxMixer = new THREE.AnimationMixer(this.box);
      // @ts-ignore
      this.rootMixer = new THREE.AnimationMixer(this.box);

      this.prepareClips();
      /*
      this.clipColorToggleOn = makeMaterialColorClip(
        THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        new THREE.Color(this.inputs.colorOff ),
        new THREE.Color(this.inputs.color)
        //new THREE.Color(1, 1, 0 ),
        //new THREE.Color(1, 0, 0)
      );
      this.clipColorToggleOff = makeMaterialColorClip(
        THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        new THREE.Color(this.inputs.color ),
        new THREE.Color(this.inputs.colorOff )
      );*/
    }

    prepareClips() {
      // @ts-ignore
      const THREE = this.context.three;

      var colorTrackToggleOn = makeMaterialColorTrack(THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        new THREE.Color(this.inputs.colorOff ),
        new THREE.Color(this.inputs.color)
      );

      var colorTrackToggleOff = makeMaterialColorTrack(THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        new THREE.Color(this.inputs.color ),
        new THREE.Color(this.inputs.colorOff)
      );

      let rotationAxisFinal = new Vector3(this.inputs.rotationAxis.x, this.inputs.rotationAxis.y, this.inputs.rotationAxis.z);
      var rotationTrackToggleOff = makeRotationTrack(THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        //new Quaternion().setFromEuler(new Euler(this.inputs.rotation.x, this.inputs.rotation.y, this.inputs.rotation.z, "XYZ")),
        new Quaternion().setFromAxisAngle(rotationAxisFinal, 0 * THREE.MathUtils.DEG2RAD),
        new Quaternion().setFromAxisAngle(rotationAxisFinal, -90 * THREE.MathUtils.DEG2RAD)
      );



      var rotationTrackToggleOn = makeRotationTrack(THREE,
        this.inputs.transitionTime,
        // @ts-ignore
        new Quaternion().setFromAxisAngle(rotationAxisFinal, -90 * THREE.MathUtils.DEG2RAD),
        //new Quaternion().setFromEuler(new Euler(this.inputs.rotation.x, this.inputs.rotation.y, this.inputs.rotation.z, "XYZ"))
        // @ts-ignore
        new Quaternion().setFromAxisAngle(rotationAxisFinal, 0 * THREE.MathUtils.DEG2RAD),
      );

      this.clipToggleOn = new AnimationClip("toggleOn", this.inputs.transitionTime, [rotationTrackToggleOn]);
      this.clipToggleOff = new AnimationClip("toggleOff", this.inputs.transitionTime, [rotationTrackToggleOff]);

      this.clipColorToggleOn = new AnimationClip("toggleColorOn", this.inputs.transitionTime, [colorTrackToggleOn]);
      this.clipColorToggleOff = new AnimationClip("toggleColorOff", this.inputs.transitionTime, [colorTrackToggleOff]);
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {

      console.log("OnOffButton.onEvent");
      if (interactionType === ComponentInteractionType.CLICK) {
        let _tmp_webstorm_ = this.context;
        // @ts-ignore
        const {root} = this.context;
        this.notify(ComponentInteractionType.CLICK, {
          type: interactionType,
          node: root,
          component: this,
        });

        (this.inputs!.toggle = !this.inputs!.toggle);
      }
      if (interactionType === ComponentInteractionType.HOVER) {
        this.notify(ComponentInteractionType.HOVER, {
          hover: (<{hover: boolean}>eventData).hover,
        });

        if(this.localEphemeralIndex == -1) {
            if(this.root) {
                let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(this.root);
                this.localEphemeralIndex = Simulation.instance.outlineComponentColor2.addEphemeralMeshes(meshes!, this.localEphemeralIndex);
            }
        } else {
                Simulation.instance.outlineComponentColor2.removeEphemeralMeshes(this.localEphemeralIndex);
                this.localEphemeralIndex = -1;
        }
      }
    }

    makeBox() {
      // @ts-ignore
      const THREE = this.context.three;

      if (this.box) {
        // @ts-ignore
        this.root.remove(this.box);
        (this.box.material as MeshLambertMaterial).dispose();
        (this.box.geometry as BoxGeometry).dispose();
        this.box = null;
      }

      const boxGeometry: BoxGeometry = new THREE.BoxGeometry(
        this.inputs.size.x,
        this.inputs.size.y,
        this.inputs.size.z,
      );

      boxGeometry.translate(0, this.inputs.size.y * 0.5 - 0.1, 0);

      var boxMaterial: MeshLambertMaterial = new THREE.MeshLambertMaterial({
        color: this.inputs.toggle ? this.inputs.color : this.inputs.colorOff
      });
    //   boxMaterial.transparent = true;
    //   boxMaterial.side = THREE.BackSide;
    //   boxMaterial.blending = THREE.AdditiveBlending;
      this.box = new THREE.Mesh(boxGeometry, boxMaterial);
      // @ts-ignore
      this.root.add(this.box);
    }

    onInputsUpdated(oldInputs: Inputs) {

      // @ts-ignore
      const THREE = this.context.three;

      if (oldInputs.toggle !== this.inputs.toggle) {
        // @ts-ignore
        this.boxMixer.stopAllAction();
        // @ts-ignore
        this.rootMixer.stopAllAction();

        //this.prepareClips();
        //this.prepareClips();
        if (this.inputs.toggle) {
          // @ts-ignore
          playAnimation(THREE, this.rootMixer, this.clipToggleOn);
          // @ts-ignore
          playAnimation(THREE, this.boxMixer, this.clipColorToggleOn);
        } else {
          // @ts-ignore
          playAnimation(THREE, this.rootMixer, this.clipToggleOff);
          // @ts-ignore
          playAnimation(THREE, this.boxMixer, this.clipColorToggleOff);
        }
      }

      if (
        oldInputs.size.x !== this.inputs.size.x ||
        oldInputs.size.y !== this.inputs.size.y ||
        oldInputs.size.z !== this.inputs.size.z
      ) {
        this.makeBox();
        return;
      }
      let bShouldPrepareClips:boolean = false;
      if (oldInputs.color !== this.inputs.color) {
        // @ts-ignore
        //(this.box.material as MeshLambertMaterial).color.set(this.inputs.color);
        bShouldPrepareClips = true;

      }
      if (oldInputs.colorOff !== this.inputs.colorOff) {
        // @ts-ignore
        //(this.box.material as MeshLambertMaterial).color.set(this.inputs.color);
        bShouldPrepareClips = true;
      }

      if (oldInputs.rotationAxis.x !== this.inputs.rotationAxis.x ||
          oldInputs.rotationAxis.y !== this.inputs.rotationAxis.y ||
          oldInputs.rotationAxis.z !== this.inputs.rotationAxis.z) {
        // @ts-ignore
        //(this.box.material as MeshLambertMaterial).color.set(this.inputs.color);
        bShouldPrepareClips = true;
      }

      if(bShouldPrepareClips) {
        this.prepareClips();
      }
/*
      if (oldInputs.opacity !== this.inputs.opacity) {
        // @ts-ignore
        (this.box.material as MeshLambertMaterial).opacity = this.inputs.opacity;
      }*/
    }

    onTick(delta: number) {
    super.onTick(delta);
      var deltaInSeconds = delta * 0.001;

      // @ts-ignore
      this.boxMixer.update(deltaInSeconds);
      // @ts-ignore
      this.rootMixer.update(deltaInSeconds);
    }

    onDestroy() {
      this.outputs.collider = null;
      this.outputs.objectRoot = null;

     (this.box?.material as MeshLambertMaterial).dispose();
      this.box?.geometry.dispose();
    }
  }

  export const onOffButtonType = 'mp.onOffButton';
  export const makeOnOffButton = function () {
    return new OnOffButton();
  };
