import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
// import AppSearchBar from "@crema/core/AppSearchBar";
// import SelectModelsDropdown from "./SelectModelsDropdown";
// import SelectedIdsActions from "./SelectedIdsActions";
import AppsPagination from "@crema/core/AppsPagination";
import Hidden from "@mui/material/Hidden";
// import ViewSelectButtons from "./ViewSelectButtons";
import { AppState } from "redux/store";
// import { TodoObj } from "../../../../../types/models/apps/Todo";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NodeStorage from 'mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import SortIcon from '@mui/icons-material/Sort';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { SORTBY } from "../InsertedObjects";
import { canEditSpace } from '@crema/utility/Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appsPagination: {
      overflow: "hidden",
      paddingRight: 2,
      paddingLeft: 2,
      "& .MuiToolbar-root": {
        paddingLeft: 0,
      },
      "& .MuiTablePagination-actions": {
        marginLeft: 0,
        "& .MuiButtonBase-root": {
          padding: 2,
        },
      },

    },
  }));

interface ModelContentHeaderProps {
  selectedIds: string[];
  setSelectedIds: (selectedIds: string[]) => void;
  onSortItemClickHandler: (sortBy: SORTBY) => void;
  // filterText: string;
  // onSetFilterText: (text: string) => void;
  // onPageChange: (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   page: number
  // ) => void;
  // onViewModeSelect: (mode: string) => void;
  // viewMode: string;
  // page: number;
}

const ModelContentHeader: React.FC<ModelContentHeaderProps> = ({
  selectedIds,
  setSelectedIds,
  onSortItemClickHandler
}) => {
  const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
  const page = 0;
  const classes = useStyles();
  const onHandleMasterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const ids = Array.from(spaceModels.keys());
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  };

  const onDeleteAllItems = async () => {
    const con = window.confirm(`Are you sure you want to delete all ${selectedIds.length} items?`);
    if (con) {
      const ids = Array.from(selectedIds);
      ids.forEach(async (id) => {
        await NodeStorage.deleteNodeById(id);
      });
    }
  }

  const { messages } = useIntl();
  const theme = useTheme();

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
        }}
      >
        {canEditSpace() && <Checkbox
          style={{
            color: "text.disabled",
            position: "relative",
            left: "-12px",
          }}
          indeterminate={
            selectedIds.length > 0 && selectedIds.length < Array.from(spaceModels.keys()).length
          }
          checked={
            Array.from(spaceModels.keys()).length > 0 && selectedIds.length === Array.from(spaceModels.keys()).length
          }
          onChange={onHandleMasterCheckbox}
        />}
        {canEditSpace() && Array.from(spaceModels.keys()).length > 0 && selectedIds.length > 0 &&
          (<DeleteOutlinedIcon
            style={{
              position: "relative",
              left: "-8px",
              cursor: 'pointer'
            }}
            onClick={(e) => {
              onDeleteAllItems();
              e.stopPropagation();
            }}
          />)}
        {Array.from(spaceModels.keys()).length > 0 && (<OverflowMenu menuItems={[
          { title: "Alphabetical", onClick: () => { onSortItemClickHandler(SORTBY.ALPHABETICAL) } },
          { title: "Last Updated On", onClick: () => { onSortItemClickHandler(SORTBY.LAST_UPDATED_ON) } },
          { title: "Created On", onClick: () => { onSortItemClickHandler(SORTBY.CREATED_ON) } },
        ]}
          propIcon={<SortIcon />} />)}
        <Box style={{ marginRight: 3 }}>
          {/* <AppSearchBar
            iconPosition="right"
            overlap={false}
            value={filterText}
            onChange={(event: any) => onSetFilterText(event.target.value)}
            placeholder={messages["common.searchHere"] as string}
          /> */}
        </Box>
        {/* <SelectModelsDropdown
          onSelectModels={onSelectModels}
          selectedIds={selectedIds}
        /> */}
        {selectedIds.length > 0 ? (
          <Box
            component="span"
            style={{
              [theme.breakpoints.down('sm')]: {
                marginRight: 4
              },
              display: "flex",
            }}
          >
            {/* <SelectedIdsActions
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              page={page}
            /> */}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ModelContentHeader;
