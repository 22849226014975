import Api from "@crema/services/ApiConfig";
import { firestore } from "@crema/services/auth/firebase/firebase";
import { store } from "App";
import { sendEmailsToCollaborator } from "modules/apps/ProjectBoard/ProjectDetail/List/AddCard";
import { getTagSidForTagId, isRepositioning, setAddAnnotationType, tagSidBeingAdded } from "modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils";
import { isST, doesTagSidExistInShowcase, getFirestoreLink, getProjectLabel, handleTagClick, initiateTag } from "modules/home/SpaceDetail/utils";
import { SDKInstance } from 'modules/fiber/Sdk';
import { Dispatch } from "react";
import shortUUID from "short-uuid";
import { AppActions } from "types";
import { SET_IS_ADDING_TAG, SET_SPACE_DATA } from "types/actions/Home.action";
import { LabelObj, TaskObj } from "types/models/apps/ProjectBoard";
import { ANNOTATION_TYPE, HomeRoutes, IQuizDetails, ShowcaseTag } from "types/models/home/HomeApp";
import { fetchError, fetchStart, fetchSuccess, showMessage } from "./Common";
import { updateTag } from "./Home";
import { onEditTaskDetails, onGetProjectDetail } from "./ProjectboardApp";

export function addTag(annoType: ANNOTATION_TYPE) {

    return (dispatch: Dispatch<AppActions>) => {
        setAddAnnotationType(annoType);
        dispatch({ type: SET_IS_ADDING_TAG, payload: true });
        dispatch(showMessage(`Move cursor to the Space area to ${isRepositioning ? 'reposition the tag' : 'add the tag'}`));
    }
}

export function stopAddingTag() {

    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SET_IS_ADDING_TAG, payload: false });
        setAddAnnotationType(ANNOTATION_TYPE.INFO);
    }
}

/*
Save tag to the db, add to showcase and sidebars
*/
export const onAddTag2 = async (
    tagLabelInput: string,
    tagDescInput: string,
    annotationType: string,
    quizDetails: IQuizDetails,
    taskTag: TaskObj | null,
    tagHtml: string,
    media: { type: string, src: string },
    attachmentsUrls: string[],
    showTextToSpeech: boolean,
    uploadType: string,
    labelIds: number[],
    tagBeingEdited: ShowcaseTag | undefined,
    space: any,
    userId: any,
    pathname: any,
    projectDetail: any,
    // onSuccess: () => void,
) => {

    let currentSpaceId = store.getState().home.currentSpace?.id || '';
    try {
        store.dispatch(fetchStart());

        let tagDocRef;
        let allTags;
        let tagAdded;
        let tagSid: string;
        if (tagBeingEdited) {

            tagDocRef = firestore.doc(`Spaces/${currentSpaceId}/tags/${tagBeingEdited.id}`);
            tagAdded = tagBeingEdited.data;
            tagSid = tagBeingEdited.data.sid;

        } else {

            let tag = tagSidBeingAdded;
            tagSid = tag[0];
            console.log(`[st] onAddTag2 tag is ${tagSid} `);

            tagDocRef = firestore.collection(`Spaces/${currentSpaceId}/tags`).doc();

            //delete tag just created
            allTags = await SDKInstance.sdk?.Mattertag.getData();
            tagAdded = allTags.find((t: any) => t.sid === tagSid);

        }

        console.log(
            `[st] adding tag id  ${getFirestoreLink(
                `Spaces/${currentSpaceId}/tags/${tagDocRef.id}`,
            )}`,
        );

        if (!tagAdded) {
            console.error(
                `couldnt find ${!tagBeingEdited?.id ? "tagAdded" : "tagEdited"}. looking for ${tagSid} found in ${allTags?.map(
                    (x: any) => x.sid + ', ' + x.label,
                )}`,
            );
            throw new Error(`couldnt find ${!tagBeingEdited?.id ? "tagAdded" : "tagEdited"}`);
        }

        if (!isST()) {
            await SDKInstance.sdk?.Mattertag.remove(tagAdded.sid);
        }
        // let attachmentsUrls: any[] = await uploadMediaFiles(attachments);

        //add tag again (to avoid editBillboard)
        tagAdded.label = tagLabelInput;
        tagAdded.description = tagDescInput;

        if (annotationType === ANNOTATION_TYPE.INFO && media.src) {
            tagAdded.media.type = media.type;
            tagAdded.media.src = media.src;
        }
        let tagSids = [];
        let newTagSid = '';
        if (!isST()) {
            try {
                tagSids = await SDKInstance.sdk?.Mattertag.add(tagAdded)
                    .then((data: any) => {
                        console.log('tag added', data)
                        return data;
                    })
                    .catch((e: any) => {
                        console.error(e);
                        return [];
                    });
                ;
            } catch (err: any) {
                console.error(err);
            }
            newTagSid = tagSids[0];
        } else {
            newTagSid = shortUUID.generate();
        }

        //create showcaseTag to save
        tagAdded.sid = newTagSid;
        tagAdded.source = 'ST';
        let showcaseTag: ShowcaseTag = {
            id: tagDocRef.id,
            data: JSON.stringify(tagAdded),
            createdOn: new Date(),
            lastUpdatedOn: new Date(),
            createdBy: userId,
            lastUpdatedBy: userId,
        };
        showcaseTag.annotationType = annotationType || ANNOTATION_TYPE.INFO;
        quizDetails && quizDetails.question && (showcaseTag.quizDetails = quizDetails);
        tagHtml && (showcaseTag.tagHtml = tagHtml);
        // mediaLinkInput && (showcaseTag.mediaLink = mediaLinkInput);
        showcaseTag.showTextToSpeech = showTextToSpeech ? true : false;
        // (attachmentsUrls?.[0]||mediaLinkInput)&&(showcaseTag.mediaUpload= attachmentsUrls.length?"attachment":"link");
        uploadType && (showcaseTag.uploadType = uploadType);
        labelIds && (showcaseTag.labelIds = labelIds);

        if (annotationType === ANNOTATION_TYPE.TASK) {
            if (tagBeingEdited) {
                // await Api.put('/api/projects/edit/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
                //   // if (data.status === 200) showcaseTag.projectId = data.data.id;
                // });

                await Api.put('/api/projects/edit/task', { task: { ...taskTag, tagId: tagDocRef.id } }).then(async (data) => {
                    // if (data.status === 200) showcaseTag.projectId = data.data.id;
                    let project = data.data;
                    // console.log(`[st] [task] task edited. project: ${JSON.stringify(project)}`)
                    space && store.dispatch({ type: SET_SPACE_DATA, payload: { ...space, currentSpaceProject: project } });
                    //TODO send emails to collaborator
                    taskTag && tagBeingEdited?.taskTag && sendEmailsToCollaborator(project.did, tagBeingEdited?.taskTag, taskTag);
                })
            } else {
                // <<<<<<< HEAD
                // await Api.post('/api/projects/add/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
                //   if (data.status === 200) showcaseTag.projectId = data.data.id;
                // });
                await Api.post('/api/projects/add/task', { task: { ...taskTag, tagId: tagDocRef.id } }).then(async data => {
                    if (data.status === 200) {
                        showcaseTag.projectId = data.data.project?.id;
                        // let index = data.data.project.sectionList.findIndex((i:TaskSectionObj)=>(i.id == taskTag?.sectionId));
                        // let selectedTask = data.data.project.sectionList[index].taskList[data.data.project.sectionList[index].taskList.length-1];

                        taskTag && (taskTag.id = data.data.task?.id);

                        space && store.dispatch({ type: SET_SPACE_DATA, payload: { ...space, currentSpaceProject: data.data.project } });
                        taskTag && sendEmailsToCollaborator(data.data.project.did, taskTag, taskTag);

                    }
                })
                // =======
                //          await Api.post('/api/projects/add/card', { project: taskTag?.project, list: taskTag?.list, card: { ...taskTag?.selectedCard, tagId: tagDocRef.id } }).then(async (data) => {
                //             if (data.status === 200) {
                //               showcaseTag.projectId = data.data.id;
                //               let index = data.data.sectionList.findIndex((i:TaskSectionObj)=>(i.id == taskTag?.selectedCard?.sectionId));
                //               let selectedTask: TaskObj = data.data.sectionList[index].taskList[data.data.sectionList[index].taskList.length-1];

                //               taskTag && taskTag?.selectedCard && (taskTag.selectedCard.id = selectedTask.id);

                //               // dispatch({ type: SET_SPACE_DATA, payload: {...space, currentSpaceProject:data.data} });
                //               // sendEmailsToCollaborator(null,taskTag);
                //             }
                //           })
                //           // if(pathname.includes(HomeRoutes.BOARD))
                //           //   dispatch(onGetProjectDetail(projectDetail?.did));
                // >>>>>>> s17/arp/decMerge
            }
            if (pathname.includes(HomeRoutes.TASKS)) {
                projectDetail?.did && store.dispatch(onGetProjectDetail(projectDetail?.did));
            }
        }

        if (tagBeingEdited) {
            await tagDocRef.update(showcaseTag).catch(console.error);


        } else {
            await tagDocRef.set({ ...showcaseTag });

            // // after save task tag in space then pass into showcase
            // taskTag && (showcaseTag.taskTag = taskTag);

            // showcaseTag.data = tagAdded;
            // await initiateTag(showcaseTag, tagAdded.sid);

        }

        // after save task tag in space then pass into showcase
        taskTag && (showcaseTag.taskTag = taskTag);
        if (!isST()) {
            //TODO //FIX DUBIOUS TWO LINES: //is this for repositioning?
            let sid = getTagSidForTagId(showcaseTag.id);
            sid && (tagAdded.sid = sid);
            //
            showcaseTag.data = tagAdded;
            tagAdded.sid && await initiateTag(showcaseTag, tagAdded.sid);
        }

        store.dispatch(fetchSuccess());
        store.dispatch(showMessage(!tagBeingEdited?.id ? "Tag created!" : "Tag edited!"));

        await SDKInstance.sdk?.Mattertag.navigateToTag(
            tagAdded.sid,
            SDKInstance.sdk.Mattertag.Transition.FLY,
        );
        console.log(`[st] [tags] updating tag`, showcaseTag);
        if (typeof showcaseTag.data == 'string') {
            showcaseTag.data = JSON.parse(showcaseTag.data);
        }
        store.dispatch(updateTag(showcaseTag));
        // handleTagClick(showcaseTag.id);

        return showcaseTag;

        // console.log(`[st] nav to tag 3`);
        //       // set Icon for tag
        //       let iconId: string;
        //       if (annotationType === ANNOTATION_TYPE.QUIZ) {

        //         if (tag.color) {
        //           iconId = `quiz-${TagColorToMatterportColorObj(tag.color)}`;
        //         } else {
        //           iconId = 'quizIconId';
        //         }

        //       } else if (annotationType === ANNOTATION_TYPE.TASK) {
        //         let labelName = showcaseTag.taskTag?.section?.name || '';
        //         iconId = `task${labelName ? labelName.replaceAll(' ', '') : ''}`;
        //         iconId = iconId;
        //       } else {
        //         if (tag.media && tag.media.src) {
        //           iconId = `media`;
        //         } else {
        //           iconId = `info-${TagColorToMatterportColorObj(tag.color)}`;
        //         }

        //       }
        //       await mpSdk.Mattertag.editIcon(tag?.sid, iconId);

    } catch (error) {
        console.error(error);
        store.dispatch(
            fetchError(
                'Something went wrong connecting to Matterport. Please try again or refresh the page if the problem persists',
            ),
        );
    }
    // }
};

// Update the labels of tags

export const updateTagLabels = async (tag: ShowcaseTag, labelIds: Array<number>) => {
    const currentSpaceId = store.getState().home.currentSpace?.id || '';

    console.log(`[st] [tags] setting tag labels to ${labelIds} for ${tag.id} task: ${tag.taskTag}`)
    if (tag.annotationType == ANNOTATION_TYPE.TASK) {
        let labels: LabelObj[] = [];
        labelIds.map(lid => {
            let l = getProjectLabel(lid);
            if (!!l) { labels.push(l) };
        })
        !!tag.taskTag && store.dispatch(onEditTaskDetails({ ...tag.taskTag, label: labels },
            (task: TaskObj) => {
                store.dispatch((updateTag({ ...tag, taskTag: task })));
            }));
    } else {
        const tagDocRef = firestore.doc(`Spaces/${currentSpaceId}/tags/${tag.id}`);
        const response = await tagDocRef.update({ labelIds: labelIds });
    }
}