import { StSdk } from '../CustomSdk/SpatialThinkSDK';

const SDK_VERSION = '3.5';
export const MATTERPORT_APP_KEY = 'd9aa6b4671a74fc69305497e14cfda8a';
export const GetSDK = function(elementId: string|HTMLIFrameElement): Promise<any> {

return new Promise(function(resolve, reject) {
  const checkIframe = async function() {
    var iframe = null;

    if (elementId instanceof HTMLIFrameElement) {
      iframe = elementId as HTMLIFrameElement;
    }
    else {
      iframe = document.getElementById(elementId);
    }

    if (iframe
        && (iframe as any).contentWindow.MP_SDK
        )
      {
        clearInterval(intervalId);

      // addSdkToDom();
        const sdk = await (iframe as any).contentWindow.MP_SDK.connect(iframe, MATTERPORT_APP_KEY, SDK_VERSION);
        // const sdk = SpatialThinkSDK.instance.getSDK();
      console.log('got showcase')
      resolve(sdk);
      // (iframe as any).contentWindow = Simulation.instance.customSDK!.renderer!.domElement!
      // iframe!.appendChild(Simulation.instance.customSDK!.renderer!.domElement!)
      // document.body.appendChild(Simulation.instance.customSDK!.renderer!.domElement!)
    }
  };
  const intervalId = setInterval(checkIframe, 100);
});
}


export async function GetSDK2(elementId: string) {

  const iframe = document.getElementById('sdk-iframe');

  // connect the sdk; log an error and stop if there were any connection issues
  try {
    const mpSdk = await(window as any).MP_SDK.connect(
      iframe, // Obtained earlier
      MATTERPORT_APP_KEY, // Your SDK key
      '' // Unused but needs to be a valid string
    );
    return mpSdk;
  } catch (e) {
    console.error(e);
  }
}
