import { Box, ClickAwayListener, IconButton, Tooltip, useTheme } from "@material-ui/core";
import { store } from "App";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { onShowPropertiesPanel } from "redux/actions/Home";
import { AppState } from "redux/store"
import { SELECT_NODE, SHOW_PROP_PANEL } from "types/actions/ThreeD.actions"
import PropertiesPanel from "./PropertiesPanel";
import * as Icons from '@mui/icons-material/';
import { canEditSpace } from '@crema/utility/Utils';
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { getNodePropsFromNode } from "types/models/home/HomeApp";

export default function PropPanelWrapper() {
    const showPropertiesPanel = useSelector<AppState, boolean>(({ threeD }) => threeD.showPropertiesPanel);
    const dispatch = useDispatch();
    const theme = useTheme();

    const [isMouseCaptured, setMouseCaptured] = React.useState(false);
    const [isHovering, setHovering]= React.useState(false);

    React.useEffect(() => {
        if (showPropertiesPanel && Simulation.instance.lastSelectedNode) {

            let node = Simulation.instance.lastSelectedNode;

            // if (node.userData.id !== store.getState().threeD.selectedNode?.userData.id) {
            store.dispatch({ type: SELECT_NODE, payload: getNodePropsFromNode(node) });
            // }
        }
    });

    return (console.log(`%c[st] rendering PropPanelWrapper ${showPropertiesPanel}`, 'color: orange;'), (canEditSpace() &&
    showPropertiesPanel && !store.getState().layer.presentationMode && (Simulation.instance.lastSelectedNode?.userData["hasPropertiesPanel"])
    ) ?
        <div id='prop-panel-wrapper' style={{ position: 'absolute', zIndex: 1110, height: "100%" }}>

            <div style={{
                backgroundColor: theme.palette.background.default, height: "100%",
            }}>
                <ClickAwayListener onClickAway={() => { !isMouseCaptured && dispatch(onShowPropertiesPanel(false)); }}
                >
                    <Box style={{
                        minWidth: '320px',
                        maxWidth: '320px',
                        maxHeight: '100%',
                    }}

                        onMouseDown={() => setMouseCaptured(true)}
                        onBlur={() => !isMouseCaptured && (setMouseCaptured(false), dispatch(onShowPropertiesPanel(false)))}
                    // onMouse={() => setMouseCaptured(false)}
                    // onMouseEnter={() => setHovering(true)}
                    // onMouseLeave={() => (console.log('[capt] left ' + isMouseCaptured), !isMouseCaptured && (dispatch(onShowPropertiesPanel(false))))}
                    >

                        {/* <Badge anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            badgeContent={
                                <IconButton color="primary" size="small">
                                    <Tooltip title={'Close Properties'}>
                                        <CancelIcon onClick={() => {
                                            store.dispatch({ type: SHOW_PROP_PANEL, payload: false })
                                        }} />
                                    </Tooltip>
                                </IconButton>}> */}
                        {/* <Tooltip title={'Go Back'}>
                            <Link
                                type='underlineAlways'
                                style={{ cursor: 'pointer' }}
                                underline='always'
                                onClick={() => {
                                    setMouseCaptured(false);
                                    store.dispatch({ type: SHOW_PROP_PANEL, payload: false })
                                }}>
                                BACK
                            </Link>
                        </Tooltip> */}
                        <Box style={{ position: 'absolute', left: 16, top: 20 }}>
                            <Tooltip title={'Back'}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setMouseCaptured(false);
                                        store.dispatch({ type: SHOW_PROP_PANEL, payload: false })

                                    }}>
                                    <Icons.ArrowBackIosNewRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <PropertiesPanel />
                        {/* </Badge> */}
                    </Box>
                </ClickAwayListener>
            </div>

        </div> : <></>)
}
