import * as THREE from "three";
import { MaterialHierarchy, MeshHierarchy } from "types/models/threeD";

export function generateRandomColor(): string {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    return randomColor;
}

export function getMeshHierarchy(scene: THREE.Scene, object: THREE.Object3D, parentName: string = ''): MeshHierarchy {
    const meshHierarchy: MeshHierarchy = {
      name: object.name ? object.name : parentName,
      children: [],
      parent: parentName,
    };

    // @ts-ignore
    if (object.hasOwnProperty('material') && Array.isArray(object.material)) {
      const materials: MaterialHierarchy[] = [];
      // @ts-ignore
      for (const material of object.material) {
        const materialName = material?.name || 'material';
        const materialColor = material?.color.getHexString() || '#efefef';
        materials.push({ name: materialName, color: materialColor });
      }
      // @ts-ignore
      meshHierarchy.material = materials;
      return meshHierarchy;
    }
    else {
      if (object instanceof THREE.Mesh) {
        const materialName = object.material?.name || 'material';
        const materialColor = object.material?.color.getHexString() || '#efefef';
        meshHierarchy.material = { name: materialName, color: materialColor };
        return meshHierarchy;
      }
    }

    for (const child of object.children) {
      const childHierarchy = getMeshHierarchy(scene, child, object.name);
      meshHierarchy.children.push(childHierarchy);
    }

    return meshHierarchy;
  }