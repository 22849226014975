import Simulation from '../../SubSystems/core/Simulation';
import * as THREE from 'three';
import {WebGLRenderer} from 'three';

export type Size = {w: number; h: number;};

export class DecoupledComponent {
    protected three: typeof THREE;
    protected renderer: WebGLRenderer;
    
    public constructor() {
        this.three = Simulation.instance.renderingSubSystem.three;
        this.renderer = Simulation.instance.renderingSubSystem.renderer;
    }
}