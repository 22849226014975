import { store } from 'App';
import { VariableTypeName } from 'modules/home/SpaceDetail/SpaceView/Sidebar/Variables/VariableTypes';
import { Object3D, AnimationMixer, AnimationAction, LoopOnce, AnimationClip, Mesh, Texture, MeshLambertMaterial, LineSegments } from 'three';
import { number } from 'yup';
import Simulation from '../../SubSystems/core/Simulation';
import { ComponentOutput, ISceneNode, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import { IoTSpecialProperties, UserDataProperties } from '../../SubSystems/ui-interop/PropertiesPanel';
import Utils from '../../Tools/Utils';
import { IPainter2d } from '../CanvasRenderer';
import { PlaneRenderer, Size } from './basic/PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

//TODO: @RAJ ON OFF Variables to map to the on off stuff of the actual thing

type Inputs = {
  loadingState: string;
  texture: Texture | null;
  updateInterval: number;
  showNumbers:boolean;
  color:string;
  strokeColor:string;
}

type Outputs = {
  painter: IPainter2d | null;
  visible: boolean;
} & ComponentOutput;


export class NestThermostat extends SceneComponent implements IPainter2d {
  private daeComponent: SceneComponent;
  private mixer: AnimationMixer | null = null;
  private onEnterClip: AnimationClip | null = null;
  private mesh: Mesh | null = null;
  private currentTime: number = 0;
  private currentDataIndex:number;
  private dataValues: string[] = [];

  inputs: Inputs = {
    loadingState: 'Idle',
    texture: null,
    updateInterval: 1000,
    showNumbers: false,
    color: "#CF5300",
    strokeColor: "#ffa500"
  }

  outputs = {
    painter: null,
    visible: false,
  } as Outputs;

  events = {
    [HoverEvent]: false,
    [UnhoverEvent]: false,
  };

  onInit() {
    const root = this.context.root;
    const THREE = this.context.three;

    let planeRenderer: PlaneRenderer;
    for (const component of root.componentIterator()) {
      if (component.componentType === 'mp.daeLoader') {
        this.daeComponent = component as SceneComponent;

      }
      else if (component.componentType === 'mp.planeRenderer') {
        planeRenderer = component as PlaneRenderer;
        //planeRenderer.outputs.objectRoot?.translateZ(0.05);
        //planeRenderer.outputs.objectRoot?.translateY(0.4);
        //planeRenderer.outputs.objectRoot?.scale.set(0.5, 0.5, 0.5);

        if(planeRenderer.outputs.objectRoot) {
          this.mixer = new THREE.AnimationMixer(planeRenderer.outputs.objectRoot);
        }
      }
    }

    this.outputs.painter = this;

    const tm = 0.2;
    const positionTrack = new THREE.VectorKeyframeTrack('.scale', [0, tm], [
      0, 0, 0,
      0.5, 0.5, 0.5
    ], THREE.InterpolateSmooth);
    this.onEnterClip = new THREE.AnimationClip(undefined, tm, [positionTrack]);

  }

  onInputsUpdated() {
    const THREE = this.context.three;
    if (this.inputs.loadingState === 'Loaded') {
      const lines: LineSegments[] = [];
      this.daeComponent.outputs.objectRoot?.traverse((obj: Object3D) => {
        // we dont want line segments, track them and remove them.
        if (obj.type === 'LineSegments') {
          lines.push(obj as LineSegments);
        }
        else if (obj.type === 'Mesh') {
          this.mesh = obj as Mesh;

          const material = this.mesh.material as MeshLambertMaterial;
          if (material && material.name === '_5b76dbe388862300126c1e14') {
            const newMaterial = new THREE.MeshBasicMaterial({ map: this.inputs.texture });
            //newMaterial.map!.offset = new THREE.Vector2(0, 0);
            this.mesh.material = newMaterial;
          }
        }
      });

      // remove the line segments.
      lines.forEach((line: LineSegments) => {
        line.parent?.remove(line);
      });
    }

    this.notify(RepaintEvent);
  }

  public activate(nodeUserData: any) {
    // if(!this.inputs.showNumbers) {
      if(this.updateDataFromSystemVariables(nodeUserData)) {
        this.currentDataIndex = 0;
        this.notify(RepaintEvent);
      }
      this.inputs.showNumbers = true;
    // }
  }

  private updateDataFromSystemVariables(nodeUserData: any):boolean {

    if(!nodeUserData){
      return false;
    }
    let targetSystemVariable = nodeUserData[UserDataProperties.inputSource1] as string;

      this.dataValues = [];

      if(targetSystemVariable && targetSystemVariable.length > 0) {
        if(targetSystemVariable !== "None") {
          // console.log(`[vars] checking var ${targetSystemVariable}`)
          let variableWithValues = store.getState().layer.variableValues?.find(v => v.name === targetSystemVariable);

          // if(variableWithValues?.type === VariableTypeName.csvVariableType) {s
            console.log("activating IOT with: " + variableWithValues?.value + " -- " + variableWithValues?.name);
            if((variableWithValues?.value as string).includes("-")){
              this.dataValues = (variableWithValues?.value as string).split("-").map(x => x.trim());
            } else {
              this.dataValues = [variableWithValues?.value as string];
            }


          // } else if(variableWithValues?.type === VariableTypeName.differenceVariableType) {
          //   let differenceSet = (variableWithValues?.values as string).split(",").map(x => x.trim());

          //   if(differenceSet) {
          //     let variableWithValues1 = store.getState().layer.variableValues?.find(v => v.name === differenceSet[0]);
          //     let variableWithValues2 = store.getState().layer.variableValues?.find(v => v.name === differenceSet[1]);

          //     if(variableWithValues1 && variableWithValues2) {
          //       let values1 = (variableWithValues1.values as string).split(",").map(x => x.trim());
          //       let values2 = (variableWithValues2.values as string).split(",").map(x => x.trim());
          //       if(values1 && values2) {
          //         if(values1.length > 0 && values2.length > 0) {
          //           if(values1.length == values2.length) {
          //               this.dataValues = values1.map((x:string, index:number) => {
          //                  return (Number.parseFloat(x) - Number.parseFloat(values2[index])).toFixed(1);
          //               })
          //           } else {
          //             console.error("[st] Error: Both data sets should have the same amount of data")
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
          return true;
        }
      }

      return false;
  }

  public Deactivate() {
    //console.log("IOT Deactivated");
    this.inputs.showNumbers = false;
  }

  onEvent(eventType: string, eventData: unknown): void {

    // if (eventType === HoverEvent) {
      console.log(`hovering on nest`)
      const data: any = eventData;
      if (data.hover) {
        this.outputs.visible = true;

        if(this.onEnterClip) {
          const onEnterAction: AnimationAction = this.mixer?.clipAction(this.onEnterClip) as AnimationAction;
          onEnterAction.stop();
          onEnterAction.loop = LoopOnce;
          onEnterAction.clampWhenFinished = true;
          onEnterAction.play();
        }
      }
      else {
        this.outputs.visible = false;
      }
    // }
  }

  paint(context2d: CanvasRenderingContext2D, size: Size): void {
    const x = 490;
    const y = 490;

    context2d.fillStyle = 'black';
    context2d.beginPath();
    context2d.arc(x, y, 400, 0, Math.PI * 2);
    context2d.fill();

    context2d.fillStyle = this.inputs.color;

    context2d.beginPath();
    context2d.arc(x, y, 300, 0, Math.PI * 2);
    context2d.fill();

    context2d.beginPath();
    context2d.strokeStyle = this.inputs.strokeColor;

    context2d.arc(x, y, 240, 0.75 * Math.PI, 0.25 * Math.PI);
    context2d.lineCap = 'butt';
    context2d.lineWidth = 80;
    context2d.stroke();

    context2d.fillStyle = 'white';
    context2d.font = '180px Arial';
    context2d.textAlign = 'center';

    let textX = x - 25;
    let textY = y + 75;

    if(this.inputs.showNumbers) {

      if(this.dataValues.length > 0) {
        context2d.fillText(`${this.dataValues[this.currentDataIndex]}`, textX, textY);
      // } else {
      //   if(Simulation.instance.spaceModels().size > 0) {
      //     let node1:ISceneNode|undefined = undefined;
      //     let node2:ISceneNode|undefined = undefined;

      //     if(nodeUserData[IoTSpecialProperties.diff1].length > 0) {
      //       node1 = nodeUserData && Simulation.instance.spaceModels().get(nodeUserData[IoTSpecialProperties.diff1])
      //     }

      //     if(nodeUserData[IoTSpecialProperties.diff2].length > 0) {
      //       node2 = nodeUserData && Simulation.instance.spaceModels().get(nodeUserData[IoTSpecialProperties.diff2])
      //     }
      //     if(node1 && node2) {
      //       let nest1 = Utils.GetNestThermostatComponent((node1 as any).nodeRef);
      //       let nest2 = Utils.GetNestThermostatComponent((node2 as any).nodeRef);

      //       if(nest1 && nest2) {
      //         let difference = nest1.getCurrentDataValue() - nest2.getCurrentDataValue();

      //         context2d.fillText(`${difference.toFixed(1)}`, textX, textY);
      //       }
      //     }
      //   }
      }

    } else {
      context2d.fillText(`OFF`, textX, textY);
    }
  }

  getCurrentDataValue():number {
    if(this.dataValues.length > 0) {
      return Number.parseFloat(this.dataValues[this.currentDataIndex]);
    }

    return 0;
  }

  onTick(delta: number) {
  super.onTick(delta);
    if (this.mixer) {
      this.mixer.update(delta/1000);
    }

    if(this.inputs.showNumbers) {
      this.currentTime += delta;
      if (this.currentTime > 1000) {
        this.currentDataIndex++;
        if(this.currentDataIndex >= this.dataValues.length) {
          this.currentDataIndex = 0;
        }
        this.notify(RepaintEvent);
        this.currentTime = 0;
      }
    }
  }
}

export const nestThermostatType = 'mp.nestThermostat';
export const makeNestThermostat = function() {
  return new NestThermostat();
}
