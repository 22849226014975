import { GenericInput, GenericInputEvent, genericInputType } from "../../components/systemComponents/GenericInput";
import Simulation from "../core/Simulation";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { GenericInputDragBeginSpy } from "./spies/GenericInputDragBeginSpy";
import { GenericInputDragEndSpy } from "./spies/GenericInputDragEndSpy";
import { GenericInputKeySpy } from "./spies/GenericInputKeySpy";

export default class InputSubSystem {
    //private members
    private static _instance:InputSubSystem | null = null;
    private genericInput: GenericInput;

    //public members
    public static get instance():InputSubSystem {
        if (!InputSubSystem._instance) {
            InputSubSystem._instance = new InputSubSystem();
        }

        return InputSubSystem._instance;
    }

    public static get input():GenericInput {
        return InputSubSystem.instance.genericInput;
    }

    private constructor() {

    }

    public async Initialize(sdk:any):Promise<void> {
        console.log("[InputSubSystem] Performing initialization for Simulation...");
        const inputNode = Simulation.instance.sceneLoader.sceneObject.addNode() as ISceneNode;
        this.genericInput = inputNode.addComponent(genericInputType) as GenericInput;

        this.genericInput.setupInputNode(inputNode);

        const genericInputDragBeginSpy = new GenericInputDragBeginSpy();
        this.genericInput.spyOnEvent(genericInputDragBeginSpy);

        // let eventPathToSpyOn = Simulation.instance.sceneLoader.sceneObject.addEmitPath(this.genericInput, GenericInputEvent.DragBegin);
        // Simulation.instance.sceneLoader.sceneObject.spyOnEvent(genericInputDragBeginSpy);


        // const genericInputDragEndSpy = new GenericInputDragEndSpy();
        // this.genericInput.spyOnEvent(genericInputDragEndSpy);

        const genericInputKeySpy = new GenericInputKeySpy();
        this.genericInput.spyOnEvent(genericInputKeySpy);
    }
}