import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import NodeStorage from "mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage";
import { MpSdk, Scene } from "types/SdkTypes2";
import { SDKInstance } from "../Sdk";
import { Node } from "./Node";

export class TextBoxLegacy extends Node {
	public planeComponent1: Scene.IComponent;

	public static async makeLegacyTextBox(mpSdk: MpSdk, sceneObject: Scene.IObject) {
		let t = new TextBoxLegacy(mpSdk, sceneObject);
		let nodes = await NodeStorage.loadNode({objectOrObjectName: 'Text Box'});
		if (nodes.length > 0){
			t.node = nodes[0];
		} else {
			console.error(' [r3f] error: no nodes could be loaded for legacy text box');
		}
		return t;
	}

	private constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {
		super(mpSdk, sceneObject);
	}
}
