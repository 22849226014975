import {Texture, WebGLRenderTarget} from 'three';
import {DecoupledComponent} from './DecoupledComponent';
import * as THREE from 'three';
import {Size} from '../meshComponents/basic/PlaneRenderer';
import {CanvasTextDecoupled} from './CanvasTextDecoupled';
import {IRenderable2D} from './IRenderable';
import {isST} from '../../../../../modules/home/SpaceDetail/utils';
import {ThreeContext} from '../systemComponents/ThreeContext';

export class CanvasRendererDecoupled extends DecoupledComponent {
    private canvas: HTMLCanvasElement;
    private renderContext2D: CanvasRenderingContext2D | null;
    private renderTarget: THREE.CanvasTexture;

    public constructor(public resolution: Size) {
        super();

        let t = ThreeContext.i?.t!;
        if (isST()) {
            t = THREE!;
        }

        // set up canvas 2d context
        this.canvas = document.createElement('canvas');
        this.renderContext2D = this.canvas.getContext('2d')!;

        this.canvas.width = resolution.w;
        this.canvas.height = resolution.h;

        // create three.js objects to render
        this.renderTarget = new t.CanvasTexture(this.renderContext2D.canvas, t.UVMapping, t.RepeatWrapping,
            t.RepeatWrapping, t.LinearFilter, t.LinearFilter, t.RGBAFormat, t.UnsignedByteType, 1);


        this.resize(resolution);
    }

    public get RenderTargetTexture(): Texture {
        return this.renderTarget;
    }

    public get Context2D(): CanvasRenderingContext2D {
        return this.renderContext2D!;
    }

    public clear(fillStyle: string | null): void {
        if (fillStyle) {
            this.renderContext2D!.fillStyle = fillStyle!;
            this.renderContext2D?.fillRect(0, 0, this.resolution.w, this.resolution.h);
        } else {
            this.renderContext2D?.clearRect(0, 0, this.resolution.w, this.resolution.h);
        }
    }

    public repaint(iRenderable2D: IRenderable2D) {
        iRenderable2D.paint(this.renderContext2D, this.resolution);
        this.renderTarget.needsUpdate = false;
    }

    public commitRepaint(): void {
        this.renderTarget.needsUpdate = true;
    }

    private resize(size: Size) {
        this.canvas.width = size.w;
        this.canvas.height = size.h;
        // this.renderTarget.width = size.w;
        // this.renderTarget.height = size.h;
    }
}