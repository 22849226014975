import { store } from 'App';
import { Object3D, Mesh, Texture, LineSegments, MeshBasicMaterial, TextureLoader, DoubleSide } from 'three';
import { ComponentOutput, ISceneNode, SceneComponent } from '../SubSystems/sceneManagement/SceneComponent';
import { UserDataProperties } from '../SubSystems/ui-interop/PropertiesPanel';
import { IPainter2d } from './CanvasRenderer';
import { CanvasText } from './CanvasText';
import { PlaneRenderer, Size } from './meshComponents/basic/PlaneRenderer';

const HoverEvent = 'hover';
const UnhoverEvent = 'unhover';
const RepaintEvent = 'repaint';

type Inputs = {
    loadingState: string;
    texture: Texture | null;
    updateInterval: number;
    showNumbers: boolean;
    color: string;
    strokeColor: string;
    textureSource: string;
    hideDigitalReading: boolean;
    minReading: number;
    maxReading: number;
    initialReadingOffset: number;
    rotationDelta: number;
}

type Outputs = {
    painter: IPainter2d | null;
    visible: boolean;
} & ComponentOutput;


export class AnalogGaugeComponent extends SceneComponent implements IPainter2d {
    private gltfComponent: SceneComponent;
    private canvasTextComponent: CanvasText;
    private mesh: THREE.Mesh | null = null;
    private currentTime: number = 0;
    private currentDataIndex: number;
    private dataValues: string[] = [];
    private planeRenderPanel: PlaneRenderer | null = null;
    // private dontLoopThroughDataValues = false; //loop by default

    public static readonly defaultTextureSource = "/assets/3d/Gauge1.jpeg";
    public static readonly defaultRotationDelta = 1; //increase the value to expand the angle (remember it's negative)
    public static readonly defaultIntitalReadingOffset = 0; //around 0.1 keeps 50 right in the middle, > 0.1 makes it drift left behind
    public static readonly defaultMinReading = 0;
    public static readonly defaultMaxReading = 100;

    inputs: Inputs = {
        loadingState: 'Idle',
        texture: null,
        updateInterval: 1000,
        showNumbers: false,
        color: "#CF5300",
        strokeColor: "#ffa500",
        textureSource: "/assets/3d/Gauge1.png",
        hideDigitalReading: false,
        minReading: AnalogGaugeComponent.defaultMinReading,
        maxReading: AnalogGaugeComponent.defaultMaxReading,
        initialReadingOffset: AnalogGaugeComponent.defaultIntitalReadingOffset,
        rotationDelta: AnalogGaugeComponent.defaultRotationDelta,
    }

    outputs = {
        painter: null,
        visible: false,
    } as Outputs;

    events = {
        [HoverEvent]: false,
        [UnhoverEvent]: false,
    };

    onInit() {
        const root = this.context.root;

        for (const component of root.componentIterator()) {
            if (component.componentType === 'mp.gltfLoader') {
                this.gltfComponent = component as SceneComponent;
            } else if (component.componentType === 'mp.canvasText') {
                this.canvasTextComponent = component as CanvasText;
            }
            if (component.componentType === 'mp.planeRenderer') {
                this.planeRenderPanel = (component as PlaneRenderer);
            }
        }

        this.outputs.painter = this;
    }

    onInputsUpdated() {
        const THREE = this.context.three;
        this.inputs.rotationDelta = !this.inputs.rotationDelta?.toString() ? AnalogGaugeComponent.defaultRotationDelta : this.inputs.rotationDelta;;
        this.inputs.initialReadingOffset = !this.inputs.initialReadingOffset?.toString() ? AnalogGaugeComponent.defaultIntitalReadingOffset : this.inputs.initialReadingOffset;
        this.inputs.minReading = this.inputs.minReading || AnalogGaugeComponent.defaultMinReading;
        this.inputs.maxReading = this.inputs.maxReading || AnalogGaugeComponent.defaultMaxReading;

        if (this.inputs.loadingState === 'Loaded') {
            const lines: LineSegments[] = [];
            let i = 0;
            this.gltfComponent.outputs.objectRoot?.traverse((obj: Object3D) => {
                if (obj.type === 'Mesh') {
                    if (i == 6) {
                        this.mesh = obj as Mesh;
                        if (parseInt(this.dataValues[this.currentDataIndex]))
                            this.mesh.rotation.y = this.getNeedleRotation();
                        // console.log("on inputsupdated getNeedleRotation", this.getNodeUserData().nameToShow, this.mesh.rotation.y, this.inputs.initialReadingOffset, this.inputs.rotationDelta, this.inputs.maxReading, this.inputs.minReading, this.dataValues[this.currentDataIndex])
                    }
                    if (i == 3) {
                        const bodyMesh = obj as THREE.Mesh;
                        const textureLoader = new THREE.TextureLoader();
                        bodyMesh.material = new THREE.MeshBasicMaterial({
                            map: textureLoader.load(this.inputs.textureSource || AnalogGaugeComponent.defaultTextureSource),
                            side: DoubleSide
                        })
                    }
                }
                i++;
            });
        }

        this.notify(RepaintEvent);
    }

    public activate(nodeUserData: any) {
        if (this.updateDataFromSystemVariables(nodeUserData)) {
            this.currentDataIndex = 0;
            this.notify(RepaintEvent);
        }
        this.inputs.showNumbers = true;
    }

    private updateDataFromSystemVariables(nodeUserData: any): boolean {

        if (!nodeUserData) {
            return false;
        }
        let targetSystemVariable = nodeUserData[UserDataProperties.inputSource1] as string;

        this.dataValues = [];

        if (targetSystemVariable && targetSystemVariable.length > 0) {
            if (targetSystemVariable !== "None") {

                let variableWithValues = store.getState().layer.variableValues?.find(v => v.name === targetSystemVariable);
                // this.dontLoopThroughDataValues = !!variableWithValues.dontLoop;
                if (variableWithValues) {
                    console.log("activating IOT with: " + variableWithValues?.value + " -- " + variableWithValues?.name, variableWithValues);
                    if ((variableWithValues?.value as string).includes("-")) {
                        this.dataValues = (variableWithValues?.value as string).split("-").map(x => x.trim());
                    } else {
                        this.dataValues = [variableWithValues?.value as string];
                    }
                }

                return true;
            }
        }
        return false;
    }

    public Deactivate() {
        this.inputs.showNumbers = false;
        this.canvasTextComponent.inputs.text = ""
        this.canvasTextComponent.notify('textUpdate');
    }

    onEvent(eventType: string, eventData: unknown): void {

        // console.log(`hovering on nest`)
        const data: any = eventData;
        if (data.hover) {
            this.outputs.visible = true;
        }
        else {
            this.outputs.visible = false;
        }
    }

    getNeedleRotation() {

        let rot = ((this.inputs.rotationDelta * -0.0515) * ((100 / (this.inputs.maxReading - this.inputs.minReading)) * parseInt(this.dataValues[this.currentDataIndex]))) +
            (this.inputs.initialReadingOffset + 0.11);
        return rot;
    }

    paint(context2d: CanvasRenderingContext2D, size: Size): void {

        if (this.mesh && this.canvasTextComponent) {

            if ((this.inputs.showNumbers) && this.dataValues.length > 0) {
                if (this.dataValues[this.currentDataIndex] !== "0") {
                    !this.inputs.hideDigitalReading && (this.canvasTextComponent.inputs.text = "" + this.dataValues[this.currentDataIndex]);
                }
                else {
                    this.canvasTextComponent.inputs.text = "";
                }

                this.canvasTextComponent.notify('textUpdate');

                // console.log("[analogGauge] setting rotation to: ", this.getNeedleRotation());
                this.mesh.rotation.y = this.getNeedleRotation();
                // console.log("on paint getNeedleRotation", this.getNodeUserData().nameToShow, this.mesh.rotation.y, this.inputs.initialReadingOffset, this.inputs.rotationDelta, this.inputs.maxReading, this.inputs.minReading, this.dataValues[this.currentDataIndex])
                return;
            }

            this.canvasTextComponent.inputs.text = ""
            this.canvasTextComponent.notify('textUpdate');

            this.mesh.rotation.y = this.inputs.initialReadingOffset + 0.11;
        }


    }

    getCurrentDataValue(): number {
        if (this.dataValues.length > 0) {
            return Number.parseFloat(this.dataValues[this.currentDataIndex]);
        }
        return 0;
    }

    onTick(delta: number) {
        super.onTick(delta);

        // @ts-ignore
        if (this.planeRenderPanel.outputs.collider) {
            // @ts-ignore
            this.planeRenderPanel.outputs.collider = null;
        }
        if (this.inputs.showNumbers) {
            this.currentTime += delta;
            if (this.currentTime > 1000) {
                this.currentDataIndex++;
                if (this.currentDataIndex >= this.dataValues.length) {
                    if (this.dataValues.length > 1
                        && store.getState().home.currentSpace?.did == 'ddo57dcxERA-1'
                        //  && !!this.dontLoopThroughDataValues
                         ) {
                        //don't loop
                    } else {
                        this.currentDataIndex = 0;
                    }

                } else {
                    this.notify(RepaintEvent);
                    this.currentTime = 0;
                }
            }
        }
    }
}


export const AnalogGaugeType = 'mp.AnalogGauge';
export const makeAnalogGaugeComponent = function () {
    return new AnalogGaugeComponent();
};
