import { GraphDataType } from "redux/reducers/Reports";
import { UserSessionModel } from "types/models/Reports";


export const SET_SPACES = 'SET_SPACES';


// export const GET_SPACES = 'GET_SPACES';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const SET_STEP_SESSION_DATA = 'SET_STEP_SESSION_DATA';


export interface SetUserSessionAction {
    type: typeof SET_USER_SESSION;
    payload: UserSessionModel;
}

export interface SetStepSessionDataAction {
    type: typeof SET_STEP_SESSION_DATA;
    payload: GraphDataType;
}
export interface SetSpacesAction {
    type: typeof SET_SPACES;
    payload: any[];
}

// export interface GetSpacesAction {
//     type: typeof GET_SPACES;
// }

export type ReportsActionTypes =
    SetUserSessionAction
    | SetStepSessionDataAction
    | SetSpacesAction
    // | GetSpacesAction;
