// import * as THREE from "three";
import { Scene, MpSdk } from "types/SdkTypes2";
import { SDKInstance } from "../Sdk";
import { Node, Spy } from "./Node";

export class InputControls extends Node {
    public inputComponent: Scene.IComponent;
    // public inputNode: Scene.INode;
    // public mpSdk: MpSdk;
    // public sceneObject: Scene.IObject;

    constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {
        super(mpSdk, sceneObject);
        this.node = sceneObject.addNode();
        // this.mpSdk = mpSdk;
        // this.sceneObject = sceneObject;
        // this.node = sceneObject.addNode();
        this.inputComponent = this.node.addComponent("mp.input", {
            eventsEnabled: true,
            userNavigationEnabled: true,
        });
        this.node.start();
        (SDKInstance.sdk as MpSdk)!.threeContext = this.inputComponent.context.three;
        (SDKInstance.sdk as MpSdk)!.scene = this.inputComponent.context.scene;

        // this.addClickSpy(this.inputComponent, (payload: unknown) => {
            // this.mpSdk.R3F.controlsToggle(true);
        // })

        // this.addHoverSpy(this.inputComponent, (payload: unknown) => {
        //     this.mpSdk.R3F.controlsToggle(true);
        // })
        console.log(' [r3f] input controls set');
    }

    public pauseCameraMovement() {
        (this.inputComponent.inputs as Scene.SceneComponentOptions["mp.input"])
            .userNavigationEnabled = false;
    }
    // public setCameraPosition(position: Vector3) {
    //     const camera = this.mpSdk.Scene.getCamera();
    //     if (camera) {
    //         camera.setPosition(position);
    //     }
    // }

    // public setCameraRotation(rotation: Quaternion) {
    //     const camera = this.mpSdk.Scene.getCamera();
    //     if (camera) {
    //         camera.setRotation(rotation);
    //     }
    // }
}
