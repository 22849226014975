import {
    Texture,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Euler,
    MathUtils,
    Wrapping,
    ClampToEdgeWrapping,
    RepeatWrapping,
    Material,
    ShapeGeometry,
    BoxGeometry,
    // ShapeGeometry,
} from 'three';
import {SceneComponent, ComponentInteractionType} from '../../../SubSystems/sceneManagement/SceneComponent';
import Utils from '../../../Tools/Utils';
import * as THREE from 'three';
import {EulerIntegrator} from '../../systemComponents/EulerIntegrator';
import {CanvasRenderer} from '../../CanvasRenderer';
import CardinalAxesAndPlanes from '../../../Tools/CardinalAxesAndPlanes';
import QueueScheduler from '../../../Tools/QueueScheduler';
import {CanvasText} from '../../CanvasText';
import {CanvasBorder} from '../../CanvasBorder';

export type Size = {w: number; h: number;};
export type SizeScale = {x: number; y: number;};

type Inputs = {
    texture: Texture | null;
    aspect: number;
    transparent: boolean;
    visible: boolean;
    opacity: number;
    color: number;
    borderRadius: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    invertScale: boolean;
    //planeSize: { w:number, y:number };
    localScale: {x: number; y: number; z: number;};
    localPosition: {x: number; y: number; z: number;};
    localRotation: {x: number; y: number; z: number;};
    // noColliders: boolean;
}

export class PlaneRenderer extends SceneComponent implements IPlaneRenderer {
    public canvasText: CanvasText | null = null;
    public canvasRenderer: CanvasRenderer | null = null;
    inputs: Inputs = {
        texture: null,
        aspect: 1,
        transparent: true,
        visible: true,
        opacity: 1,
        color: 0xffffff,
        polygonOffset: false,
        borderRadius: 10,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        invertScale: false,
        localScale: {x: 1, y: 1, z: 1},
        localPosition: {x: 0, y: 0, z: 0},
        localRotation: {x: 0, y: 0, z: 0},
        // noColliders: false
    };
    events = {
        [ComponentInteractionType.CLICK]: true,
    };
    private mesh: Mesh | null = null;
    private pivotNode: Object3D;
    private oldRootScale: THREE.Vector2;
    private isTextBox: boolean = false;
    private textCanvasRenderer: CanvasRenderer | null = null;
    private textCanvasText: CanvasText | null = null;
    private textCanvasBorder: CanvasBorder | null = null;
    private rebuildMeshQueue: QueueScheduler<any>;

    buildMesh(any: any | null = null): boolean {
        const THREE = this.context.three;

        if (this.mesh) {
            this.pivotNode.remove(this.mesh);
            this.mesh.geometry.dispose();
            (this.mesh.material as Material).dispose();
            this.mesh = null;
        }

        if (this.inputs.invertScale) {
            this.mesh = new THREE.Mesh(
                //new THREE.PlaneBufferGeometry(this.oldRootScale.x, this.oldRootScale.y),
                new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, this.inputs.borderRadius)).translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                new THREE.MeshBasicMaterial({
                    transparent: this.inputs.transparent,
                    map: this.inputs.texture,
                    opacity: this.inputs.opacity,
                    color: this.inputs.color,
                    polygonOffset: this.inputs.polygonOffset,
                    polygonOffsetFactor: this.inputs.polygonOffsetFactor,
                    polygonOffsetUnits: this.inputs.polygonOffsetUnits,
                    side: THREE.DoubleSide,
                }));
            //this.mesh!.scale.set(1.0/this.oldRootScale.x, 1.0/this.oldRootScale.y, 1);

            var geometry = (this.mesh!.geometry as ShapeGeometry);
            geometry.computeBoundingBox();
            var max = geometry.boundingBox!.max,
                min = geometry.boundingBox!.min;

            var inverseOldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(this.oldRootScale);
            var offset = inverseOldRootScale.clone().multiplyScalar(0.5);//new THREE.Vector2(0 - min.x, 0 - min.y);
            var range = new THREE.Vector2(max.x - min.x, max.y - min.y);
            // var faces = geometry.faces;

            range.multiply(inverseOldRootScale);

            // geometry.faceVertexUvs[0] = [];

            // var rangeInverse = CardinalAxesAndPlanes.instance.unitVector2.clone().divide(range);

            // for (var i = 0; i < faces.length; i++) {

            //     var v1 = geometry.vertices[faces[i].a],
            //         v2 = geometry.vertices[faces[i].b],
            //         v3 = geometry.vertices[faces[i].c];

            //     geometry.faceVertexUvs[0].push([
            //         new THREE.Vector2((v1.x + offset.x) * rangeInverse.x, (v1.y + offset.y) * rangeInverse.y),
            //         new THREE.Vector2((v2.x + offset.x) * rangeInverse.x, (v2.y + offset.y) * rangeInverse.y),
            //         new THREE.Vector2((v3.x + offset.x) * rangeInverse.x, (v3.y + offset.y) * rangeInverse.y),
            //     ]);
            // }
            this.mesh!.scale.set(1.0, 1.0, 1);
        } else {
            if (this.isTextBox && this.textCanvasRenderer && this.textCanvasText) {
                this.textCanvasRenderer.inputs.sizeScale = {x: this.oldRootScale.x, y: this.oldRootScale.y};
                this.textCanvasText.inputs.sizeScale = {x: this.oldRootScale.x, y: this.oldRootScale.y};
            }

            var testMaterial: MeshBasicMaterial = new THREE.MeshBasicMaterial({
                color: this.inputs.color,
                map: this.inputs.texture,
            });

            this.mesh = new THREE.Mesh(
                // new THREE.PlaneBufferGeometry(this.oldRootScale.x, this.oldRootScale.y),
                new THREE.ShapeGeometry(Utils.RoundedRectShape(this.oldRootScale.x, this.oldRootScale.y, this.inputs.borderRadius)).translate(-0.5 * this.oldRootScale.x, -0.5 * this.oldRootScale.y, 0),//.scale(1, this.oldRootScale.y, 1),
                //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
                new THREE.MeshBasicMaterial({
                    transparent: this.inputs.transparent,
                    map: this.inputs.texture,
                    opacity: this.inputs.opacity,
                    color: this.inputs.color,
                    polygonOffset: this.inputs.polygonOffset,
                    polygonOffsetFactor: this.inputs.polygonOffsetFactor,
                    polygonOffsetUnits: this.inputs.polygonOffsetUnits,
                    side: THREE.DoubleSide,
                }));

            var geometry = (this.mesh!.geometry as ShapeGeometry);
            geometry.computeBoundingBox();

            // This gets # of vertices
            const vertexCount = geometry.getAttribute('position').count;

            // geometry.faceVertexUvs[0] = [];

            //faces is an array of THREE.Face3 objects
            const index = geometry.getIndex()!.array;

            //with attribute
            const indexCount = geometry.getIndex()!.count;
            // //
            // //
            const uv = geometry.getAttribute('uv');
            //
            for (let i = 0; i < vertexCount; i++) {
                // const vv = new THREE.Vector3().fromArray(positions, i);
                // let nev = new THREE.Vector2();
                let xx = uv.getX(i);
                let yy = uv.getY(i);
                uv.setXY(i, xx/ this.oldRootScale.x, yy / this.oldRootScale.y);
            }

            this.mesh!.scale.set(this.inputs.localScale.x / this.oldRootScale.x, this.inputs.localScale.y / this.oldRootScale.y, this.inputs.localScale.z);
        }


        /*
        this.context.root.userData["width"] = this.context.root.scale.x;
        this.context.root.userData["height"] = this.context.root.scale.y;
        this.context.root.scale.x = 1;
        this.context.root.scale.y = 1;
        this.context.root.scale.z = 1;*/
        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
        this.mesh!.updateMatrixWorld();
        this.pivotNode.add(this.mesh!);
        this.mesh!.visible = this.inputs.visible;

        // const boxGeometry: BoxGeometry = new THREE.BoxGeometry(
        //     1, 1, 1
        // );

        // var boxMaterial: MeshBasicMaterial = new THREE.MeshBasicMaterial({
        //     color: this.inputs.color
        // });
        // boxMaterial.transparent = true;
        // this.pivotNode.add(new THREE.Mesh(boxGeometry, boxMaterial));
        //this.mesh!.scale.set(1.0/this.oldRootScale.x, 1.0/this.oldRootScale.y, 1);

        /*
        const material = this.mesh!.material as MeshBasicMaterial;
        material.map = this.inputs.texture;
        material.needsUpdate = true;*/

        return true;
    }

    // _applyBoxUV(geom:THREE.BufferGeometry, transformMatrix:THREE.Matrix4, bbox, bbox_max_size) {
    //
    //     let coords = [];
    //     coords.length = 2 * geom.attributes.position.array.length / 3;
    //
    //     // geom.removeAttribute('uv');
    //     if (geom.attributes.uv === undefined) {
    //         geom.addAttribute('uv', new THREE.Float32BufferAttribute(coords, 2));
    //     }
    //
    //     //maps 3 verts of 1 face on the better side of the cube
    //     //side of the cube can be XY, XZ or YZ
    //     let makeUVs = function(v0:THREE.Vector3, v1:THREE.Vector3, v2:THREE.Vector3) {
    //
    //         //pre-rotate the model so that cube sides match world axis
    //         v0.applyMatrix4(transformMatrix);
    //         v1.applyMatrix4(transformMatrix);
    //         v2.applyMatrix4(transformMatrix);
    //
    //         //get normal of the face, to know into which cube side it maps better
    //         let n = new THREE.Vector3();
    //         n.crossVectors(v1.clone().sub(v0), v1.clone().sub(v2)).normalize();
    //
    //         n.x = Math.abs(n.x);
    //         n.y = Math.abs(n.y);
    //         n.z = Math.abs(n.z);
    //
    //         let uv0 = new THREE.Vector2();
    //         let uv1 = new THREE.Vector2();
    //         let uv2 = new THREE.Vector2();
    //         // xz mapping
    //         if (n.y > n.x && n.y > n.z) {
    //             uv0.x = (v0.x - bbox.min.x) / bbox_max_size;
    //             uv0.y = (bbox.max.z - v0.z) / bbox_max_size;
    //
    //             uv1.x = (v1.x - bbox.min.x) / bbox_max_size;
    //             uv1.y = (bbox.max.z - v1.z) / bbox_max_size;
    //
    //             uv2.x = (v2.x - bbox.min.x) / bbox_max_size;
    //             uv2.y = (bbox.max.z - v2.z) / bbox_max_size;
    //         } else
    //         if (n.x > n.y && n.x > n.z) {
    //             uv0.x = (v0.z - bbox.min.z) / bbox_max_size;
    //             uv0.y = (v0.y - bbox.min.y) / bbox_max_size;
    //
    //             uv1.x = (v1.z - bbox.min.z) / bbox_max_size;
    //             uv1.y = (v1.y - bbox.min.y) / bbox_max_size;
    //
    //             uv2.x = (v2.z - bbox.min.z) / bbox_max_size;
    //             uv2.y = (v2.y - bbox.min.y) / bbox_max_size;
    //         } else
    //         if (n.z > n.y && n.z > n.x) {
    //             uv0.x = (v0.x - bbox.min.x) / bbox_max_size;
    //             uv0.y = (v0.y - bbox.min.y) / bbox_max_size;
    //
    //             uv1.x = (v1.x - bbox.min.x) / bbox_max_size;
    //             uv1.y = (v1.y - bbox.min.y) / bbox_max_size;
    //
    //             uv2.x = (v2.x - bbox.min.x) / bbox_max_size;
    //             uv2.y = (v2.y - bbox.min.y) / bbox_max_size;
    //         }
    //
    //         return {
    //             uv0: uv0,
    //             uv1: uv1,
    //             uv2: uv2
    //         };
    //     };
    //
    //     if (geom.index) { // is it indexed buffer geometry?
    //         for (let vi = 0; vi < geom.index.array.length; vi += 3) {
    //             let idx0 = geom.index.array[vi];
    //             let idx1 = geom.index.array[vi + 1];
    //             let idx2 = geom.index.array[vi + 2];
    //
    //             let vx0 = geom.attributes.position.array[3 * idx0];
    //             let vy0 = geom.attributes.position.array[3 * idx0 + 1];
    //             let vz0 = geom.attributes.position.array[3 * idx0 + 2];
    //
    //             let vx1 = geom.attributes.position.array[3 * idx1];
    //             let vy1 = geom.attributes.position.array[3 * idx1 + 1];
    //             let vz1 = geom.attributes.position.array[3 * idx1 + 2];
    //
    //             let vx2 = geom.attributes.position.array[3 * idx2];
    //             let vy2 = geom.attributes.position.array[3 * idx2 + 1];
    //             let vz2 = geom.attributes.position.array[3 * idx2 + 2];
    //
    //             let v0 = new THREE.Vector3(vx0, vy0, vz0);
    //             let v1 = new THREE.Vector3(vx1, vy1, vz1);
    //             let v2 = new THREE.Vector3(vx2, vy2, vz2);
    //
    //             let uvs = makeUVs(v0, v1, v2, coords);
    //
    //             coords[2 * idx0] = uvs.uv0.x;
    //             coords[2 * idx0 + 1] = uvs.uv0.y;
    //
    //             coords[2 * idx1] = uvs.uv1.x;
    //             coords[2 * idx1 + 1] = uvs.uv1.y;
    //
    //             coords[2 * idx2] = uvs.uv2.x;
    //             coords[2 * idx2 + 1] = uvs.uv2.y;
    //         }
    //     } else {
    //         for (let vi = 0; vi < geom.attributes.position.array.length; vi += 9) {
    //             let vx0 = geom.attributes.position.array[vi];
    //             let vy0 = geom.attributes.position.array[vi + 1];
    //             let vz0 = geom.attributes.position.array[vi + 2];
    //
    //             let vx1 = geom.attributes.position.array[vi + 3];
    //             let vy1 = geom.attributes.position.array[vi + 4];
    //             let vz1 = geom.attributes.position.array[vi + 5];
    //
    //             let vx2 = geom.attributes.position.array[vi + 6];
    //             let vy2 = geom.attributes.position.array[vi + 7];
    //             let vz2 = geom.attributes.position.array[vi + 8];
    //
    //             let v0 = new THREE.Vector3(vx0, vy0, vz0);
    //             let v1 = new THREE.Vector3(vx1, vy1, vz1);
    //             let v2 = new THREE.Vector3(vx2, vy2, vz2);
    //
    //             let uvs = makeUVs(v0, v1, v2, coords);
    //
    //             let idx0 = vi / 3;
    //             let idx1 = idx0 + 1;
    //             let idx2 = idx0 + 2;
    //
    //             coords[2 * idx0] = uvs.uv0.x;
    //             coords[2 * idx0 + 1] = uvs.uv0.y;
    //
    //             coords[2 * idx1] = uvs.uv1.x;
    //             coords[2 * idx1 + 1] = uvs.uv1.y;
    //
    //             coords[2 * idx2] = uvs.uv2.x;
    //             coords[2 * idx2 + 1] = uvs.uv2.y;
    //         }
    //     }
    //
    //     geom.attributes.uv.array = new Float32Array(coords);
    // }
    //
    // applyBoxUV(bufferGeometry :THREE.BufferGeometry, transformMatrix:THREE.Matrix4, boxSize: number) {
    //
    //     if (transformMatrix === undefined) {
    //         transformMatrix = new THREE.Matrix4();
    //     }
    //
    //     if (boxSize === undefined) {
    //         let geom = bufferGeometry;
    //         geom.computeBoundingBox();
    //         let bbox = geom.boundingBox;
    //
    //         let bbox_size_x = bbox.max.x - bbox.min.x;
    //         let bbox_size_z = bbox.max.z - bbox.min.z;
    //         let bbox_size_y = bbox.max.y - bbox.min.y;
    //
    //         boxSize = Math.max(bbox_size_x, bbox_size_y, bbox_size_z);
    //     }
    //
    //     let uvBbox = new THREE.Box3(new THREE.Vector3(-boxSize / 2, -boxSize / 2, -boxSize / 2), new THREE.Vector3(boxSize / 2, boxSize / 2, boxSize / 2));
    //
    //     _applyBoxUV(bufferGeometry, transformMatrix, uvBbox, boxSize);
    //
    // }

    onInit() {
        const THREE = this.context.three;

        if (this.context.root.name === 'Text Box') {
            if ((this.getCompFromRoot(4) as any) == this) {
                // console.log('found');
                this.isTextBox = true;
                this.textCanvasRenderer = (this.getCompFromRoot(5) as any) as CanvasRenderer;
                this.textCanvasText = (this.getCompFromRoot(6) as any) as CanvasText;
            }
        }

        this.pivotNode = new THREE.Group();
        //

        this.outputs.objectRoot = this.pivotNode;
        this.outputs.collider = this.pivotNode;

        this.rebuildMeshQueue = new QueueScheduler<any>(this.buildMesh.bind(this), 50);

        if (this.inputs.invertScale) {
            this.oldRootScale = new THREE.Vector2(0, 0);
            //this.oldRootScale.set(1, 1);
            //this.rebuildMeshQueue.addQueueElement({}, true);
            //this.rebuildMeshQueue.addQueueElement(new THREE.Vector2(this.oldRootScale.x, this.oldRootScale.y), true);
        } else {
            if (this.isTextBox) {
                this.oldRootScale = new THREE.Vector2(0, 0);
                //this.oldRootScale.set(1, 1);
            } else {
                this.oldRootScale = CardinalAxesAndPlanes.instance.unitVector2.clone();
                this.rebuildMeshQueue.addQueueElement({}, true);
            }
        }
    }


    onTick(delta: number) {
        super.onTick(delta);
        if (this.inputs.invertScale) {

            var rootScale = new THREE.Vector2(this.context.root.scale.x, this.context.root.scale.y);
            if (Math.abs(rootScale.x - this.oldRootScale.x) > 0.1 ||
                Math.abs(rootScale.y - this.oldRootScale.y) > 0.1) {
                this.oldRootScale.set(rootScale.x, rootScale.y);
                this.rebuildMeshQueue.addQueueElement({}, true);

            }
        } else {
            if (this.isTextBox) {
                var rootScale = new THREE.Vector2(this.context.root.scale.x, this.context.root.scale.y);
                if (Math.abs(rootScale.x - this.oldRootScale.x) > 0.1 ||
                    Math.abs(rootScale.y - this.oldRootScale.y) > 0.1) {
                    this.oldRootScale.set(rootScale.x, rootScale.y);
                    this.rebuildMeshQueue.addQueueElement({}, true);

                }
            }
        }

        // if (!!this.inputs.noColliders) {
        //     this.outputs.collider = null
        // }

    }

    onEvent(eventType: string, eventData: unknown) {
        // console.log('[events] event on plane', eventType, eventData)
        this.notify(eventType, eventData);
    }

    onInputsUpdated(oldInputs: Inputs) {
        if (!this.mesh) {
            return;
        }

        if (oldInputs.transparent !== this.inputs.transparent) {
            (this.mesh!.material as MeshBasicMaterial).transparent = this.inputs.transparent;
        }

        if (oldInputs.texture !== this.inputs.texture) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.map = this.inputs.texture;
            const THREE = this.context.three;
            //let scale = this.pivotNode.parent!.scale.y;
            //material.map!.offset = new THREE.Vector2(0, 0.25);
            //material.map!.repeat.set(1, 2);
            //material.map!.wrapT = ClampToEdgeWrapping;
            material.needsUpdate = true;

        }

        if (oldInputs.visible !== this.inputs.visible) {
            this.mesh!.visible = this.inputs.visible;
        }

        if (oldInputs.color !== this.inputs.color) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).color.set(this.inputs.color);
        }

        if (oldInputs.opacity !== this.inputs.opacity) {
            // @ts-ignore
            (this.mesh.material as MeshBasicMaterial).opacity = this.inputs.opacity;
        }

        if (oldInputs.polygonOffset !== this.inputs.polygonOffset) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.polygonOffset = this.inputs.polygonOffset;
            material.polygonOffsetFactor = this.inputs.polygonOffsetFactor;
            material.polygonOffsetUnits = this.inputs.polygonOffsetUnits;
        }

        this.mesh!.scale.set(this.inputs.localScale.x, this.inputs.localScale.y / this.inputs.aspect, this.inputs.localScale.z);
        this.mesh!.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        this.mesh!.setRotationFromEuler(new Euler(this.inputs.localRotation.x * MathUtils.DEG2RAD, this.inputs.localRotation.y * MathUtils.DEG2RAD, this.inputs.localRotation.z * MathUtils.DEG2RAD));
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;

        (this.mesh!.material as MeshBasicMaterial).dispose();
        this.mesh!.geometry.dispose();
    }

}

export interface IPlaneRenderer extends SceneComponent {
    inputs: Inputs;
}

export const planeRendererType = 'mp.planeRenderer';

export function makePlaneRenderer() {
    return new PlaneRenderer();
}
