import { MpSdk } from 'types/SdkTypes2';
import { SpatialThinkSDKMode, StSdk } from 'CustomSdk/SpatialThinkSDK';
import { ExternalSdkMode } from 'mp/core/craEngine/SubSystems/core/Simulation';
import { ISceneNode } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import * as ThreeDActions from 'types/actions/ThreeD.actions';
import { NodeProperties } from 'types/models/home/HomeApp';
import { MeModelList, MeModelResponse } from 'types/models/home/ThirdParty/Sketchfab';
import { AppActions } from '../../types';
import { ObjectAnimation } from 'types/models/threeD'

const INIT_STATE: {
  objectPlacedId: string;
  showPropertiesPanel: boolean;
  showPoses: boolean;
  selectedNodeId: string;
  showGizmo: boolean;
  mySketchfabModels: MeModelResponse;
  selectedNode?: NodeProperties | undefined;
  sdkMode: SpatialThinkSDKMode | ExternalSdkMode | undefined;
  sdk?: MpSdk | StSdk;
  r3fComponents: Array<string>;
  // hiddenNodeIds: string[];
  collidersDisabledOn: string[]; //Record works better than Map with Redux because it's an plain object
  animations: ObjectAnimation[];
} = {
  objectPlacedId: '',
  showPropertiesPanel: false,
  showPoses: false,
  selectedNodeId: '',
  showGizmo: false,
  mySketchfabModels: { results: [] },
  selectedNode: undefined,
  sdkMode: undefined,
  sdk: undefined,
  r3fComponents: [],
  // hiddenNodeIds: [],
  collidersDisabledOn: [],
  animations: []
};

const ThreeD = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case ThreeDActions.OBJECT_PLACED: {
      return {
        ...state,
        objectPlacedId: action.payload,
      };
    }

    case ThreeDActions.SHOW_PROP_PANEL:
      return {
        ...state,
        showPropertiesPanel: action.payload
      }

    case ThreeDActions.SHOW_POSES:
      return {
        ...state,
        showPoses: action.payload
      }

    case ThreeDActions.SHOW_GIZMO:
      return {
        ...state,
        showGizmo: action.payload
      }

    case ThreeDActions.SET_MY_SKETCHFAB_MODELS:
      return {
        ...state,
        mySketchfabModels: action.payload
      }
    case ThreeDActions.SELECT_NODE:
      return {
        ...state,
        selectedNode: action.payload
      }
    case ThreeDActions.SET_SELECTED_NODE_ID:
      return {
        ...state,
        selectedNodeId: action.payload
      }
    case ThreeDActions.SET_SDK_MODE:
      let sm = state.mySketchfabModels;
      return {
        ...INIT_STATE,
        mySketchfabModels: sm,
        sdkMode: action.payload
      }

    case ThreeDActions.SET_SDK:
      return {
        ...state,
        sdk: action.payload
      }
    case ThreeDActions.ADD_COMPONENT:
      return {
        ...state,
        r3fComponents: [
          ...state.r3fComponents,
          action.payload
        ]
      }
    case ThreeDActions.SET_HIDDEN_NODE_IDS:
      return {
        ...state,
        hiddenNodeIds: action.payload
      }
    case ThreeDActions.DISABLE_COLLIDERS_ON:
      let x1 = new Set([...state.collidersDisabledOn, ...action.payload]);

      return {
        ...state,
        collidersDisabledOn: Array.from(x1),
      };

    case ThreeDActions.ENABLE_COLLIDERS_ON:
      let x2 = new Set([...state.collidersDisabledOn]);
      action.payload.map(y => x2.delete(y));

      // const enabledKeys = Object.keys(action.payload);
      // const updatedCollidersDisabledOn = Object.fromEntries(
      //   Object.entries(state.collidersDisabledOn).filter(([key]) => !enabledKeys.includes(key))
      // );
      return {
        ...state,
        collidersDisabledOn: Array.from(x2),
      };

    case ThreeDActions.SET_ANIMATIONS:
      return {
        ...state,
        animations: action.payload
      }

    default:
      return state;
  }
};
export default ThreeD;