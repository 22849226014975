// import THREE, { FontLoader } from "three";
import * as THREE from 'three';
import { ARButton } from './jsm/webxr/ARButton.js';
import { TagsARInteraction } from './TagsARInteraction';
import { MimickScene } from './Mimick/MimickScene';
import { TagSystem } from './Systems/TagSystem';
import { RenderingSystem } from './Systems/RenderingSystem';
import { WindowingSystem } from './Systems/WindowingSystem';
import { CameraSystem } from './Systems/CameraSystem';
import { InputSystem } from './Systems/InputSystem';
import { RaycastSystem } from './Systems/RaycastSystem';
import Simulation from '../mp/core/craEngine/SubSystems/core/Simulation';
import { MimickPointer } from './Mimick/MimickPointer';
import { MimickCamera } from './Mimick/MimickCamera';
import { LightSystem } from './Systems/LightSystem';
import { VRButton } from './jsm/webxr/VRButton.js';
import { Font } from 'three/examples/jsm/loaders/FontLoader.js';
import { GrabbableSystem } from './Systems/GrabbableSystem';
import { TagsVRInteraction } from './TagsVRInteraction';
import { store } from 'App.js';
import { Emitter, Mode } from 'types/SdkTypes2.js';
import * as Types from 'types/SdkNamespace';
import { SDKInstance } from 'modules/fiber/Sdk';
import { MpSdk } from "types/SdkTypes2";

export enum SpatialThinkSDKMode {
    Plain = 'Plain',
    AR = 'AR',
    VR = 'VR',
    PlainR3F = 'PlainR3F',
}

export interface SpatialSDKType {
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    effectComposer: null;
    camera: THREE.Camera;
    Scene: MimickScene;
    Pointer: MimickPointer;
    Camera: MimickCamera;
    customSDK: boolean;
}

// export class SpatialThinkSDK implements StSdk {
//     public renderingSystem: RenderingSystem;
//     protected windowingSystem: WindowingSystem;
//     public cameraSystem: CameraSystem;
//     protected raycastSystem: RaycastSystem;
//     protected lightingSystem: LightSystem;

//     protected mode: SpatialThinkSDKMode;

//     //Misc
//     protected font: Font | null = null;
//     protected boxObject: THREE.Object3D | null = null;
//     protected arInteraction: TagsARInteraction | null = null;
//     protected vrInteraction: TagsVRInteraction | null = null;

//     protected mimickScene: MimickScene;
//     protected mimickPointer: MimickPointer;
//     protected mimickCamera: MimickCamera;

//     public tagSystem: TagSystem;
//     // protected grabbableSystem:GrabbableSystem;

//     public constructor() {
//         // super();
//         this.windowingSystem = new WindowingSystem();
//         this.cameraSystem = new CameraSystem(this.windowingSystem);
//         this.renderingSystem = new RenderingSystem(this.cameraSystem);
//         this.raycastSystem = new RaycastSystem(this.renderingSystem);
//         // this.grabbableSystem = new GrabbableSystem(this.renderingSystem, this.raycastSystem, );
//         this.lightingSystem = new LightSystem(this.renderingSystem);
//         //document.body.appendChild(this.container);

//         const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
//         light.position.set(0.5, 1, 0.25);
//         this.renderingSystem.scene.add(light);

//         const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
//         this.boxObject = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff }));
//         this.boxObject.position.set(0, 0, -0.5);
//         // this.renderingSystem.scene.add(this.boxObject);

//         this.tagSystem = new TagSystem(this.renderingSystem);

//         this.renderingSystem.startRenderer();
//         this.renderingSystem.registerRenderCallback(this.render.bind(this))

//         this.mimickScene = new MimickScene(this.renderingSystem);
//         this.mimickPointer = new MimickPointer();
//         this.mimickCamera = new MimickCamera();
//     }

//     public SetMode(mode: SpatialThinkSDKMode): void {
//         this.mode = mode;

//         this.arInteraction && this.arInteraction.dispose();
//         this.vrInteraction && this.vrInteraction.dispose();

//         switch (mode) {
//             case SpatialThinkSDKMode.AR:
//                 this.arInteraction = new TagsARInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);

//                 // document.getElementById('app-sidebar')?.remove();
//                 document.getElementById('shortcut-panel')?.remove();
//                 // document.getElementById('quick-access-toolbar')?.remove();
//                 document.body.appendChild(ARButton.createButton(this.renderingSystem.renderer, { requiredFeatures: ['hit-test'] }));

//                 break;
//             case SpatialThinkSDKMode.Plain:

//                 break;

//             case SpatialThinkSDKMode.VR:
//                 this.renderingSystem.enableXR();
//                 document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));
//                 this.vrInteraction = new TagsVRInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);
//             //     this.renderingSystem.enableVR();
//             //     document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));

//         }
//     }

//     protected render(timestamp: any, frame: any) {
//         switch (this.mode) {
//             case SpatialThinkSDKMode.Plain:
//                 break;
//             case SpatialThinkSDKMode.VR:
//                 break;
//             case SpatialThinkSDKMode.AR:

//                 this.arInteraction!.xrRender(frame);
//                 break;
//         }
//         // console.log(timestamp);
//         // console.log(frame);
//         // if (this.boxObject) {
//         //     this.boxObject?.rotateY(0.01);
//         // }
//         // this.boxObject?.rotateY(0.01);

//     }

//     public getSDK(): SpatialSDKType {
//         return {
//             scene: this.renderingSystem.scene,
//             renderer: this.renderingSystem.renderer,
//             effectComposer: null,
//             camera: this.cameraSystem.camera,
//             Scene: this.mimickScene,
//             Pointer: this.mimickPointer,
//             Camera: this.mimickCamera,
//             customSDK: true
//         };
//     }

//     //Getter for raycast system
//     public get RaycastSystem(): RaycastSystem {
//         return this.raycastSystem;
//     }

//     //get grabbable system
//     // public get GrabbableSystem(): GrabbableSystem {
//     //     return this.grabbableSystem;
//     // }

//     // private static _instance: SpatialThinkSDK | null = null;
//     // public static get instance(): SpatialThinkSDK {
//     //     if (!SpatialThinkSDK._instance) {
//     //         SpatialThinkSDK._instance = new SpatialThinkSDK();
//     //     }

//     //     return SpatialThinkSDK._instance;
//     // }

//     //Getter for mode
//     // public get Mode(): SpatialThinkSDKMode {
//     //     return this.mode;
//     // }

//     public destroy(){
//         this.renderingSystem.renderer.dispose();
//         // this.renderingSystem.labelRenderer?.dispose();
//     }
// }

export class StSdk {//} implements MpSdk {
    Camera: StCamera = new StCamera();
    // App: MpSdk.App;
    // Asset: MpSdk.Asset;
    // Camera: MpSdk.Camera;
    // Conversion: MpSdk.Conversion;
    Floor: StFloor = new StFloor();
    // Graph: MpSdk.Graph;
    // Label: MpSdk.Label;
    // Link: MpSdk.Link;
    Mattertag: StMattertag = new StMattertag();
    // Measurements: MpSdk.Measurements;
    // Mode: MpSdk.Mode;
    // Model: MpSdk.Model;
    // OAuth: MpSdk.OAuth;
    Pointer: StPointer;
    Renderer: StRenderer;
    // Room: MpSdk.Room;
    // Sensor: MpSdk.Sensor;
    // Settings: MpSdk.Settings;
    Sweep: StSweep;
    Tag: StTag;
    // Tour: MpSdk.Tour;
    on: {
        // (event: MpSdk.App.Event, callback: (app: MpSdk.App.Phase) => void): Emitter;
        // (event: MpSdk.Camera.Event, callback: (pose: MpSdk.Camera.Pose) => void): Emitter;
        // (event: MpSdk.Floor.Event.CHANGE_START, callback: (to: number, from: number) => void): Emitter;
        // (event: MpSdk.Floor.Event.CHANGE_END, callback: (floorIndex: number, floorName: string) => void): Emitter;
        // (event: MpSdk.Label.Event, callback: (labelData: MpSdk.Label.Label[]) => void): Emitter;
        // (event: MpSdk.Mattertag.Event.HOVER, callback: (tagSid: string, hovering: boolean) => void): Emitter;
        (event: Types.MpSdkNamespace.Mattertag.Event.CLICK, callback: (tagSid: string) => void): Emitter;
        // (event: MpSdk.Mattertag.Event.LINK_OPEN, callback: (tagSid: string, url: string) => void): Emitter;
        // (event: MpSdk.Mode.Event.CHANGE_START, callback: (oldMode: string, newMode: string) => void): Emitter;
        // (event: MpSdk.Mode.Event.CHANGE_END, callback: (oldMode: string, newMode: string) => void): Emitter;
        // (event: MpSdk.Model.Event, callback: (model: MpSdk.Model.ModelData) => void): Emitter;
        // (event: MpSdk.Sweep.Event.ENTER, callback: (oldSweep: string, newSweep: string) => void): Emitter;
        // (event: MpSdk.Sweep.Event.EXIT, callback: (fromSweep: string, toSweep: string | undefined) => void): Emitter;
        // (event: MpSdk.Tour.Event.STARTED, callback: () => void): Emitter;
        // (event: MpSdk.Tour.Event.STOPPED, callback: () => void): Emitter;
        // (event: MpSdk.Tour.Event.ENDED, callback: () => void): Emitter;
        // (event: MpSdk.Tour.Event.STEPPED, callback: (activeIndex: number) => void): Emitter;
    };
    // off: (event: any, callback: (...any: any[]) => void) => Emitter;
    // disconnect: () => void;
    Scene: Types.MpSdkNamespace.Scene;
    R3F: Types.MpSdkNamespace.R3F;
    scene?: THREE.Scene;

    camera: THREE.Camera;
    renderer: THREE.Renderer;

    public renderingSystem: RenderingSystem;
    protected windowingSystem: WindowingSystem;
    public cameraSystem: CameraSystem;
    protected raycastSystem: RaycastSystem;
    protected lightingSystem: LightSystem;
    public tagSystem: TagSystem;

    public constructor() {
        // super();
        this.windowingSystem = new WindowingSystem();
        this.cameraSystem = new CameraSystem(this.windowingSystem);
        this.renderingSystem = new RenderingSystem(this.cameraSystem);
        this.raycastSystem = new RaycastSystem(this.renderingSystem);
        // this.grabbableSystem = new GrabbableSystem(this.renderingSystem, this.raycastSystem, );
        this.lightingSystem = new LightSystem(this.renderingSystem);
        //document.body.appendChild(this.container);
        const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
        light.position.set(0.5, 1, 0.25);
        this.renderingSystem.scene.add(light);
        // const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        // this.boxObject = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff }));
        // this.boxObject.position.set(0, 0, -0.5);
        // this.renderingSystem.scene.add(this.boxObject);
        this.tagSystem = new TagSystem(this.renderingSystem);
        // this.renderingSystem.startRenderer();
        // this.renderingSystem.registerRenderCallback(this.render.bind(this))
        // this.mimickScene = new MimickScene(this.renderingSystem);
        // this.mimickPointer = new MimickPointer();
        // this.mimickCamera = new MimickCamera();
    }

    // public getSDK(): SpatialSDKType {
    //     return {
    //         scene: this.renderingSystem.scene,
    //         renderer: this.renderingSystem.renderer,
    //         effectComposer: null,
    //         camera: this.cameraSystem.camera,
    //         Scene: this.mimickScene,
    //         Pointer: this.mimickPointer,
    //         Camera: this.mimickCamera,
    //         customSDK: true
    //     };
    // }
    //Getter for raycast system
    public get RaycastSystem(): RaycastSystem {
        return this.raycastSystem;
    }
    //get grabbable system
    // public get GrabbableSystem(): GrabbableSystem {
    //     return this.grabbableSystem;
    // }


    public destroy() {
        this.renderingSystem.renderer.dispose();
        // this.renderingSystem.labelRenderer?.dispose();
    }
}

export class StFloor implements Partial<Types.MpSdkNamespace.Floor>{
    // Event: typeof MpSdk.Floor.Event;
    getData(): Promise<Types.MpSdkNamespace.Floor.Floors> {
        throw new Error('Method not implemented.');
    }
    data: Types.MpSdkNamespace.IObservableMap<Types.MpSdkNamespace.Floor.FloorData>;
    // current: MpSdk.IObservable<MpSdk.Floor.ObservableFloorData>;
    // moveTo(index: number): Promise<number> {
    //     throw new Error('Method not implemented.');
    // }
    // showAll(): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }

}
export class StTag implements Partial<Types.MpSdkNamespace.Tag> {
    AttachmentType: typeof Types.MpSdkNamespace.Tag.AttachmentType;
    attachments: Types.MpSdkNamespace.IObservableMap<Types.MpSdkNamespace.Tag.Attachment>;
    attach(tagId: string, ...attachmentIds: string[]): Promise<void> {
        throw new Error('Method not implemented.');
    }
    // detach(tagId: string, ...attachmentIds: string[]): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // registerAttachment(...srcs: string[]): Promise<string[]> {
    //     throw new Error('Method not implemented.');
    // }
    registerSandbox(html: string, options?: Partial<Types.MpSdkNamespace.Tag.SandboxOptions>): Promise<[string, Types.MpSdkNamespace.Tag.IMessenger]> {
        throw new Error('Method not implemented.');
    }
    // data: MpSdk.IObservableMap<MpSdk.Tag.TagData>;
    // openTags: MpSdk.IObservable<MpSdk.Tag.OpenTags>;
    // add(...tags: MpSdk.Tag.Descriptor[]): Promise<string[]> {
    //     throw new Error('Method not implemented.');
    // }
    allowAction(id: string, actions: Partial<Types.MpSdkNamespace.Tag.AllowableActions>): Promise<void> {
        throw new Error('Method not implemented.');
    }
    // open(id: string, options?: Partial<MpSdk.Tag.OpenOptions>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // close(id: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // dock(id: string, options?: Partial<MpSdk.Tag.OpenOptions>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editBillboard(id: string, properties: Partial<MpSdk.Tag.EditableProperties>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editColor(id: string, color: MpSdk.Color): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editIcon(tagId: string, iconId: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editOpacity(id: string, opacity: number): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editStem(tagSid: string, options: Partial<MpSdk.Tag.StemHeightEditOptions>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editPosition(id: string, options: Partial<MpSdk.Tag.PositionOptions>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // remove(...ids: string[]): Promise<string[]> {
    //     throw new Error('Method not implemented.');
    // }
    // resetIcon(id: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // } //Partial<MpSdk.Mattertag>{

}
export class StPointer implements Partial<Types.MpSdkNamespace.Pointer>{
    Colliders: typeof Types.MpSdkNamespace.Pointer.Colliders;
    intersection: Types.MpSdkNamespace.IObservable<Types.MpSdkNamespace.Pointer.Intersection>;
    // registerTexture(textureId: string, textureSrc: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editTexture(textureId: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // resetTexture(): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // setFadeProps(props: MpSdk.Pointer.FadeProps): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // setVisible(visible: boolean): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }

}
export class StMattertag implements Partial<Types.MpSdkNamespace.Mattertag>{
    Transition: typeof Types.MpSdkNamespace.Mattertag.Transition;
    LinkType: typeof Types.MpSdkNamespace.Mattertag.LinkType;
    DescriptionChunkType: typeof Types.MpSdkNamespace.Mattertag.DescriptionChunkType;
    Event: typeof Types.MpSdkNamespace.Mattertag.Event;
    MediaType: typeof Types.MpSdkNamespace.Mattertag.MediaType;
    getData(): Promise<Types.MpSdkNamespace.Mattertag.MattertagData[]> {
     return Promise.resolve([]);
    }
    data: Types.MpSdkNamespace.IObservableMap<Types.MpSdkNamespace.Mattertag.ObservableMattertagData>;
    navigateToTag(tagSid: string, transition: Types.MpSdkNamespace.Mattertag.Transition): Promise<string> {
        // throw new Error('Method not implemented.');
        return Promise.resolve('');
    }
    getDiscPosition(tag: Types.MpSdkNamespace.Mattertag.MattertagData | Types.MpSdkNamespace.Mattertag.ObservableMattertagData, result?: Types.MpSdkNamespace.Vector3): Types.MpSdkNamespace.Vector3 {
        // throw new Error('Method not implemented.');
        return {x: 0, y: 0, z: 0};
    }

    add(newTagData: Types.MpSdkNamespace.Mattertag.MattertagDescriptor | Types.MpSdkNamespace.Mattertag.MattertagDescriptor[]): Promise<string[]> {
        throw new Error('Method not implemented.');
        // return Promise.resolve([]);
    }
    // editBillboard(tagSid: string, properties: Partial<MpSdk.Mattertag.EditableProperties>): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    editPosition(tagSid: string, moveOptions: Partial<Types.MpSdkNamespace.Mattertag.PositionOptions>): Promise<void> {
        // throw new Error('Method not implemented.');
        return Promise.resolve();
    }
    editColor(tagSid: string, color: Types.MpSdkNamespace.Color): Promise<void> {
        // throw new Error('Method not implemented.');
        return Promise.resolve();
    }
    // editOpacity(tagSid: string, opacity: number): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // editStem(tagSid: string, options: { stemHeight?: number | undefined; stemVisible?: boolean | undefined; }): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    registerIcon(iconId: string, iconSrc: string): Promise<void> {
        throw new Error('Method not implemented.');
    }
    editIcon(tagSid: string, iconId: string): Promise<void> {
        // throw new Error('Method not implemented.');
        return Promise.resolve();
    }
    // resetIcon(tagSid: string): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    remove(tagSids: string | string[]): Promise<string[]> {
        // throw new Error('Method not implemented.');
        return Promise.resolve([]);
    }
    preventAction(tagSid: string, actions: Partial<Types.MpSdkNamespace.Mattertag.PreventableActions>): Promise<void> {
        // throw new Error('Method not implemented.');
        return Promise.resolve();
    }
    injectHTML(tagSid: string, html: string, options: Types.MpSdkNamespace.Mattertag.InjectionOptions): Promise<Types.MpSdkNamespace.Mattertag.IMessenger> {
        throw new Error('Method not implemented.');

    }

}
class StRenderer implements Partial<Types.MpSdkNamespace.Renderer> {
    // takeScreenShot(resolution?: MpSdk.Renderer.Resolution, visibility?: Partial<MpSdk.Renderer.Visibility>): Promise<string> {
    //     throw new Error('Method not implemented.');
    // }
    takeEquirectangular(): Promise<string> {
        throw new Error('Method not implemented.');
    }
    // getWorldPositionData(screenPosition: MpSdk.Vector2, height?: number, includeHiddenFloors?: boolean): Promise<MpSdk.Renderer.WorldPositionData> {
    //     throw new Error('Method not implemented.');
    // }
    // getScreenPosition(worldPosition: MpSdk.Vector3): Promise<MpSdk.Vector2> {
    //     throw new Error('Method not implemented.');
    // }
}
class StSweep implements Partial<Types.MpSdkNamespace.Sweep> {

    Event: typeof Types.MpSdkNamespace.Sweep.Event;
    Transition: typeof Types.MpSdkNamespace.Sweep.Transition;
    Alignment: typeof Types.MpSdkNamespace.Sweep.Alignment;
    Placement: typeof Types.MpSdkNamespace.Sweep.Placement;
    data: Types.MpSdkNamespace.IObservableMap<Types.MpSdkNamespace.Sweep.ObservableSweepData>;
    // createGraph(): Promise<MpSdk.Graph.IDirectedGraph<MpSdk.Sweep.ObservableSweepData>> {
    //     throw new Error('Method not implemented.');
    // }
    current: Types.MpSdkNamespace.IObservable<Types.MpSdkNamespace.Sweep.ObservableSweepData>;
    // enable(...sweepIds: string[]): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // disable(...sweepIds: string[]): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    moveTo(sweep: string, options: Types.MpSdkNamespace.Sweep.MoveToOptions): Promise<string> {
        throw new Error('Method not implemented.');
    }
}

class StCamera implements Partial<Types.MpSdkNamespace.Camera> {
    public camera: THREE.Camera;
    Event: typeof Types.MpSdkNamespace.Camera.Event;
    Direction: typeof Types.MpSdkNamespace.Camera.Direction;
    zoom: Types.MpSdkNamespace.IObservable<Types.MpSdkNamespace.Camera.ZoomData>;
    pose: Types.MpSdkNamespace.IObservable<Types.MpSdkNamespace.Camera.Pose>;

    // pan(params: { x: number; z: number; }): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    // rotate(horizontal: number, vertical: number, options?: MpSdk.Camera.RotateOptions): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    setRotation(rotation: Types.MpSdkNamespace.Rotation, options?: Types.MpSdkNamespace.Camera.RotateOptions): Promise<void> {
        throw new Error('Method not implemented.');
    }
    // lookAtScreenCoords(x: number, y: number): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }

    getPose(): Promise<Types.MpSdkNamespace.Camera.Pose> {
        console.log('[r3f] going to pose. sdk is', SDKInstance.sdk);
        this.camera = SDKInstance.sdk.camera!;
        let p: THREE.Matrix4Tuple = (this.camera as THREE.PerspectiveCamera).projectionMatrix.toArray();
        let pose: Types.MpSdkNamespace.Camera.Pose = {
            position: this.camera.position,
            rotation: this.camera.rotation,
            projection: new Float32Array(p),
            sweep: '',
            //@ts-ignore
            mode: "mode.inside",
        }
        return Promise.resolve(pose);
    }

    // moveInDirection(direction: MpSdk.Camera.Direction): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }
    zoomTo(zoomLevel: number): Promise<number> {
        throw new Error('Method not implemented.');
    }
    // zoomBy(zoomDelta: number): Promise<number> {
    //     throw new Error('Method not implemented.');
    // }
    // zoomReset(): Promise<void> {
    //     throw new Error('Method not implemented.');
    // }


}



// export class SpatialThinkSDK implements StSdk {
            //     public renderingSystem: RenderingSystem;
            //     protected windowingSystem: WindowingSystem;
            //     public cameraSystem: CameraSystem;
            //     protected raycastSystem: RaycastSystem;
            //     protected lightingSystem: LightSystem;
            //     protected mode: SpatialThinkSDKMode;
            //     //Misc
            //     protected font: Font | null = null;
            //     protected boxObject: THREE.Object3D | null = null;
            //     protected arInteraction: TagsARInteraction | null = null;
            //     protected vrInteraction: TagsVRInteraction | null = null;
            //     protected mimickScene: MimickScene;
            //     protected mimickPointer: MimickPointer;
            //     protected mimickCamera: MimickCamera;
            //     public tagSystem: TagSystem;
            //     // protected grabbableSystem:GrabbableSystem;
            //     public constructor() {
            //         // super();
            //         this.windowingSystem = new WindowingSystem();
            //         this.cameraSystem = new CameraSystem(this.windowingSystem);
            //         this.renderingSystem = new RenderingSystem(this.cameraSystem);
            //         this.raycastSystem = new RaycastSystem(this.renderingSystem);
            //         // this.grabbableSystem = new GrabbableSystem(this.renderingSystem, this.raycastSystem, );
            //         this.lightingSystem = new LightSystem(this.renderingSystem);
            //         //document.body.appendChild(this.container);
            //         const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
            //         light.position.set(0.5, 1, 0.25);
            //         this.renderingSystem.scene.add(light);
            //         const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
            //         this.boxObject = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff }));
            //         this.boxObject.position.set(0, 0, -0.5);
            //         // this.renderingSystem.scene.add(this.boxObject);
            //         this.tagSystem = new TagSystem(this.renderingSystem);
            //         this.renderingSystem.startRenderer();
            //         this.renderingSystem.registerRenderCallback(this.render.bind(this))
            //         this.mimickScene = new MimickScene(this.renderingSystem);
            //         this.mimickPointer = new MimickPointer();
            //         this.mimickCamera = new MimickCamera();
            //     }
            //     public SetMode(mode: SpatialThinkSDKMode): void {
            //         this.mode = mode;
            //         this.arInteraction && this.arInteraction.dispose();
            //         this.vrInteraction && this.vrInteraction.dispose();
            //         switch (mode) {
            //             case SpatialThinkSDKMode.AR:
            //                 this.arInteraction = new TagsARInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);
            //                 // document.getElementById('app-sidebar')?.remove();
            //                 document.getElementById('shortcut-panel')?.remove();
            //                 // document.getElementById('quick-access-toolbar')?.remove();
            //                 document.body.appendChild(ARButton.createButton(this.renderingSystem.renderer, { requiredFeatures: ['hit-test'] }));
            //                 break;
            //             case SpatialThinkSDKMode.Plain:
            //                 break;
            //             case SpatialThinkSDKMode.VR:
            //                 this.renderingSystem.enableXR();
            //                 document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));
            //                 this.vrInteraction = new TagsVRInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);
            //             //     this.renderingSystem.enableVR();
            //             //     document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));
            //         }
            //     }
            //     protected render(timestamp: any, frame: any) {
            //         switch (this.mode) {
            //             case SpatialThinkSDKMode.Plain:
            //                 break;
            //             case SpatialThinkSDKMode.VR:
            //                 break;
            //             case SpatialThinkSDKMode.AR:
            //                 this.arInteraction!.xrRender(frame);
            //                 break;
            //         }
            //         // console.log(timestamp);
            //         // console.log(frame);
            //         // if (this.boxObject) {
            //         //     this.boxObject?.rotateY(0.01);
            //         // }
            //         // this.boxObject?.rotateY(0.01);
            //     }
            //     public getSDK(): SpatialSDKType {
            //         return {
            //             scene: this.renderingSystem.scene,
            //             renderer: this.renderingSystem.renderer,
            //             effectComposer: null,
            //             camera: this.cameraSystem.camera,
            //             Scene: this.mimickScene,
            //             Pointer: this.mimickPointer,
            //             Camera: this.mimickCamera,
            //             customSDK: true
            //         };
            //     }
            //     //Getter for raycast system
            //     public get RaycastSystem(): RaycastSystem {
            //         return this.raycastSystem;
            //     }
            //     //get grabbable system
            //     // public get GrabbableSystem(): GrabbableSystem {
            //     //     return this.grabbableSystem;
            //     // }
            //     // private static _instance: SpatialThinkSDK | null = null;
            //     // public static get instance(): SpatialThinkSDK {
            //     //     if (!SpatialThinkSDK._instance) {
            //     //         SpatialThinkSDK._instance = new SpatialThinkSDK();
            //     //     }
            //     //     return SpatialThinkSDK._instance;
            //     // }
            //     //Getter for mode
            //     // public get Mode(): SpatialThinkSDKMode {
            //     //     return this.mode;
            //     // }
            //     public destroy(){
            //         this.renderingSystem.renderer.dispose();
            //         // this.renderingSystem.labelRenderer?.dispose();
            //     }
            // }