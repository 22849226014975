import * as three from 'three';
import { ComponentInteractionType, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import Simulation from '../../SubSystems/core/Simulation';
import * as THREE from 'three';
import { FrontSide } from 'three/src/constants';
import { MeshUserDataProperties } from '../../SubSystems/ui-interop/PropertiesPanel';
import { ThreeContext } from '../systemComponents/ThreeContext';
import { store } from 'App';

interface Inputs {
    activate: boolean,
    arrowColor: string,
    flowRadius: number,
    // markerIds: string[]
}
export class FlowComponent extends SceneComponent {
    private root: three.Object3D | null = null;
    private box: three.Object3D | null = null;
    private tagList: any[] = [];
    private timeVal: number = 0;
    private boxUpdateTimer: number = 0;
    private isPlaying: boolean = false;
    private colorR: number = 0;
    private colorG: number = 0;
    private colorB: number = 0;
    private arrowScale: number = 1;

    public testNumber: number = 123;
    private tagListPositions: THREE.Vector3[];

    inputs: Inputs = {
        activate: false,
        arrowColor: "0x0000ff",
        flowRadius: 1.0,
        // markerIds: []
    };

    events = {
        [ComponentInteractionType.CLICK]: true,
        [ComponentInteractionType.HOVER]: false,
        [ComponentInteractionType.DRAG]: false,
    };

    onInit() {
        // @ts-ignore
        const THREE = this.context.three;
        this.root = new THREE.Object3D();
        this.box = new THREE.Object3D();
        this.box.userData[MeshUserDataProperties.arrowFlow] = true;

        // @ts-ignore
        this.outputs.collider = this.root;
        // @ts-ignore
        this.outputs.objectRoot = this.root;

        this.inputs.activate = false;
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
        console.log(`[st] [Simulation] [MP] Arrow flow clicked`, this.box?.position);
        if (interactionType === ComponentInteractionType.CLICK) {
            let _tmp_webstorm_ = this.context;
            // @ts-ignore
            const { root } = this.context;

            console.log(`[st] [Simulation] [MP] Arrow flow clicked`, this.box?.position);
            this.notify(ComponentInteractionType.CLICK, {
                type: interactionType,
                node: root,
                component: this,
            });
        }
    }

    makeBox() {
        const THREE = ThreeContext.i!.t;
        let nodeUserData = this.getNodeUserData();
        if (this.box) {
            this.context.scene.remove(this.box);
            this.box.clear();
        }
        if (store.getState().layer.presentationMode) {//TODO-ST
            // let x = this.getCompFromRoot(1);
            // (x as any).visible = false;
        } else {
            // let x = this.getCompFromRoot(1);
            // (x as any).visible = true;
        }
        let newMeshScale = this.inputs.flowRadius * 0.5;
        if (nodeUserData?.markers) {
            for (let i = 0; i < nodeUserData.markers.length; i++) {
                let marker = Simulation.instance.sceneLoader.findNodeByID(nodeUserData.markers[i].id);
                if (marker) {
                    let markerObj3D = marker.obj3D as THREE.Object3D;
                    markerObj3D.scale.set(newMeshScale, newMeshScale, newMeshScale);
                }
            }
        }

        let localIndex = nodeUserData?.markers?.length;
        while (localIndex--) {
            let marker = Simulation.instance.sceneLoader.findNodeByID(nodeUserData.markers[localIndex].id);
            if (marker) {
                // let markerNode = marker.obj3D as SceneNode;
            } else {
                nodeUserData?.markers.splice(localIndex, 1);
            }
        }

        this.getRGBFromString(this.inputs.arrowColor);
        this.tagListPositions = [];
        this.tagList = [];
        if (nodeUserData?.markers) {
            this.tagList = this.tagList.concat(nodeUserData?.markers);
            this.tagList.reverse();
            this.tagList.push({
                position: this.context.root.position,
                id: nodeUserData['id']
            });
        }
        // console.log(`[st] [objects] ${JSON.stringify(this.tagList)}`);
        //store.getState().home.spaceTags;

        // setting boxcomponent
        if (this.tagList) {

            // make a texture with an arrow
            //@ts-ignore
            const ctx: CanvasRenderingContext2D = document.createElement('canvas').getContext("2d");
            ctx.canvas.width = 64;
            ctx.canvas.height = 64;

            ctx.fillStyle = "rgba(0,0,0,0)";
            ctx.fillRect(0, 0, 64, 64);

            ctx.translate(32, 32);
            ctx.rotate(Math.PI * .5);
            ctx.fillStyle = `rgb(${this.colorR},${this.colorG},${this.colorB})`;
            ctx.textAlign = "center";
            ctx.textBaseline = "hanging";
            ctx.font = "36px sans-serif";
            let totalArrows: number = 5;
            for (var i = -1; i < totalArrows - 1; i++) {
                ctx.fillText("➡︎", 0, -13 * i);
            }

            ctx.fillText("➡︎", 0, 0);

            ctx.fillStyle = "rgba(1,0,0,0)";
            ctx.fillRect(0, 0, 64, 64);

            const texture = new THREE.CanvasTexture(ctx.canvas);
            texture.wrapS = three.RepeatWrapping;
            texture.wrapT = three.RepeatWrapping;
            texture.repeat.x = 1;
            texture.repeat.y = 5;


            let isFirst: boolean = true;
            let prePosition: three.Vector3 = new THREE.Vector3(0, 0, 0);

            const arrayTag: any[] = [];
            let flg = true;
            // for (let index = 0; index < this.tagList.length; index++) {

            //     try {
            //         this.tagList.forEach(tag => {

            //             if ((tag.data.label == ("" + (index + 1))) && (flg == true)) {
            //                 arrayTag.push(tag);
            //                 throw 'Break';
            //             }
            //         });

            //         flg = false;
            //     }
            //     catch (e) {
            //         if (e !== 'Break') throw e
            //     }

            // }


            // const boxGeometry2 = new THREE.BoxGeometry(1, 1, 1);
            // const boxMaterial2 = new MeshPhongMaterial({
            //     map: texture,
            //     side: DoubleSide,
            //     transparent: false,
            // });
            // const boxMesh2 = new Mesh(boxGeometry2, boxMaterial2);
            // boxMesh2.position.y += 1;
            // this.box!.add(boxMesh2);

            // this.tagList.reverse();
            this.tagList.forEach(tag => {
                this.tagListPositions.push(new THREE.Vector3(tag.position.x, tag.position.y, tag.position.z));
                tag.position = Simulation.instance.sceneLoader.findNodeByID(tag.id)?.position;
                if (isFirst == true) {
                    isFirst = false;
                    prePosition = tag.position;
                    return;
                }

                const distance = Math.sqrt((tag.position.x - prePosition.x) * (tag.position.x - prePosition.x) + (tag.position.y - prePosition.y) * (tag.position.y - prePosition.y) + (tag.position.z - prePosition.z) * (tag.position.z - prePosition.z));

                const texture = new THREE.CanvasTexture(ctx.canvas);
                texture.wrapS = three.RepeatWrapping;
                texture.wrapT = three.RepeatWrapping;
                texture.repeat.x = 1;
                texture.repeat.y = Math.floor(distance / (0.15 * this.arrowScale));

                const boxGeometry: THREE.CylinderGeometry = new THREE.CylinderGeometry(this.inputs.flowRadius * 0.05, this.inputs.flowRadius * 0.05, distance, 20, 2, true);
                const boxMaterial = new THREE.MeshPhongMaterial({
                    map: texture,
                    side: FrontSide,
                    transparent: true,
                });
                const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);

                boxMesh.position.x = (tag.position.x + prePosition.x) / 2;
                boxMesh.position.y = (tag.position.y + prePosition.y) / 2;
                boxMesh.position.z = (tag.position.z + prePosition.z) / 2;

                boxMesh.lookAt(new three.Vector3(tag.position.x, tag.position.y, tag.position.z));
                boxMesh.rotateX(Math.PI / 2);

                // console.log(`[st] [objects] boxmesh ${JSON.stringify(boxMesh.position)}`);

                prePosition = tag.position;

                boxMesh.userData["distance"] = Number.POSITIVE_INFINITY;
                boxMesh.userData["goal"] = 0;

                // console.log(`[st] [Simulation] MP ArrowFlow boxmesh`, boxMesh.position, boxMesh);

                // @ts-ignore

                // let group = new THREE.Group();
                // group.add(boxMesh);
                // group.add(circle);
                // this.box && this.box.add(group);
                this.box?.add(boxMesh);
                boxMesh.userData[MeshUserDataProperties.arrowFlow] = true;

            });

            //@ts-ignore

            this.context.scene.add(this.box)
            // this.root?.scale.set(newMeshScale * 0.05, newMeshScale * 0.05, newMeshScale * 0.05);
            this.isPlaying = true;
        }
    }

    onInputsUpdated() {
        this.makeBox();
    }

    onTick(delta: number) {
        super.onTick(delta);

        let nodeUserData = this.getNodeUserData(); //TODO optimize
        this.timeVal += delta * 0.001;

        if (this.inputs.activate == true && this.isPlaying == false) {
            this.makeBox();
        }

        if (this.boxUpdateTimer < 1000) {
            this.boxUpdateTimer += delta;
        } else {
            this.boxUpdateTimer = 0;
            // let newTagList = nodeUserData?.markers || [];

            let newTagList: any[] = [];
            if (nodeUserData?.markers) {
                newTagList = newTagList.concat(nodeUserData?.markers);
                newTagList.reverse();
                newTagList.push({
                    position: this.context.root.position,
                    id: nodeUserData['id']
                });
            }
            if (newTagList && this.tagListPositions) {
                // newTagList.reverse();
                if (newTagList.length != this.tagListPositions.length) {
                    this.makeBox();
                } else {
                    for (let i = 0; i < newTagList.length; i++) {
                        let oldTagPos = this.tagListPositions[i] as THREE.Vector3;
                        let newTagPos = newTagList[i].position as THREE.Vector3;

                        let dist = oldTagPos.manhattanDistanceTo(newTagPos);
                        if (dist > 0.1) {
                            this.makeBox();
                            break;
                        }
                    }
                }
            }
        }

        if (this.isPlaying == true) {

            if (this.context.root.scale.distanceTo(new three.Vector3(0, 0, 0)) / 1.4 !== this.arrowScale) {
                this.arrowScale = this.context.root.scale.distanceTo(new three.Vector3(0, 0, 0)) / 1.4;
                this.makeBox();
            }
            if (this.box) {
                // const tmpPosition: Object3D = new Object3D();
                this.box.traverse((object) => {
                    if ((object as THREE.Mesh).isMesh) {
                        if ((this.timeVal * 3 % 1) > 1) {
                            this.timeVal = 0;
                        }
                        const c = object as THREE.Mesh;
                        //@ts-ignore
                        (c.material as THREE.MeshBasicMaterial).map.offset.y = (this.timeVal * 3 % 1);
                        // c.rotateY(-0.01);
                        // var box_leftAxis = new Vector3();
                        // var box_upAxis = new Vector3();
                        // var box_forwardAxis = new Vector3();
                        // c.matrixWorld.extractBasis(box_leftAxis, box_upAxis,box_forwardAxis );
                        //
                        // let view = new THREE.Vector3(0, 0, -1);
                        // Simulation.instance.camera.Camera.getWorldDirection(view);
                        // let angleToMakeBoxFaceCamera = box_leftAxis.angleTo(view);
                        //
                        //
                        //
                        // var dist = Math.abs(c.userData["goal"] - angleToMakeBoxFaceCamera);
                        //
                        // if (dist < c.userData["distance"]) {
                        //     c.userData["distance"] = dist;
                        //     c.userData["goal"] = angleToMakeBoxFaceCamera;
                        //     c.setRotationFromEuler(new THREE.Euler(0, angleToMakeBoxFaceCamera, 0))
                        // }
                        //
                        //
                        // console.log(angleToMakeBoxFaceCamera * MathUtils.RAD2DEG);
                        // var goalRotation = c.userData["goal"];
                        // if (angleToMakeBoxFaceCamera > goalRotation) {
                        //     c.rotateY(-0.05);
                        // } else if (angleToMakeBoxFaceCamera< -goalRotation) {
                        //     c.rotateY(0.05);
                        // }

                    }
                })
                // dispose
            }
        }

        // store.getState().layer.presentationMode
        if (this.inputs.activate == false && this.isPlaying == true) {
            if (this.box) {
                //@ts-ignore
                this.context.scene.remove(this.box);
                this.isPlaying = false;
            }
        }
    }

    setNextInputValue() {
        this.inputs.activate = !this.inputs!.activate;
        console.log(`[st] [nodes] flow arrow activate is now ${this.inputs.activate}`)
    }

    onDestroy() {
        if (this.box) {

            // destroy all meshes and materials
            this.box?.children.forEach((child: any) => {
                child.geometry.dispose();
                child.material.dispose();
            });

            //@ts-ignore
            this.context.scene.remove(this.box);

            //destroy everything
            this.outputs.collider = null;
            // @ts-ignore
            this.outputs.objectRoot = null;

            this.isPlaying = false;
        }
    }

    getRGBFromString(colorString: string) {
        const colorNumber: number = parseInt(colorString);

        this.colorB = colorNumber % 256;
        this.colorG = Math.floor(colorNumber / 256) % 256;
        this.colorR = Math.floor(colorNumber / 65536) % 256;
    }
}
export const FlowModelType = 'mp.flowmodel';
export const makeFlowComponent = function () {
    return new FlowComponent();
};