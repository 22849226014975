import makeStyles from '@material-ui/core/styles/makeStyles';
import { Fonts } from '../../constants/AppEnums';
import { alpha } from '@material-ui/core/styles';
import { CremaTheme } from '../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  '@global': {
    // for global styles
    '.MuiLink-root': {
      fontWeight: Fonts.REGULAR,
    },
    '.pointer': {
      cursor: 'pointer',
    },
    '.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.background.paper,
    },
    '.item-hover': {
      transition: 'all .2s ease',
      transform: 'scale(1)',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        transform: 'translateY(-2px)',
        boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.black, 0.2)}`,
      },
    },
    '.card-hover': {
      transition: 'all 0.3s ease',
      transform: 'scale(1)',
      '&:hover': {
        boxShadow: '0 4px 8px rgba(0,0,0,.45)',
        transform: 'scale(1.05)',
      },
    },
    '.sidebar-container': {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxWidth: '350px',
      minWidth: '350px',
      backgroundColor: theme.palette.background.default,
      height: "100%",
      maxHeight: "100%",
      minHeight: "100%",
      position: 'relative',
      right: 0
    },
    '.sidebar-container-broad': {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxWidth: '650px',
      minWidth: '650px',
      backgroundColor: theme.palette.background.default,
      height: "100%",
      maxHeight: "100%",
      minHeight: "100%",
      position: 'relative',
      right: 0
      // maxWidth: '100%',
      // minWidth: '100%',
    },
    '.two-column-sidebar-container': {
      position: 'relative',
      right: 0,
      // minWidth: '700px',
      // maxWidth: '700px',

    },
    '.h7': {
      textAlign: 'center',
      fontSize: '0.97rem',
      fontWeight: 500,
      margin: '10px 10px'
    },
    '.h7Left': {
      // textAlign: 'center',
      fontSize: '0.97rem',
      fontWeight: 500,
      // margin: '20px 10px'
    },
    '.fancyBorder': {
      borderRadius: '30px',
      borderWidth: '2px',
      borderColor: `linear-gradient(45deg, #ffd800, #ff5520, #750cf2, #0cbcf2)`,
    }
  },
}));

export default useStyles;
