import * as THREE from "three";
import { initComponents } from "mp";
import { EulerIntegrator } from "mp/core/craEngine/components/systemComponents/EulerIntegrator";
import { SimpleCamera } from "mp/core/craEngine/SubSystems/ancillary/SimpleCamera";
import Simulation, { ExternalSdkMode } from "mp/core/craEngine/SubSystems/core/Simulation";
import { CraSubSystem } from "mp/core/craEngine/SubSystems/CraSubSystem";
import InputSubSystem from "mp/core/craEngine/SubSystems/input/InputSubSystem";
import { SceneLoader } from "mp/core/craEngine/SubSystems/sceneManagement/SceneLoader";
import { Arrow } from "./MpUtilComponents/Arrow";
import { InputControls } from "./MpUtilComponents/InputControls";
import { DialLegacy } from "./MpUtilComponents/LegacyDial";
import { FireLegacy } from "./MpUtilComponents/LegacyFire";
import { LeverLegacy } from "./MpUtilComponents/LegacyLever";
import { TextBoxLegacy } from "./MpUtilComponents/TextBoxLegacy";
import { TransformControls } from "./MpUtilComponents/TransformControls";
import { Node } from "./MpUtilComponents/Node";
import { SpatialSDKType, SpatialThinkSDKMode, StSdk } from "CustomSdk/SpatialThinkSDK";
import { store } from "App";
import { SweepData, SweepTransition } from "types/models/home/HomeApp";
import { currentZoom, getTagIdForTagSid } from "modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils";
import { MpSdk } from "types/SdkTypes2";
import { isST } from "modules/home/SpaceDetail/utils";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
// import * as Types from "types/SdkTypes2";


// export declare type MpSdk = CommonMpSdk & {
// 	Scene: Scene;
// 	R3F: R3F;
// }
//  & {
// 	sceneObject?: Scene.IObject;
// 	inputControls?: any;
// 	threeContext?: typeof THREE;
// };

export let SDKInstance: SDK;

// export type CustomMpSdk = {
// 	sceneObject?: Scene.IObject;
// 	inputControls?: InputControls;
// 	threeContext?: typeof THREE;
// }

// export type CustomStSdk = {
// 	plainSceneObject?: THREE.Scene;
// }
export class SDK {
	private _sdk: MpSdk | StSdk;

	// public stSdk: SpatialThinkSDK;

	// public arrowLegacy: Arrow;
	// public textBoxLegacy: TextBoxLegacy;
	// public leverLegacy: LeverLegacy;
	// public dialLegacy: DialLegacy;
	// public fireLegacy: FireLegacy;

	// public transformControls: TransformControls;
	// public r3fContext: MpSdk.R3F.IContext;


	//NEVER PUT ME IN REDUX
	private _sceneNodes: Map<string, ISceneNode> = new Map(); //will always have props: obj3D and userData.objectHierarchy for CustomModels
	private _sceneObjs: Map<string, THREE.Object3D> = new Map();

	public get sceneNodes() {
		Array.from(this._sceneNodes.values()).map(node => node.obj3D = this._sceneObjs.get(node.id));
		return this._sceneNodes;
	}

	public getObj3DForNodeId(id: string) {
		return this._sceneObjs.get(id);
	}

	public storeObj3DForNode(id: string, obj: THREE.Object3D) {
		this._sceneObjs.set(id, obj);
    }

	public sdkMode: ExternalSdkMode | SpatialThinkSDKMode;
	public get sdk() {
		if (this.sdkMode === ExternalSdkMode.MPR3F) {
			return this._sdk as MpSdk;
		} else {
			return this._sdk as StSdk;
		}
	}

	public static makeSdk(sdk: MpSdk | StSdk, mode: ExternalSdkMode | SpatialThinkSDKMode, callback: () => void) {
		if (!SDKInstance) {
			switch (mode) {
				case ExternalSdkMode.MPR3F:
					SDKInstance = new SDK(sdk, callback);
					(sdk as MpSdk).Scene.createObjects(1).then(async (sceneObjects) => {
						(SDKInstance.sdk as MpSdk).sceneObject = sceneObjects[0];
						await initComponents(SDKInstance._sdk) // needed to support legacyNodes
						callback();
					});
					break;

				case SpatialThinkSDKMode.PlainR3F:
					SDKInstance = new SDK(sdk as StSdk, callback);
					Simulation.instance.initialize(SDKInstance._sdk, mode as SpatialThinkSDKMode);
					break;
			};

		}
	}

	private constructor(mpSdk: MpSdk | StSdk, callback: () => void) {
		SDKInstance = this;
		this._sdk = mpSdk;
	}

	public async getCurrentSweep(): Promise<SweepData> {
		try {
			let pose = await SDKInstance.sdk.Camera.getPose();
			let s: SweepData = { sid: pose.sweep, rotation: pose.rotation, pose: pose };
			currentZoom && (s.zoom = currentZoom);
			return JSON.parse(JSON.stringify(s));
			// .catch(console.error);
		} catch (e) {
			console.error(e);
			return { sid: '' };
		}
	}

	public async goToZoom(sweepData: SweepData): Promise<void> {
		let mpSdk = SDKInstance.sdk;
		if (mpSdk && sweepData && sweepData.zoom &&
			(sweepData.zoom?.level !== currentZoom?.level)) {
			console.log(`[st] [vp] changing zoom to:  ${sweepData?.zoom?.level} from ${currentZoom?.level}`);
			try {
				await mpSdk.Camera.zoomTo(sweepData.zoom?.level)
				// .catch((e: any) => console.error(`Error step/cant-move-to-zoom: ${e}`))
			}
			catch (e: any) { console.error(`[st] [vp] Error step/cant-move-to-zoom: ${e}`) };
		}
	}

	public async goToPose(sweepData: SweepData): Promise<void> {
		// console.log('[r3f] gotopose sdk is', SDKInstance.sdk.camera?.position);
		let sdk = SDKInstance.sdk;

		if (isST()) {
			// let currentSweep = await this.getCurrentSweep();
			// let pose = await SDKInstance.sdk.Camera.getPose();
			let pose = sweepData.pose;
			console.log('[r3f] loading pose', pose);
			SDKInstance.sdk.camera && loadCameraPose(SDKInstance.sdk.camera, JSON.stringify(pose))

		} else if (sdk && sweepData) {

			// console.log(`[st] tg:  ${JSON.stringify(sweepData.sid)} --- ${JSON.stringify(sweepData.pose.rotation)} ----- `);
			let currentSweep = await this.getCurrentSweep();
			console.log(`[st] [vp] currentSweep: `, currentSweep?.sid, currentSweep?.rotation);

			if (currentSweep?.sid !== sweepData.sid) { // different sweeps, move, rotate and zoom

				try {
					console.log(`[st] [vp] moving to sweep:`, sweepData?.sid);
					await sdk.Sweep.moveTo(sweepData.sid, {
						rotation: sweepData?.pose?.rotation,
						//@ts-ignore
						transition: "transition.fly"
					})
					// .then(() => {
					await this.goToZoom(sweepData);
					// })
				} catch (e: any) { console.error(`[st] [vp] Error step/cant-move-to-pose: ${e}`) };

			} else if (currentSweep?.rotation !== sweepData.rotation) { //same sweep, check rotation
				console.log(`[st] [vp] setting rotation from`, currentSweep?.rotation, sweepData?.rotation)

				try {
					sweepData.rotation && await sdk.Camera.setRotation(sweepData.rotation);
				} catch (e: any) {
					console.error('[st] [mpsdk] couldnt move camera', sweepData.rotation);
				}
				// .then(
				try {
					await this.goToZoom(sweepData);
					// ).
				}
				catch (e: any) {
					console.error(`Error step/cant-move-to-rotation: ${e}`)
				};

			} else { //same sweep, same rotation, check zoom

				await this.goToZoom(sweepData);
			}
		}
	}

	public async goToHomeSweep() {
		let space = store.getState().home.currentSpace;
		let sdk = SDKInstance.sdk;

		console.log(`[st] [vp] going to home sweep`)
		let dp = space?.poses?.find(p => !!p.isDefault);
		if (dp) {
			console.log(`[st] [vp] default pose found`)
			this.goToPose(dp.data);
		} else {
			console.log(`[st] [vp] default pose not found`)
			sdk && space && space.homeSweepId && sdk.Sweep.moveTo(space.homeSweepId, {})
				.then(async () => {
					sdk.Camera.zoomTo(0.7).then(() => { }).catch(console.error);

				}).catch(console.error);
		}
	}

	public clickHandler = (tagSid: any) => {
		let tagId = getTagIdForTagSid(tagSid);

		// console.log(`[st] clicked on ${tagSid} - ${JSON.stringify(tagId && store.getState().home.spaceTags[tagId])}`);
		console.log(`[st] clicked on`, tagSid, store.getState().home.spaceTags[tagId || '']);

		// prevent the billboard from showing
		// var noBillboardTag = mattertagData[1];
		this.sdk.Mattertag.navigateToTag(tagSid, SDKInstance.sdk.Mattertag.Transition.FLY)
			.catch(console.error);
	}

	public async goToPoseById(poseId: string): Promise<void> {
		let sweepAndPose = (store.getState().home.currentSpace?.poses || []).find(p => p.id === poseId)?.data;
		return sweepAndPose && await this.goToPose(sweepAndPose);
	}

	public lookAt(nodeId: string) {
		let obj = this.sdk.scene?.getObjectByProperty('uuid', nodeId);
		if (obj) {
			(this._sdk as StSdk).camera?.lookAt(obj.position)
		}
	}

	// }
	///////////////////////////////////////    STSDK  Specific /////////////////////////////////////////////////////////



	///////////////////////////////////////    MPSDK  Specific /////////////////////////////////////////////////////////
	public createHelperComponents() {
		const so = (this.sdk as MpSdk).sceneObject
		if (so) {
			let ic = new InputControls(this._sdk as MpSdk, so);
			(this.sdk as MpSdk).scene = ic.inputComponent.context.scene;
			(this.sdk as MpSdk).camera = ic.inputComponent.context.camera;
			(this.sdk as MpSdk).renderer = ic.inputComponent.context.renderer;
		}
		// this.transformControls = new TransformControls(this.mpSdk, this.sceneObject);
		// this.camera = new Camera(this.mpSdk, this.sceneObject);

		// this.arrowLegacy = new Arrow(this.mpSdk, this.sceneObject);
		// this.textBoxLegacy = new TextBoxLegacy(this.mpSdk, this.sceneObject);
		// this.leverLegacy = new LeverLegacy(this.mpSdk, this.sceneObject);
		// this.dialLegacy = new DialLegacy(this.mpSdk, this.sceneObject);
		// this.fireLegacy = new FireLegacy(this.mpSdk, this.sceneObject);

	}

	private setUpLegacy() {

		// public camera: Camera;
		// renderingSubSystem: CraSubSystem;
		// camera: SimpleCamera;
		// integrator: EulerIntegrator;
		// // sceneLoader: SceneLoader;
		// // lastSelectedNode: ISceneNode | null;

		// Simulation.instance.initialize(this.mpSdk, ExternalSdkMode.MPR3F);
		//
		// replaces:
		// this.renderingSubSystem = new CraSubSystem();
		// this.renderingSubSystem.initialize(this.mpSdk);

		// Simulation.instance.sceneLoader.sceneObject = this.sceneObject;

		// const eulerIntegratorNode = this.sceneObject.addNode();
		// this.integrator = eulerIntegratorNode.addComponent("mp.eulerIntegrator") as unknown as EulerIntegrator;
		// eulerIntegratorNode.start();
		// setupPointerIntersectionSubscription();

		// //Needed for global access of three.js context
		// const threeContextNode = this.sceneLoader.sceneObject.addNode();
		// threeContextNode.addComponent(rootThreeContextType);
		// threeContextNode.start();

		// InputSubSystem.instance.Initialize(this);

		// this.camera = new SimpleCamera();
		// this.camera.initialize(this.renderingSubSystem);

	}

	public makeLegacyNode(name: string): Promise<Node | null> {
		const so = (this.sdk as MpSdk).sceneObject;
		if (!so) {
			return Promise.resolve(null);
		}
		switch (name) {
			case 'Fire':
				return FireLegacy.makeLegacyFire(this._sdk as MpSdk, so);
			case 'Lever':
				return LeverLegacy.makeLegacyLever(this._sdk as MpSdk, so);
			case 'Dial':
				return DialLegacy.makeLegacyDial(this._sdk as MpSdk, so);
			case 'TextBox':
				return TextBoxLegacy.makeLegacyTextBox(this._sdk as MpSdk, so);

			default:
				return Promise.resolve(null);
		}
	}

}


// Save camera pose
const saveCameraPose = (camera: THREE.Camera) => {
	const cameraPose = {
		position: camera.position,
		rotation: new THREE.Vector3().setFromEuler(camera.rotation), // Convert Euler to Vector3 before saving
		projectionMatrix: camera.projectionMatrix
	}

	// Convert to JSON string and save
	const cameraPoseJSON = JSON.stringify(cameraPose)
	console.log(cameraPoseJSON)
	localStorage.setItem('cameraPose', cameraPoseJSON)
}

// Load camera pose
const loadCameraPose = (camera: THREE.Camera, cameraPoseJSON: string) => {

	if (cameraPoseJSON) {
		const cameraPose = JSON.parse(cameraPoseJSON)

		// Load position
		camera.position.copy(new THREE.Vector3(cameraPose.position.x, cameraPose.position.y, cameraPose.position.z))

		// Load rotation
		camera.rotation.setFromVector3(
			new THREE.Vector3().setFromEuler(camera.rotation)
		)

		//TODO   Load projection matrix
		// camera.projectionMatrix.copy(
		// 	new THREE.Matrix4().fromArray(camera.projectionMatrix as unknown as Float32Array)
		// )

		//TODO Zoom
		// (camera as THREE.PerspectiveCamera).zoom
	}
}

