import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Form, Formik, useField } from 'formik';
import Modal from '@material-ui/core/Modal';
import * as yup from 'yup';
// import {onSaveSpace} from '../../../../../../redux/actions';
import Box from '@material-ui/core/Box';
import { Fonts } from 'shared/constants/AppEnums';
import {
  List
} from '@material-ui/core';
import {
  logUserActivity,
  setLockView,
} from 'redux/actions/Home';
import {
  onGetTagGroupsList,
  onUpdateTagGroup,
  setCurrentTagGroupId,
  handleGoToTagGroupByIndex,
  setTagGroupsList,
} from 'redux/actions/Step';
import { ANNOTATION_TYPE, Lesson, } from 'types/models/home/HomeApp';
// import { SweepTransition, } from 'types/models/home/HomeApp';
// import { currentSweep, currentZoom, getMpSdkGlobal } from '../TagSidebar/Tags/ShowcaseUtils';
import { TagGroup, ACTIONS, OBJECT_TYPES } from 'types/models/home/HomeApp';

import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SET_TAG_GROUP_LIST } from 'types/actions/Home.action';
import { CremaTheme } from 'types/AppContextPropsType';
import { InfoView } from '@crema';
import { firestore } from '@crema/services/auth/firebase/firebase';
import TagGroupCard from './TagGroup';
import { addTagsToEmptyShowcase, isST, getCurrentTagGroup, handleTagClick, handleTagClickBySid, isJumpToTagIdValid } from 'modules/home/SpaceDetail/utils';
import { AuthUser } from 'types/models/AuthUser';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import { LogicEngine } from 'types/models/dataAccess/Logic';
import { SET_STEP_STATUS } from 'types/actions/Layer.action';
import { store } from 'App';
import { SpatialThinkSDKMode, StSdk } from 'CustomSdk/SpatialThinkSDK';
import { SDKInstance } from 'modules/fiber/Sdk';

const useStyles = makeStyles((theme: Theme) =>

  createStyles({
    tagGroupsListing: {},
    tagGroupsListingRoot: {
      width: '100%',
      paddingLeft: '15px',
      paddingRight: '15px',
      // backgroundColor: theme.palette.background.paper,
    },
    tagGroupsListSubHeader: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    tagGroupsListItem: {
      cursor: 'pointer',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '12px',
    },
    tagGroupsListItemActive: {
      backgroundColor: 'rgba(244, 247, 254, 0.8)',
      paddingTop: '2px',
      paddingBottom: '2px',
      fontSize: '12px',
    },
    tagsNested: {
      // paddingLeft: theme.spacing(6),
      fontSize: '12px',
      cursor: 'pointer',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    tagGroupName: {
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
      paddingTop: '0px',
      paddingBottom: '0px',
      maxWidth: '150px',
    },
    tagsNestedText: {
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
      // paddingLeft: theme.spacing(6),
      cursor: 'pointer',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    deleteTagGroupIcon: {
      marginRight: 4,
      color: theme.palette.text.primary,
    },
    icon: {
      marginRight: 4,
      cursor: 'pointer',
      height: '18px',
      width: '18px',
      '& .MuiSvgIcon-root': {
        // width: '0.5em'
      }
    },
    iconActive: {
      // display: 'none',
      // '&:hover': {
      display: 'block',
      marginRight: 4,
      cursor: 'pointer',
      // height: '12px',
      // width: '12px',
      color: "blue",
      zIndex: 20,
      fontSize: '10px'

      // }

    },
    iconInactive: {
      // display: 'none',
      // '&:hover': {
      display: 'block',
      marginRight: 4,
      cursor: 'pointer',
      // height: '12px',
      // width: '12px',
      color: "grey",
      zIndex: 20,
      fontSize: '10px'
      // }
    },
    outlineBox: {
      // border: '1px solid #0000001c',
      padding: "15px",
      // paddingTop: "25px",
      // width: '280px',
      borderRadius: '10px',
      marginBottom: '12px'
    },
    outlineBoxTitle: {
      // marginBottom: "-5px",
      backgroundColor: "white",
      color: "black",
      marginTop: "-45px", //removes notched outline
      padding: "10px",
      width: "fit-content",
      // textTransform: "uppercase",
      fontSize: "13px",
    },
    bgActive: {
      // backgroundColor: '#0e6ebf4a'// '#f0f8ff',
      backgroundColor: '#c8d9e9',
      border: '1px solid #0388e5bd'
    },
    bgInactive: {
      backgroundColor: '#3c48991c'
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      fontSize: "13px",
      width: '250px',
      '& .MuiInputBase-input': {
        fontSize: "13px",
      },
      '& .MuiFormLabel-root': {
        fontSize: "13px",
      },
      '& .MuiInput-root': {
        // borderTopWidth: "0.5px",
        borderLeftWidth: "0.5px",
        borderRightWidth: "0.5px",
      },
      '& Mui-focused': {
        borderTopWidth: "0px",
      }
    },
    dense: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface TagGroupsProps {
  onDuplicateTagGroups: (tg: TagGroup) => void;
}

const TagGroups: React.FC<TagGroupsProps> = ({ onDuplicateTagGroups }) => {
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)

  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const spaceTags = useSelector<AppState, ShowcaseTagMap>(({ home }) => home.spaceTags);
  const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
  const isWorkflowsSidebarOpen = useSelector<AppState, boolean>(({ layer }) => layer.isWorkflowsSidebarOpen);
  const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
  const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId || '');
  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ layer }) => layer.tagGroupsList);
  const variableValues = useSelector<AppState, any[]>(({ layer }) => layer.variableValues || []);
  const presentationMode = useSelector<AppState, boolean>(({ layer }) => layer.presentationMode);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [editTagGroup, setEditTagGroup] = useState<TagGroup | null>(null);



  useEffect(() => {
    let tg = getCurrentTagGroup();
    //B
    console.log(`%c[nodes] rendering [vars] step ${(tg?.bannerText)}`, 'color: darkgreen;');
    showTagsAnd3DForStep(currentTagGroupId).then(() => {
      console.log(`%c[nodes] setting object components`, 'color: darkgreen;');


      Simulation.instance.updateVariablesState();

      //B

      let logic = tg?.logic;
      console.log(`%c[nodes] executing logic for step `, tg?.name);
      tg?.id && logic && LogicEngine.processStepLogicConditions({ tgId: tg.id, stepLogic: logic });
      console.log(`%c[nodes] step ${tg?.bannerText} ready`, 'color: darkgreen;');
    });
  }, [
    //B
    getCurrentTagGroup(),
    variableValues,
    spaceModels, // if models are loading after lesson, we need to refresh for node filtering
    spaceTags,
    // presentationMode
  ]);

  // one effect to re-load spacetags, tg
  // one effect to re-load models, tg
  // BANNER, VIEWPOINT should happen once per tg, unless with explicit Button
  // one effect for variables, tg -> update object states, run logic

  // useEffect(() => {
  //   runStepEnterWorkflow();

  //   // }, [currentTagGroupId])
  // =======
  //   let tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === currentTagGroupId);
  //   tagGroup && tagGroup.logic && LogicEngine.processStepLogicConditions(tagGroup.logic, null, false, false, true, spaceModels, variableValues);

  // }, [currentTagGroupId,
  //   tagGroupsList, variableValues, currentSpace
  //  ])

  const showTagsAnd3DForStep = async (tagGroupId: any): Promise<void> => {

    if (tagGroupId) {
      let tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === tagGroupId);

      if (!tagGroup) {
        return;
      }

      let tagIdsForTagGroup = tagGroup.tagIds || [];

      let tagIds = tagIdsForTagGroup.filter(t => Object.keys(spaceTags).includes(t));
      let modelIds = tagIdsForTagGroup.filter(spaceModel => spaceModels.has(spaceModel));

      let modelId360 = modelIds.find((modelId: string) => spaceModels.get(modelId)?.userData.is360 && spaceModels.get(modelId)?.userData.textureSource);
      let show360InAR = modelId360 ? spaceModels.get(modelId360)?.userData.showInAR : false;
      let textureSrc360 = modelId360 ? spaceModels.get(modelId360)?.userData.textureSource : '';

      modelIds = modelIds.filter((modelId: string) => !spaceModels.get(modelId)?.userData.is360);

      if (!isST()) {
        addTagsToEmptyShowcase({
          tagIds: tagIds, callback: (id: string, sid: string) => {
            if (id == tagGroup?.jumpToTagId) {
              handleTagClickBySid(sid);
              console.log(`%c[nodes] Went to focus tag`, 'color: darkgreen;');
            }
          }
        });
        // (SDKInstance.sdk)?.tagSystem.load360(textureSrc360);
      } else {
        // (!!currentTagGroupId && areNodesSpatialThinkSDK()) && tagIds?.map((i: string, index: number) => {
        //   let tagBox = <div id='tag-box'><div key={index} style={{
        //     height: "100px", width: "200px", background: 'pink', visibility: 'hidden'
        //   }} id={`${i}-ar-react-overlay`} >AROVERLAY</div ></div>;
        //   //@ts-ignore
        //   (document.getElementById('xrOverlay'))?.appendChild(tagBox);
        //   //@ts-ignore
        //   store.getState().threeD.sdkMode == SpatialThinkSDKMode.Plain && (document.getElementById('sdk-iframe'))?.appendChild(tagBox);
        // });

        (Simulation.instance.sdk as StSdk)?.tagSystem.loadTags(tagIds);
        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.Plain ||
          store.getState().threeD.sdkMode == SpatialThinkSDKMode.VR ||
          (show360InAR && store.getState().threeD.sdkMode == SpatialThinkSDKMode.AR)
        ) {
          (Simulation.instance.sdk as StSdk)?.tagSystem.load360(textureSrc360);
        }

      }

      //load 360 image


      if (!isST() && tagGroup.jumpToTagId && isJumpToTagIdValid(tagGroup)) {
        try {
          console.log(`%c[nodes] going to focus id: ${tagGroup.jumpToTagId} - ${tagIds.includes(tagGroup.jumpToTagId)} - ${modelIds.includes(tagGroup.jumpToTagId)}`, 'color: darkgreen;');
          // tagIds.includes(tagGroup.jumpToTagId) && handleTagClick(tagGroup.jumpToTagId);
          modelIds.includes(tagGroup.jumpToTagId) && Simulation.instance.handleModelClick(tagGroup.jumpToTagId);
          console.log(`%c[nodes] Went to focus tag/ model`, 'color: darkgreen;');
        } catch (e) {
          console.error(`%c[nodes] go to tag failed ${e}`, 'color: darkgreen;');
          tagGroup.poseId && await SDKInstance.goToPoseById(tagGroup.poseId);
        }

      } else if (tagGroup.poseId) {

        if (!isST()) {
          await SDKInstance.goToPoseById(tagGroup.poseId);
          if (!!tagGroup.lockView) {
            setLockView(true);
            // !store.getState().layer.presentationMode && dispatch(showMessage('Movement locked by Step'));
          } else {
            // if(!lockView){ //if top level view is not locked, then unlock
            setLockView(false);
            // !store.getState().layer.presentationMode && dispatch(showMessage('Movement resumed'));
          }
        } else {
          (Simulation.instance.sdk as StSdk)?.cameraSystem.goToPose(tagGroup.poseId);

        }

        console.log(`%c[nodes] Went to pose ${tagGroup.poseId}`, 'color: darkgreen;');
      }

      // addTagsToEmptyShowcase({ tagIds: tagIds });
      // let modelsList = tagIdsForTagGroup.map((tagId: any) => tagId.split('-')[1]);
      console.log(`[nodes] filtered tags for step ${tagGroup.bannerText}`, 'color: darkgreen;');
      // let spaceModels = store.getState().home.spaceModels;
      let showModelIds: string[] = spaceModels && spaceModels.size > 0 ?
        Array.from(spaceModels.values()).filter(s => s.userData.alwaysShow).map(s => s.id) : [];

      showModelIds.push(...modelIds);
      // console.log(`[st] step: filtering nodes, showing ${showModelIds.length}`);

      console.log(`%c[nodes] about to filter nodes for step ${tagGroup.bannerText} - showing tagsGroup models: ${modelIds.length} total: ${showModelIds.length} out of -- ${Array.from(spaceModels.values()).length}`, 'color: darkgreen;');
      Simulation.instance.filterVisibleNodes(showModelIds);
      // .then(() => {
      // Simulation.instance.updateVariablesState(variableValues, getCurrentTagGroup() || null);
      // })
      console.log(`%c[nodes] filtered nodes for step ${tagGroup.bannerText} - showing ${showModelIds.length}`, 'color: darkgreen;');

      store.getState().layer.stepStatus !== 'FILTERED' && dispatch({ type: SET_STEP_STATUS, payload: 'FILTERED' });
      // if(tagIdsForTagGroup && tagIdsForTagGroup.length > 0){

      // let showTags: ShowcaseTag[] = [];

      // tagIdsForTagGroup.forEach(spaceTagId => {
      //   let st = spaceTags[spaceTagId];
      //   st && showTags.push(st);
      // });

      // console.log(`[st] step: filtering tags, showing ${showTags.length}`);
      // dispatch(setCurrentShowcaseTags(showTags));
      // dispatch(setCurrentShowcaseTags(Object.values(spaceTags).filter(t => tagIdsForTagGroup.includes(t.id))));



      // if(modelsList.length == tagIdsForTagGroup.length) { //i.e. this group only has models, no tags
      //TODO: @raj highlight modelList[0]
      // } else {


      // let mpSDK = await (showcaseContext.initMpSdk() as any);
      // if (tagGroup.sweepAndPose) {
      //   goToPose(tagGroup);
      // }

      // else if (showTags.length > 0) {
      //   handleTagClick(showTagIds[0], {
      //   // preventNavigation: true
      // });
      // }
    }

    // mpSDK.Mattertag.getData().then((tags: any[]) => {

    //   let tag: any = tags.find(tag => tag.sid == _currentTagsSidMapGlobal.get( showTagIds[0]));

    //   if(tag) {
    //     let discAnchorPosition = mpSDK.Mattertag.getDiscPosition(tag);
    //     let anchoreDiscPositionVec3 = new THREE.Vector3(discAnchorPosition.x, discAnchorPosition.y, discAnchorPosition.z);

    //     // if(Simulation.instance.camera.isPointInFOV(anchoreDiscPositionVec3)){ //TODO RAJ if showTagIds[0] is in FOV
    //       //look at that tag
    //       alert("looking at " + JSON.stringify(tag) )
    //       Simulation.instance.camera.LookAtXYZ(anchoreDiscPositionVec3);
    //     // } else {
    //       // handleTagClick(showTagIds[0]);
    //     // }
    //   } else {
    //     console.log("[st] Error: First tag in this step not found in tag list")
    //   }
    // });
    //console.log(showTagIds[0]);

    // showcaseContext.initMpSdk().then(mpSdk => mpSdk && mpSdk.Camera.zoomTo(0.7).then(() => {

    // }).catch(console.error))

    // for(let i = 1; i < showTagIds.length; i++) {
    //   setTimeout(() => {
    //     handleTagClick(showTagIds[i]);
    //   }, 1000)
    // }
  }

  // }

  const focusOnTagGroup = (tg: any) => {
    // setBannerTextBeingEdited(tg.bannerText)
    currentTagGroupId !== tg.id && dispatch(setCurrentTagGroupId(tg.id));
    // dispatch(logUserActivity('CLICK', 'TagGroup', tg.id));
    dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tg.id));

  };

  const handleTagInTagGroupClick = async (tg: any, tagId: string) => {

    if (tg.id !== currentTagGroupId) {
      dispatch(setCurrentTagGroupId(tg.id));
      await showTagsAnd3DForStep(tg.id);
    }
    handleTagClick(tagId);
    Simulation.instance.handleModelClick(tagId);
  }

  function handleUpTagGroups(tg: any) {
    if (tg.sortIndex == 0) return;
    let index = tagGroupsList.findIndex(
      (tag) => tag.sortIndex == tg.sortIndex - 1,
    );
    let index1 = tagGroupsList.findIndex((tag) => tag == tg);
    tagGroupsList[index].sortIndex++;
    tagGroupsList[index1].sortIndex--;
    let batch = firestore.batch();
    let docRef = firestore
      .collection(
        `Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index].sortIndex });
    docRef = firestore
      .collection(
        `Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index1].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index1].sortIndex });
    batch.commit();
    let tgs = [...tagGroupsList].sort(
      (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
    dispatch(setTagGroupsList(tgs));
  }


  function handleDownTagGroups(tg: any) {
    if (tg.sortIndex == tagGroupsList.length - 1) return;

    let index = tagGroupsList.findIndex(
      (tag) => tag.sortIndex == tg.sortIndex + 1,
    );
    let index1 = tagGroupsList.findIndex((tag) => tag == tg);
    tagGroupsList[index].sortIndex--;
    tagGroupsList[index1].sortIndex++;
    let batch = firestore.batch();
    let docRef = firestore
      .collection(
        `Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index].sortIndex });
    docRef = firestore
      .collection(
        `Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups`,
      )
      .doc(tagGroupsList[index1].id);
    batch.update(docRef, { sortIndex: tagGroupsList[index1].sortIndex });
    batch.commit();
    let tgs = [...tagGroupsList].sort(
      (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });

    dispatch(setTagGroupsList(tgs));
  }

  function toggleJumpToTagId(tagId: string, activeTg: TagGroup) {
    let tg = tagGroupsList.find(tagGroup => tagGroup.id == activeTg.id);
    if (tg) {
      tg.jumpToTagId = (tg.jumpToTagId == tagId) ? '' : tagId;
      currentSpaceId && currentLesson && dispatch(onUpdateTagGroup(tg
        // currentSpaceId, currentLesson?.id, tg, tagGroupsList
      ));
    }
  }

  // async function savePose(activeTg: TagGroup) {
  //   let tg = tagGroupsList.find(tagGroup => tagGroup.id == activeTg.id);
  //   if (tg) {
  //     tg.sweepAndPose = await getCurrentSweep();
  //     // let pose = await SDKInstance.sdk.Camera.getPose().catch(console.error);
  //     // dispatch(showMessage("Viewpoint saved!"));
  //     // console.log(`[st] pose saved - ${JSON.stringify( pose)}`);

  //     // tg.sweepAndPose && (tg.sweepAndPose.pose = pose);
  //     // tg.sweepAndPose && currentZoom && (tg.sweepAndPose.zoom = currentZoom);
  //     console.log(`[st] ${JSON.stringify(tg.sweepAndPose)}`);

  //     // tg.sweepAndPose = _.cloneDeep(tg.sweepAndPose)
  //     currentSpaceId && currentLesson && dispatch(onUpdateTagGroup(tg
  //       // currentSpaceId, currentLesson?.id, tg, tagGroupsList
  //     ));
  //   }
  // }

  function getTagLabel(tagId: string, spaceTags: any): string {
    let tagLabel: string;
    if (spaceModels.size > 0 && spaceModels.has(tagId)) {
      tagLabel = spaceModels.get(tagId).nameToShow;
    } else {
      let showcaseTag = spaceTags[tagId];

      switch (showcaseTag?.annotationType) {
        case ANNOTATION_TYPE.QUIZ:
          tagLabel =
            showcaseTag.quizDetails?.question.slice(0, 30) + '...' || 'no label';
          break;

        case ANNOTATION_TYPE.TASK:
          tagLabel = showcaseTag.taskTag?.title;
          break;

        default:
          tagLabel = showcaseTag?.data.label;
          break;
      }
    }
    // }
    return tagLabel;
  }
  const handleDeleteTagGroups = async (tg: TagGroup) => {

    if (!currentSpaceId || !currentLesson) return;
    let res = window.confirm(`Are you sure you want to delete this step: ${tg?.name}?`);

    if (res) {

      const newTagGroupsRef: any = await firestore.doc(
        `Spaces/${currentSpaceId}/lessons/${currentLesson.id}/tagGroups/${tg.id}`,
      );
      newTagGroupsRef
        .delete()
        .then(async () => {
          //fix order

          let batch = firestore.batch();

          for (let si = tg.sortIndex + 1; si < tagGroupsList.length; si++) {
            let docRef = firestore.collection(
              `Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups`,
            ).doc(tagGroupsList[si].id);
            batch.update(docRef, { sortIndex: tagGroupsList[si].sortIndex - 1 });
          }

          await batch.commit();

          dispatch(onGetTagGroupsList(authUser, currentLesson));
          tagGroupsList.length > 0 && dispatch(handleGoToTagGroupByIndex(tg.sortIndex - 1));
        })
        .catch(console.error);
    }
  };

  const handleEditTagGroupsInLesson = async (id: string, name: string,
    bannerText: string, variableNameForInput: string,
    poseId: string, lockView: boolean, textOnly: boolean
  ) => {
    const newTagGroupsRef: any =
      currentSpaceId &&
      (firestore
        .collection(`Spaces/${currentSpaceId}/lessons/${currentLesson?.id}/tagGroups/`)
        // .doc(editTagGroup?.id));
        .doc(id));

    newTagGroupsRef
      .set({ name: name, bannerText: bannerText, variableNameForInput: variableNameForInput,
        poseId: poseId, lockView: lockView, textOnly: !!textOnly }, { merge: true })
      .then(() => {
        dispatch(onGetTagGroupsList(authUser, currentLesson));
        setEditTagGroup(null);

      })
      .catch(console.error);
  };


  return (
    <>
      {!presentationMode && <div className={classes.tagGroupsListing}
        style={{ visibility: isWorkflowsSidebarOpen ? 'hidden' : undefined }}>
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          disablePadding
          className={classes.tagGroupsListingRoot}
        // ref={listRef}
        >
          {tagGroupsList.map((tg: TagGroup, index: number) => <TagGroupCard
            key={index}
            isActive={currentTagGroupId == tg.id}
            // mpSdk={mpSdkLocal}
            tg={tg}
            onDuplicateTagGroups={onDuplicateTagGroups}
            onClick={() => focusOnTagGroup(tg)}
            showTagsAnd3DForStep={() => showTagsAnd3DForStep(tg.id)}
            handleEditTagGroup={(utg: TagGroup) => handleEditTagGroupsInLesson(utg.id, utg.name, utg.bannerText
               || '', utg.variableNameForInput || '', utg.poseId || '', !!utg.lockView, !!utg.textOnly)}
            toggleJumpToTagId={toggleJumpToTagId}
            getTagLabel={getTagLabel}
            handleTagInTagGroupClick={handleTagInTagGroupClick}
            // savePose={savePose}
            handleUpTagGroups={handleUpTagGroups}
            handleDownTagGroups={handleDownTagGroups}
            handleDeleteTagGroups={handleDeleteTagGroups}

          />)}
        </List>
      </div>}
    </>
  );
};



export default TagGroups;

const useStylesTags = makeStyles((theme: CremaTheme) => ({
  formRoot: {
    textAlign: 'left',
    [theme.breakpoints.up('xl')]: {
      marginBottom: 24,
    },
  },
  myTextFieldRoot: {
    width: '100%',
  },
  checkboxRoot: {
    marginLeft: -12,
  },
  pointer: {
    cursor: 'pointer',
  },
  btnRoot: {
    borderRadius: theme.overrides.MuiCard.root.borderRadius,
    width: '10rem',
    fontWeight: Fonts.REGULAR,
    fontSize: 16,
  },
  dividerRoot: {
    marginBottom: 16,
    marginLeft: -48,
    marginRight: -48,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 32,
    },
  },
  iconButtonRoot: {
    marginLeft: 4,
    marginRight: 4,
    color: theme.palette.grey[500],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 8,
      marginRight: 8,
    },
  },
  textLg: {
    fontSize: 18,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  colorTextPrimary: {
    color: theme.palette.primary.main,
  },
  underlineNone: {
    textDecoration: 'none',
  },
  textGrey: {
    color: theme.palette.grey[500],
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

const validationSchema = yup.object({
  name: yup.string().required('Name required'),
});

interface EditTagFormProps {
  open: boolean;
  onClosed: () => void;
  tg: TagGroup | null;
  onSubmit: (name: string, bannerText: string, variableNameForInput: string) => void;
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const EditTagForm: React.FC<EditTagFormProps> = (props) => {
  let userObj: any = {};
  userObj = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);
  const dispatch = useDispatch();
  const classes = useStylesTags(props);
  const [modalStyle] = React.useState(getModalStyle);
  const [name, setName] = useState<string>('');
  const [bannerTextEditForm, setBannerTextEditForm] = useState<string>('');
  const [variableNameForInput, setVariableNameForInput] = useState<string>('');

  useEffect(() => {
    setName(props.tg?.name || '');
    setBannerTextEditForm(props.tg?.bannerText || '');
    setVariableNameForInput(props.tg?.variableNameForInput || '');
  }, [props.tg]);

  return (
    <>
      {props.open && (
        <Modal
          open={props.open}
          onClose={props.onClosed}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'>
          <div style={modalStyle} className={classes.paper}>
            <Box flex={1} display='flex' flexDirection='column'>
              <Box
                px={{ xs: 6, sm: 10, xl: 15 }}
                pt={8}
                flex={1}
                display='flex'
                flexDirection='column'>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    name: name,
                    bannerText: bannerTextEditForm,
                    variableNameForInput: variableNameForInput,
                    jumpToTagId: props.tg?.jumpToTagId
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);
                    props.onSubmit(data.name, data.bannerText, data.variableNameForInput);
                    setSubmitting(false);
                  }}>
                  {({ isSubmitting }) => (
                    <Form
                      className={classes.formRoot}
                      noValidate
                      autoComplete='off'>
                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={name}
                          label='Step name'
                          name='name'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={bannerTextEditForm}
                          label='Banner text'
                          name='bannerText'
                          variant='outlined'
                          multiline
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={variableNameForInput}
                          label='Ask for variable input'
                          name='variableNameForInput'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box>

                      {/* <Box mb={{ xs: 3, lg: 4 }}>
                        <MyTextField
                          value={props.tg?.variableNameForInput}
                          label='Jump to Tag by default'
                          name='jumpToTagId'
                          variant='outlined'
                          className={classes.myTextFieldRoot}
                        />
                      </Box> */}

                      <Box
                        mb={6}
                        display='flex'
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        alignItems={{ sm: 'center' }}
                        justifyContent={{ sm: 'space-between' }}>
                        <Button
                          variant='contained'
                          color='secondary'
                          type='submit'
                          disabled={isSubmitting}
                          className={classes.btnRoot}>
                          Save
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>

              <InfoView />
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
};




// export const focusOnTagGroup = (tg: any, authUser: any, dispatch: any, isForced = false, currentTagGroupId: string) => {
//   // setBannerTextBeingEdited(tg.bannerText)
//   if (isForced) {
//     dispatch(setCurrentTagGroupId(tg.id));
//     // showTagsAnd3DForStep(tg.id)
//   } else {
//     currentTagGroupId !== tg.id && dispatch(setCurrentTagGroupId(tg.id));
//   }
//   // dispatch(logUserActivity('CLICK', 'TagGroup', tg.id));
//   dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tg.id));

// };

