import { ISceneNode } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent'
import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getMeshHierarchy } from 'modules/fiber/components/Utils';
import { SDKInstance } from 'modules/fiber/Sdk';

type GLTFListProps = {
  // object: Array<any>;
  node: ISceneNode;
}

type GLTFProps = {
  // open: boolean;
} & GLTFListProps

type Material = {
  name: string;
  color: string;
  // Add other properties as needed
};

interface ShowNestedState {
  [name: string]: boolean;
}

const GLTFModelObjects: React.FC<GLTFProps> = ({
  // open,
  node }) => {
    let obj3D = SDKInstance.getObj3DForNodeId(node.id);
  const object = (SDKInstance.sdk.scene && obj3D) ?
    getMeshHierarchy(SDKInstance.sdk.scene, obj3D)?.children as Array<any>
    : [];

  return (
    <div style={{ paddingLeft: "2rem" }}>
      {object.length > 0 && <GLTFModelObjectList object={object} />}
    </div>
  )
}
const GLTFModelObjectList = ({ object }: { object: Array<any> }) => {
  const [showNested, setShowNested] = useState<ShowNestedState>({});

  const toggleNested = (name: string) => {
    setShowNested((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };
  return (
    <div style={{ paddingLeft: "1.2rem", cursor: "pointer" }}>
      {object.map((parent: any) => {
        return (
          <div key={parent.name}>
            {(parent.children as Array<any>).length > 0 ?
              <div style={{ display: "flex", gap: "0.1rem", alignItems: "center" }} onClick={() => toggleNested(parent.name)}>
                {showNested[parent.name] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                <div>{parent.name}</div>
              </div> :
              <div style={{ padding: "0.8rem 0 0.8rem 0.8rem" }}>
                <div><span>{parent.name}</span></div>
                <div style={{ paddingLeft: "0.4rem" }}>
                  {renderMaterialInfo(parent.material)}
                </div>
              </div>}
            {/* @ts-ignore */}
            <div style={{ display: !showNested[parent.name] && "none" }}>
              {parent.children && <GLTFModelObjectList object={parent.children} />}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const renderMaterialInfo = (material: Material | Material[]): (JSX.Element | null)[] => {
  if (Array.isArray(material)) {
    return material.flatMap((mat) => renderMaterialInfo(mat));
  } else if (material) {
    return [
      <div key={material.name}>
        <div>{"material name: " + material.name}</div>
        <div style={{ display: "flex", gap: ".4rem", alignItems: "center" }}>
          <div>{"color: #" + material.color}</div>
          <div style={{ width: "15px", height: "15px", backgroundColor: "#" + material.color }}></div>
        </div>
      </div>
    ];
  }
  return [];
};

export default GLTFModelObjects