import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { fetchError, fetchStart, fetchStartNonBlocking, fetchSuccess, showMessage } from './Common';
import {
  ADD_SPACES_HIDE_MODAL,
  GET_PEOPLE_LIST_DATA,
} from '../../types/actions/Home.action';
import { GET_SPACES_LIST } from 'types/actions/Auth.actions';
import { appIntl } from '@crema/utility/Utils';
import Api from "@crema/services/ApiConfig";
import { onGetSpacesList } from './FirebaseAuth';
import {
  InviteStatus,
  PeopleObj,
  SpaceCopyObj,
  SpaceCreateObj,
  SpaceData,
} from 'types/models/home/HomeApp';
import { AuthUser } from 'types/models/AuthUser';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { QuerySnapshot } from '@firebase/firestore-types';
import { SpaceInvite } from 'types/models/dataAccess/Space';
import { getMetadata } from 'types/models/Model';
import { getInQueryResultWithChunks } from 'modules/home/SpaceDetail/utils';
import { setCurrentSpace } from './Home';
import { store } from 'App';
import { history } from 'redux/store';
import { UseCase } from 'shared/constants/AppConst';

export const onCopySpace = (createSpaceData: SpaceCopyObj, callback: () => void): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions | any>, getState: any) => {
    // const { projectList } = getState().projects;
    dispatch(fetchStartNonBlocking('Creating your copy...'));

    console.log(`[st] creating space ${createSpaceData} `, createSpaceData);

    Api.post('/api/spaces/', createSpaceData)
      .then(data => {

        console.log(`[st] space create response ${Object.entries(data.data)}`);

        if (data.status === 200) {

          if (!createSpaceData.isCleanCopy) {

            // console.log(`[st] copying into new space ${data.data.id} -- space ${JSON.stringify(createSpaceData)} with api api/spaces/copy`);
            Api.post('/api/spaces/copy/', { srcSpaceId: createSpaceData.masterSpaceId, destSpaceId: data.data.id })
              .then(data => {

                console.log(`[st] response ${Object.entries(data.data)}`);

                if (data.status === 200) {
                  dispatch(fetchSuccess());
                  console.log(`[st] space copy response ${Object.entries(data.data)}`);
                  // dispatch(onGetSpacesList());
                  // dispatch(showMessage(`Copied space as ${createSpaceData.name}. It may take a few seconds to load`));
                  // !space.isSample && dispatch({ type: ADD_SPACES_HIDE_MODAL, payload: false });

                  callback && callback();
                  // dispatch(onGetSpacesList({}));
                  dispatch(onGetSpacesList({ callback: (spaces: SpaceData[]) => dispatch(goToSpace(data.data.id, spaces)) }));
                } else {
                  dispatch(
                    fetchError(messages['message.somethingWentWrong'] as string),
                  );
                }

              }).catch(error => {
                dispatch(fetchError(error.message));
              });
          } else {
            dispatch(fetchSuccess());
            console.log(`[st] space copy response ${Object.entries(data.data)}`);
            // dispatch(showMessage(`Copied space as ${createSpaceData.name}. It may take a few seconds to load`));

            callback && callback();
            // dispatch(onGetSpacesList({}));
            dispatch(onGetSpacesList({ callback: (spaces: SpaceData[]) => dispatch(goToSpace(data.data.id, spaces)) }));
          }
        } else {
          dispatch(fetchError(data.statusText));
        }
      }).catch(console.error);

  };
};

export const onCreateSpace = (space: SpaceCreateObj, callback?: (newSpaceId: string) => void): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions | any>, getState: any) => {
    // const { projectList } = getState().projects;
    dispatch(fetchStart());

    console.log(`[st] creating space `, space);

    Api.post('/api/spaces/', space)
      .then(data => {

        console.log(`[st] response ${Object.entries(data.data)}`);

        if (data.status === 200) {
          dispatch(fetchSuccess());
          console.log(`[st] [spaces] total spaces: ${store.getState().auth.spacesList.length}`);
          dispatch(onGetSpacesList({ callback: (spaces: SpaceData[]) => dispatch(goToSpace(data.data.id, spaces)) }));
          !space.isSample && dispatch(showMessage(messages['message.spaceCreated'] as string));
          !space.isSample && dispatch({ type: ADD_SPACES_HIDE_MODAL, payload: false });
          // return data.data;
          // dispatch(fetchStart());
          // Api.get('/api/spaces/list')
          //   .then(data => {
          //     if (data.status === 200) {
          //       // dispatch(fetchSuccess());
          //       { console.log(`[st] current space ${JSON.stringify(data.data, (key, val) => key == "imageSrc" ? "" : val)}`); }
          //       dispatch({ type: GET_SPACES_LIST, payload: data.data });
          //     } else {
          //       console.error(`[st] ${data.status} -- ${data.statusText} `)
          //       dispatch(
          //       fetchError(messages['message.somethingWentWrong'] as string),
          //       );
          //     }
          //   })
          //   .catch(error => {
          //     // dispatch(fetchError(error.message));
          //   });
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }

      })
      .catch(error => {
        dispatch(fetchError(error.message));

      }).catch(console.error);

  };
};

export const resolveSpaceInvites = (userEmail: string, userId: string): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    console.log(`[st] getting space invites for user}`);

    firestore
      .collection('SpaceInvites')
      .where('userEmail', '==', userEmail)
      .get()
      .then((querySnapshot: QuerySnapshot) => {
        let invites: any[] = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        invites.forEach((invite) => {
          let doc = firestore.collection(`SpaceMemberships`).doc();

          doc.set({
            userId: userId,
            spaceId: invite.spaceId,
            role: invite.role,
            ...getMetadata(userId, doc.id)
          }).catch((err) => console.error(err))
        })


      }).catch((error) => {
        console.error(error);
        dispatch(fetchError('Error adding invited space'));
      });

  };
};

export const onDeleteSpace = (spaceIds: string[]): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStartNonBlocking("Deleting..."));

    console.log(`[st] deleting spaces ${spaceIds}`);

    Api.delete(`/api/spaces/`, { data: { id: spaceIds.join(',') } })
      .then(data => {

        console.log(`[st] delete response ${data.data ? Object.entries(data.data) : ""}`);

        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch(showMessage(messages['message.spaceDeleted'] as string));
          dispatch(onGetSpacesList({}));

        } else if (data.status === 404) {
          dispatch(showMessage("Space not found, or already deleted"));
        } else if (data.status === 401) {
          dispatch(showMessage("You don't have enough permissions to preform this operation"));

        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }

        // dispatch(fetchStart());
        // Api.get('/api/spaces/list')
        //   .then(data => {
        //     if (data.status === 200) {
        //       // dispatch(fetchSuccess());
        //       dispatch({ type: GET_SPACES_LIST, payload: data.data });
        //     }
        //   })
        //   .catch(error => {
        //     // dispatch(fetchError(error.message));
        //   });

      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data));

      }).catch(console.error);

  };
};

export const onGetPeopleList = (
  spaceId: string | undefined,
  authUser: AuthUser | null,
): any => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    try {
      let res: PeopleObj[] = [];
      let qs = await firestore
        .collection('SpaceMemberships')
        .where('spaceId', '==', spaceId)
        .get();

      let memberships = qs.docs.map((doc) => doc.data());

      let qsInvites = await firestore
        .collection('SpaceInvites')
        .where('spaceId', '==', spaceId)
        .get();

      let invites: SpaceInvite[] = qsInvites.docs.map((doc: any) => doc.data());

      getInQueryResultWithChunks('Users', 'id', memberships.map(membership => membership.userId))
        // firestore
        //   .collection('Users')
        //   .where('id', 'in', memberships.map(membership => membership.userId))
        //   .get()
        // .then((qs: QuerySnapshot) => {
        .then((data: any[]) => {
          let users = data.reduce((obj: any, doc) => (obj[doc.id] = doc, obj), {});

          dispatch(fetchSuccess());

          //add memberships first
          res.push(...(memberships.map(mem => ({ userId: mem.userId, role: mem.role?.toUpperCase(), email: users[mem.userId].email, name: users[mem.userId].firstName + ' ' + users[mem.userId].lastName }))));

          //then add invites
          res.push(...invites.map((invite) => ({ userId: '', email: invite.userEmail, role: invite.role, name: invite.userEmail, inviteStatus: InviteStatus.INVITED })));

          dispatch({ type: GET_PEOPLE_LIST_DATA, payload: res });
        })
    } catch (error: any) {
      console.error("error getting people: " + error)
    }
  };
};

export const onDeleteSpacePeople = (spaceId: string, projectId: string, people: PeopleObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    Api.post('/api/spaces/delete/people', { spaceId, projectId, people })
      .then(data => {
        if (data.status === 200) {
          dispatch(fetchSuccess());
          dispatch({ type: GET_PEOPLE_LIST_DATA, payload: data.data });
          dispatch(showMessage(messages['message.peopleDeleted'] as string));
        } else {
          dispatch(
            fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
};

function goToSpace(id: any, spaces: SpaceData[]): any {
  return (dispatch: Dispatch<AppActions>) => {
    // !space.isSample && dispatch(setCurrentSpace({ ...space, currentSpaceProject: project }));

    let sp: SpaceData | undefined = spaces.find(s => s.id == id);

    if (sp) {
      let project = sp?.spaceProjects && sp?.spaceProjects[0];

      console.log(`[st] [spaces] going to space ${sp.did} with project ${project.did} -- ${spaces.length} --`, sp);
      sp && project && dispatch(setCurrentSpace({ ...sp, currentSpaceProject: project }));
      !sp.isSample && project.did && history.push(`/home/space/${sp.did}/project/${project.did}`);

    } else {
      console.error(`[st] [spaces] new space not found ${spaces.length} --${JSON.stringify(sp)}`);
    }
  }
}

