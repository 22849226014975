import React from 'react';
import { Box, Button, Checkbox, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { onSetVariableValue, onStopLesson, updateCurrentLesson } from 'redux/actions/Layer';
import { Lesson } from 'types/models/home/HomeApp';
import VariablesButtonBar from './VariablesButtonBar';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import Spacer from '@crema/core/Spacer';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { store } from 'App';
import _ from 'lodash';
import Utils from 'mp/core/craEngine/Tools/Utils';
import { showMessage } from 'redux/actions';
import Draggable from "react-draggable";
import clsx from 'clsx';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
import { BasicProfiler } from '@crema/utility/Utils';
import * as Icons from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({

    container: {
        position: 'absolute',
        bottom: "5%",
        right: "1%",
        paddingLeft: "8px",
        paddingRight: "8px",
        margin: "8px",
        maxHeight: "35%",
        maxWidth: '40%',
        // overflow: "scroll",
        borderRadius: "16px",
        backgroundColor: 'transparent',
        color: "white",
        // pointerEvents: 'none'
    },

    scrollbarRoot: {
        position: 'relative',
        perspective: '200px',
        perspectiveOrigin: 'center',
        // pointerEvents: 'none'
    },

    variablesBox: {
        padding: '15px',
        position: 'relative',
        // transform: 'rotateY(-5deg)',
        // boxShadow: 'rgb(0 0 0 / 40%) 0px 0px 9px 0px',
        borderRadius: '16px',
        // pointerEvents: 'none'
    },
    draggable: {
        // cursor: 'grab'
    }

}));

interface Props {
}

const VariablesPanel: React.FC<Props> = ({
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const variableValues = useSelector<AppState, any[]>(({ layer }) => { return layer.variableValues || []; });
    const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
    const [draggable, setDraggable] = React.useState(false);
    const [showToolbar, setShowToolbar] = React.useState(false);

    function isComponentHidden() {
        return !!currentLesson?.hideVariablesPanel;
    }

    return isComponentHidden() ? null : (console.log(`%c[st] rendering VariablesPanel`, 'color: orange;'),
        <Draggable disabled={!draggable} bounds="parent">
            <Box className={clsx(classes.container, (draggable ? classes.draggable : ''))}
                onMouseOver={() => setShowToolbar(true)}
                onMouseLeave={() => {
                    setDraggable(false);
                    setShowToolbar(false);
                }}
            >

                {showToolbar &&
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {/* <Typography variant='subtitle2' style={{}}>Variables</Typography>
                        <Spacer x={2} /> */}
                        {/* <Tooltip title='Settings'>
                            <div onMouseOver={() => setDraggable(true)} style={{ cursor: 'grab' }}>
                                <DragIndicatorRoundedIcon />
                            </div>
                        </Tooltip> */}
                        <Tooltip title='Drag to move'>
                            <div onMouseOver={() => setDraggable(true)} style={{ cursor: 'grab' }}>
                                <DragIndicatorRoundedIcon />
                            </div>
                        </Tooltip>
                        <Spacer x={2} />
                        <Tooltip title='Hide'>
                            <div onClick={() => {
                                currentLesson && dispatch(updateCurrentLesson({ ...currentLesson, hideVariablesPanel: !currentLesson?.hideVariablesPanel } as Lesson))
                                // localStorage.setItem('hideVariablesPanel' + currentLesson?.id, 'true')
                            }}
                                style={{ cursor: 'pointer' }}
                            >
                                <VisibilityOffRoundedIcon />
                            </div>
                        </Tooltip>
                        <Spacer x={4} />

                        {/* <OverflowMenu buttonStyle={{
                        color: "white",
                        // height: '80%',
                        // position: 'absolute',
                        // right: 0,
                        // top: 0,
                        // padding: 0,
                        // margin: 0
                    }} menuItems={[
                        {
                            title: "Hide", onClick: () => {
                                setShowVariablesPanel(false);
                                localStorage.setItem('hideVariablesPanel', (localStorage.getItem('hideVariablesPanel') || '').concat(currentLesson?.id || ''));
                            }
                        },
                        // {
                        //     title: `${currentLesson?.hideVariablesPanel ? 'Always show' : 'Always hide'} for this Experience`, onClick: () => {
                        //         let spaceId = store.getState().home.currentSpace?.id;
                        //         if (spaceId) {
                        //             setShowVariablesPanel(!!currentLesson?.hideVariablesPanel);
                        //             localStorage.setItem('hideVariablesPanel', (localStorage.getItem('hideVariablesPanel') || '').replace((currentLesson?.id || ''), ''));
                        //             firestore.collection(`Spaces/${spaceId}/lessons`)
                        //                 .doc(currentLesson?.id)
                        //                 .update("hideVariablesPanel", !currentLesson?.hideVariablesPanel)
                        //                 .then(() => { })
                        //                 .catch(console.error);
                        //             dispatch(onGetLessonsList(spaceId));
                        //         }
                        //     }
                        // }
                    ]} /> */}
                    </Box>}

                {/* <Scrollbar
            className={classes.scrollbarRoot}
            > */}
                <VarBox variables={variableValues}
                    isDefaultVarsUI={false}
                    onValueChange={(varName: string, selectedValue: any) => dispatch(onSetVariableValue(varName, selectedValue))} />
                {/* </Scrollbar> */}
            </Box>
        </Draggable>);


    // <MyDraggable
    // initialPos= {{"x": 100, "y": 200}}
    // className='my-draggable'
    // style={{
    //     border: '2px solid #aa5',
    //     padding: '10px'
    // }}
    // />
    //         <DragDropContext onDragEnd={() => { }}>
    //             <Box className={classes.bar}>
    //                 <Box
    //                     // className={classes.root}
    //                     // border={`solid 4px ${grey[300]}`}
    //                     // p={2}
    //                     // mb={3}
    //                     >
    // draggable
    //                 </Box>
    //             </Box>
    //         </DragDropContext>


};

export default VariablesPanel;

export function VarBox({ variables, lessonDefaultVars = [], isDefaultVarsUI, onValueChange }: { variables: any[], lessonDefaultVars?: any[], isDefaultVarsUI: boolean, onValueChange: (varName: string, selectedValue: any) => void }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    function getInitialValue(variable: any) {
        if (!isDefaultVarsUI) {
            return variable.value;
        } else {
            let v = lessonDefaultVars.find(v => v.name == variable.name);
            return v ? v.value : variable.value;
        }
    }

    let bp = new BasicProfiler();
    let vs: any[] = _.cloneDeep(variables);

    if (!isDefaultVarsUI) {
        vs = vs.filter(v => v.allowControl);
    }

    // bp.lap('[clone] vars in VarBox');

    return (console.log('%c[st] rendering VarBox', 'color: orange;'), <div className={classes.variablesBox}>
        {(vs || []).map((variable, index) =>
            <Tooltip key={index} title={!getInitialValue(variable) ? 'Please select a default value for this variable for this lesson' : ''}>
                <Box display="flex" flexDirection="row" alignItems={isDefaultVarsUI ? 'center' : 'center'}
                    justifyContent={isDefaultVarsUI ? "flex-start" : 'center'} flexWrap="nowrap"
                    key={index}
                    style={{ border: !getInitialValue(variable) ? '1px dashed red' : '', padding: '0px 5px' }}
                // style={{ paddingBottom: 10 }}
                >
                    <Box style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    }}>
                        {isDefaultVarsUI &&
                            <Tooltip title='Show/ Hide this variable in the Variables Panel'>
                                <Checkbox
                                    edge='start'
                                    icon={<Icons.VisibilityOffRounded fontSize="medium" />}
                                    checkedIcon={<Icons.VisibilityRounded fontSize="medium" />}
                                    checked={(() => {
                                        // return true;
                                        let v = lessonDefaultVars.find(v => v.name == variable.name);
                                        return !!v?.allowControl;
                                    })()}
                                    onChange={(e) => {
                                        //if checked => has value ? add to lesson DV : alert
                                        //if unchecked => remove from DV
                                        let v = lessonDefaultVars.find(v => v.name == variable.name);
                                        let le = store.getState().layer.currentLesson;
                                        bp = new BasicProfiler();
                                        let l = _.cloneDeep(le);
                                        // bp.lap('[clone] lesson in VarBox add/remove var from lesson');

                                        if (l) {
                                            if (e.target.checked) {
                                                if (!v.value) {
                                                    alert('Please select a value first');
                                                } else {

                                                    let varToChange = (l.defaultVars || []).find((v: any) => v.name == variable.name);
                                                    varToChange && (varToChange.allowControl = true);
                                                    // if (v) {
                                                    //     console.error('not possible');
                                                    // } else {
                                                    //     console.log(`[vars] adding ${variable.name} -- ${variable.value} as DV to lesson`)
                                                    //     let v = (l.defaultVars || []).find((v: any) => v.name == variable.name);
                                                    //     v && (v.allowControl = true);
                                                    //     dispatch(showMessage("Default Value updated!"));
                                                    dispatch(updateCurrentLesson(l));
                                                    dispatch(onStopLesson());

                                                    // }
                                                }
                                            } else {
                                                // if (!v) {
                                                //     console.error('not possible');
                                                // } else {
                                                console.log(`[vars] setting allowControl for ${variable.name} to false as DV in lesson`);
                                                let varToChange = (l.defaultVars || []).find((v: any) => v.name == variable.name);
                                                varToChange && (varToChange.allowControl = false);
                                                // dispatch(showMessage("Default Value updated!"));

                                                dispatch(updateCurrentLesson(l));
                                                dispatch(onStopLesson());
                                                // }
                                            }
                                        }
                                    }}
                                /></Tooltip>}
                        <Typography
                            // variant="subtitle2"
                            style={{
                                width: "100%",
                                textAlign: "center",
                                textShadow: isDefaultVarsUI ? '' : "0 0 5px rgb(0 0 0)",
                                fontSize: '14px',
                                fontWeight: 500,
                            }}> {variable.name} </Typography>
                    </Box>
                    <Spacer x={2} />
                    <VariablesButtonBar
                        isDefaultVarsUI={isDefaultVarsUI}
                        size={"small"}
                        children={variable.values?.split(',').map((v: any) => v.trim())}
                        initialValue={getInitialValue(variable)}
                        handleChange={(selectedValue: any) => {
                            // if (isDefaultVarsUI) {
                            //     onValueChange(variable.name, selectedValue);
                            // } else {
                            variable.value = selectedValue;
                            onValueChange(variable.name, selectedValue);
                            // }
                        }} />
                    {/* //TEST will it dispatch */}
                </Box>
            </Tooltip>
        )
        }
    </div >)
}