import * as THREE from "three"
import { ComponentInteractionType, SceneComponent } from "../../../SubSystems/sceneManagement/SceneComponent";
import TextureManager, { TextureWithLoadState } from "../../../SubSystems/TextureManager";
import QueueScheduler from "../../../Tools/QueueScheduler";
import Utils from "../../../Tools/Utils";
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';


type Inputs = {
    visible: boolean;
    opacity: number;
    // borderOpacity: number;
    textureSource: string
    color: number;
    // borderColor:number;
    // borderRadius:number;
    // borderSize:number;
    localScale: { x: number; y: number; z: number; };
    localPosition: { x: number; y: number; z: number; };
    localRotation: { x: number; y: number; z: number; };
}


export class ImageRenderer extends SceneComponent {
    private mesh: THREE.Mesh | null = null;
    // private borderMesh: THREE.Mesh|null = null;
    private pivotNode: THREE.Object3D;
    private oldRootScale: THREE.Vector2;
    private rebuildMeshQueue: QueueScheduler<any>;
    //private texture:THREE.Texture|null = null;

    //private static defaultTexture:THREE.Texture|null = null;
    //private static TextureLoader:THREE.TextureLoader|undefined = undefined;

    private texture: TextureWithLoadState | undefined;

    inputs: Inputs = {
        visible: true,
        opacity: 0.98,
        //   borderOpacity: 1,
        //   borderSize: 1,
        textureSource: "",
        color: 0xffffffff,
        //   borderColor: 0xffffff00,
        //   borderRadius: 10,
        localScale: { x: 1, y: 1, z: 1 },
        localPosition: { x: 0, y: 0, z: 0 },
        localRotation: { x: 0, y: 0, z: 0 },
    }

    events = {
        [ComponentInteractionType.CLICK]: true,
        // [ComponentInteractionType.HOVER]: true,
    };

    onInit() {
        const THREE = this.context.three;
        this.pivotNode = new THREE.Group();

        this.outputs.objectRoot = this.pivotNode;
        this.outputs.collider = this.pivotNode;


        //this.rebuildMeshQueue = new QueueScheduler<any>(this.buildMesh.bind(this), 50);
        //this.initDefaultTextureAndBuildMesh();
        //'/assets/images/imageComponent/noPicture.png'
        ///assets/images/st-logo-only.png

        this.texture = TextureManager.instance.LoadTexture(this.inputs.textureSource, this.buildMesh.bind(this), this.fallbackToDefaultTexture.bind(this));


        //========================
        // let gltfLoader = new (this.context.three as any).GLTFLoader();
        // // GLTFLoader
        // gltfLoader.load("https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/Arrow%20Large/Arrow_New.gltf", (gltf:GLTF) => {
        //     // gltf.multiplyScalar( 0.1 );
        //     this.pivotNode.add(gltf.scene);
        // }, (event:ProgressEvent) => {
        //     console.log(event);
        // }, (event:ErrorEvent) => {
        //     console.log(event);
        // });
        //========================
        // let geometry = new THREE.BoxGeometry( 20, 20, 20 );
        // let object = new THREE.Mesh( geometry, new THREE.MeshLambertMaterial( { color: Math.random() * 0xffffff, side: THREE.DoubleSide } ) );
        // this.pivotNode.add(object);
    }

    fallbackToDefaultTexture(e: any): void {
        console.log( "[st] mp image fallbackToDefaultTexture");
        TextureManager.instance.LoadTexture('/assets/images/imageComponent/noPicture.png', this.buildMesh.bind(this), null)
    }

    buildMesh(_texture: THREE.Texture | null = null) {
        this.freeAllMeshResources();

        let finalColorTransparent: boolean = false;
        // let finalBorderColorTransparent:boolean = false;

        // if (this.inputs.opacity < 0.99) {
        //     finalColorTransparent = true;
        // }

        this.mesh = new this.context.three.Mesh(
            //new THREE.PlaneGeometry(2, 2, 1, 1),
            new this.context.three.ShapeGeometry(Utils.RoundedRectShape(1, 1, 0)).translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            new this.context.three.MeshBasicMaterial({
                transparent: true,
                map: _texture,
                opacity: this.inputs.opacity,
                color: this.inputs.color,
                side: THREE.DoubleSide,
                alphaTest: 0.05
            }));


        // let borderScale:number = 1 + this.inputs.borderSize * 0.01;

        // this.borderMesh = new THREE.Mesh(
        //         //new THREE.PlaneGeometry(2, 2, 1, 1),
        //         new THREE.ShapeGeometry(Utils.RoundedRectShape(borderScale, borderScale, this.inputs.borderRadius)).translate(-borderScale* 0.5, -borderScale * 0.5, -0.01),//.scale(1, this.oldRootScale.y, 1),
        //         //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
        //         new THREE.MeshBasicMaterial({
        //         transparent: finalBorderColorTransparent,
        //         opacity: this.inputs.borderOpacity,
        //         color: this.inputs.borderColor,
        //         side: THREE.DoubleSide
        //         }));

        this.pivotNode.add(this.mesh!);

        // this.mesh.add(this.borderMesh);

        // if(url === "https://storage.googleapis.com/virtual-tc.appspot.com/3d/gltf/tree_gltf/scene.gltf") {


        this.outputs.objectRoot = this.pivotNode;
        this.outputs.collider = this.pivotNode;

        this.mesh!.visible = this.inputs.visible;
    }

    onEvent(eventType: string, eventData: unknown) {
        this.notify(eventType, eventData);
    }

    onInputsUpdated(oldInputs: Inputs) {
        // if(!this.mesh || !this.borderMesh) {
        //     return;
        // }

        if (oldInputs.visible !== this.inputs.visible && this.mesh?.material) {
            this.mesh!.visible = this.inputs.visible;
        }

        if (oldInputs.color !== this.inputs.color && this.mesh?.material) {
            (this.mesh.material as THREE.MeshBasicMaterial).color.set(this.inputs.color);
        }

        if (oldInputs.textureSource !== this.inputs.textureSource) {
            //this.loadTextureAndBuildMesh(true);
            this.texture = TextureManager.instance.LoadTexture(this.inputs.textureSource, this.buildMesh.bind(this), this.fallbackToDefaultTexture.bind(this));

        }


        if (oldInputs.opacity !== this.inputs.opacity && this.mesh?.material) {
            (this.mesh.material as THREE.MeshBasicMaterial).opacity = this.inputs.opacity;

            // if (this.inputs.opacity < 0.99) {
                (this.mesh.material as THREE.MeshBasicMaterial).transparent = true;
            // } else {
            //     (this.mesh.material as THREE.MeshBasicMaterial).transparent = false;
            // }
        }

        // if (oldInputs.borderOpacity !== this.inputs.borderOpacity) {
        //     (this.borderMesh.material as THREE.MeshBasicMaterial).opacity = this.inputs.borderOpacity;

        //     if(this.inputs.borderOpacity < 0.99) {
        //         (this.borderMesh.material as THREE.MeshBasicMaterial).transparent = true;
        //     } else {
        //         (this.borderMesh.material as THREE.MeshBasicMaterial).transparent = false;
        //     }
        // }
        /*
        if (oldInputs.texture !== this.inputs.texture) {
            const material = this.mesh!.material as MeshBasicMaterial;
            material.map = this.inputs.texture;
            const THREE = this.context.three;
            material.needsUpdate = true;
        }*/
    }

    freeAllMeshResources(): void {
        this.pivotNode && (this.mesh && this.pivotNode.remove(this.mesh!));

        this.outputs.collider = null;
        this.outputs.objectRoot = null;

        this.mesh && (this.mesh!.material && (this.mesh!.material as THREE.MeshBasicMaterial).dispose());
        this.mesh && this.mesh!.geometry.dispose();

        // this.borderMesh && (this.borderMesh!.material && (this.borderMesh!.material as THREE.MeshBasicMaterial).dispose());
        // this.borderMesh && this.borderMesh!.geometry.dispose();
    }

    onDestroy() {
        this.freeAllMeshResources();

        if (this.texture) {
            this.texture.Destroy();
        }

        //this.texture && this.texture?.dispose();
    }

}

export const imageRendererType = 'st.imageRenderer';
export function makeImageRenderer() {
    return new ImageRenderer();
}
