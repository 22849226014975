import { getUrlHashParams } from '@crema/utility/Utils';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { showMessage, updateUserThirdPartyToken } from 'redux/actions';
import { history } from 'redux/store';
import { SET_CURRENT_SIDEBAR_INDEX } from 'types/actions/Home.action';
import { SketchfabToken, SketchfabTokenData } from 'types/models/dataAccess/User';
import { SIDEBAR_INDEX } from 'types/models/home/HomeApp';
import { authRole, initialUrl } from '../../shared/constants/AppConst';
import { store } from 'App';
import _ from 'lodash';

function RerouteToHome() {

  // render(
  return (history.push(initialUrl), React.lazy(() => import('./Dashboard')))
  // )

}

export enum URL_TERMS {
  LAYERS = 'layers',
  LAYER = 'layer',
  PROJECT = 'project',
  SPACE = 'space',
  SPACES = 'spaces',
}

export const homeConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/home/spaces',
        component: React.lazy(() => import('./Dashboard')),
      }
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/home/space/:did/project/:pDid?/:menu?/:mid?',
        component: React.lazy(() => import('./SpaceDetail')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/integrate/:x',
        //https://app.spatialthink.com/integrate/sketchfab#access_token=71l0OJQz06CDtQgfptUsMLjzdO4j3X&expires_in=2592000&token_type=Bearer&scope=read+write&state=123456789
        // component: React.lazy(() => import('./SpaceDetail')),
        component: () => {

          if (window.location.pathname == '/integrate/sketchfab') {
            console.log(`[st] preSketchfabUrl 2 currentUrl ${window.location.pathname}`);

            let sketchfabTokenData: SketchfabTokenData = getUrlHashParams() as SketchfabTokenData;
            // console.log(`got data ${JSON.stringify(sketchfabTokenData)}`);
            let sketchfabToken: SketchfabToken = new SketchfabToken;
            sketchfabToken.addedAt = new Date();
            sketchfabToken.data = sketchfabTokenData;

            let url = window.localStorage.getItem('preSketchfabUrl') || '';
            localStorage.setItem('sketchfabResult', JSON.stringify(sketchfabToken));
            store.dispatch(updateUserThirdPartyToken({ token: sketchfabToken, successMessage: "Sketchfab verified - you may close this tab, or continue here." }));
            console.log(`[st] preSketchfabUrl 3 ${url}`);
            store.dispatch({ type: SET_CURRENT_SIDEBAR_INDEX, payload: SIDEBAR_INDEX.THREED });
            // store.dispatch(showMessage("Sketchfab verified - you may close this tab!"))

            return <Redirect to={url} />;
          } else {
            return <Redirect to={initialUrl} />;
          }
        }
        // (
        //   localStorage.setItem('sketchfabResult', window.location.pathname),
        // <Redirect to={window.localStorage.getItem('preSketchfabUrl')} />
        // ),
      }
    ],
  },
  // {
  //   auth: authRole.user,
  //   routes: [
  //     {
  //       path: '*',
  //       component: <RerouteToHome/>,
  //     },
  //   ],
  // },
];
