import { Dispatch } from "react";
import { onAddNewNotification } from "redux/actions";
import { AppActions } from "types";
import emailjs from 'emailjs-com';
// const { messages } = useIntl();

const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID || "service_es2irf1"
const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID || 'user_NoiI1UCkGafchkLBtzfzr'
export default class NotificationService {

    public static sendEmail = (templateID: string, emailParams: any) => {

        emailjs.send(EMAILJS_SERVICE_ID, templateID, emailParams, EMAILJS_USER_ID)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (error) => {
                console.error('FAILED...', error, error.text);
                throw new Error(error);
            }).catch(error => {
                console.error(error)
            })
    }

    public static welcomeUser = (appLink: string, recipientId: string, emailParams: {
        toFirstName: string,
        toEmail: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'welcome_user';
                const notificationText = `Welcome to SpatialThink ${emailParams.toFirstName}!`;
                const channels = ["email"];

                //NO IN-APP NOTIFICATION FOR A NEW USER BC NO USER ID. POTENTIALLY CHANGE LATER
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);
            } catch (error) {
                console.error(error)
            }
        }

    }

    public static inviteNewUserToSpace = (appLink: string, creatorId: string, emailParams: {
        fromFirstName: string,
        fromLastName: string,
        toEmail: string,
        spaceLink: string,
        spaceName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'invite_new_user_to_space';
                const notificationText = `${emailParams.fromFirstName} shared a space - ${emailParams.spaceName} - with you!`;
                const channels = ["email"];

                //NO IN-APP NOTIFICATION FOR A NEW USER BC NO USER ID. POTENTIALLY CHANGE LATER
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);
            } catch (error) {
                console.error(error)
            }
        }

    }

    public static shareSpaceWithUser = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        fromLastName: string,
        toEmail: string,
        spaceLink: string,
        spaceName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'share_space_with_user';
                const notificationText = `${emailParams.fromFirstName} shared a space - ${emailParams.spaceName} - with you!`;
                const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }
    }

    public static assignTaskToUser = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        // spaceName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'assign_task_to_user';
                const notificationText = `${emailParams.fromFirstName} assigned a task to you`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }

    public static removeUserFromTask = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        // spaceName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'remove_user_from_task';
                const notificationText = `${emailParams.fromFirstName} removed you from a task`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }


    public static mentionInTaskComment = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        // spaceName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'mention_in_task_comment';
                const notificationText = `${emailParams.fromFirstName} mentioned you in a task`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }

    public static assignLabelInTask = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        labelName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'assign_label_in_task';
                const notificationText = `${emailParams.fromFirstName} assigned a label - ${emailParams.labelName} in task`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }

    public static removeLabelFromTask = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        labelName: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'remove_label_from_task';
                const notificationText = `${emailParams.fromFirstName} removed a label - ${emailParams.labelName} from task`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }

    public static fieldUpdatedInTask = (appLink: string, creatorId: string, recipientId: string, emailParams: {
        fromFirstName: string,
        toEmail: string,
        taskTitle: string,
        taskLink: string,
        updatedField: string,
    }) => {

        return (dispatch: Dispatch<AppActions>) => {

            try {
                const TEMPLATE_ID = 'field_updated_in_task';
                const notificationText = `${emailParams.fromFirstName} updated ${emailParams.updatedField} in task ${emailParams.taskTitle}.`; // in ${emailParams.spaceName}`;
                // const channels = ["notification", "email"];

                dispatch(onAddNewNotification([recipientId], notificationText, creatorId, appLink));
                NotificationService.sendEmail(TEMPLATE_ID, emailParams);

            } catch (error) {
                console.error(error)
            }
        }

    }

}