import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from '@crema/utility/AppHooks';
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { makeStyles, Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Scrollbar from '../Scrollbar';
import IntlMessages from '../../utility/IntlMessages';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import NotificationItem from './NotificationItem';
import { AppState } from '../../../redux/store';
import { Fonts } from '../../../shared/constants/AppEnums';
import { CremaTheme } from '../../../types/AppContextPropsType';
import { onGetNotificationList, onMarkReadAllNotification } from 'redux/actions';
import { NotificationModel } from 'types/models/NotificationModel';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { GET_NOTIFICATIONS } from 'types/actions/Notification.actions';

const useStyles = makeStyles((theme: CremaTheme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('sm')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 500, //TODO this should be 100%
      [theme.breakpoints.up('xl')]: {
        maxHeight: 380,
      },
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeft: 'solid 1px',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface NotificationsProps { }

const Notifications: React.FC<NotificationsProps> = () => {

  const notifications = useSelector<AppState, NotificationModel[]>(({ auth }) => auth.notifications);
  const notifCount = useSelector<AppState, number>(({ auth }) => auth.notifCount);

  const dispatch = useDispatch();
  const classes = useStyles();
  const authUser = useAuthUser();

  // useEffect(() => {
  // dispatch(onGetNotificationList(authUser));
  // }, [
  // dispatch
  // ]);

  useEffect(() => {
    const subscriber = onGetRealTimeNotifications();
    // Stop listening for updates when no longer required
    return () => subscriber();
  }, []);

  const onGetRealTimeNotifications = () => {
    return firestore
      .collection('notifications')
      .doc(authUser?.uid)
      .onSnapshot((documentSnapshot) => {
        let notificationLength = 0;
        let notifications: NotificationModel[] = [];

        (documentSnapshot.data()?.notifications || []).forEach((notif: any) => {
          notifications = [...notifications, { ...notif, createdOn: new Date(notif.createdOn?.seconds * 1000) }]
          if (!notif.read) notificationLength++;
        });

        const sorted_notif = notifications
          .sort((a: NotificationModel, b: NotificationModel) => (
            new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())
          ).reverse();

        const data = {
          notifications: sorted_notif,
          notifCount: notificationLength,
        };
        dispatch({ type: GET_NOTIFICATIONS, payload: data });
      });
  };

  const [anchorNotification, setAnchorNotification] =
    React.useState<HTMLButtonElement | null>(null);

  const onClickNotificationButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorNotification(event.currentTarget);
    dispatch(onGetNotificationList(authUser));
  };

  return (
    <>
      <IconButton
        className={clsx(classes.notiBtn, 'notiBtn')}
        aria-label='new notifications'
        color='inherit'
        onClick={onClickNotificationButton}>
        <Badge
          className={classes.badge}
          badgeContent={notifCount}
          color='secondary'>
          <NotificationsActiveIcon
            className={clsx(classes.notiIcon, 'notiIcon')}
          />
        </Badge>
        {/* @ts-ignore */}
        <Hidden mdUp>
          <Box
            ml={4}
            fontSize={16}
            fontFamily='Poppins'
            fontWeight={Fonts.REGULAR}
            component='span'>
            <IntlMessages id='common.notifications' />
          </Box>
        </Hidden>
      </IconButton>

      <Popover
        anchorEl={anchorNotification}
        id='language-switcher'
        className={classes.crPopover}
        keepMounted
        open={Boolean(anchorNotification)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorNotification(null)}>
        <Box>
          <Box px={5} py={3} className={classes.notificationHeader}>
            <Box component='h5' fontFamily='Poppins' fontSize={16}>
              <IntlMessages id='common.notifications' />({notifCount})
            </Box>
            {notifCount !== 0 && (
              <Box>
                <Button
                  onClick={() => {
                    const markReadNotifications = notifications.map(
                      (notif) => ({
                        ...notif,
                        read: true,
                      }),
                    );
                    dispatch(
                      onMarkReadAllNotification(authUser, markReadNotifications),
                    );
                    dispatch(onGetNotificationList(authUser));
                  }}
                  variant='contained'
                  color='primary'
                  style={{ fontSize: '10px' }}>
                  Mark all as read
                </Button>
              </Box>
            )}
          </Box>
          <Scrollbar className='scroll-submenu'>
            <List
              className={classes.list}
              onClick={() => {
                setAnchorNotification(null);
              }}>
              {notifications.map((item: NotificationModel, index) => (
                <NotificationItem key={item.id} item={item} />
              ))}
            </List>
          </Scrollbar>
          {/* <Box mt={2}>
            <Button
              className={classes.btnPopover}
              variant='contained'
              color='primary'>
              <IntlMessages id='common.viewAll' />
            </Button>
          </Box> */}
        </Box>
      </Popover>
    </>
  );
};

export default Notifications;
