import { Box, makeStyles, useTheme } from "@material-ui/core"
import React from "react"
import { InviteStatus, PeopleObj, SpaceRole } from "types/models/home/HomeApp";

const useStyles = makeStyles((theme) => ({
    badgeRoot: {
        padding: '3px 10px',
        borderRadius: 15,
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
}))

export const RolePill = ({ role, inviteStatus }: { role: SpaceRole, inviteStatus?: InviteStatus | undefined }): any => {
    const classes = useStyles();
    const theme = useTheme();

    const getStatusColor = () => {
        if (role === SpaceRole.VIEWER) {

            return theme.palette.secondary.main;
        } else if (role == SpaceRole.EDITOR) {
            // return '#43C888';
            return '#105fa5';

        } else if (role == SpaceRole.OWNER) {
            // return '#43C888';Í
            return '#018989';

        }
        return '#315f72';
    };

    return (
    // <Badge invisible={!inviteStatus} badgeContent={inviteStatus}>
        <Box
            className={classes.badgeRoot}
            style={{
                color: getStatusColor(),
                backgroundColor: getStatusColor() + 20,
            }}>
            {role ? role.toLowerCase() : ""}
        </Box>
        // {/* </Badge> */}
        );

}