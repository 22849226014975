
import { showGizmo } from "redux/actions/ThreeD";
import { store } from "App";
import { Euler, MathUtils, Object3D, Quaternion, Vector3 } from "three";
import { GizmoTools } from "../../../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import CardinalAxesAndPlanes from "../../../Tools/CardinalAxesAndPlanes";
import { SimulationMode } from "../../core/RenderingAndPlaceObjectStateSystem";
import Simulation from "../../core/Simulation";
import { IComponentEventSpy, Keys } from "../../sceneManagement/SceneComponent";
import { Behaviors } from "../../core/Behaviors";
import { LOCK_VIEW } from "types/actions/Home.action";

export class GenericInputKeySpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.Key;
    constructor() {
    }
    onEvent(payload: any) {
        //state = 0 is pressed, 1 is up, 2 is released
        // console.log(`[GenericInputKeySpy] keyCode:   ${payload.event.key}, shift: ${payload.event.modifiers.altKey}`);

        let isShiftKey = payload.event.modifiers.shiftKey;
        let isAltKey = payload.event.modifiers.altKey;

        if (payload.event.key == Keys.SPACE) {

            console.log('[st] [drag] grabbing node', Simulation.instance.lastSelectedNode)
            if (Simulation.instance.lastSelectedNode) {
                Behaviors.saveNodePosition(Simulation.instance.lastSelectedNode);
                Simulation.instance.grabbedObject = Simulation.instance.lastSelectedNode;
                Simulation.instance.dragging = true;
              }
        } else {
            Simulation.instance.grabbedObject = null;
            Simulation.instance.dragging = false;
        }

        if (payload.event.state == 2) {
            if (payload.event.key == Keys.ESCAPE) {
                if (Simulation.instance.getSimulationMode() == SimulationMode.ADD_OBJECT
                    || Simulation.instance.getSimulationMode() == SimulationMode.ADD_INTERNAL_OBJECT) {
                    Simulation.instance.cancelAddObject();
                } else if (Simulation.instance.getSimulationMode() == SimulationMode.PLACE_MODE) {
                    Simulation.instance.cancelPlaceMode();
                } else {
                    Simulation.instance.sceneLoader.hideTransformGizmo();
                    store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
                    // Simulation.instance.propertiesPanel.hidePropertiesPanel();
                }
            } else if (payload.event.key == Keys.SPACE) {
                if (Simulation.instance.tryToEnterGrabMode()) {
                    Simulation.instance.sceneLoader.hideTransformGizmo();
                    store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));


                    // Simulation.instance.propertiesPanel.hidePropertiesPanel();
                }
            } else {

            }
        } else if (payload.event.state == 0) {
            let rotationOnYAxis = 0;
            let rotationOnXAxis = 0;
            let rotationOnZAxis = 0;
            let moveOnXAxis = 0;
            let moveOnYAxis = 0;
            let moveOnZAxis = 0;
            let rotationDelta = 11.25;
            let resetRotation = false;
            let resetScale = false;
            let moveDisplacement: number = 0.02564; //1 inch
            let nodeChanged: boolean = false;
            switch (payload.event.key) {
                // case Keys.Q:
                //     rotationOnYAxis = rotationDelta;
                //     break;
                // case Keys.E:
                //     rotationOnYAxis = -rotationDelta;
                //     break;
                // case Keys.NUMPAD_LEFT:
                //     rotationOnXAxis = rotationDelta;
                //     break;
                // case Keys.NUMPAD_RIGHT:
                //     rotationOnXAxis = -rotationDelta;
                //     break;
                // case Keys.NUMPAD_UP:
                //     rotationOnZAxis = rotationDelta;
                //     break;
                // case Keys.NUMPAD_DOWN:
                //     rotationOnZAxis = -rotationDelta;
                //     break;
                case Keys.H:
                    resetRotation = true;
                    nodeChanged = true;
                    break;
                case Keys.Y:
                    resetScale = true;
                    nodeChanged = true;
                    break;
                case Keys.R:
                case Keys.O:
                    // moveOnXAxis = isShiftKey ? moveDisplacement : -moveDisplacement;
                    if (isShiftKey && isAltKey) {
                        rotationOnYAxis = rotationDelta;
                    } else if (isShiftKey) {
                        moveOnXAxis = -moveDisplacement;
                    } else if (isAltKey) {
                        rotationOnYAxis = -rotationDelta;
                    } else {
                        moveOnXAxis = moveDisplacement;
                    }
                    nodeChanged = true;
                    break;
                // case Keys.PERIOD:
                //     moveOnXAxis = moveDisplacement;
                //     break;
                case Keys.M:
                    store.dispatch({ type: LOCK_VIEW, payload: false })
                    break;
                case Keys.N:
                    store.dispatch({ type: LOCK_VIEW, payload: true })
                    break;
                case Keys.G:
                    if (isShiftKey && isAltKey) {
                        rotationOnXAxis = rotationDelta;
                    } else if (isShiftKey) {
                        moveOnYAxis = -moveDisplacement;
                    } else if (isAltKey) {
                        rotationOnXAxis = -rotationDelta;
                    } else {
                        moveOnYAxis = moveDisplacement;
                    }
                    nodeChanged = true;
                    // moveOnYAxis = isShiftKey ? -moveDisplacement : moveDisplacement;
                    break;
                // case Keys.SINGLE_QUOTE:
                //     moveOnYAxis = -moveDisplacement;
                //     break;
                case Keys.B:
                    // moveOnZAxis = isShiftKey ? moveDisplacement: -moveDisplacement;
                    if (isShiftKey && isAltKey) {
                        rotationOnZAxis = rotationDelta;
                    } else if (isShiftKey) {
                        moveOnZAxis = -moveDisplacement;
                    } else if (isAltKey) {
                        rotationOnZAxis = -rotationDelta;
                    } else {
                        moveOnZAxis = moveDisplacement;
                    }
                    nodeChanged = true;
                    break;
                // case Keys.BACKSLASH:
                //     moveOnZAxis = moveDisplacement;
                //     break;
            }

            let target3D: Object3D | null = null;

            if (Simulation.instance.lastSelectedNode) {
                if ((Simulation.instance.lastSelectedNode as any).obj3D) {

                    target3D = ((Simulation.instance.lastSelectedNode as any).obj3D as Object3D);
                }
            }
            var saveNode: boolean = false;
            if (target3D) {
                if (resetRotation) {
                    if (target3D.quaternion.equals(CardinalAxesAndPlanes.instance.quaternionIdentity)) {
                        nodeChanged = false;
                    } else {
                        target3D.setRotationFromQuaternion(CardinalAxesAndPlanes.instance.quaternionIdentity);
                        saveNode = true;
                    }
                } else if (resetScale) {
                    if (target3D.scale.equals(CardinalAxesAndPlanes.instance.unitVector)) {
                        nodeChanged = false;
                    } else {
                        target3D.scale.set(CardinalAxesAndPlanes.instance.unitVector.x,
                            CardinalAxesAndPlanes.instance.unitVector.y,
                            CardinalAxesAndPlanes.instance.unitVector.z);
                        saveNode = true;
                    }

                } else {
                    saveNode = true;
                    //target3D.rotateY(rotationOnYAxis * MathUtils.DEG2RAD);
                    target3D.rotateOnWorldAxis(CardinalAxesAndPlanes.instance.yAxis, rotationOnXAxis * MathUtils.DEG2RAD);
                    target3D.rotateOnWorldAxis(CardinalAxesAndPlanes.instance.xAxis, rotationOnYAxis * MathUtils.DEG2RAD);
                    target3D.rotateOnWorldAxis(CardinalAxesAndPlanes.instance.zAxis, rotationOnZAxis * MathUtils.DEG2RAD);
                    //target3D.translateX(moveOnXAxis);
                    //target3D.translateZ(moveOnZAxis);

                    target3D.position.x += moveOnXAxis;
                    target3D.position.y += moveOnYAxis;
                    target3D.position.z += moveOnZAxis;

                    /*

                    Please keep this code, it's the move according to camera's view vector, we'll use it later

                    let worldZDirection = new Vector3();
                    target3D.getWorldDirection(worldZDirection);
                    target3D.position.y += moveOnYAxis;

                    let cameraDirectionToTarget = Simulation.instance.camera.Position.clone().sub(target3D.position);//.normalize();

                    let angleWithXAxis = cameraDirectionToTarget.angleTo(CardinalAxesAndPlanes.instance.xAxis);
                    let angleWithZAxis = cameraDirectionToTarget.angleTo(CardinalAxesAndPlanes.instance.zAxis);
                    let shortTestAngleWithXAxis:number = angleWithXAxis;
                    let shortTestAngleWithZAxis:number = angleWithZAxis;

                    let xNegated:number = -1;
                    let zNegated:number = -1;

                    if(Simulation.instance.camera.Position.x < target3D.position.x) {
                        shortTestAngleWithXAxis = Math.PI - angleWithXAxis;
                        xNegated *= -1;
                    }
                    if(Simulation.instance.camera.Position.z < target3D.position.z) {
                        shortTestAngleWithZAxis = Math.PI - angleWithZAxis;
                        zNegated *= -1;
                    }

                    //z axis is the axis we're looking at, so we swap movement of x and z
                    if(shortTestAngleWithXAxis > shortTestAngleWithZAxis) {
                        target3D.position.x += moveOnXAxis * zNegated * -1;
                        target3D.position.z += moveOnZAxis * zNegated;
                    } else {
                        target3D.position.z += moveOnXAxis * xNegated;
                        target3D.position.x += moveOnZAxis * xNegated;
                    }*/
                }

                if (Simulation.instance.lastSelectedNode && saveNode && nodeChanged) {
                    Simulation.instance.propertiesPanel.saveLastNode(true, Simulation.instance.lastSelectedNode);
                }
            }
        }
    }
}

