import { OBJECT_ACTIONS } from "types/models/dataAccess/Logic";

export enum ActionType {
    Show = 'Show',
    Hide = 'Hide',
    Highlight = 'Highlight',
    GrowShrinkAnimate = 'Grow Shrink Animate',
    CallActivate = 'Activate',
    Move_Parameterized = 'Move',
    Rotate_Parameterized = 'Rotate',
    Scale_Parameterized = 'Scale'
    //Drag = "Drag"
}

export interface EventActionOutcome {
    actionType: ActionType;
    parameter: string;
    objectID: string;
}

export interface TriggerActionOutcome {
    actionType: ActionType;
    parameter: string;
}
export interface VariableValueActionPair {
    name: string;
    value: string;
}

export enum VariableLogicType {
    blank = '',
    and = 'and',
    or = 'or'
}

export interface VariableValueTriggerPair {
    name: string;
    value: string;
    logic: VariableLogicType;
}

export default class PropertiesPanelBehaviorActions {
    
    public isActionParameterized(actionType:OBJECT_ACTIONS):boolean {
        for (const parameterizedAction of this.parameterizedActions) {
            if(parameterizedAction === actionType) {
                return true;
            }
        }
        
        return false;
    }
    
    constructor() {
        this.parameterizedActions = [];
    
        this.parameterizedActions.push(OBJECT_ACTIONS.MOVE);
        this.parameterizedActions.push(OBJECT_ACTIONS.ROTATE);
        this.parameterizedActions.push(OBJECT_ACTIONS.SCALE);
    }
    
    protected parameterizedActions:OBJECT_ACTIONS[];
}

