import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    Box,
    Checkbox,
    Dialog,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
    Typography
} from '@material-ui/core';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import VideocamRoundedIcon from '@mui/icons-material/Videocam';

import {
    setTagsViewModeSecondary,
    setWorkflowsSidebarOpen,
} from 'redux/actions/Home';
import { SpacePose, SpaceRole, TagGroup } from 'types/models/home/HomeApp';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import clsx from 'clsx';
import { ViewModeSecondary } from '..';
import EditableTextField from './EditableTextField';
import { useHistory } from 'react-router';
import { canEditSpace } from '@crema/utility/Utils';
import Alert from '@material-ui/lab/Alert/Alert';
import Spacer from '@crema/core/Spacer';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import VideoRecorder3 from './VideoRecorder3';
import { SHOW_POSES } from 'types/actions/ThreeD.actions';
import MicIcon from '@mui/icons-material/Mic';
import { LockedViewIcon, UnlockedViewIcon } from '@crema/core/Icons/CustomIcons';
import IconHover from '@crema/core/AppAnimation/ItemHover/IconHover';
import { RichHelpBadge } from '@crema/core/HelpBage';
import { onUpdateTagGroup } from 'redux/actions/Step';

const useStyles = makeStyles((theme: Theme) =>
    // root: {
    //   height: 216,
    //   flexGrow: 1,
    //   maxWidth: 400,
    // },
    createStyles({
        tagGroupsListing: {},
        tagGroupsListingRoot: {
            width: '100%',
            paddingLeft: '15px',
            paddingRight: '15px',
            backgroundColor: theme.palette.background.paper,
        },
        tagGroupsListSubHeader: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        tagGroupsListItem: {
            cursor: 'pointer',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: '2px',
            paddingBottom: '2px',
            fontSize: '12px',
        },
        tagGroupsListItemActive: {
            backgroundColor: 'rgba(244, 247, 254, 0.8)',
            paddingTop: '2px',
            paddingBottom: '2px',
            fontSize: '12px',
        },
        tagsNested: {
            // paddingLeft: theme.spacing(6),
            fontSize: '12px',
            cursor: 'pointer',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        tagGroupName: {
            '& .MuiTypography-body1': {
                fontSize: '12px',
            },
            paddingTop: '0px',
            paddingBottom: '0px',
            maxWidth: '150px',
        },
        tagsNestedText: {
            '& .MuiTypography-body1': {
                fontSize: '12px',
            },
            // paddingLeft: theme.spacing(6),
            cursor: 'pointer',
            paddingRight: '2px',
            fontSize: '12px',
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        deleteTagGroupIcon: {
            marginRight: 4,
            color: theme.palette.text.primary,
        },
        icon: {
            marginRight: 4,
            cursor: 'pointer',
            height: '18px',
            width: '18px',
            '& .MuiSvgIcon-root': {
                // width: '0.5em'
            }
        },
        iconActive: {
            // display: 'none',
            // '&:hover': {
            display: 'block',
            marginRight: 4,
            cursor: 'pointer',
            // height: '12px',
            // width: '12px',
            color: "blue",
            zIndex: 20,
            fontSize: '10px'

            // }

        },
        iconInactive: {
            // display: 'none',
            // '&:hover': {
            display: 'block',
            marginRight: 4,
            cursor: 'pointer',
            // height: '12px',
            // width: '12px',
            color: "grey",
            zIndex: 20,
            fontSize: '10px'
            // }
        },
        outlineBox: {
            // border: '1px solid #0000001c',
            padding: "15px",
            // paddingTop: "25px",
            // width: '280px',
            borderRadius: '10px',
            marginBottom: '12px'
        },
        outlineBoxTitle: {
            // marginBottom: "-5px",
            backgroundColor: "white",
            color: "black",
            marginTop: "-45px", //removes notched outline
            padding: "10px",
            width: "fit-content",
            // textTransform: "uppercase",
            fontSize: "13px",
        },
        bgActive: {
            // backgroundColor: '#0e6ebf4a'// '#f0f8ff',
            backgroundColor: '#c8d9e9',
            border: '1px solid #0388e5bd'
        },
        bgInactive: {
            backgroundColor: '#3c48991c'
        },
        textField: {
            // marginLeft: theme.spacing(1),
            // marginRight: theme.spacing(1),
            fontSize: "13px",
            width: '250px',
            '& .MuiInputBaseInput': {
                fontSize: "13px",
            },
            '& .MuiFormLabel': {
                fontSize: "13px",
            },
            '& .MuiInputRoot': {
                // borderTopWidth: "0.5px",
                borderLeftWidth: "0.5px",
                borderRightWidth: "0.5px",
            },
            '& MuiFocused': {
                borderTopWidth: "0px",
            }
        },
        dense: {
            marginTop: theme.spacing(3),
        },
        tgCheckbox: {
            '& .PrivateSwitchBase-root-3751': {
                padding: '4px 9px',
            }
        }
    }),
);

interface TagGroupsProps {
    onDuplicateTagGroups: (tg: TagGroup) => void;
    tg: TagGroup;
    isActive: boolean;
    // mpSdk: any;
    onClick: () => void;
    showTagsAnd3DForStep: (tgId: string) => void;
    handleEditTagGroup: (utg: TagGroup) => void;
    toggleJumpToTagId: (tagId: string, activeTg: TagGroup) => void
    getTagLabel: (tagId: string, spaceTags: ShowcaseTagMap) => string
    handleTagInTagGroupClick: (tg: any, tagId: string) => void
    // savePose: (activeTg: TagGroup) => void
    handleUpTagGroups: (tg: any) => void
    handleDownTagGroups: (tg: any) => void
    handleDeleteTagGroups: (tg: TagGroup) => void
    // ref: any
}

const TagGroupCard: React.FC<TagGroupsProps> = ({
    onDuplicateTagGroups,
    tg,
    isActive,
    // mpSdk,
    onClick,
    showTagsAnd3DForStep,
    handleEditTagGroup,
    toggleJumpToTagId,
    getTagLabel,
    handleTagInTagGroupClick,
    // savePose,
    handleDownTagGroups,
    handleUpTagGroups,
    handleDeleteTagGroups,
    // ref
}) => {

    const spaceMembershipRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home.currentSpace?.spaceMembership.role);
    const variables = useSelector<AppState, any[]>(({ home }) => home.spaceVariables || []);
    const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
    const spaceTags = useSelector<AppState, ShowcaseTagMap>(({ home }) => home.spaceTags);
    const spacePoses = useSelector<AppState, SpacePose[]>(({ home }) => home.currentSpace?.poses || []);
    const stepStatus = useSelector<AppState, string>(({ layer }) => layer.stepStatus);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const theme = useTheme();

    React.useEffect(() => {
        if (stepStatus == 'FILTERED') {
            //if tg.id == currentTgId
            // performNoConditionActions();
        }
        if (stepStatus == 'NO_COND_PERFORMED') {
            //if tg.id == currentTgId
            // performVarConditionActions();
        }
    }, [stepStatus]);

    const [showVideoRecorder, setShowVideoRecorder] = React.useState(false);
    // useEffect(() => {})
    return (
        // console.log(`%c[st] rendering TagGroup ${JSON.stringify(tg.name)}`,'color: orange;'),
        <>

            <Dialog
                // style={{width: '50%'}}
                open={showVideoRecorder}
                onClose={() => setShowVideoRecorder(false)}
                aria-labelledby='Video Recorder'
            // TransitionComponent={Transition}
            // aria-describedby='Add your system variables here'
            // className={classes.dialogBox}
            >
                <Box pt={4} px={4} mb={4}>
                    <Typography variant='h5'
                    // fontWeight={Fonts.LIGHT}
                    >
                        Step Video
                    </Typography>
                </Box>

                {/* <Scrollbar
                // className={classes.scrollRoot}
                > */}
                <VideoRecorder3 onClose={() => setShowVideoRecorder(false)} />
                {/* </Scrollbar> */}
            </Dialog>
            <div
                // ref={ref}
                className={
                    isActive
                        ? classes.tagGroupsListItem +
                        ' ' +
                        classes.tagGroupsListItemActive
                        : classes.tagGroupsListItem
                }
                // onMouseLeave={() => console.log(`[st] on m leave`)}
                onClick={onClick}
            // onBlur={async () => {
            //     // editTagGroup &&
            //     // (currentTagGroupId === tg.id) && (bannerTextBeingEdited !== tg.bannerText) && handleEditTagGroupsInLesson(tg.name, bannerTextBeingEdited, tg?.variableNameForInput || '')
            //     // setEditTagGroup(null);
            // }}
            >
                <ItemHover>
                    <div className={clsx(classes.outlineBox, (isActive) ? classes.bgActive : classes.bgInactive)}>

                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} > */}
                        {/* <Typography variant='subtitle2' style={{ color: '#315F72' }}>{tg.name}</Typography> */}
                        {/* <Tooltip title="Edit">
                                <EditIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => handleEditTagGroup(tg)}
                                />
                            </Tooltip> */}
                        {/* </div>  */}

                        {`Step ${tg.sortIndex + 1}`}

                        <EditableTextField
                            save={(name) => { (name !== tg.name) && handleEditTagGroup({ ...tg, name: name }) }}
                            saveOnEnter
                            style={
                                {
                                    marginTop: '10px',
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: '25px',
                                    width: '250px',
                                }
                            }
                            multiline={false}
                            initValue={tg.name || ''}
                            placeholder={'Step name or number'}
                        />

                        <Divider />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <Typography variant='body1' style={{ color: 'black', marginTop: '5px', marginBottom: '-15px', marginLeft: '0px', fontWeight: 500 }}>BANNER</Typography>

                            {spaceMembershipRole && canEditSpace(spaceMembershipRole) &&
                                <Tooltip title={`${tg?.video ? `Re-record` : 'Record'} Audio/ Video narration`}>
                                    <IconButton size='small' style={{ marginTop: '2px' }}
                                        onClick={() => { setShowVideoRecorder(true) }} >
                                        {(() => {
                                            if (tg.video) {
                                                if (tg.audioOnly) {
                                                    return <MicIcon fontSize='small' htmlColor={'green'} />
                                                }
                                                else {
                                                    return <VideocamRoundedIcon fontSize='small' htmlColor={'green'} />
                                                }
                                            } else {
                                                return <VideocamRoundedIcon fontSize='small' htmlColor={'black'} />
                                            }
                                        })()}

                                    </IconButton>
                                </Tooltip>}
                            <Spacer x={2} />
                            {spaceMembershipRole && canEditSpace(spaceMembershipRole) && !tg.video &&
                                <>
                                    <Typography variant='body1' style={{ color: 'black', marginTop: '5px', marginBottom: '-15px', marginLeft: '0px', fontWeight: 500 }}>Text only</Typography>
                                    <Checkbox
                                        style={{ padding: '4px' }}
                                        size='small'
                                        color='primary'
                                        checked={!!tg.textOnly}
                                        onChange={(event) => dispatch(onUpdateTagGroup({ ...tg, textOnly: (event.target.checked) }))} />
                                </>}
                        </div>


                        <EditableTextField
                            save={(bannerText) => { (bannerText !== tg.bannerText) && handleEditTagGroup({ ...tg, bannerText: bannerText }) }}
                            style={
                                {
                                    marginTop: '10px',
                                    fontSize: "13px",
                                    width: '250px',
                                    // color: '#315F72',
                                    '& .MuiInputBaseInput': {
                                        fontSize: "13px",
                                    },
                                    '& .MuiFormLabelRoot': {
                                        fontSize: "13px",
                                    },
                                    '& .MuiInputRoot': {
                                        // borderTopWidth: "0.5px",
                                        borderLeftWidth: "0.5px",
                                        borderRightWidth: "0.5px",
                                    },
                                    '& MuiFocused': {
                                        borderTopWidth: "0px",
                                    }
                                }
                            }
                            multiline={true}
                            initValue={tg.bannerText || ''}
                            placeholder={'Add a helpful cue'}

                        />
                        {/* <TextField
                            id='outlined-dense-multiline'
                            // label='Banner Text'
                            className={clsx(classes.textField, classes.dense)}
                            // margin='dense'
                            variant='standard'
                            multiline
                            maxRows='4'
                            // defaultValue={tg.bannerText}
                            // value={currentTagGroupId === tg.id ? bannerTextBeingEdited : tg.bannerText}
                            value={bannerTextBeingEdited}
                            // onFocus={() => focusOnTagGroup(tg)}

                            // onMouseOut={() => console.log(`[st] on m out`)}

                            placeholder={'Add a helpful cue'}
                            onChange={(event: any) => {
                                // setEditTagGroup(tg);
                                // alert(event.target.value)
                                setBannerTextBeingEdited(event.target.value);
                            }}
                            onBlur={() => {
                                console.log(`[st] onblur`)
                                updateBannerText(bannerTextBeingEdited)
                                // handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
                                //   editTagGroup && handleEditTagGroupsInLesson(editTagGroup.name, bannerTextBeingEdited, editTagGroup?.variableNameForInput || '')
                                //   setEditTagGroup(null);
                            }}
                        /> */}

                        {/* <Collapse
                in={currentTagGroupId === tg.id}
                timeout='auto'
                unmountOnExit> */}

                        <Typography variant='body1' style={{ color: 'black', marginTop: '10px', fontWeight: 500 }}>{'VIEWPOINT'}</Typography>
                        {/* <Box id="pose-select" mt={4} style={{ backgroundColor: 'transparent',
                            width: 'fit-content', borderRadius: '16px', border: '1px solid grey',
                            marginRight: '8px',
                            padding: '0px 0px 0px 8px' }}> */}
                        {/* <AppSelect
                                    // labelId="pose-label"
                                    // id="pose-select"

                                    menus={spacePoses}
                                    selectionKey='id'
                                    labelKey='label'
                                    defaultValue={spacePoses.find(p => p.id === tg?.poseId)}
                                    onChange={(value) => handleEditTagGroup({ ...tg, poseId: (value.id || '') as string })}
                                /> */}

                        <Select
                            labelId="pose-label"
                            id="pose-select"
                            value={tg?.poseId}
                            style={{ fontSize: '13px' }}
                            fullWidth
                            onChange={(event) => handleEditTagGroup({ ...tg, poseId: (event.target.value || '') as string })}
                        >
                            <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem>
                            {spacePoses?.map((p, i) => <MenuItem key={i} style={{ fontSize: '13px' }} value={p.id}>{p.label}</MenuItem>)}
                            <MenuItem style={{ fontSize: '13px' }} value={''} onClick={() => dispatch({ type: SHOW_POSES, payload: true })}>
                                <Typography style={{ color: theme.palette.secondary.main }} variant="subtitle1">Manage Viewpoints</Typography>
                            </MenuItem>
                        </Select>
                        {/* </Box> */}
                        {/* </Stack> */}

                        <Box style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}
                        >
                            <Typography variant='body1' style={{ color: 'black', fontWeight: 500 }}>{'LOCK MOVEMENT'}</Typography>
                            {/* <FormControlLabel className={classes.tgCheckbox} control={ */}
                            <IconHover tooltip={`${tg.lockView ? 'Unlock' : 'Lock'} movement in this step`} >
                                <Checkbox
                                    icon={<UnlockedViewIcon fill={theme.palette.primary.main} />}
                                    checkedIcon={<LockedViewIcon fill={theme.palette.primary.main} />}
                                    checked={!!tg.lockView}
                                    onChange={(event) => handleEditTagGroup({ ...tg, lockView: (event.target.checked) })} />
                                {/* // } label="Lock View" /> */}
                            </IconHover>
                        </Box>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >

                            <Box display='inline-flex' alignItems={'center'}>
                                <Typography variant='body1' style={{
                                    color: 'black',
                                    fontWeight: 500
                                }}>{'TAGS'}</Typography>
                                <RichHelpBadge
                                    title="Select one or more tags that will be shown when your viewer is in this step"
                                >
                                    <Box>
                                        <ul>
                                            <li><p>Showing only a few tags per step help your viewer focus on a small amount of information at a time</p></li>
                                            <br />
                                            <li><p>Click Select Tags and <b>select/ unselect tags</b> to be shown/ hidden in this step</p></li>
                                            <br />
                                            <li><p>You may add Quiz tags at frequent intervals to get feedback or test your viewer's knowledge</p></li>
                                        </ul>
                                    </Box>
                                </RichHelpBadge>
                            </Box>
                            <Spacer x={3} />
                            {/* <LittleButton title='Update Tags' icons={[]} */}
                            <Tooltip title="Add/ remove tags to this step">
                                <Button style={{ height: '1.8em' }}
                                    color='primary'
                                    size='small'
                                    variant='outlined'
                                    onClick={() => {
                                        dispatch(setTagsViewModeSecondary(ViewModeSecondary.LAYERS_TAGS));
                                    }} ><span style={{ fontWeight: 500 }}>Select Tags</span></Button></Tooltip>
                        </div>
                        <Spacer y={1} />
                        {tg?.tagIds && <List
                            component='div'
                            disablePadding
                        // style={
                        //   currentTagGroupId === tg.id
                        //     ? { backgroundColor: 'rgba(244, 247, 254, 0.9)' }
                        //     : {}
                        // }
                        >
                            {(tg?.tagIds || []).filter((tagId: string) => Object.keys(spaceTags).includes(tagId)
                            ).map((tagId: string) => (
                                <ItemHover key={`itemhover-${tagId}`}>
                                    <ListItem
                                        key={`list-item-${tagId}`}
                                        className={classes.tagsNested}
                                        style={{ cursor: 'pointer' }}
                                        disableGutters
                                        onClick={() => { handleTagInTagGroupClick(tg, tagId) }}>

                                        <ListItemText
                                            className={classes.tagsNestedText}
                                            primary={getTagLabel(tagId, spaceTags)}
                                        />
                                        {spaceMembershipRole && canEditSpace(spaceMembershipRole) && <ItemHover>
                                            <Tooltip title="Jump to this tag and open it by default for this step">
                                                <div
                                                    className={tg?.jumpToTagId == tagId ? classes.iconActive : classes.iconInactive}
                                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                                    onClick={() => toggleJumpToTagId(tagId, tg)}
                                                >Focus</div>
                                            </Tooltip>
                                        </ItemHover>}
                                    </ListItem>
                                </ItemHover>
                            ))}
                        </List>}
                        <Spacer y={1} />
                        <Divider orientation='horizontal' />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                            <Box display='inline-flex' alignItems={'center'} style={{ marginTop: '6px', }}>
                                <Typography variant='body1' style={{ color: 'black', fontWeight: 500 }}>{'3D'}</Typography>
                                <RichHelpBadge
                                    title=""
                                >
                                    <Box>
                                        <ul>
                                            <li><p>Select one or more 3D objects that will be shown when your viewer is in this step</p></li>
                                            <br />
                                            <li><p>Click Select 3D and <b>select/ unselect objects</b> to be shown/ hidden in this step</p></li>
                                            <br />
                                            <li><p>You can always <b>conditionally show or hide an object via Interactions</b> in a step. For e.g. IF (something is clicked) or (variable value is x), show/hide an object</p></li>
                                        </ul>
                                    </Box>
                                </RichHelpBadge>
                            </Box>
                            <Spacer x={4} />
                            {spaceMembershipRole && canEditSpace(spaceMembershipRole) &&
                                <Tooltip title="Add/ remove 3D objects to this step">
                                    <Button style={{ height: '1.8em' }}
                                        color='primary'
                                        size='small'
                                        variant='outlined'
                                        onClick={() => {
                                            dispatch(setTagsViewModeSecondary(ViewModeSecondary.LAYERS_3D));
                                        }} ><span style={{ fontWeight: 500 }}>Select 3D</span></Button></Tooltip>
                            }
                        </div>
                        <Spacer y={1} />
                        {tg?.tagIds && <List
                            component='div'
                            disablePadding
                        // style={
                        //   currentTagGroupId === tg.id
                        //     ? { backgroundColor: 'rgba(244, 247, 254, 0.9)' }
                        //     : {}
                        // }
                        >
                            {(tg?.tagIds || []).filter((tagId: string) =>
                                Array.from(spaceModels.keys()).includes(tagId)
                            ).map((tagId: string) => (
                                <ItemHover key={`itemhover-${tagId}`}>
                                    <ListItem
                                        key={`list-item-${tagId}`}
                                        className={classes.tagsNested}
                                        style={{ cursor: 'pointer' }}
                                        disableGutters
                                        onClick={() => { handleTagInTagGroupClick(tg, tagId) }}>

                                        <ListItemText
                                            className={classes.tagsNestedText}
                                            primary={getTagLabel(tagId, spaceTags)}
                                        />
                                        {spaceMembershipRole && canEditSpace(spaceMembershipRole) && <ItemHover>
                                            <Tooltip title="Focus on this 3D object by default for this step">
                                                <div
                                                    className={tg?.jumpToTagId == tagId ? classes.iconActive : classes.iconInactive}
                                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                                    onClick={() => toggleJumpToTagId(tagId, tg)}
                                                >Focus</div>
                                            </Tooltip>
                                        </ItemHover>}
                                    </ListItem>
                                </ItemHover>
                            ))}
                        </List>}

                        <Spacer y={1} />
                        <Divider orientation='horizontal' />


                        <div style={{ width: "100%" }}>
                            <Tooltip title="User must select an option to proceed. Define these as Variables from the sidebar!">
                                <FormControl
                                    fullWidth
                                >

                                    <InputLabel id="variable-for-input-label" style={{ fontSize: '13px' }}>
                                        {/* <Typography variant='body1' style={{ color: 'black', fontWeight: 500 }}> */}
                                        <span style={{ fontWeight: 500 }}>Ask User for Input</span>
                                        {/* </Typography> */}
                                    </InputLabel>

                                    <Select
                                        labelId="variable-for-input-label"
                                        id="variable-for-input-select"
                                        value={tg?.variableNameForInput}
                                        style={{ fontSize: '13px' }}
                                        label="Ask User to set a Variable"
                                        // displayEmpty
                                        onChange={(event) => handleEditTagGroup({ ...tg, variableNameForInput: (event.target.value || '') as string })}
                                    >
                                        <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem>
                                        {variables?.map((v, i) => <MenuItem key={i} style={{ fontSize: '13px' }} value={v.name}>{v.name}</MenuItem>)}
                                        {(!variables || variables.length == 0)
                                            && <Alert severity="info">
                                                You have no variables. Try adding one from Variables in the sidebar. For example: add a variable like Temperature, with allowed values like 68, 70, and 72</Alert>}
                                    </Select>
                                </FormControl>
                            </Tooltip>
                            {/* </div>
                            </div> */}

                        </div>
                        {/* <Divider /> */}

                        <Spacer y={1} />

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                            <Box display='inline-flex' alignItems={'center'} style={{ marginTop: '6px', }}>
                                <Typography variant='body1' style={{ color: 'black', fontWeight: 500 }}>INTERACTIONS ({`${((((tg || {}).logic || {}).conditions || {}).length || 0)})`}</Typography>
                                <RichHelpBadge
                                    title="Build scenarios with a simple if-this-then-that builder"
                                >
                                    <Box>

                                    </Box>
                                </RichHelpBadge>
                            </Box>
                            {/* <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}> */}
                            {/* <div>{`${((((tg || {}).logic || {}).conditions || {}).length || 0)} `} </div> */}
                            {spaceMembershipRole && canEditSpace(spaceMembershipRole) &&
                                <Tooltip title="Add/ remove interactions for this step">
                                    <Button style={{ height: '1.8em' }}
                                        color='primary'
                                        // size='small'
                                        variant='contained'
                                        onClick={() => {
                                            dispatch(setWorkflowsSidebarOpen(true));
                                        }} > <span style={{ fontWeight: 500 }}>Edit</span></Button></Tooltip>
                            }
                        </div>
                        <Divider orientation='horizontal' />
                        {/*
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }} >
                            <Typography variant='body1' style={{ color: 'black', marginTop: '10px', fontWeight: 500 }}>{'Variables'}</Typography>
                            <Spacer x={4} />

                        </div>
                        <Spacer y={1} /> */}

                        {/* <Spacer y={1} />
                        <Divider /> */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <LittleButton title={`Go to Step Viewpoint`} disabled={!tg?.poseId} icon={[
                                <Tooltip title={`Go to the Step Viewpoint`}>
                                    <SendRoundedIcon fontSize='small' htmlColor='black' />
                                </Tooltip>
                            ]} onClickFunc={() => {
                                // goToPose(tg);
                                showTagsAnd3DForStep(tg.id);
                            }} />

                            {/* {spaceMembershipRole && canEditSpace(spaceMembershipRole) &&
                                <LittleButton title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} Step start position`} icons={[

                                    <Tooltip title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} Step start position - current view and zoom will be recorded`}>
                                        <LocationOnRoundedIcon fontSize='small' htmlColor='black' /></Tooltip>

                                ]}
                                    onClickFunc={() => { savePose(tg) }} />}

                            {spaceMembershipRole && canEditSpace(spaceMembershipRole) &&
                                <LittleButton title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} Video`} icons={[

                                    <Tooltip title={`${tg?.sweepAndPose?.sid ? `Re-record` : 'Record'} video for this step`}>
                                        <VideocamRoundedIcon fontSize='small' htmlColor='black' /></Tooltip>

                                ]}
                                    onClickFunc={() => { setShowVideoRecorder(true) }} />}
                            {/* </div>


                        <div style={{ marginTop: '10px' }}> */}
                            <Tooltip title="Move up">

                                <KeyboardArrowUpIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 0, cursor: 'pointer' }}
                                    onClick={() => handleUpTagGroups(tg)}
                                />
                            </Tooltip>
                            <Tooltip title="Move down">

                                <KeyboardArrowDownIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => handleDownTagGroups(tg)}
                                />
                            </Tooltip>
                            <Tooltip title="Duplicate this Step">
                                <FileCopyIcon
                                    className={classes.icon}
                                    // style={{ marginRight: 4, cursor: 'pointer' }}
                                    onClick={() => onDuplicateTagGroups(tg)}
                                />
                            </Tooltip>
                            {/* <Tooltip title="Edit">
                                    <EditIcon
                                        className={classes.icon}
                                        // style={{ marginRight: 4, cursor: 'pointer' }}
                                        onClick={() => handleEditTagGroups(tg)}
                                    />
                                    </Tooltip> */}
                            <Tooltip title="Delete this step">
                                <DeleteIcon
                                    className={classes.icon + ' deleteTagGroupIcon'}
                                    // style={{ cursor: 'pointer' }}
                                    onClick={() => handleDeleteTagGroups(tg)}
                                />
                            </Tooltip>
                        </div>

                    </div>
                </ItemHover>
            </div >


        </>
    );
};

export default TagGroupCard;

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

export const LittleButton = ({ title, onClickFunc, icon,
    color, width, variant = '', disabled = false
}: { title: any; onClickFunc: any; icon: any, color?: string, width?: string, variant?: any, disabled?: boolean }) => {
    const theme = useTheme();
    let style: any = {
        height: '30px',
        color: color || theme.palette.secondary.main,
        margin: '2px 1px',
    }
    if (width) {
        style.width = width
    }
    // if(variant='contained'){
    //     color= 'white'

    // }
    // color = color || 'secondary'
    return (<Button
        variant={variant || 'text'}
        disabled={disabled}
        color='secondary'
        size='small'
        style={style}
        type='button'
        disableElevation={true}
        onClick={onClickFunc}
    // {() => {
    //   // showcaseContext.addTagsToEmptyShowcase(Array.from(spaceTags.keys()));
    //   // spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
    //   handleShowAllSpaceTags();
    // }}
    >
        {icon ? icon : title}
    </Button>)
}