import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { Box, Button, Typography, Tooltip, useMediaQuery, Dialog, IconButton, Divider, Switch, Checkbox } from '@material-ui/core';
import { TagGroup, Lesson } from 'types/models/home/HomeApp';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { BasicProfiler, canEditSpace } from '@crema/utility/Utils';
import { Fonts } from 'shared/constants/AppEnums';
import { grey, red } from '@material-ui/core/colors';
import { setTagsViewMode } from 'redux/actions/Home';
import { setPresentationMode, updateCurrentLesson } from 'redux/actions/Layer';
import { onAddTagGroup, onGetTagGroupsList } from 'redux/actions/Step';
import { onStopLesson } from 'redux/actions/Layer';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { useHistory } from 'react-router-dom';
import { ViewMode } from '..';
import _ from 'lodash';
import Spacer from '@crema/core/Spacer';
import { showMessage } from 'redux/actions';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { store } from 'App';
import { UseCase } from 'shared/constants/AppConst';
import { AuthUser } from 'types/models/AuthUser';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import { addTagsToEmptyShowcase, getCurrentTagGroup } from 'modules/home/SpaceDetail/utils';
import WorkflowsSidebar from '../WorkflowsSidebar';
import Scrollbar from '@crema/core/Scrollbar';
import { VarBox } from '../../ShowcaseOverlay/Variables/VariablesPanel';
import * as Icons from '@mui/icons-material/';
import IconHover from '@crema/core/AppAnimation/ItemHover/IconHover';
import { RichHelpBadge } from '@crema/core/HelpBage';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      // lineHeight: '48px',
      width: '100%',
      // fontSize: '14px'
    },
    fab: {
      '&	.MuiFab-colorInherit': {
        color: 'blue'
      }
    },
    root: {
      width: '100%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    createTagsLink: {
      paddingLeft: theme.spacing(4),
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatar: {
      cursor: 'pointer',
      backgroundColor: grey[100],
      border: `1px dashed ${grey[400]}`,
    },
    pointer: {
      cursor: 'pointer',
    },
    addIconRoot: {
      color: theme.palette.primary.light,
      fontWeight: Fonts.LIGHT,
    },
    btnRoot: {
      backgroundColor: red[500],
      color: theme.palette.primary.contrastText,
      paddingLeft: 24,
      paddingRight: 24,
      marginTop: 12,
      '&:hover, &:focus': {
        backgroundColor: red[800],
        color: theme.palette.primary.contrastText,
      },
    },
    backButton: {
      height: '90%',
      boxShadow: 'none',
      '& .MuiSvgIcon-root': {
        height: '0.75em',
      },
    },
    minimizedContainer: {
      // display: 'flex',
      // flexDirection: 'row',
      minWidth: '0px !important',
      maxWidth: '0px !important',
    },
    regularContainer: {
      // display: 'flex', flexDirection: 'row',
    }
  }),
);

interface Props {
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
}

export default function StepsBar({ setMinimized, isMinimized }: Props) {

  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentSpaceDid = useSelector<AppState, string>(({ home }) => home.currentSpace?.did || '');
  const currentSpaceUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.currentSpace?.primaryUseCase || null);
  const currentSpaceProjectDid = useSelector<AppState, string>(({ home }) => home.currentSpace?.currentSpaceProject?.did || '');
  const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
  const spaceVariables = useSelector<AppState, any[]>(({ home }) => home.spaceVariables);
  const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
  // const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId || '');
  const isWorkflowsSidebarOpen = useSelector<AppState, boolean>(({ layer }) => layer.isWorkflowsSidebarOpen);
  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ layer }) => layer.tagGroupsList);
  const presentationMode = useSelector<AppState, boolean>(({ layer }) => layer.presentationMode);
  const spaceTags = useSelector<AppState, ShowcaseTagMap>(({ home }) => home.spaceTags);
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const [showDefaultVarsPanel, setShowDefaultVarsPanel] = useState(false);

  // const [isMin, setMin] = useState<boolean>(false);

  useEffect(() => {
    // const shouldShowDefaultVarsPanel = () => {
    let shouldShowDefaultVarsPanel = false;
    spaceVariables.forEach(sv => {
      let dv = currentLesson?.defaultVars?.find(v => v.name == sv.name);

      if (!dv || !dv.value) {
        shouldShowDefaultVarsPanel = true;
        console.log(`[vars] default value not found for ${sv?.name}`)
      }

    });
    setShowDefaultVarsPanel(shouldShowDefaultVarsPanel);

    // }
  }, [currentLesson?.defaultVars])

  useEffect(() => {
    currentLesson && dispatch(onGetTagGroupsList(authUser, currentLesson));

    // if (tgs && tgs.length > 0 && !store.getState().layer.currentTagGroupId) {
    //   // if(){
    //   dispatch(setCurrentTagGroupId(tgs[0].id));
    //   // }
    // }
    // return () => {
    //   dispatch({ type: SET_LESSON_DATA, payload: null }); //DUBIOUS
    // };

  }, [currentLesson?.id]);

  const history = useHistory();

  const handleShowAllSpaceTags = () => {

    addTagsToEmptyShowcase({ tagIds: [], tagObjects: Object.values(spaceTags) });
    // dispatch(setCurrentShowcaseTags(Object.values(spaceTags)));
    spaceModels.size > 0 && Simulation.instance.filterVisibleNodes(Array.from(spaceModels.keys()));
    // dispatch(setCurrentTagGroupId(''));
  }

  console.log(`%c[st] rendering Stepsbar `, 'color: orange;');

  function PosesDialog({ showPosesDialog, setShowPosesDialog }: { showPosesDialog: boolean, setShowPosesDialog: (show: boolean) => void }) {

    const [lessonPoseIds, setLessonPoseIds] = useState<string[]>([]);
    const [dirty, setDirty] = useState<boolean>(false);

    useEffect(() => {
      setLessonPoseIds(store.getState().layer.currentLesson?.poseIds || []);
    }, []);

    return (<Dialog
      open={showPosesDialog}
      onClose={() => setShowPosesDialog(false)}
      aria-labelledby='Viewpoints'
      aria-describedby='Add Viewpoints for this lesson here'
    >

      <Box margin={4}>
        <Box id='lesson-viewpoints-header'
          style={{
            textAlign: "center",
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
          }}
        >
          <Typography variant='h6'
          >Set Viewpoints for this {currentSpaceUseCase?.layerTermSingular} here</Typography>

          <Box
            style={{
              float: 'right',
            }}>
            <IconButton onClick={() => setShowPosesDialog(false)}>
              <Icons.Close />
            </IconButton>
          </Box>
        </Box>
        <Scrollbar
        >


          <Box>
            {store.getState().home.currentSpace?.poses.map((p, i) => {
              return (
                <Box key={i} display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                  <Checkbox checked={lessonPoseIds.includes(p.id)} onChange={(e) => {

                    !dirty && setDirty(true);
                    if (e.target.checked) {
                      setLessonPoseIds([...lessonPoseIds, p.id])
                    } else {
                      setLessonPoseIds(lessonPoseIds.filter(id => id != p.id));
                    }
                  }} />
                  <Typography variant='body1'>{p.label}</Typography>
                </Box>)
            })}
          </Box>
        </Scrollbar>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Button disabled={!dirty} color='secondary' variant='contained'
            size="small"
            onClick={() => {
              let l = store.getState().layer.currentLesson;
              l && dispatch(updateCurrentLesson({ ...l, poseIds: lessonPoseIds }));
              setDirty(false);
            }}>
            Save
          </Button>
          <Spacer x={2} />
          <Button color='primary' variant='outlined'
            size="small"
            onClick={() => {
              setShowPosesDialog(false);
            }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
    )

  }

  function StepsHeader() {
    const [showPosesDialog, setShowPosesDialog] = useState(false);
    return (console.log(`%c[st] rendering GetSteps `, 'color: orange;'),
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'row' }}
        // height={'100%'}
        className={presentationMode ? classes.minimizedContainer : classes.regularContainer}>
        <Box style={{
          width: '100%',
          // height: '100%',
        }} maxHeight={matches ? '250px' : '100%'}>
          {/* <Layout */}
          {/* // style={{backgroundColor: 'red'}}
          // > */}

          {/* {isMinimized ?
          <div onClick={() => setMinimized(false)}
            style={{ marginLeft: '-5px', paddingLeft: '1px', cursor: 'pointer ' }}
          ><KeyboardArrowLeftRoundedIcon /></div> : null} */}
          <Spacer y={2} />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingRight: '8px',
              paddingLeft: '8px',
              paddingTop: '8px',
            }}>
            <IconHover dark tooltip="Back" iconProps={{ height: '20px', width: '20px' }}>
              <ArrowBackIosIcon
                onClick={() => {
                  dispatch(onStopLesson());
                  dispatch(setTagsViewMode(ViewMode.LAYERS));
                  handleShowAllSpaceTags();

                  let searchParams = new URLSearchParams(window.location.search);
                  searchParams.delete('mode');
                  let path = `/home/space/${currentSpaceDid}/project/${currentSpaceProjectDid}/layers`;

                  history.push({ pathname: path, search: searchParams.toString() });
                }}
              />
            </IconHover>

            <Typography
              variant='h6'
              align='center'
              className={classes.heading}
              style={{ color: 'black', padding: 5 }}
              gutterBottom
            >
              {currentLesson?.name}
            </Typography>
          </Box>

          <Spacer y={2} />
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-start" }}>
            <Divider orientation='horizontal' />
            <Spacer y={2} />
            {canEditSpace() && (

              <div id='steps-toolbar-container' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', paddingLeft: '8px', paddingRight: '8px' }}
              >
                <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                  <Tooltip title="Add Step">
                    <Button variant='outlined'
                      style={{ height: '30px', margin: '4px' }}
                      onClick={() => {
                        onAddTagGroup(null);
                        dispatch(showMessage("Step added as the last step. Scroll down to view..."));
                      }}>
                      Add Step
                    </Button>
                  </Tooltip>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <IconHover dark tooltip="Start Presentation" iconProps={{ color: theme.palette.grey.A400 }}>
                    <Icons.PlayCircleOutlineRounded onClick={() => {

                      let searchParams = new URLSearchParams(window.location.search);
                      searchParams.set('mode', 'present');

                      let path = `/home/space/${currentSpaceDid}/project/${currentSpaceProjectDid}/layers/${currentLesson?.did}`;
                      history.replace({ pathname: path, search: searchParams.toString() });
                      dispatch(setPresentationMode(true));
                    }}
                    />
                  </IconHover>

                  <IconHover dark tooltip="Default Variables" >
                    <Icons.DataArray onClick={() => setShowDefaultVarsPanel(true)}
                    />
                  </IconHover>
                  <IconHover dark tooltip="See All Tags & 3D" >
                    <Icons.Visibility fontSize='small' onClick={() => {
                      handleShowAllSpaceTags();
                    }}
                    />
                  </IconHover>

                  <OverflowMenu containerStyles={{ marginBottom: '4px' }}
                    menuItems={[{
                      title: 'Viewpoints', onClick: () => {
                        setShowPosesDialog(true);
                      }
                    }]} />
                </div>
              </div>
            )}
            <Spacer y={1} />

          </Box>

          {showPosesDialog && <PosesDialog showPosesDialog={showPosesDialog} setShowPosesDialog={setShowPosesDialog} />}
          {/*
            {tagGroupsList.length >= 2 && <div style={{ display: "block", textAlign: 'center', }}>
              <Tooltip title="Add Step">
                <Fab size="small"
                  aria-label="add"
                  onClick={() => {
                    onAddTagGroup(null);
                    dispatch(showMessage("Step added as the last step"));
                  }}
                >
                  <AddIcon style={{ color: theme.palette.secondary.main }}
                  />
                </Fab>
              </Tooltip>
            </div>} */}

          {/* </Layout> */}
        </Box>

      </Box>
    );
  }

  if (isWorkflowsSidebarOpen) {
    let tg = getCurrentTagGroup();
    return (currentSpaceId && currentLesson && tg) ?
      <WorkflowsSidebar
        // currentSpace={currentSpace} currentLesson={currentLesson}
        tagGroupx={tg}
      />
      : null

  } else {
    // if (tagsViewModeSecondary == ViewModeSecondary.LAYERS_PROP) return <PropPanelInLessons />;
    // else
    return <>
      {!presentationMode && <><Dialog
        open={showDefaultVarsPanel && !presentationMode}
        onClose={() => setShowDefaultVarsPanel(false)}
        aria-labelledby='variables'
        // TransitionComponent={Transition}
        aria-describedby='Add your system variables here'
      >

        <Box display='flex' justifyContent='flex-end'>
          <IconButton onClick={() => setShowDefaultVarsPanel(false)}>
            <Icons.Close />
          </IconButton>
        </Box>

        <Scrollbar
        >
          <Box padding={4}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant='h6'
            >Set Initial values for this {currentSpaceUseCase?.layerTermSingular}</Typography>
            <Box display="flex" alignItems="center">

              <RichHelpBadge
                title={'Show the Variable Panel to the viewer for this lesson?'}
                content={[{
                  text: "Variable Panel is the box that appears at the bottom right that shows variable values during the lesson. It also allows your viewer to change the values.",
                  imgSrc: '/assets/images/help/VarPanelBox.png'
                },
                {
                  text: "You need to individually select the variables that should be shown in the panel below",
                  imgSrc: '/assets/images/help/SelectVars.png'
                }
                ]}
              >
                {/* <Typography variant='caption'>
                  <b>You need to individually select the variables that should be shown in the panel below</b>
                </Typography>
                <Image src='/assets/images/help/SelectVars.png' alt="select variables"/> */}
              </RichHelpBadge>
              <Box id='show-var-panel-for-lesson'>
                Show Variables Panel for this lesson?
              </Box>

              <Switch checked={!currentLesson?.hideVariablesPanel} onChange={() => {
                currentLesson && dispatch(updateCurrentLesson({ ...currentLesson, hideVariablesPanel: !currentLesson?.hideVariablesPanel } as Lesson))
              }} />
            </Box>
            {/* </Tooltip> */}
            <VarBox isDefaultVarsUI={true} lessonDefaultVars={currentLesson?.defaultVars || []} variables={store.getState().home.spaceVariables}
              onValueChange={(varName: string, selectedValue: any) => {

                if (currentLesson) {
                  let bp = new BasicProfiler();
                  let l = _.cloneDeep(currentLesson);
                  bp.lap('[clone] lesson in varbox valuechange');

                  let vi = (l.defaultVars || []).findIndex((v: any) => v.name == varName);
                  if (vi > -1 && l.defaultVars) { // if (inlessonvars) => update value
                    l.defaultVars[vi].value = selectedValue;
                    dispatch(showMessage("Default Value updated!"));
                    dispatch(onStopLesson());
                    dispatch(updateCurrentLesson(l));
                  } else {

                    console.log(`[vars] adding ${varName} -- ${selectedValue} as DV to lesson`)
                    let sv = store.getState().home.spaceVariables.find(v => v.name == varName);
                    sv.value = selectedValue;

                    l.defaultVars = (l.defaultVars || []).concat(sv);
                    dispatch(showMessage("Default Value set!"));
                    dispatch(onStopLesson());
                    dispatch(updateCurrentLesson(l));
                  }
                }
              }} />
          </Box>
        </Scrollbar>
      </Dialog>
        <StepsHeader />
      </>}
    </>

  }
}