import React, { useState } from "react";
import { Checkbox, Box, Tooltip } from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ActionWrapper, StyledListItem, ModelInfoWrapper } from "./index.style";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import NodeStorage from 'mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { firestore } from "@crema/services/auth/firebase/firebase";
import { getCurrentTagGroup, isR3F } from "modules/home/SpaceDetail/utils";
import { useSelector } from "react-redux";
import { setTagGroupsList } from "redux/actions/Step";
import { AppState } from "redux/store";
import { ViewModeSecondary } from "../..";
import * as Icons from '@mui/icons-material/';
import { TagGroup } from "types/models/home/HomeApp";
import { store } from 'App';
import { canEditSpace } from '@crema/utility/Utils';
import modelMap from "modules/home/models";
import Spacer from "@crema/core/Spacer";
import GLTFModelObjects from "./GLTFModelObjects";
import { SDKInstance } from "modules/fiber/Sdk";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    listItem: {
      padding: 0,
      // paddingLeft: '2px',
      // paddingRight: '2px',
      fontSize: '12px',
      // paddingTop: '8px',
      // paddingBottom: '8px',
      // borderRadius: '16px',
      // marginBottom: '8px',
      backgroundColor: theme.palette.background.paper,
      '& .MuiListItem-root': {
        padding: 0,
      },
      // width: '300px',
    },
  }),
);
interface ModelListItemProps {
  model: any;
  onChangeCheckedModels: (event: React.ChangeEvent<HTMLInputElement>, modelId: string) => void;
  checkedModels: string[];
  onChangeStarred: (checked: boolean, model: any) => void;
  onDeleteModel: (model: any) => void;
}

const ModelListItem: React.FC<ModelListItemProps> = ({
  model,
  onChangeCheckedModels,
  checkedModels,
  onChangeStarred,
  onDeleteModel,
}) => {

  const classes = useStyles();
  const tagsViewModeSecondary = useSelector<AppState, string>(({ home }) => home.tagsViewModeSecondary);


  const onViewModelDetail = (model: any) => {
    Simulation.instance.handleModelClick(model.id);
  };


  function isObjInStep(model: any): boolean {
    return store.getState().layer.currentTagGroupId &&
      store.getState().layer.tagGroupsList &&
      store.getState().layer.tagGroupsList.length > 0 &&
      (store.getState().layer.tagGroupsList
        .filter(
          (tagGroup: TagGroup) =>
            tagGroup.id === store.getState().layer.currentTagGroupId,
        )[0]
        ?.tagIds || []).includes(model.id)
      ? true
      : false
  }

  const onDeleteModelHandler = (nodeId: string, text: string) => {
    const con = window.confirm(`Are you sure you want to delete ${text}?`);
    if (con) {
      NodeStorage.deleteNodeById(nodeId);
    }
  }

  const [openGLTFObjects, setOpenGLTFObject] = useState(false);
  const currentNode = SDKInstance.sceneNodes.get(model.id);

  return (
    <>
      <StyledListItem
        dense
        key={model.id}
        className={clsx("item-hover", classes.listItem, {
          checked: checkedModels.includes(model.id),
        })}
        onClick={() => {
          onViewModelDetail(model)
          console.log("The model being clicked is: ", store.getState().home.spaceModels.get(model.id));
          setOpenGLTFObject(open => !open);
        }}
      >
        <ModelInfoWrapper>
          <Box width={"100%"}
            style={{ display: 'flex', alignItems: 'center' }}
            className={`hover-to-show-link`}>
            {canEditSpace() && (tagsViewModeSecondary !== ViewModeSecondary.LAYERS_3D) && <Box className={`${!checkedModels.includes(model.id) ? 'hover-to-show' : ''}`}>
              <span onClick={(event) => event.stopPropagation()}>
                <Checkbox
                  style={{
                    color: "text.disabled",
                  }}
                  checked={checkedModels.includes(model.id)}
                  onChange={(event) => onChangeCheckedModels(event, model.id)}
                  color="primary"
                />
              </span>
            </Box>}
            {tagsViewModeSecondary == ViewModeSecondary.LAYERS_3D && <Tooltip title={isObjInStep(model) ? 'Remove from Step' : 'Add to Step'}>
              <Checkbox
                edge='start'
                icon={<Icons.RadioButtonUncheckedRounded fontSize="medium" />}
                checkedIcon={<Icons.CheckCircleRounded fontSize="medium" />}
                checked={isObjInStep(model)
                }
                tabIndex={-1}
                onChange={async () => {
                  let currentTagGroup = getCurrentTagGroup();
                  let tgs: TagGroup[] = [];
                  if (!currentTagGroup) {
                    return;
                  }
                  if ((currentTagGroup?.tagIds || []).includes(model.id)) {
                    // tagGroupsList =
                    // // currentTagGroup?.tagIds = currentTagGroup?.tagIds.filter((tagId: string) => tagId !== showcaseTag.id);
                    // tagGroupsList.map(((tagGroup: TagGroup) => tagGroup.id == currentTagGroup.id && tagGroup?.tagIds.splice(
                    //   tagGroup?.tagIds.indexOf(showcaseTag.id)
                    // )))

                    //remove model from tagGroup
                    store.getState().layer.tagGroupsList.forEach((tagGroup: TagGroup) => {
                      if (tagGroup.id == store.getState().layer.currentTagGroupId) {
                        tagGroup.tagIds = (tagGroup?.tagIds || []).filter(
                          (tid) => tid != model.id,
                        );
                        currentTagGroup = tagGroup;
                      }
                      tgs.push(tagGroup);
                    });
                  } else {

                    //add model to tagGroup
                    store.getState().layer.tagGroupsList.forEach((tagGroup) => {
                      if (tagGroup.id == store.getState().layer.currentTagGroupId) {
                        (tagGroup?.tagIds || []).push(model.id);
                        currentTagGroup = tagGroup;
                      }
                      tgs.push(tagGroup);
                    });
                  }
                  store.dispatch(setTagGroupsList(tgs));

                  await firestore
                    .doc(
                      `Spaces/${store.getState().home.currentSpace?.id}/lessons/${store.getState().layer?.currentLesson?.id}/tagGroups/${currentTagGroup.id}`,
                    )
                    .set(currentTagGroup);
                }}
                disableRipple
                inputProps={{ 'aria-labelledby': model.nameToShow }}
              /></Tooltip>}
            <Box
              // component="p"
              style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                marginRight: 3,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: "13px",
                // marginTop: '0.85rem',
                width: '100%',

              }}
            >
              {model.icon && <model.icon />}
              {/* <Spacer x={4} /> */}
              <p style={{ marginLeft: '4px' }}>{model.nameToShow}</p>
            </Box>
          </Box>
        </ModelInfoWrapper>
        {canEditSpace() && <ActionWrapper>
          <Box className="labelAction">
            {/* <LabelOutlinedIcon onClick={(e) => e.stopPropagation()} /> */}
            <DeleteOutlinedIcon
              onClick={(e) => {
                if (model.id && model.nameToShow) {
                  onDeleteModelHandler(model.id, model.nameToShow);
                }
                e.stopPropagation();
              }}
            />
          </Box>
        </ActionWrapper>}
      </StyledListItem>

      {currentNode && isR3F() && openGLTFObjects &&
        // currentNode.userData.objectHierarchy &&
        <GLTFModelObjects
          node={model}
          // open={openGLTFObjects}
           />}
    </>

  );
};

export default ModelListItem;
