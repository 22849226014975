import { SpatialThinkSDKMode, StSdk } from "CustomSdk/SpatialThinkSDK";
import { ObjectAnimation } from 'types/models/threeD'
import { ExternalSdkMode } from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import { NodeProperties } from "types/models/home/HomeApp";
import { MeModelList, MeModelResponse } from "types/models/home/ThirdParty/Sketchfab";
import { MpSdk } from "types/SdkTypes2";

export const OBJECT_PLACED = 'OBJECT_PLACED';
export const SHOW_PROP_PANEL = 'SHOW_PROP_PANEL';
export const SHOW_POSES = 'SHOW_POSES';
export const SET_SELECTED_NODE_ID = 'SET_SELECTED_NODE';
export const SHOW_GIZMO = 'SHOW_GIZMO';
export const SET_MY_SKETCHFAB_MODELS = 'SET_MY_SKETCHFAB_MODELS';
export const SELECT_NODE = 'SELECT_NODE';
export const SET_SDK_MODE = 'SET_SDK_MODE';
export const SET_SDK = 'SET_SDK';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const SET_HIDDEN_NODE_IDS = 'SET_HIDDEN_NODE_IDS';
export const DISABLE_COLLIDERS_ON = 'DISABLE_COLLIDERS_ON';
export const ENABLE_COLLIDERS_ON = 'ENABLE_COLLIDERS_ON';
export const SET_ANIMATIONS = 'SET_ANIMATIONS';
export interface ObjectPlacedAction {
  type: typeof OBJECT_PLACED;
  payload: string;
}

export interface ShowPropPanelAction {
  type: typeof SHOW_PROP_PANEL;
  payload: boolean;
}

export interface ShowPosesAction {
  type: typeof SHOW_POSES;
  payload: boolean;
}

export interface SetSelectedNodeIdAction {
  type: typeof SET_SELECTED_NODE_ID;
  payload: string;
}
export interface SetShowGizmoAction {
  type: typeof SHOW_GIZMO;
  payload: boolean;
}
export interface SetMySketchfabModelsAction {
  type: typeof SET_MY_SKETCHFAB_MODELS;
  payload: MeModelResponse;
}

export interface SetSelectedNodeAction {
  type: typeof SELECT_NODE;
  payload: NodeProperties | undefined;
}

export interface SetSDKModeAction {
  type: typeof SET_SDK_MODE;
  payload: SpatialThinkSDKMode | ExternalSdkMode | undefined;
}

export interface SetSDKAction {
  type: typeof SET_SDK;
  payload: MpSdk | StSdk | undefined;
}

export interface SetR3FComponentAction{
  type: typeof ADD_COMPONENT;
  payload: string;
}

export interface SetHiddenNodeIdsAction{
  type: typeof SET_HIDDEN_NODE_IDS;
  payload: string[];
}

export interface DisableCollidersOnAction{
  type: typeof DISABLE_COLLIDERS_ON;
  payload: string[];
}

export interface EnableCollidersOnAction{
  type: typeof ENABLE_COLLIDERS_ON;
  payload: string[];
}

export interface SetAnimationsAction{
  type: typeof SET_ANIMATIONS;
  payload: ObjectAnimation[];
}

export type ThreeDActions =
  | ObjectPlacedAction
  | ShowPropPanelAction
  | ShowPosesAction
  | SetSelectedNodeIdAction
  | SetShowGizmoAction
  | SetMySketchfabModelsAction
  | SetSelectedNodeAction
  | SetSDKModeAction
  | SetSDKAction
  | SetR3FComponentAction
  | SetHiddenNodeIdsAction
  | DisableCollidersOnAction
  | EnableCollidersOnAction
  | SetAnimationsAction
  ;