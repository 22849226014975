import * as THREE from "three"
import { EulerIntegrator } from "../../components/systemComponents/EulerIntegrator";
import { CraSubSystem } from "../CraSubSystem";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import CardinalAxesAndPlanes from "../../Tools/CardinalAxesAndPlanes";
import { isST } from "modules/home/SpaceDetail/utils";

export class SimpleCamera {
    private _position: THREE.Vector3;
    private craSubSystem: CraSubSystem;
    private camera: THREE.PerspectiveCamera;

    private cameraPose: any;
    private navigationTagSID: string;

    public get Camera(): THREE.PerspectiveCamera {
        return this.camera;
    }

    constructor() {
        this._position = new THREE.Vector3();
    }
    public async initialize(renderingSubSystem: CraSubSystem) {

        if (!EulerIntegrator.instance) {
            return

        }
        this.camera = EulerIntegrator.instance!.context.camera as THREE.PerspectiveCamera;
        //this.camera.fov = 50;
        //this.camera.updateProjectionMatrix();

        this.craSubSystem = renderingSubSystem;

        //EulerIntegrator.instance!.integrators.push(this.simulate.bind(this));
        await this.setupCameraPoseSubscription();

        //await this.setupCameraMoveSubscription();
    }

    private async setupCameraMoveSubscription() {
        await this.craSubSystem.sdk().Sweep.current.subscribe((currentSweep: any) => {
            // Change to the current sweep has occurred.
            if (currentSweep.sid === '') {
                console.log('Not currently stationed at a sweep position');
            } else {
                console.log('Currently at swedep', currentSweep.sid);
                console.log('Current position', currentSweep.position);
                console.log('On floor', currentSweep.floorInfo.sequence);
            }
        });
    }

    public async NavigateToNode(node: ISceneNode, callback: (() => void) | null = null): Promise<void> {
        if (!isST()) {
            // await this.NavigateToPosition(node.position.clone(), node.userData?.floorIndex, callback);
            this.LookAtNode(node, callback);
        }
        else { //TODO-ST
            //look at SceneNode
        }
    }

    public async NavigateToPosition(coords: THREE.Vector3, floorIndex?: number, callback: (() => void) | null = null): Promise<void> {
        // await this.craSubSystem.sdk().Mattertag.editPosition(this.navigationTagSID[0], {
        //     anchorPosition: {
        //       x: coords.x,
        //       y: coords.y,
        //       z: coords.z,
        //     }
        // })
        try {
            floorIndex = (floorIndex && floorIndex != -1) ? floorIndex : (await this.craSubSystem.sdk().Floor.getData()).currentFloor;
        } catch (e) { console.error("error getting floor " + e) }

        this.craSubSystem.sdk().Mattertag.add(
            {
                label: 'Dummy Tag',
                description: '',
                anchorPosition: { x: coords.x, y: coords.y, z: coords.z, },
                stemVector: { x: 0, y: 0, z: 0 },
                stemVisible: true,
                color: { r: 1, g: 0, b: 0 },
                floorIndex: floorIndex,
                stemHeight: 0.1,
            },
        )
            .then(async (sids: string[]) => {
                let sid = sids[0];
                console.log(`[st] nav to tag 4`);
                this.craSubSystem.sdk().Mattertag.getData().then((data: any) => {


                    this.craSubSystem.sdk().Mattertag.navigateToTag(sid, this.craSubSystem.sdk().Mattertag.Transition.FLY).
                        then(async () => {
                            if (callback) {
                                callback();
                            }

                            this.craSubSystem.sdk().Mattertag.remove(sid).catch(console.error);
                        }).catch((e: any) => {
                            console.error(e);
                        });
                }).catch((e: any) => {
                    console.error(e);
                });
                ;
            })
            .catch((e: any) => {
                console.error(e);
            });
    }


    private simulate(dt: Number): void {

        if (this.camera) {
            let tempObj = EulerIntegrator.instance!.pivot;
        }
        //this.camera.position.x += 0.01;
    }

    public LookAtNode(node: ISceneNode, callback: (() => void) | null = null) {

        if (!node) {
            return;
        }

        this.LookAtXYZ(node.position.clone().sub(this.Position), callback);
    }

    public LookAtXYZ(coords: THREE.Vector3, callback: (() => void) | null = null) {

        let view = new THREE.Vector3(0, 0, -1);
        this.camera.getWorldDirection(view);
        let goalView = coords;
        goalView.normalize();

        let projectedViewOnXZ = view.clone();
        projectedViewOnXZ.y = 0;
        projectedViewOnXZ.normalize();

        let projectedGoalViewOnXZ = goalView.clone();
        projectedGoalViewOnXZ.y = 0;
        projectedGoalViewOnXZ.normalize();

        let projectedXZ_normal = new THREE.Vector3();
        projectedXZ_normal.crossVectors(projectedGoalViewOnXZ, projectedViewOnXZ).normalize();

        let sign = projectedXZ_normal.dot(CardinalAxesAndPlanes.instance.yAxis);
        let radiansToRotateAroundYAxis = sign * Math.acos(projectedViewOnXZ.dot(projectedGoalViewOnXZ));
        let degressToRotateAroundYAxis = radiansToRotateAroundYAxis * THREE.MathUtils.RAD2DEG;

        let rotatedGoalView = goalView.applyAxisAngle(CardinalAxesAndPlanes.instance.yAxis, radiansToRotateAroundYAxis);

        let normalToGoalPlane = new THREE.Vector3();
        normalToGoalPlane.crossVectors(view, rotatedGoalView);
        normalToGoalPlane.normalize();
        let goalAngle = view.angleTo(rotatedGoalView);

        let localCameraBasis_xAxis = new THREE.Vector3();
        let localCameraBasis_yAxis = new THREE.Vector3();
        let localCameraBasis_zAxis = new THREE.Vector3();

        this.camera.matrix.extractBasis(localCameraBasis_xAxis, localCameraBasis_yAxis, localCameraBasis_zAxis);

        let signOfGoalPlaneAngle = normalToGoalPlane.dot(localCameraBasis_xAxis);
        let degreesToRotateOnLocalStrafeAxis = Math.sign(signOfGoalPlaneAngle) * goalAngle * THREE.MathUtils.RAD2DEG;


        this.craSubSystem.sdk().Camera.rotate(degressToRotateAroundYAxis, degreesToRotateOnLocalStrafeAxis, { speed: 180 })
            .then(function () {
                // Camera rotation complete.
                if (callback) {
                    callback();
                }
            })
            .catch(function (error: any) {
                // Camera rotation error.
                console.error(error)
            });
    }

    /*
     */
    public getScreenCoordsOfPointWithoutMatterPortSDK(obj: THREE.Object3D | THREE.Vector3, displace: THREE.Vector3 | null = null): THREE.Vector3 {
        var vector = new THREE.Vector3();

        var showcase = document.getElementById('sdk-iframe');
        var showcaseSize = {
            w: showcase!.clientWidth,
            h: showcase!.clientHeight,
        };

        var box = { left: 0, top: 0 };
        try {
            box = showcase!.getBoundingClientRect();
        }
        catch (e) { }

        var widthHalf = 0.5 * showcaseSize.w;
        var heightHalf = 0.5 * showcaseSize.h;

        if ((obj as any).hasOwnProperty('type')) {
            vector.setFromMatrixPosition((obj as THREE.Object3D).matrixWorld);
        } else {
            //let newVec = new THREE.Vector3((obj as THREE.Vector3).x, (obj as THREE.Vector3).y, (obj as THREE.Vector3).z);
            vector.set((obj as THREE.Vector3).x, (obj as THREE.Vector3).y, (obj as THREE.Vector3).z);
            /*
            console.log(newVec)
            EulerIntegrator.instance!.pivot!.position.x = newVec.x;
            EulerIntegrator.instance!.pivot!.position.y = newVec.y;
            EulerIntegrator.instance!.pivot!.position.z = newVec.z;
            EulerIntegrator.instance!.pivot!.updateMatrix();
            EulerIntegrator.instance!.pivot!.updateMatrixWorld();
            vector.setFromMatrixPosition(EulerIntegrator.instance!.pivot!.matrixWorld);
            console.log(vector)*/
        }

        if (displace) {
            vector.add(displace);
        }
        vector.project(this.camera);
        vector.x = vector.x * widthHalf + widthHalf;// - box.left - showcase!.clientLeft;
        vector.y = -(vector.y * heightHalf) + heightHalf;// + box.top + showcase!.clientTop;
        return vector;

    }

    public getScreenCoordsOfPoint(p: THREE.Vector3): any {
        var showcase = document.getElementById('sdk-iframe');
        var showcaseSize = {
            w: showcase!.clientWidth,
            h: showcase!.clientHeight,
        };

        var box = { left: 0, top: 0 };
        try {
            box = showcase!.getBoundingClientRect();
        }
        catch (e) { }

        console.log(box)
        var screenCoordinate = this.craSubSystem.sdk().Conversion.worldToScreen(p, this.cameraPose, showcaseSize)
        screenCoordinate.x = Math.abs(screenCoordinate.x);// - box.left - showcase!.clientLeft;
        screenCoordinate.y = Math.abs(screenCoordinate.y);// + box.top + showcase!.clientTop;
        return screenCoordinate;
    }

    public isPointInFOV(point: THREE.Vector3): boolean {
        this.camera.updateMatrix();
        this.camera.updateMatrixWorld();
        var frustum = new THREE.Frustum();

        frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(this.camera.projectionMatrix, this.camera.matrixWorldInverse));

        if (frustum.containsPoint(point)) {
            return true;
        }

        return false;
    }

    private async setupCameraPoseSubscription() {
        await this.craSubSystem.sdk().Camera.pose.subscribe((pose: any) => {
            // Changes to the Camera pose have occurred.
            this._position.set(
                pose.position.x,
                pose.position.y,
                pose.position.z,
            );
            this.cameraPose = pose;
        });
    }

    public get Position(): THREE.Vector3 {
        return this._position;
    }
}
