import { store } from "App";
import { SceneNode } from "CustomSdk/Mimick/SceneNode";
import { getCurrentTagGroup, isR3F } from "modules/home/SpaceDetail/utils";
import { Behaviors } from "mp/core/craEngine/SubSystems/core/Behaviors";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import { ActionType, TriggerActionOutcome } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions";
import { onSetVariableValue, showMessage } from "redux/actions";
import { handleGoToTagGroupByIndex } from "redux/actions/Step";
import { TagGroup } from "../home/HomeApp";

export interface Logic {
    conditions: ConditionTree[];
}
export interface ConditionTree {
    id?: string, // Identify Condition Tree
    conditionRoot: NestedCondition;
    actions: Action[];
}
export enum CONDITION_TYPE {
    VARIABLE_CONDITION = "Variable Condition",
    OBJECT_CONDITION = "Object Condition",
    NESTED_TREE = "Nested Tree"
}

export abstract class Condition {
    type: CONDITION_TYPE;
    toString() {
        return JSON.stringify(this)
        // return `Action: ${this.actionType} for $ {this.objectId} ${this.iotVariableName ? (', iot var ' + this.iotVariableName) : ''} `
    }
}
export interface VarCondition extends Condition {
    varName: string;
    varValue: string;
}
export interface ObjectCondition extends Condition {
    objectId?: string;
    objectId2?: string;
    subObject1Names?: string[];
    subObject2Names?: string[];
    objectEventType: OBJECT_EVENT_TYPES;
}

export interface TreeCondition extends Condition {
    nestedCondition: NestedCondition
}

export enum OBJECT_EVENT_TYPES {
    CLICK = "Click",
    COLLIDES = "Collides",
    HOVER = "Hover - coming soon"
}
export interface NestedCondition {
    conditionJoiner: CONDITION_JOINER;
    conditions: Condition[];
}

export abstract class Action {
    type: ACTION_TYPE;
    toString() {
        return JSON.stringify(this)
        // return `Action: ${this.actionType} for ${this.objectId} ${this.iotVariableName ? (', iot var ' + this.iotVariableName) : ''} `
    }
}

export enum ACTION_TYPE {
    VARIABLE_ACTION = "Variable action",
    OBJECT_ACTION = "Object action",
    NEXTSTEP_ACTION = "NextStep action",
    WAIT_ACTION = "Wait Action"
}
export class ObjectAction extends Action {
    actionType: OBJECT_ACTIONS;
    objectId: string;
    iotVariableName?: string;
    parameters?: string;
    subObjectNames?: string[];
}

export class VarAction extends Action {
    varName: string;
    varValue: string;
}
export class NextStepAction extends Action {
    currentTagGroupId: string;
    waitInSeconds: number;
}
export enum CONDITION_JOINER_LEGACY { ALL = "All" }
export enum CONDITION_JOINER { ALL = "And", ANY = "Or", NONE = "None" }
export enum OBJECT_ACTIONS {
    HIGHLIGHT = "Highlight", GROW_SHRINK = "Animate", SHOW = "Show", HIDE = "Hide", MOVE = "Move", ROTATE = "Rotate", SCALE = "Scale",
    // CALL_ACTIVATE = "Show values on Digital Display"
}

export const conditionsTest = async () => {

    let c1: VarCondition = { varName: "Material", varValue: "Copper", type: CONDITION_TYPE.VARIABLE_CONDITION };
    let c2: VarCondition = { varName: "Diameter", varValue: "1/2 inch", type: CONDITION_TYPE.VARIABLE_CONDITION };

    let orCondition: NestedCondition = { conditions: [c1, c2], conditionJoiner: CONDITION_JOINER.ALL }

    let a1: ObjectAction = { actionType: OBJECT_ACTIONS.SHOW, objectId: "someId", type: ACTION_TYPE.OBJECT_ACTION };
    let a2: VarAction = { varName: "System Pressure", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION };
    let a3: VarAction = { varName: "Lever State", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION };

    let mainCondition1Nested: ConditionTree = { conditionRoot: orCondition, actions: [a1, a2] };

    let logic: Logic = {
        conditions: [
            mainCondition1Nested
        ]
    }
    console.log(`%c[st] logic`, logic);
}

export class LogicEngine {
    public static async onVariablesStateChange(tg: TagGroup,
        /*allModels: Map<string, any> = Simulation.instance.spaceModels(),*/
    ): Promise<void> {

        // if(currentSpaceId) {
        //     if(currentLessonId && currentTagGroupId) {
        //         let docRef = await firestore.doc(`Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);
        //         let doc = await docRef.get();
        //         let data = await doc.data();

        //         if(data ) {


        if (tg.logic) {
            LogicEngine.processStepLogicConditions(
                { tgId: tg.id, stepLogic: tg.logic as Logic }
            );
        }
        //         }
        //     }
        // }
        /*
        allModels.forEach((model: any) => {
            let targetNode: ISceneNode =  model.nodeRef;//
            if(targetNode){
                //LogicEngine.processSceneNodeLogicConditions(targetNode, false, false, Simulation.instance.spaceModels(), variableValues);
            }
        });*/
    }

    public static evaluateNestedCondition({
        nestedCondition,
        targetNode,
        // clickEvent: boolean = false, hoverEvent: boolean = false,
        eventType = null,
        allModels = Simulation.instance.spaceModels(),
        variableValues = store.getState().layer.variableValues,
        targetNodeList = []
    }: {
        nestedCondition: NestedCondition,
        targetNode: ISceneNode | SceneNode | null,
        // clickEvent: boolean = false, hoverEvent: boolean = false,
        eventType: OBJECT_EVENT_TYPES | null,
        allModels: Map<string, any>,
        variableValues: any[] | undefined,
        targetNodeList: string[]
    },
    ): boolean {
        let conditionMet: boolean = false;

        if (!nestedCondition.conditions || nestedCondition.conditions.length == 0) {
            return true;
        }
        if (nestedCondition.conditionJoiner === CONDITION_JOINER.ALL || (nestedCondition.conditionJoiner as string) === 'All') {
            nestedCondition.conditionJoiner = CONDITION_JOINER.ALL;

            if (nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (!LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList
                        }
                    )) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.ANY) {
            if (nestedCondition.conditions) {
                conditionMet = false;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList
                        }
                        // clickEvent, hoverEvent
                    )) {
                        conditionMet = true;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.NONE) {
            if (nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList
                        }
                        // clickEvent, hoverEvent
                    )) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        }

        return conditionMet;
    }

    public static processStepLogicConditions({
        tgId,
        stepLogic,
        targetNode = null,
        targetNodeList = [],
        eventType = null,
        allModels = Simulation.instance.spaceModels(),
        variableValues = store.getState().layer.variableValues,
    }: {
        tgId: string,
        stepLogic: Logic,
        targetNode?: ISceneNode | SceneNode | null,
        targetNodeList?: string[],
        eventType?: OBJECT_EVENT_TYPES | null,
        // clickEvent: boolean = false, hoverEvent: boolean = false, collideEvent = false,
        allModels?: Map<string, any>,
        variableValues?: any[] | undefined,
    }
    ): void {

        //  if (getCurrentTagGroup()?.id !== tgId) { //TODO commenting this out -> might not be right
        //      return;
        //  }

        if (stepLogic?.conditions) {
            // console.trace();
            let conditionTree = stepLogic.conditions as ConditionTree[];
            conditionTree.forEach((condition: ConditionTree) => {

                //performActions(condition.actions);
                let nestedCondition = condition.conditionRoot as NestedCondition;
                console.log(`%c[vars] Checking condition:`, 'color: grey;', nestedCondition);
                if (LogicEngine.evaluateNestedCondition({
                    nestedCondition: nestedCondition,
                    targetNode: targetNode,
                    eventType: eventType,
                    allModels: allModels,
                    variableValues: variableValues,
                    targetNodeList: targetNodeList
                })) {
                    //Perform actions
                    console.log(`%c[vars] Performing actions`, 'color: grey;', condition.actions);
                    LogicEngine.performActions(tgId, condition.actions, variableValues, allModels);
                } else {
                    // LogicEngine.performUnActions(tgId, condition.actions, variableValues, allModels);
                }
            });
        }
    }

    public static checkCondition({ targetNode, allModels, variableValues, condition, eventType = null,
        targetNodeList }:
        {
            targetNode: ISceneNode | SceneNode | null,
            allModels: Map<string, any>,
            variableValues: any[] | undefined,
            condition: Condition,
            eventType: OBJECT_EVENT_TYPES | null,
            targetNodeList: string[],
        },
    ): boolean {
        if (condition.type === CONDITION_TYPE.OBJECT_CONDITION) {
            let objectCondition = (condition as ObjectCondition);


            if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.CLICK) {
                if (targetNode) {
                    if (eventType == OBJECT_EVENT_TYPES.CLICK) {
                        if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                            return true;
                        }
                    }
                }
            } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.HOVER) {
                if (targetNode) {
                    if (eventType == OBJECT_EVENT_TYPES.HOVER) {
                        if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                            return true;
                        }
                    }
                }
            } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.COLLIDES) {
                if (targetNodeList.length > 0) {
                    if (eventType == OBJECT_EVENT_TYPES.COLLIDES) {
                        if (objectCondition.objectId && objectCondition.objectId2 &&
                            targetNodeList.includes(objectCondition.objectId)
                            && targetNodeList.includes(objectCondition.objectId2)) {
                            return true;
                        }
                    }

                }
            }
        } else if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
            // console.log(`%cchecking var condition `, 'color: grey;');
            let variableCondition = (condition as VarCondition);
            if (variableCondition.varName) {
                let relevantSystemVariable = variableValues?.find(vv => vv.name.toLowerCase() === variableCondition.varName.toLowerCase());

                console.log(`%c[vars] for var condition`, 'color: grey;', variableCondition)
                if (relevantSystemVariable) {
                    if (relevantSystemVariable.hasOwnProperty("value")) {
                        if ((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
                            if (relevantSystemVariable.value === variableCondition.varValue) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
            let nestedTreeCondition = (condition as TreeCondition);

            let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;
            return LogicEngine.evaluateNestedCondition({
                nestedCondition: nestedCondition, targetNode: targetNode,
                eventType: eventType, allModels: allModels, variableValues: variableValues, targetNodeList: targetNodeList
            });
            // clickEvent, hoverEvent,

        }

        return false;
    }

    public static performUnActions(tgId: string, actions: Action[], variableValues: any[] | undefined, allModels: Map<string, any>): void {
        // if (getCurrentTagGroup()?.id !== tgId) {
        //     return;
        // }
        // console.log(`%c[vars] performing actions ${targetNode.userData?.nameToShow} -- ${JSON.stringify(actionOutcome)}`)
        actions.forEach(action => {
            if (action.type === ACTION_TYPE.OBJECT_ACTION) {
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };

                switch (objectAction.actionType) {
                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    // case OBJECT_ACTIONS.CALL_ACTIVATE:
                    //     triggerActionOutcome.actionType = ActionType.CallActivate;
                    //     break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if (model) {
                    let localNodeForAction = model.nodeRef as ISceneNode;
                    // if (objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                    //     localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    // }
                    Behaviors.runUnActionsOnNode(localNodeForAction, triggerActionOutcome);
                }
            }
        });
    }

    public static performActions(tgId: string, actions: Action[], variableValues: any[] | undefined, allModels: Map<string, any>): void {
        // if (getCurrentTagGroup()?.id !== tgId) {
        //     return;
        // }

        // console.log('%c[vars] performing actions', 'color: grey;');
        actions.forEach(action => {
            if (action.type === ACTION_TYPE.OBJECT_ACTION) {
                console.log(`%c[vars] logic obj action -- `, action);
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };
                let subObjectNames: string[] = [];

                subObjectNames = (action as ObjectAction).subObjectNames || [];

                switch (objectAction.actionType) {

                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    // case OBJECT_ACTIONS.CALL_ACTIVATE:
                    //     triggerActionOutcome.actionType = ActionType.CallActivate;
                    //     break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if (model) {
                    let localNodeForAction = model.nodeRef;// as ISceneNode;
                    if (isR3F()) {
                        localNodeForAction = model;
                    }
                    // if (objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                    //     localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    // }
                    Behaviors.runActionsOnNode(localNodeForAction, triggerActionOutcome, subObjectNames || []);
                }
            } else if (action.type === ACTION_TYPE.VARIABLE_ACTION) {
                //This throws Error: Reducers may not dispatch actions.", don't know why, but setTimeout fixes it
                //store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: (action as any).varName, value: (action as any).varValue} });
                console.log(`%c[vars] Var Action found in perfActions:`, 'color: grey;', action);
                let variableAction = action as VarAction;

                //This is needed to check if the variable is actually unchanged
                if (variableAction.varName) {
                    let localSystemVariable = variableValues!.find(element => (element.name as string).toLowerCase() === (variableAction.varName as string).toLowerCase());


                    if (localSystemVariable) {
                        console.log(`%cCHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        // setTimeout(() =>
                        store.dispatch(onSetVariableValue(variableAction.varName, variableAction.varValue))
                        // 100);
                        /*
                        if(localSystemVariable.value === variableAction.varValue) {
                            console.log(`%cNO CHANGE: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        } else {
                            setTimeout(()=> store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: variableAction.varName, value: variableAction.varValue } }),
                            100);
                            console.log(`%cCHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        }*/
                    }
                } else {
                    console.log(`%c[st] A variable action was not saved properly`)
                }

            } else if (action.type === ACTION_TYPE.NEXTSTEP_ACTION) {
                let tg = getCurrentTagGroup();

                let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;


                let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`%c[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000, 'color: grey;');

                setTimeout(() => {
                    if (store.getState().layer.presentationMode) {
                        store.dispatch(handleGoToTagGroupByIndex(nextTagGroupIndex));
                    } else {
                        store.dispatch(showMessage("Auto-moving to next step suppresed since you are in Studio mode. Press Next to continue"));
                    }

                }, (nextStepAction.waitInSeconds || 0) * 1000);

            } else if (action.type === ACTION_TYPE.WAIT_ACTION) {
                // console.log(`%c[st] logic sleeping `, 'color: grey;');

                // let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000, 'color: grey;');
                // sleep(3000);
                // let tg = getCurrentTagGroup();

                // let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;
                // store.dispatch(handleGoToTagGroup(nextTagGroupIndex));
            }

        });
    }
}


