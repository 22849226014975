// @ts-ignore
import {
  AnimationAction,
  AnimationClip,
  AnimationMixer,
  BoxGeometry,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  // @ts-ignore
} from 'three';
import { ComponentInteractionType, ISceneNode, SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';

interface Inputs {
  size: { x: number; y: number; z: number };
  color: number;
  visible: boolean;
  opacity: number;
  transitionTime: number;
  hasBorder: boolean;
  lineOpacity: number;
  lineColor: number;
}

const makeMaterialOpacityClip = function (
  THREE: any,
  time: number,
  startOpacity: number,
  endOpacity: number,
): AnimationClip {
  const track = new THREE.NumberKeyframeTrack(
    '.material.opacity',
    [0, time],
    [startOpacity, endOpacity],
  );
  return new THREE.AnimationClip(null, time, [track]);
};

const playAnimation = function (
  THREE: any,
  mixer: AnimationMixer,
  clip: AnimationClip,
  root?: any,
) {
  const action: AnimationAction = mixer.clipAction(clip, root);
  action.loop = THREE.LoopOnce;
  action.clampWhenFinished = true;
  action.play();
};

export class OrientedBox extends SceneComponent {
  private root: Object3D | null = null;
  private box: Mesh | null = null;
  private edges: LineSegments | null = null;
  private boxMixer: AnimationMixer | null = null;
  private clipVisible: AnimationClip | null = null;
  private clipNotVisible: AnimationClip | null = null;
  private edgesClipVisible: AnimationClip | null = null;
  private edgesClipNotVisible: AnimationClip | null = null;

  inputs: Inputs = {
    size: { x: 0.5, y: 0.5, z: 0.5 },
    color: 0xffff00,
    visible: true,
    opacity: 0.4,
    hasBorder: true,
    lineOpacity: 1,
    lineColor: 0xffffff,
    transitionTime: 0.4,

  };

  events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
    [ComponentInteractionType.DRAG]: false,
  };

  onInit() {
    // @ts-ignore
    const THREE = this.context.three;
    this.root = new THREE.Object3D();
    // @ts-ignore
    this.outputs.objectRoot = this.root;
    // @ts-ignore
    this.outputs.collider = this.root;

    this.makeBox();

    // must be done after the box is created
    // @ts-ignore
    this.boxMixer = new THREE.AnimationMixer(this.box);
    this.clipVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      0,
      this.inputs.opacity,
    );
    this.clipNotVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      this.inputs.opacity,
      0,
    );
    this.edgesClipVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      0,
      1,
    );
    this.edgesClipNotVisible = makeMaterialOpacityClip(
      THREE,
      this.inputs.transitionTime,
      1,
      0,
    );
  }

  onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
    if (interactionType === ComponentInteractionType.CLICK) {
      let _tmp_webstorm_ = this.context;
      // @ts-ignore
      // console.log('click on box')
      const { root } = this.context;
      this.notify(ComponentInteractionType.CLICK, {
        type: interactionType,
        node: root,
        component: this,
      });
    }
    if (interactionType === ComponentInteractionType.HOVER) {
      // console.log('hovering on box', this.getNodeUserData()?.nameToShow);
      this.notify(ComponentInteractionType.HOVER, {
        // hover: (<{ hover: boolean }>eventData).hover,
        type: interactionType,
        node: this.context.root,
        component: this,
      });
    }
    // if (interactionType === ComponentInteractionType.DRAG) {
    //   console.log('DRAG on box')
    //   this.notify(ComponentInteractionType.DRAG, {
    //     // drag: (<{ drag: boolean }>eventData).drag,
    //     type: interactionType,
    //     node: this.context.root,
    //     component: this,
    //   });
    // }
    // if (interactionType === ComponentInteractionType.DRAG_BEGIN) {
    //   console.log('drag begin on box')
    //   this.notify(ComponentInteractionType.DRAG_BEGIN, {
    //     type: interactionType,
    //     node: this.context.root,
    //     component: this,
    //   });
    // }
    // if (interactionType === ComponentInteractionType.DRAG_END) {
    //   console.log('drag end on box')
    //   this.notify(ComponentInteractionType.DRAG_END, {
    //     type: interactionType,
    //     node: this.context.root,
    //     component: this,
    //   });
    // }
  }

  makeBox() {
    // @ts-ignore
    const THREE = this.context.three;

    if (this.box) {
      // @ts-ignore
      this.root.remove(this.box);
      (this.box.material as MeshBasicMaterial).dispose();
      (this.box.geometry as BoxGeometry).dispose();
      this.box = null;
    }
    if (this.edges) {
      // @ts-ignore
      this.root.remove(this.edges);
      (this.edges.material as LineBasicMaterial).dispose();
      (this.edges.geometry as EdgesGeometry).dispose();
      this.edges = null;
    }

    const boxGeometry: BoxGeometry = new THREE.BoxGeometry(
      this.inputs.size.x,
      this.inputs.size.y,
      this.inputs.size.z,
    );

    var boxMaterial: MeshBasicMaterial = new THREE.MeshBasicMaterial({
      color: this.inputs.color,
      opacity: this.inputs.opacity,
      depthWrite: false,
    });
    boxMaterial.transparent = true;
    boxMaterial.side = THREE.BackSide;
    boxMaterial.blending = THREE.AdditiveBlending;
    this.box = new THREE.Mesh(boxGeometry, boxMaterial);
    // @ts-ignore
    this.root.add(this.box);

    const edgesGeometry = new THREE.EdgesGeometry(boxGeometry);
    this.edges = new THREE.LineSegments(
      edgesGeometry,
      new THREE.LineBasicMaterial({
        transparent: true,
        color: this.inputs.lineColor,
        linewidth: 1,
        opacity: this.inputs.lineOpacity,
      }),
    );

    // @ts-ignore
    this.root.add(this.edges);
    /*
    // put the edges object directly in the scene graph so that they dont intercept
    // raycasts. The edges object will need to be removed if this component is destroyed.
    // @ts-ignore
    const obj3D = (this.context.root as any).obj3D as Object3D;
    // @ts-ignore
    const worldPos = new this.context.three.Vector3();
    obj3D.getWorldPosition(worldPos);
    this.edges.position.copy(worldPos);
    // @ts-ignore
    this.context.scene.add(this.edges);*/
  }

  onInputsUpdated(oldInputs: Inputs) {
    // @ts-ignore
    const THREE = this.context.three;

    if (oldInputs.visible !== this.inputs.visible) {
      // @ts-ignore
      this.boxMixer.stopAllAction();

      if (this.inputs.visible) {
        // @ts-ignore
        playAnimation(THREE, this.boxMixer, this.clipVisible);
        // @ts-ignore
        playAnimation(THREE, this.boxMixer, this.edgesClipVisible, this.edges);
      } else {
        // @ts-ignore
        playAnimation(THREE, this.boxMixer, this.clipNotVisible);
        // @ts-ignore
        playAnimation(
          THREE,
          // @ts-ignore
          this.boxMixer,
          this.edgesClipNotVisible,
          this.edges,
        );
      }
    }

    if (
      oldInputs.size.x !== this.inputs.size.x ||
      oldInputs.size.y !== this.inputs.size.y ||
      oldInputs.size.z !== this.inputs.size.z
    ) {


      this.makeBox();
      return;
    }

    if (oldInputs.color !== this.inputs.color) {
      // @ts-ignore
      (this.box.material as MeshBasicMaterial).color.set(this.inputs.color);
    }

    if (oldInputs.hasBorder !== this.inputs.hasBorder) {
      // @ts-ignore
      this.edges.visible = this.inputs.hasBorder;
    }

    if (oldInputs.opacity !== this.inputs.opacity) {
      // @ts-ignore
      (this.box.material as MeshBasicMaterial).opacity = this.inputs.opacity;
    }

    if (oldInputs.lineOpacity !== this.inputs.lineOpacity) {
      // @ts-ignore
      (this.edges
        .material as LineBasicMaterial).opacity = this.inputs.lineOpacity;
    }

    if (oldInputs.lineColor !== this.inputs.lineColor) {
      // @ts-ignore
      (this.edges.material as LineBasicMaterial).color = new THREE.Color(
        this.inputs.lineColor,
      );
    }
  }

  onTick(delta: number) {
    super.onTick(delta);
    // @ts-ignore
    this.boxMixer.update(delta * 0.001);
  }
}

export const orientedBoxType = 'mp.highlightBox';
export const makeOrientedBox = function () {
  return new OrientedBox();
};
