import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import NodeStorage from "mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage";
import { MpSdk, Scene } from "types/SdkTypes2";
import { SDKInstance } from "../Sdk";
import { Node } from "./Node";

export class LeverLegacy extends Node {

	public static async makeLegacyLever(mpSdk: MpSdk, sceneObject: Scene.IObject) {

		let l = new LeverLegacy(mpSdk, sceneObject);
		let nodes = await NodeStorage.loadNode({objectOrObjectName: 'Lever Toggle'});
		if (nodes.length > 0){
			l.node = nodes[0];
		} else {
			console.error(' [r3f] error: no nodes could be loaded for legacy lever');
		}
		return l;

	}
	private constructor(mpSdk: MpSdk, sceneObject: Scene.IObject) {
		super(mpSdk, sceneObject);
	}
}
