import {
    BoxGeometry,
    Mesh,
    MeshPhongMaterial,
    Object3D,
    SpotLight,
    SpotLightHelper,
} from 'three';
import { ComponentInteractionType, SceneComponent } from '../SubSystems/sceneManagement/SceneComponent';

interface Inputs {
    size: { x: number; y: number; z: number };
    color: number;
    intensity: number;
    distance: number;
    angle: 0.7;
    penumbra: number;
    decay: number;
}


export class SpotLightComponent extends SceneComponent {
    private root: Object3D | null = null;
    private box: Object3D | null = null;
    private light: SpotLight | null = null;
    private lightHelper: SpotLightHelper | null = null;

    inputs: Inputs = {
        size: { x: 0.2, y: 0.2, z: 0.2 },
        color: 0xff0000,
        intensity: 1,
        distance: 5,
        angle: 0.7,
        penumbra: 0.1,
        decay: 1,
    };

    events = {
        [ComponentInteractionType.CLICK]: true,
        [ComponentInteractionType.HOVER]: false,
        [ComponentInteractionType.DRAG]: false,
    };

    onInit() {
        // @ts-ignore
        const THREE = this.context.three;
        this.root = new THREE.Object3D();
        this.box = new THREE.Object3D();

        // @ts-ignore
        this.outputs.collider = this.box;
        // @ts-ignore
        this.outputs.objectRoot = this.root;


        this.makeBox();
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
        if (interactionType === ComponentInteractionType.CLICK) {
            let _tmp_webstorm_ = this.context;
            // @ts-ignore
            const { root } = this.context;
            this.notify(ComponentInteractionType.CLICK, {
                type: interactionType,
                node: root,
                component: this,
            });
        }
    }

    makeBox() {
        //setting boxcomponent
        {
            const boxGeometry: BoxGeometry = new BoxGeometry(
                this.inputs.size.x,
                this.inputs.size.y,
                this.inputs.size.z,
            );
            const boxMaterial = new MeshPhongMaterial({ color: this.inputs.color });
            const boxMesh = new Mesh(boxGeometry, boxMaterial);

            // @ts-ignore
            this.box.add(boxMesh);
            // @ts-ignore
            // this.root.add(this.box);
        }

        //setting lightcomponent
        {

            this.light = new SpotLight();
            this.light.distance = this.inputs.distance;
            this.light.intensity = this.inputs.intensity;
            this.light.angle = this.inputs.angle;
            this.light.penumbra = this.inputs.penumbra;
            this.light.decay = this.inputs.decay;

            // @ts-ignore
            this.light.position.x = this.root.position.x;
            // @ts-ignore
            this.light.position.y = this.root.position.y;
            // @ts-ignore
            this.light.position.z = this.root.position.z - 0.01;

            // @ts-ignore
            this.light.target = this.box;

            // @ts-ignore
            // this.root.add(this.light)

            // @ts-ignore
            this.root.add(this.light.target)
        }


        //setting light
        {
            // @ts-ignore
            this.lightHelper = new SpotLightHelper(this.light);
            // @ts-ignore
            this.root.add(this.lightHelper)
        }
    }

    onTick(delta: number) {
        super.onTick(delta);

        // @ts-ignore
        this.lightHelper.update();

    }
}
export const spotLightModelType = 'mp.spotlightmodel';
export const makeSpotLigthComponent = function () {
    return new SpotLightComponent();
};
